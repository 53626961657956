import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMessageForwardBody } from 'gcs-common/slices/messages/messageSelector';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import QuotedMessage from '../QuotedMessage';
import styles from './styles.module.scss';
import LinkifyBlank from '../../LinkifyBlank/LinkifyBlank';

const ForwardMessage = ({ messageId }) => {

  const forwardBody = useSelector(getMessageForwardBody(messageId));

  return (
    <>
      <div className={styles.forwardHeader}>
        <IconComponent Icon={ICON.FORWARD} color={ICON_COLOR.LIGHT_GREY} />
        <div className={styles.forwardHeaderText}>
          Weitergeleitet
        </div>
      </div>
      <QuotedMessage messageId={messageId} />
      <div className={styles.txtMsgBody}>
        <LinkifyBlank>{forwardBody}</LinkifyBlank>
      </div>
    </>
  );
};

ForwardMessage.propTypes = { messageId: PropTypes.string.isRequired };

export default ForwardMessage;
