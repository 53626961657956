import CloseIcon from 'gcs-common/img/close.svg?react';
import { useDispatch } from 'react-redux';
import {
  channelForFileSharingSelected, filesShareClosed,
  sharedFilesCleared, fileShareLoadSucceeded,
} from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSlice';
import styles from './styles.module.scss';
import Button from '../../Button/Button';


const FileShareHeader = () => {

  const dispatch = useDispatch();

  const onShareViewClose = () => {
    dispatch(channelForFileSharingSelected({ channelId: '' }));
    dispatch(sharedFilesCleared());
    dispatch(filesShareClosed());
    dispatch(fileShareLoadSucceeded());
  };

  return (
    <div className={styles.shareHeadline}>
      <Button
        className={styles.shareExit}
        onClick={onShareViewClose}
        type="button"
      >
        <CloseIcon alt="Schließen" />
      </Button>
      <h3 className={styles.headline}>Senden an</h3>
    </div>
  );
};


export default FileShareHeader;
