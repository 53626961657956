import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import { captureException } from '@sentry/react';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import downloadFile from './downloadFile';
import { getDownloadFileLoading, getInitiateMessageMediaDownloadLoading } from '../mediaSelectors';

const initiateMessageMediaDownload = createAsyncThunk(
  'media/initiateMessageMediaDownload',
  async ({ messageMediaRelativePath, showError = false }, {
    dispatch,
  }) => {
    try {
      const imageRemoteUrl = `${import.meta.env.VITE_MEDIA_BASE_URL}/${messageMediaRelativePath}`;
      dispatch(downloadFile({ key: messageMediaRelativePath, remoteUrl: imageRemoteUrl }));
    } catch (e) {
      if (showError) {
        dispatch(openErrorDialog({ dialogErrorMsg: getErrorMsg('load_media.default') }));
      }
      if (e.message !== 'Failed to fetch') {
        captureException(e);
      }
      throw e;
    }
  },
  {
    condition({ messageMediaRelativePath }, { getState }) {
      return !getInitiateMessageMediaDownloadLoading(messageMediaRelativePath)(getState())
        && !getDownloadFileLoading(messageMediaRelativePath)(getState());
    },
  },
);

export default initiateMessageMediaDownload;
