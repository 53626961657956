import { Clipboard } from '@capacitor/clipboard';
import { captureException } from '@sentry/react';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';
import { showSimpleNotification } from '../../notification/notificationThunks';

const copyTextToClipboard = ({ text }) => async (dispatch) => {
  if (IS_NATIVE) {
    try {
      await Clipboard.write({ string: text });
      dispatch(showSimpleNotification({ notification: 'Nachricht kopiert' }));
    } catch (e) {
      captureException(e);
    }
  } else {
    await navigator.clipboard.writeText(text);
    dispatch(showSimpleNotification({ notification: 'Nachricht kopiert' }));
  }
};

export default copyTextToClipboard;
