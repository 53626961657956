import { useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteForSelectedChannel } from 'gcs-common/slices/favorites/favoritesSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import { removeFavorite } from 'gcs-common/slices/favorites/favoritesThunks/removeFavorite';
import {
  getCurrentUserId,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getUserName } from 'gcs-common/slices/user/userSelectors';
import { getMessage, getMessageLoaded } from 'gcs-common/slices/messages/messageSelector';
import fetchMessage from 'gcs-common/slices/messages/messagesThunks/fetchMessage';
import { useNavigate } from 'react-router';
import MediaMessage from '../../MediaMessage/MediaMessage';
import styles from './styles.module.scss';
import favoriteIcon from '../../../img/icons/star_full.svg';
import { MESSAGE_TYPE } from '../../../models/MessageTypes';
import AsyncMessageLoader from '../../AsyncMessageLoader/AsyncMessageLoader';
import DeletedMessage from '../../DeletedMessage/DeletedMessage';
import VideoMessage from '../../VideoMessage/VideoMessage';

const FavoriteItem = ({ favoriteId }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedChannelId = useSelector(getSelectedChannelId);

  const favorite = useSelector(getFavoriteForSelectedChannel(favoriteId));
  const currentUserId = useSelector(getCurrentUserId);

  const {
    messageId,
  } = favorite;

  const isMessageLoaded = useSelector(getMessageLoaded(messageId));
  const message = useSelector(getMessage(messageId));

  useEffect(() => {
    if (!isMessageLoaded && messageId) {
      dispatch(fetchMessage({ messageId }));
    }
  }, [dispatch, isMessageLoaded, messageId]);

  const {
    text, forwardedMessageText, index, authorId, dateUpdated, deleted, messageType,
  } = message || {};

  const userName = useSelector(getUserName(authorId));

  const onRemoveFavorite = useCallback(async () => {
    dispatch(removeFavorite({ channelId: selectedChannelId, favoriteId }));
  }, [dispatch, favoriteId, selectedChannelId]);

  const selectFavorite = () => {
    dispatch(scrollToMessageId(
      { channelId: selectedChannelId,
        messageId,
        messageIndex: index },
    ));
    navigate(-1);
  };

  const time = moment(dateUpdated);

  const fromMe = currentUserId === authorId;

  let content = null;

  if (!message) {
    return <></>;
  }

  if (deleted) {
    return <DeletedMessage />;
  }

  if (messageType === MESSAGE_TYPE.MEDIA
    || messageType === MESSAGE_TYPE.MEDIA_FROM_MAIL) {
    content = (
      <AsyncMessageLoader
        messageId={messageId}
        messageIndex={index}
        channelId={selectedChannelId}
      >
        <MediaMessage
          messageId={messageId}
          disableClick
        />
      </AsyncMessageLoader>
    );
  } else if (messageType === MESSAGE_TYPE.VIDEO) {
    content = (
      <AsyncMessageLoader
        channelId={selectedChannelId}
        messageIndex={index}
        messageId={messageId}
      >
        <VideoMessage
          messageId={messageId}
          disableClick
        />
      </AsyncMessageLoader>
    );
  } else {
    content = text || forwardedMessageText;
  }

  return (
    <div
      key={messageId}
      className={`${styles.favoriteWrapper} ${fromMe ? styles.me : ''}`}
    >
      <button
        type="button"
        onClick={onRemoveFavorite}
      >
        <img
          src={favoriteIcon}
          className={styles.favoriteIcon}
          alt="todo-circle"
        />
      </button>
      <button
        type="button"
        onClick={selectFavorite}
        className={styles.favoriteContent}
      >
        <div className={styles.userName}>{userName}</div>
        {content}
        <div className={styles.favoriteDate}>{time.format('LLL')}</div>
      </button>
    </div>
  );
};

FavoriteItem.propTypes = {
  favoriteId: PropTypes.string.isRequired,
};

export default FavoriteItem;
