/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  selectedMessageIds: [],
  showBlockUserModal: false,
  showReportUserModal: false,
  showReportMessageModal: false,
  showSnackbar: false,
  snackbarMessage: null,
  snackbarIcon: null,
  showFilePicker: false,
  showNoGCOPAccountModal: false,
  showRegistrationExitModal: false,
  showFetchProcessesErrorModal: false,
  sideMenuOpen: false,
  settingsMenuOpen: false,
  initialDataLoadSucceeded: false, // Should be persisted,
  // if the user opens the app for the first time,
  // we want to show the spinner for the whole initialData loading
  isAppInForeground: true,
  onScreenKeyboardHeight: undefined,
  houseSwitcherActionSheetOpen: false,
  safeAreaInsets: undefined,
  salesSpaceChannelActionSheetOpen: false,
  showAppMustUpdateDialog: false,
  showAppMustUpdateDialogConfig: {},
  showDeletionConfirmationModal: false,
  videoFullScreenId: undefined,
  videoMessageId: undefined,
  ticketMedia: null,
};

const persistWhitelist = [
  'initialDataLoadSucceeded',
  'onScreenKeyboardHeight',
  'safeAreaInsets',
];

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    showBlockUserModal: (state) => {
      state.showBlockUserModal = true;
    },
    hideBlockUserModal: (state) => {
      state.showBlockUserModal = false;
    },
    showReportUserModal: (state) => {
      state.showReportUserModal = true;
    },
    hideReportUserModal: (state) => {
      state.showReportUserModal = false;
    },
    showReportMessageModal: (state) => {
      state.showReportMessageModal = true;
    },
    hideReportMessageModal: (state) => {
      state.showReportMessageModal = false;
    },
    showSnackbar: (state, action) => {
      const { message, icon } = action.payload;
      state.snackbarMessage = message;
      state.snackbarIcon = icon;
      state.showSnackbar = true;
    },
    hideSnackbar: (state) => {
      state.showSnackbar = false;
    },
    showFilePicker: (state) => {
      state.showFilePicker = true;
    },
    hideFilePicker: (state) => {
      state.showFilePicker = false;
    },
    setOnScreenKeyboardHeight: (state, action) => {
      state.onScreenKeyboardHeight = action.payload;
    },
    addSelectedMessageIds: (state, action) => {
      const { messageId } = action.payload;
      state.selectedMessageIds = [...state.selectedMessageIds, messageId];
    },
    removeSelectedMessageIds: (state, action) => {
      const { messageId } = action.payload;
      state.selectedMessageIds = state.selectedMessageIds
        .filter(id => id !== messageId);
    },
    resetSelectedMessageIds: (state) => {
      state.selectedMessageIds = [];
    },
    showNoGCOPAccountModalToggled: (state) => {
      state.showNoGCOPAccountModal = !state.showNoGCOPAccountModal;
    },
    showRegistrationExitModal: (state) => {
      state.showRegistrationExitModal = true;
    },
    hideRegistrationExitModal: (state) => {
      state.showRegistrationExitModal = false;
    },
    showFetchProcessesErrorModal: (state) => {
      state.showFetchProcessesErrorModal = true;
    },
    hideFetchProcessesErrorModal: (state) => {
      state.showFetchProcessesErrorModal = false;
    },
    setSideMenuClosed: (state) => {
      state.sideMenuOpen = false;
    },
    setSideMenuOpen: (state) => {
      state.sideMenuOpen = true;
    },
    setSettingsMenuClosed: (state) => {
      state.settingsMenuOpen = false;
    },
    setSettingsMenuOpen: (state) => {
      state.settingsMenuOpen = true;
    },
    openAppMustUpdateDialog: (state, action) => {
      const { config } = action.payload;
      state.showAppMustUpdateDialog = true;
      state.showAppMustUpdateDialogConfig = config;
    },
    setLoadInitialDataSucceeded: (state) => {
      state.initialDataLoadSucceeded = true;
    },
    appResumed: (state) => {
      state.isAppInForeground = true;
    },
    appPaused: (state) => {
      state.isAppInForeground = false;
    },
    setHouseSwitcherActionSheetClose: (state) => {
      state.houseSwitcherActionSheetOpen = false;
    },
    setHouseSwitcherActionSheetOpen: (state) => {
      state.houseSwitcherActionSheetOpen = true;
    },
    setSalesSpaceChannelActionSheetClose: (state) => {
      state.salesSpaceChannelActionSheetOpen = false;
    },
    setSalesSpaceChannelActionSheetOpen: (state) => {
      state.salesSpaceChannelActionSheetOpen = true;
    },
    openDeletionConfirmationModal: (state) => {
      state.showDeletionConfirmationModal = true;
    },
    closeDeletionConfirmationModal: (state) => {
      state.showDeletionConfirmationModal = false;
    },
    openVideoFullscreen: (state, action) => {
      const { videoId } = action.payload;
      const { videoMessageId } = action.payload;
      state.videoFullScreenId = videoId;
      state.videoMessageId = videoMessageId;
    },
    closeVideoFullscreen: (state) => {
      state.videoFullScreenId = undefined;
      state.videoMessageId = undefined;
    },
    setSafeAreaInsets: (state, action) => {
      state.safeAreaInsets = action.payload;
    },
    openTicketMediaFullScreen: (state, action) => {
      state.ticketMedia = action.payload;
    },
    closeTicketMediaFullScreen: (state) => {
      state.ticketMedia = null;
    },
  },
});

export { persistWhitelist as uiStatePersistWhitelist };

export const {
  showBlockUserModal,
  hideBlockUserModal,
  showReportUserModal,
  hideReportUserModal,
  showReportMessageModal,
  hideReportMessageModal,
  showSnackbar,
  hideSnackbar,
  showFilePicker,
  hideFilePicker,
  setOnScreenKeyboardHeight,
  addSelectedMessageIds,
  removeSelectedMessageIds,
  resetSelectedMessageIds,
  showNoGCOPAccountModalToggled,
  showFetchProcessesErrorModal,
  hideFetchProcessesErrorModal,
  setSideMenuClosed,
  setSideMenuOpen,
  setSettingsMenuClosed,
  setSettingsMenuOpen,
  setLoadInitialDataSucceeded,
  showRegistrationExitModal,
  hideRegistrationExitModal,
  appResumed,
  appPaused,
  setHouseSwitcherActionSheetClose,
  setHouseSwitcherActionSheetOpen,
  setSalesSpaceChannelActionSheetClose,
  setSalesSpaceChannelActionSheetOpen,
  openAppMustUpdateDialog,
  openDeletionConfirmationModal,
  closeDeletionConfirmationModal,
  openVideoFullscreen,
  closeVideoFullscreen,
  setSafeAreaInsets,
  openTicketMediaFullScreen,
  closeTicketMediaFullScreen,
} = uiStateSlice.actions;

export default uiStateSlice.reducer;
