import { captureException } from '@sentry/react';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';


const clearReduxStateMigration = async (state) => {
  const newState = { ...state };
  try {
    if (IS_NATIVE) {
      await Promise.all(Object.entries(newState).map(async ([key]) => {
        if (key !== 'auth') {
          delete newState[key];
        }
      }));
    }
  } catch (e) {
    captureException(e);
  }
  return newState;
};

export default clearReduxStateMigration;
