import { Toast } from '@capacitor/toast';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';

export const showSimpleNotification = ({ notification }) => async () => {
  if (IS_NATIVE) {
    await Toast.show({ text: notification, duration: 'long' });
  } else {
    debugLogger(`Would have show notification: ${notification}`);
  }
};
