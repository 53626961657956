/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isKeyboardVisible: false,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setKeyboardVisible: (state) => {
      state.isKeyboardVisible = true;
    },
    setKeyboardNotVisible: (state) => {
      state.isKeyboardVisible = false;
    },
  },
});

export const {
  setKeyboardVisible,
  setKeyboardNotVisible,
} = deviceSlice.actions;

export default deviceSlice.reducer;
