import { ICON_SIZE } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import styles from './styles.module.scss';

const VideoProcessing = () => {
  return (
    <div className={styles.videoMessageContainer}>
      <div className={styles.loader}>
        <div className={styles.icon}>
          <Spinner size={ICON_SIZE.X_LARGE} />
        </div>
      </div>
      <p>Video In Bearbeitung</p>
    </div>
  );

};

export default VideoProcessing;

VideoProcessing.propTypes = {
};
