/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchProcessesFilterSuggestionsData } from '../../clients/api/entities/processes/processes-api-helpers';

export interface ProcessesState {
  searchInput: string,
  filter: { type: FetchProcessesFilterSuggestionsData[number]['Type'], name: string } | null;
  selectedProcessNumber: string | undefined;
  selectedProductNumber: string | undefined;
}

// Note: State is legacy
const initialState: ProcessesState = {
  searchInput: '',
  filter: null,
  selectedProcessNumber: undefined,
  selectedProductNumber: undefined,
};

const processesSlice = createSlice({
  name: 'processes',
  initialState,
  reducers: {
    resetProcessFilter: (state) => {
      state.filter = null;
      state.searchInput = '';
    },
    suggestionSelected: (state, action: PayloadAction<{
      suggestion: FetchProcessesFilterSuggestionsData[number]
    }
    >) => {
      const { suggestion } = action.payload;
      const filter = { type: suggestion.Type, name: suggestion.Suggestion };
      state.searchInput = '';
      state.filter = filter;
    },
    searchInputUpdated: (state, action: PayloadAction<{ searchInput: string }>) => {
      const { searchInput } = action.payload;
      state.searchInput = searchInput;
    },
    processSelected: (state, action: PayloadAction<{
      selectedProcessNumber: string | undefined
    }>) => {
      const { selectedProcessNumber } = action.payload;
      state.selectedProcessNumber = selectedProcessNumber;
    },
    productSelected: (state, action: PayloadAction<{
      selectedProductNumber: string | undefined
    }>) => {
      const { selectedProductNumber } = action.payload;
      state.selectedProductNumber = selectedProductNumber;
    },
  },
});

export const {
  suggestionSelected,
  productSelected,
  searchInputUpdated,
  processSelected,
  resetProcessFilter,
} = processesSlice.actions;

export default processesSlice.reducer;
