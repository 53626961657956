import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import changeSelectedChannelId from 'gcs-common/slices/channels/channelsThunks/changeSelectedChannelId';
import {
  getChannelImage,
  getChannelStatus,
  getChannelSubtitle,
  getChannelType,
  getIsHidden,
  getIsSalesSpaceChannelType,
  getIsSelectedChannel,
  getOtherMemberTyping,
  getChannelMemberCount,
  getChannelTitle,
} from 'gcs-common/slices/channel/channelSelectors';
import {
  getUnreadMessageCountForChannel,
} from 'gcs-common/slices/channels/unreadMessagesSelectors';
import { getSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import { setSideMenuClosed } from '../../slices/uiState/uiStateSlice';
import ChannelListItemNormal from '../ChannelListItemNormal/ChannelListItemNormal';
import { sendSharedFilesToChannel } from '../../helper/sendSharedFilesToChannelHelper';

const ChannelListItemNormalWithId = ({ channelId }) => {
  const isSelectedChannel = useSelector(getIsSelectedChannel(channelId));
  const unreadCount = useSelector(getUnreadMessageCountForChannel(channelId));
  const otherUserTyping = useSelector(getOtherMemberTyping(channelId));
  const channelType = useSelector(getChannelType(channelId));
  const channelImage = useSelector(getChannelImage(channelId));
  const channelStatus = useSelector(getChannelStatus(channelId));
  const channelTitle = useSelector(getChannelTitle(channelId));
  const channelSubtitle = useSelector(getChannelSubtitle(channelId));
  const isSalesSpace = useSelector(getIsSalesSpaceChannelType(channelId));
  const channelMemberCount = useSelector(getChannelMemberCount(channelId));
  const isHidden = useSelector(getIsHidden(channelId));

  const sharedFiles = useSelector(getSharedFiles);
  const isChannelsListItemForFileSharing = sharedFiles.length;

  const dispatch = useDispatch();

  const selectChannel = useCallback(() => {
    if (!isChannelsListItemForFileSharing) {
      dispatch(changeSelectedChannelId({ selectedChannelId: channelId }));
      dispatch(setSideMenuClosed());
    } else {
      sendSharedFilesToChannel(sharedFiles, channelId, dispatch);
    }
  }, [channelId, dispatch, sharedFiles, isChannelsListItemForFileSharing]);

  return (
    <ChannelListItemNormal
      isSelectedChannel={isSelectedChannel}
      unreadCount={unreadCount}
      otherUserTyping={otherUserTyping}
      channelType={channelType}
      channelImage={channelImage}
      channelStatus={channelStatus}
      channelTitle={channelTitle}
      channelSubtitle={channelSubtitle}
      onChannelSelect={selectChannel}
      isSalesSpace={isSalesSpace}
      channelMemberCount={channelMemberCount}
      channelId={channelId}
      isHidden={isHidden}
    />
  );
};

ChannelListItemNormalWithId.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelListItemNormalWithId;
