import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { currentUserIdSelected } from 'gcs-common/slices/memberDetails/memberDetailsSlice';
import {
  getMemberDetailsSelectedChannelId,
} from 'gcs-common/slices/memberDetails/memberDetailsSelectors';
import {
  getIsSalesSpaceChannelType,
} from 'gcs-common/slices/channel/channelSelectors';
import { getFavicon } from 'gcs-common/slices/theme/themeSelectors';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import Page from '../Page/Page';
import ChannelDetails from '../ChannelDetails/ChannelDetails';

const ChannelProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserId = useSelector(getCurrentUserId);
  const selectedChannelId = useSelector(getMemberDetailsSelectedChannelId);
  const isSalesSpaceChannel = useSelector(getIsSalesSpaceChannelType(selectedChannelId));
  const defaultLogo = useSelector(getFavicon);

  const channelInfoTitle = isSalesSpaceChannel ? 'Teamchatinfo' : 'Gruppeninfo';

  const back = () => {
    navigate(-1);
  };

  const openUserProfile = useCallback(({ userId }) => {
    if (currentUserId === userId) return;

    dispatch(currentUserIdSelected({ userId, channelId: selectedChannelId }));
    navigate(CHAT_ROUTES.USER_PROFILE(selectedChannelId));
  }, [currentUserId, dispatch, selectedChannelId, navigate]);

  return (
    <Page className={styles.main}>
      <div className={styles.top}>
        <button
          type="button"
          onClick={back}
          className={styles.back}
        >
          <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.ROTATE_180} />
        </button>
        <div className={styles.title}>{channelInfoTitle}</div>
      </div>
      <div className={styles.body}>
        <ChannelDetails onSelectMember={openUserProfile} logoFallbackSrc={defaultLogo} />
      </div>
    </Page>
  );
};

export default ChannelProfile;
