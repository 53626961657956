import { FetchProcessDetailsResponseData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import styles from './styles.module.scss';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

interface ProductSelectedDisplayProps {
  product: FetchProcessDetailsResponseData['items'][number];
}

const ProductSelectedDisplay = (props: ProductSelectedDisplayProps) => {
  const { product } = props;
  return (
    <>
      <div className={styles.productArticleNumber}>
        {product.ArticleNumber}
      </div>
      <div className={styles.productDescription}>
        <div>{product.ArticleDescription1}</div>
        <div>{product.ArticleDescription2}</div>
        {/* <ProductQuantity
          product={product}
        /> */}
      </div>
    </>
  );
};

export default ProductSelectedDisplay;
