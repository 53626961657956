import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedChannelOrder,
  setUnreadMessagesFilterSelected,
} from 'gcs-common/slices/channelList/channelListSlice';
import { ChannelOrder } from 'gcs-common/slices/channelList/channelSorter';
import { getSelectedChannelOrder } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelOrder';
import { getUnreadMessagesFilterSelected } from 'gcs-common/slices/channelList/channelListSelectors/getUnreadMessagesFilterSelected';
import {
  UnreadMessagesFilter,
} from 'gcs-common/helper/channelOrderingHelper';
import unreadIndicatorIcon from 'gcs-common/img/unread_filter_indicator.svg';
import closeIcon from 'gcs-common/img/close_filter.svg';
import styles from './styles.module.scss';
import PopupMenuWithSections from '../../../PopupMenuWithSections/PopupMenuWithSections';
import channelSortIcon from '../../../../img/icons/sort.svg';

function DirectChatChannelSort() {
  const [showMenu, setShowMenu] = useState(false);

  const selectedChannelOrder = useSelector(getSelectedChannelOrder);
  const unreadMessagesFilterSelected = useSelector(getUnreadMessagesFilterSelected);

  const dispatch = useDispatch();

  const updateSelectedChannelOrder = useCallback((newChannelOrder) => {
    dispatch(setSelectedChannelOrder({ selectedChannelOrder: newChannelOrder }));
  }, [dispatch]);

  const onToggleUnreadMessagesFilter = useCallback(() => {
    if (unreadMessagesFilterSelected === UnreadMessagesFilter.UNREAD) {
      dispatch(setUnreadMessagesFilterSelected(
        { unreadMessagesFilterSelected: UnreadMessagesFilter.ALL },
      ));
    } else {
      dispatch(setUnreadMessagesFilterSelected(
        { unreadMessagesFilterSelected: UnreadMessagesFilter.UNREAD },
      ));
    }
  }, [dispatch, unreadMessagesFilterSelected]);

  const allSortOptions = useMemo(() => Object.values(ChannelOrder), []);

  const onIndicatorClick = () => {
    if (showMenu) {
      setShowMenu(false);
    }
    onToggleUnreadMessagesFilter();
  };

  const menuSections = [{
    header: 'Sortieren',
    options: allSortOptions,
    selectionCallback: updateSelectedChannelOrder,
    selectedOption: selectedChannelOrder,
  },
  {
    header: 'Filtern nach',
    // only show one option because it's a toggle
    options: [UnreadMessagesFilter.UNREAD],
    selectionCallback: onToggleUnreadMessagesFilter,
    selectedOption: unreadMessagesFilterSelected,
  },
  ];

  return (
    <div
      className={styles.channelSortWrapper}
    >
      {unreadMessagesFilterSelected === UnreadMessagesFilter.UNREAD
              && (
              <div className={styles.indicatorWrapper} tabIndex={0} role="button" onClick={onIndicatorClick}>
                <div className={styles.filterActiveIndicator}>
                  <img alt="Ungelesen-Filter aktiv" className={styles.channelSortIcon} src={unreadIndicatorIcon} />
                  <img alt="Schließen" src={closeIcon} />
                </div>
              </div>
              )
          }
      <div
        className={styles.channelSort}
      >
        <div
          tabIndex={0}
          role="button"
          onKeyUp={() => setShowMenu(!showMenu)}
          onClick={() => setShowMenu(!showMenu)}
          className={styles.channelTextWrapper}
        >
          <span className={styles.channelSortText}>
            {selectedChannelOrder}
          </span>
          <img alt="Kontakte Sortieren" className={styles.channelSortIcon} src={channelSortIcon} />
        </div>
        <PopupMenuWithSections sections={menuSections} onHide={setShowMenu} isVisible={showMenu} />
      </div>
    </div>
  );
}

DirectChatChannelSort.propTypes = {};

export default DirectChatChannelSort;
