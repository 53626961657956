import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const NewChannelErrorMessage = ({ onShowMore }) => (
  <div className={styles.newChannelOptionsErrorMessageWrapper}>
    <p className={styles.newChannelOptionsErrorMessage}>
      Fehler:
      <button type="button" className={styles.newChannelOptionsTryAgain} onClick={onShowMore}>
        Bitte tippen Sie hier
      </button>
      und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an
      <a href="mailto:support@getbeem.de" className={styles.newChannelOptionsSupport}>
        support@getbeem.de
      </a>
      .
    </p>
  </div>
);

NewChannelErrorMessage.propTypes = {
  onShowMore: PropTypes.func.isRequired,
};

export default NewChannelErrorMessage;
