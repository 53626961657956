/* eslint-disable max-len */
import { createSelector } from 'reselect';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { TicketsState } from './ticketsSlice';

const getTicketsState = (state: { tickets: TicketsState }) => state.tickets || {};

export const getTicketsFilters = createSelector(
  getTicketsState,
  (ticketsState) => ticketsState.filters,
);

const getTicketViewState = (channelId: string | undefined) => createSelector(
  getTicketsState,
  (ticketsState) => ticketsState.ticketView[channelId || ''],
);

export const getTicketView = (channelId: string) => createSelector(
  getTicketViewState(channelId),
  (ticketViewState) => ticketViewState?.view,
);

export const getTicketFormDraft = (channelOrTicketId: string | undefined) => createSelector(
  getTicketsState,
  (ticketsState) => ticketsState.drafts[channelOrTicketId || ''],
);

export const getChannelTicketFormPrefill = (channelId: string) => createSelector(
  getTicketViewState(channelId),
  (ticketViewState) => (ticketViewState ? ticketViewState.prefill : undefined),
);

export const getSelectedTicketId = (channelId?: string | undefined) => createSelector(
  getTicketViewState(channelId),
  (ticketViewState) => ticketViewState?.ticketId ?? '',
);

export const getTicketNotificationsCount = () => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.ticketNotifications.length,
);

// TODO remove later when feasible
// Currently coupled with other redux selectors and cannot use react-query
export const getChannelNotificationDates = createSelector(
  [getTicketsState],
  ticketState => {
    const channelNotificationDate: { [key: string]: string } = {}; // { channel_id: dateStr};
    if (ticketState.ticketNotifications) {
      ticketState.ticketNotifications.forEach(notification => {
        const { channelId, updatedAt } = notification;
        if (channelId) {
          if (channelNotificationDate[channelId]) {
            const latestDate = moment.max(moment(channelNotificationDate[channelId]), moment(updatedAt));
            channelNotificationDate[channelId] = latestDate.toISOString();
          } else {
            channelNotificationDate[channelId] = moment(updatedAt).toISOString();
          }
        }
      });
    }
    return channelNotificationDate;
  },
);

export const getDashboardSelectedTicketId = () => createSelector(
  getTicketsState,
  (ticketsState) => ticketsState.dashboard.selectedTicketId,
);

export const getTicketFirstTimeView = (channelId?: string | undefined) => createSelector(
  [getTicketViewState(channelId)],
  (ticketViewState) => (!!(ticketViewState && ticketViewState.firstTime)),
);
