import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import { cn } from '../utils/tailwind-merge';

const buttonVariants = cva(
  'box-border flex items-center justify-center rounded-md p-1 text-center text-sm font-medium transition-colors aria-disabled:pointer-events-none aria-disabled:bg-neutral-alpha-3 aria-disabled:text-neutral-alpha-8 aria-disabled:opacity-50',
  {
    variants: {
      variant: {
        solid_accent: ' bg-accent-9 text-white hover:bg-accent-10 active:bg-accent-10',
        solid_neutral: ' bg-neutral-9 text-white hover:bg-neutral-10 active:bg-neutral-10',
        solid_error: ' bg-error-9 text-white hover:bg-error-10 active:bg-error-10',
        soft_accent: 'bg-accent-alpha-3 text-accent-alpha-11 hover:bg-accent-alpha-4 active:bg-accent-alpha-5',
        soft_neutral: 'bg-neutral-alpha-3 text-neutral-alpha-11 hover:bg-neutral-alpha-4 active:bg-neutral-alpha-5',
        soft_error: 'bg-error-3 text-error-alpha-11 hover:bg-error-4 active:bg-error-5',
        surface_accent:
          'border border-accent-alpha-7 bg-accent-alpha-3 text-accent-alpha-11 hover:border-accent-alpha-8 active:border-accent-alpha-8',
        surface_neutral:
          'border border-neutral-alpha-7  text-neutral-11 hover:border-neutral-alpha-8 active:border-neutral-alpha-8 active:bg-neutral-alpha-3',
        surface_error:
          'border border-error-alpha-7  text-error-11 hover:border-error-alpha-8 active:border-error-alpha-8 active:bg-error-alpha-3',
        ghost_accent: 'text-accent-alpha-11  hover:bg-accent-alpha-3 active:bg-accent-alpha-4',
        ghost_neutral: 'text-neutral-11 hover:bg-neutral-alpha-3 active:bg-neutral-alpha-4',
        ghost_error: ' bg-white text-error-11 hover:bg-error-alpha-3 active:bg-error-alpha-4',
      },
      size: {
        xs: 'rounded-[3px] text-xs',
        sm: 'rounded-[3px] text-base',
        m: 'size-8 rounded-lg',
        base: 'rounded-md',
        l: 'rounded-md text-3xl',
      },
    },
    defaultVariants: {
      variant: 'surface_neutral',
      size: 'base',
    },
  },
);

export interface IconButtonProps extends Partial<LinkProps>, VariantProps<typeof buttonVariants> {
  Icon?: React.ElementType;
  isLoading?: boolean;
  disabled?: boolean;
  iconSize?: string;
}

const IconButton = React.forwardRef<HTMLAnchorElement, IconButtonProps>(
  ({
    className, variant, size, Icon, isLoading, disabled = false, to, iconSize = '', ...props
  }, ref) => {
    const ButtonIcon = () => {
      if (isLoading) {
        return (
          <div className="bg-transparent size-4 animate-spin rounded-full border-2 border-neutral-5 border-t-neutral-8" />
        );
      }
      if (Icon) return <Icon className={`pointer-events-none ${iconSize}`} />;

      return null;
    };

    if (!to) {
      return (
        <a className={cn(buttonVariants({ variant, size, className }))} {...props} ref={ref} aria-disabled={disabled}>
          <ButtonIcon />
        </a>
      );
    }

    return (
      <Link
        to={to}
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
        ref={ref}
        aria-disabled={disabled}
      >
        <ButtonIcon />
      </Link>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
