import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

class MessageLikeTextBox extends Component {

  render() {
    const {
      className = '',
      right = false,
      children,
      width = '100%',
      noMargin = false,
      padding = '20px',
    } = this.props;
    return (
      <div
        style={{ maxWidth: width, padding }}
        className={`${styles.messageLookalike} ${className} ${right ? styles.right : ''} ${noMargin ? styles.noMargin : ''}`}
      >
        {children}
      </div>
    );
  }
}

export default MessageLikeTextBox;

MessageLikeTextBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  right: PropTypes.bool,
  noMargin: PropTypes.bool,
  padding: PropTypes.string,
};
