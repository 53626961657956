/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCraftsmanConnections } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import { getJoinedHousesIds } from 'gcs-common/slices/houses/housesSelectors';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { HouseConnections } from '../../../HouseConnections/HouseConnections';
import styles from './styles.module.scss';

const CraftsmanConnectionsOverview = () => {
  const joinedHouseIds = useSelector(getJoinedHousesIds);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchCraftsmanConnections());
  }, []);

  const addConnection = useCallback(
    () => {
      navigate('/craftsmanAccounts/add');
    },
    [dispatch],
  );

  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button type="button" onClick={onGoBack} className={styles.back}>
          <IconComponent
            Icon={ICON.ARROW}
            transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
            alt="Zurück"
            style={{ position: 'absolute', left: '20px' }}
          />
          <div className={styles.headline}>Verknüpfungen bearbeiten</div>
        </button>
      </div>
      <div className={styles.body}>
        {joinedHouseIds.map(
          (houseId) => <HouseConnections key={houseId} houseId={houseId} />,
        )}

        <button className={styles.btn} onClick={addConnection} type="button">
          Verknüpfung hinzufügen
        </button>
      </div>

    </div>
  );
};

export default CraftsmanConnectionsOverview;
