import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getUnreadMessageCountForChannel } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import UnreadCountDisplay from '../Chat/UnreadCountDisplay';
import styles from './styles.module.scss';
import { useCurrentlyRenderedVirtuosoData } from '../../hooks/useCurrentlyRenderedVirtuosoData';

const NewMessageIndicator = ({ messageId }) => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const unreadCount = useSelector(getUnreadMessageCountForChannel(selectedChannelId));
  const message = useCurrentlyRenderedVirtuosoData({ messageId });

  if (!message?.showUnreadNumber) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.line} />
      <div className={styles.text}>
        <UnreadCountDisplay unreadCount={unreadCount} />
        {' '}
        NEUE
        {' '}
        {unreadCount === 1 ? 'NACHRICHT' : 'NACHRICHTEN'}
      </div>
    </div>
  );

};

NewMessageIndicator.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default NewMessageIndicator;
