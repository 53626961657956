/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/await-thenable */
import { ReactEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { friendlyTimeOrDate } from 'gcs-common/helper/dateHelper';
import brokenImage from 'gcs-common/img/broken_image.svg';
import { calculateMediaIconFromType, mimeTypeIsImage } from 'gcs-common/helper/mediaHelper';
import { ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { MediaMessage } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import useDownloadETSMedia from '../../../../hooks/useDownloadETSMedia';
import openEtsFile from '../../../../slices/media/mediaThunks/openEtsFile';

interface TicketMessageMediaProps extends MediaMessage {
  ticketId: string;
}

const TicketMessageMedia = (props: TicketMessageMediaProps) => {
  const {
    ticketId, id, fromBeem, thumbnailUrl, createdAt, name, mimeType, url,
  } = props;
  const { localKey } = useDownloadETSMedia({
    ticketId, title: name, attachmentId: id, attachmentUrl: url,
  });
  const mediaIcon = calculateMediaIconFromType(mimeType);

  const handleImageError: ReactEventHandler<HTMLImageElement> = (e) => {
    e.currentTarget.src = brokenImage;
  };
  const dispatch = useDispatch();

  const onOpenFile = useCallback(async () => {
    // @ts-expect-error future files migration
    await dispatch(openEtsFile({ localKey, contentType: mimeType }));
  }, [dispatch, localKey, mimeType]);

  return (
    <div className={`${styles.message} ${fromBeem ? styles.me : ''}`}>
      <div className={styles.commentMsg}>
        {mimeTypeIsImage(mimeType)
          ? (
            <button
              onClick={onOpenFile}
              type="button"
            >
              <img
                alt={name}
                src={thumbnailUrl}
                onError={handleImageError}
              />
            </button>
          ) : (
            <button
              onClick={onOpenFile}
              type="button"
            >
              <div className={styles.mediaIconWrapper}>
                <div className={styles.mediaIcon}>
                  <IconComponent
                    Icon={mediaIcon}
                    color={ICON_COLOR.DARK_GREY}
                    size={ICON_SIZE.X_LARGE}
                  />
                </div>
                <div className={styles.mediaTitle}>
                  <span>{name}</span>
                </div>
              </div>
            </button>
          )}
        <div className={styles.msgFooter}>
          <span>{friendlyTimeOrDate(createdAt)}</span>
        </div>
      </div>
    </div>
  );
};

export default TicketMessageMedia;
