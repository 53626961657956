import { useEffect } from 'react';
import reverseIcon from 'gcs-common/img/revert_3s.svg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { getShowSnackbar, getSnackbarMessage, getSnackbarIcon } from '../../slices/uiState/uiStateSelectors';
import { hideSnackbar } from '../../slices/uiState/uiStateSlice';

const Snackbar = () => {

  const dispatch = useDispatch();
  const isVisible = useSelector(getShowSnackbar);
  const iconName = useSelector(getSnackbarIcon);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => dispatch(hideSnackbar()), 6000);
    }
  }, [dispatch, isVisible]);

  const text = useSelector(getSnackbarMessage);
  const onClick = () => {
    dispatch(hideSnackbar());
  };
  return isVisible ? (
    <div role="button" tabIndex={0} onClick={onClick} className={!iconName ? styles.snackbarNoIcon : styles.snackbarWithIcon}>
      {text}
      {iconName === 'reverse' && <img src={reverseIcon} className={styles.reverseIcon} alt="reverse" />}
    </div>
  ) : null;
};

export default Snackbar;
