import PropTypes from 'prop-types';

import ZoomWrapper from 'gcs-common/components/ZoomWrapper/ZoomWrapper';
import { useSelector } from 'react-redux';
import {
  getMessageBody,
} from 'gcs-common/slices/messages/messageSelector';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import useDownloadMessageMedia from '../../../hooks/useDownloadMessageMedia';

import LocalImage from '../../LocalImage/LocalImage';

function GalleryImage({ messageId }) {

  const body = useSelector(getMessageBody(messageId));

  const { loading, localKey, download } = useDownloadMessageMedia({ messageId });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (!localKey) {
    return (
      <div className={styles.errorWrapper}>
        <div className={styles.errorText}>Fehler beim Laden des Bildes</div>
        <button type="button" onClick={download}>
          <IconComponent Icon={ICON.REFRESH} color={ICON_COLOR.WHITE} style={{ width: '120px', height: '120px' }} />
        </button>
      </div>
    );
  }

  return (
    <>
      <ZoomWrapper>
        <LocalImage
          alt="Bild aus Nachricht"
          localKey={localKey}
          className={styles.img}
        />
      </ZoomWrapper>
      {
        body && (
          <div className={styles.messageText}>
            {body}
          </div>
        )
      }
    </>
  );
}

GalleryImage.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default GalleryImage;
