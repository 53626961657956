import { useDispatch, useSelector } from 'react-redux';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { getHasFeatureFlag, getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getChannelOPUserId, getPrimaryHouseForChannel } from 'gcs-common/slices/channel/channelSelectors';
import { getFilter } from 'gcs-common/slices/processes/processesSelectors';
import { useGetProcesses } from 'gcs-common/clients/api/entities/processes/processes-api';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { useMemo } from 'react';
import { hideFetchProcessesErrorModal } from '../../slices/uiState/uiStateSlice';
import InfoDialog from '../InfoDialog/InfoDialog';
import styles from './styles.module.scss';
import { getshowFetchProcessesErrorModal } from '../../slices/uiState/uiStateSelectors';

const OpErrorModal = () => {

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelOpUserId: string = useSelector(getChannelOPUserId(selectedChannelId));
  const filter = useSelector(getFilter);
  const { name: queryString, type: queryType } = filter || {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getPrimaryHouseForChannel(selectedChannelId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const showErrorModal: boolean = useSelector(getshowFetchProcessesErrorModal);

  const {
    error,
  } = useGetProcesses(
    {
      query: {
        query_type: queryType,
        query_string: queryString,
        user_id: isAgent ? channelOpUserId : undefined,
        house_id: houseId,
      },
    },
  );

  const processesFetchErrorMessage = useMemo(() => {
    if (!error) return '';
    const {
      message: errorMessage,
    } = error;

    return errorMessage || '';
  }, [error]);

  const shouldShowErrorModal = !!processesFetchErrorMessage && showErrorModal;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isOpMaintenanceEnabled: boolean = useSelector(
    getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE),
  );

  const closeModal = () => dispatch(hideFetchProcessesErrorModal());

  return (
    <InfoDialog
      title="ONLINE PLUS Account"
      isOpen={shouldShowErrorModal}
      onClose={closeModal}
    >
      <p className={styles.errorMessageText}>
        {isOpMaintenanceEnabled ? opMaintenanceMessage : processesFetchErrorMessage}
      </p>
    </InfoDialog>
  );
};

export default OpErrorModal;
