import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import FileThumbnail from '../FileThumbnail/FileThumbnail';
import styles from './styles.module.scss';

const ThumbnailPreview = ({
  files = [], currentIndex = 0, loading = false, onClick, onRemoveImage,
}) => {
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (currentIndex) {
      fileInputRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'start',
      });
    }
  }, [files, currentIndex]);

  const imagePreviews = useMemo(() => {
    return files && files.map((file, index) => (
      <FileThumbnail
        loading={loading}
        file={file}
        key={file.name}
        alt="thumb-img-preview"
        selected={index === currentIndex}
        onClose={onRemoveImage}
        onClick={() => onClick(index)}
      />
    ));
  }, [files, currentIndex, loading, onClick, onRemoveImage]);

  return (
    <div className={styles.imagePickerBottom} ref={fileInputRef}>
      {imagePreviews}
    </div>
  );
};

ThumbnailPreview.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  currentIndex: PropTypes.number,
  loading: PropTypes.bool,
  onRemoveImage: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ThumbnailPreview;
