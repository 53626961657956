import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '../utils/tailwind-merge';
import LoadingSpinner from './loading-spinner';

export enum ButtonVariant {
  // soft
  soft_accent = 'soft_accent',
  soft_neutral = 'soft_neutral',
  soft_error = 'soft_error',
  // surface
  surface_accent = 'surface_accent',
  surface_neutral = 'surface_neutral',
  surface_error = 'surface_error',
  // ghost
  ghost_accent = 'ghost_accent',
  ghost_neutral = 'ghost_neutral',
  ghost_error = 'ghost_error',
  // solid
  solid_accent = 'solid_accent',
  solid_neutral = 'solid_neutral',
  solid_error = 'solid_error',
  // outline
  outline_accent = 'outline_accent',
}


export enum ButtonSize {
  base = 'base',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
}

const ButtonVariantTextColor = {
  [ButtonVariant.soft_accent]: 'text-accent-alpha-11',
  [ButtonVariant.soft_neutral]: 'text-neutral-alpha-11',
  [ButtonVariant.soft_error]: 'text-error-alpha-11',
  [ButtonVariant.surface_accent]: 'text-accent-alpha-11',
  [ButtonVariant.surface_neutral]: 'text-neutral-12',
  [ButtonVariant.surface_error]: 'text-error-11',
  [ButtonVariant.ghost_accent]: 'text-accent-alpha-11',
  [ButtonVariant.ghost_neutral]: 'text-neutral-12',
  [ButtonVariant.ghost_error]: 'text-error-11',
  [ButtonVariant.solid_accent]: 'text-white',
  [ButtonVariant.solid_neutral]: 'text-white',
  [ButtonVariant.solid_error]: 'text-white',
  [ButtonVariant.outline_accent]: 'text-accent-alpha-11',
} as const;

const buttonVariants = cva(
  'relative flex items-center justify-center overflow-hidden rounded-full p-3 text-center text-sm font-medium antialiased transition-colors disabled:pointer-events-none disabled:bg-neutral-alpha-3 disabled:text-neutral-alpha-10 disabled:opacity-50',
  {
    variants: {
      variant: {
        [ButtonVariant.soft_accent]: `bg-accent-alpha-3 hover:bg-accent-alpha-4 active:bg-accent-alpha-5 ${ButtonVariantTextColor.soft_accent}`,
        [ButtonVariant.soft_neutral]: `bg-neutral-alpha-3 hover:bg-neutral-alpha-4 active:bg-neutral-alpha-5 disabled:bg-neutral-alpha-3 ${ButtonVariantTextColor.soft_neutral}`,
        [ButtonVariant.soft_error]: `bg-error-3 hover:bg-error-4 active:bg-error-5 ${ButtonVariantTextColor.soft_error}`,
        [ButtonVariant.surface_accent]: `border border-accent-alpha-7 bg-accent-alpha-2 active:bg-accent-alpha-3 ${ButtonVariantTextColor.surface_accent}`,
        [ButtonVariant.surface_neutral]: `border border-neutral-alpha-7 bg-white hover:border-neutral-alpha-8 active:bg-neutral-alpha-3 ${ButtonVariantTextColor.surface_neutral}`,
        [ButtonVariant.surface_error]: `border border-error-alpha-7 bg-white hover:border-error-alpha-8 active:border-error-alpha-8 active:bg-error-alpha-3 ${ButtonVariantTextColor.surface_error}`,
        [ButtonVariant.ghost_accent]: `hover:bg-accent-alpha-3 active:bg-accent-alpha-4 ${ButtonVariantTextColor.ghost_accent}`,
        [ButtonVariant.ghost_neutral]: `bg-white hover:bg-neutral-alpha-3 active:bg-neutral-alpha-3 ${ButtonVariantTextColor.ghost_neutral}`,
        [ButtonVariant.ghost_error]: `bg-white hover:bg-error-alpha-3 active:bg-error-alpha-3 ${ButtonVariantTextColor.ghost_error}`,
        [ButtonVariant.solid_accent]: `bg-accent-9 hover:bg-accent-10 active:bg-accent-10 ${ButtonVariantTextColor.solid_accent}`,
        [ButtonVariant.solid_neutral]: `bg-neutral-9 hover:bg-neutral-10 active:bg-neutral-10 ${ButtonVariantTextColor.solid_neutral}`,
        [ButtonVariant.solid_error]: `bg-error-9 hover:bg-error-10 active:bg-error-10 ${ButtonVariantTextColor.solid_error}`,
        [ButtonVariant.outline_accent]: `border-accent border border-solid border-accent-alpha-8 hover:bg-accent-alpha-2 active:bg-accent-alpha-8 disabled:border-neutral-8 ${ButtonVariantTextColor.outline_accent}`,
      },
      size: {
        [ButtonSize.base]: 'h-8 text-sm',
        [ButtonSize.m]: 'h-6 text-sm',
        [ButtonSize.l]: 'h-10 text-base',
        [ButtonSize.xl]: 'h-12 text-base',
        [ButtonSize.xxl]: 'h-[65px] w-[210px] text-base',
      },
    },
    defaultVariants: {
      variant: ButtonVariant.soft_accent,
      size: ButtonSize.base,
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  Icon?: React.ElementType;
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  classNames?: {
    icon?: string;
  };
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      Icon,
      isLoading,
      iconPosition = 'left',
      disabled,
      classNames,
      children,
      ...props
    },
    ref,
  ) => {
    const ButtonContent = () => (
      <React.Fragment>
        {iconPosition === 'left' && Icon && !isLoading && (
          <Icon className={cn(ButtonVariantTextColor[variant!], 'mr-2 size-5', classNames?.icon)} />
        )}
        <div className="grow text-center">{isLoading ? <LoadingSpinner className="m-auto" /> : children}</div>
        {iconPosition === 'right' && Icon && !isLoading && (
          <Icon className={cn(ButtonVariantTextColor[variant!], 'flex-end size-5')} />
        )}
      </React.Fragment>
    );

    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        disabled={disabled || isLoading}
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      >
        {isLoading && <div className="absolute left-0 top-0 size-full animate-shimmer bg-shimmer-gradient" />}
        <div
          className={cn(
            'pointer-events-none flex items-center justify-center',
            iconPosition === 'right' ? 'gap-1' : '',
          )}
        >
          <ButtonContent />
        </div>
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
