import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

function SettingsInput({
  initialValue,
  onSave,
  placeholder,
  onChange,
  label,
  labelClassName,
  showEditIcon = true,
  errorMessage,
  disabled = false,
  type = 'text',
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [inpValue, setInpValue] = useState('');
  const [error, setError] = useState(null);
  const inputEl = useRef(null);

  useEffect(() => {
    setInpValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isEditing) {
      inputEl.current.focus();
    }
  }, [isEditing]);

  const handleOnBlur = useCallback(() => {
    if (!error) {
      setIsEditing(false);
      if (onSave) {
        onSave(inpValue);
      }
    }
  }, [error, inpValue, onSave]);

  const setValue = useCallback((value) => {
    if (value && value.length >= 0) {
      setError(null);
    } else {
      setError('*Erforderlich');
    }
    setInpValue(value);
  }, []);

  const handleOnChange = useCallback((e) => {
    if (onChange) {
      onChange(e.target.value);
    }
    setValue(e.target.value);
  }, [onChange, setValue]);

  const setIsEditingTrue = useCallback(() => {
    setIsEditing(true);
  }, []);

  return (
    <button
      type="button"
      className={styles.inpListItem}
      onClick={setIsEditingTrue}
      disabled={disabled}
    >
      <div className={`${styles.inpDesription} ${labelClassName || ''}`}>{label}</div>
      <div className={`${styles.inputWrapper} ${isEditing && styles.inputWrapperEditing}`}>
        <input
          className={styles.inp}
          type={type}
          placeholder={placeholder || label}
          value={inpValue}
          ref={inputEl}
          onFocus={setIsEditingTrue}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          autoComplete="off"
          disabled={disabled}
        />
        {showEditIcon && (
          <div
            className={`${styles.inpActionBtn} ${isEditing && styles.inpActionBtnEditing}`}
          >
            <IconComponent Icon={ICON.EDIT} alt="edit" />
          </div>
        )}
      </div>
      <div className={`${styles.inputError} ${!error && !errorMessage && styles.hideError}`}>
        {errorMessage || error || 'Kein Fehler'}
      </div>
    </button>
  );
}

SettingsInput.propTypes = {
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
  showEditIcon: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default SettingsInput;
