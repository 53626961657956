import notificationStack from 'gcs-common/img/notification_stack.png';
import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import { useNavigate } from 'react-router';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { PushNotifications } from '@capacitor/push-notifications';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';

const checkPermissions = async () => {
    try {
        if (IS_NATIVE) {
            const result = await PushNotifications.checkPermissions();
            return result.receive;
        }
        return Notification.permission;
    } catch (error) {
        console.error('Error checking push notification permissions:', error);
        return null;
    }
};

const NotificationPage = () => {
    const navigate = useNavigate()
    
    checkPermissions().then(result => {
        if (result === 'granted' || result === 'denied') {
            navigate(CHAT_ROUTES.CHAT);
        }
    });
    

    const handleNotifications = async () => {
        if(IS_NATIVE){
            await PushNotifications.requestPermissions();
        }else {
            if ('Notification' in window) {
                await Notification.requestPermission();
            }
        }
        navigate(CHAT_ROUTES.CHAT);
    };

    const ignoreNotifications = () => {
        navigate(CHAT_ROUTES.CHAT);
    };

    return (
        <div className="flex h-full items-center justify-center px-6">
            <div className="w-[360px] text-center">
                <div
                    className="flex h-full flex-col justify-between gap-5"
                >
                    <div>
                        <div>
                            <img className="w-full" alt="notification-stack" src={notificationStack} />
                        </div>
                        <div>
                            <p className="text-2xl">Benachrichtigungen einschalten?</p>
                        </div>
                        <div>
                            <p className="text-base text-neutral-11">
                                Verpassen Sie keine wichtigen Nachrichten von Beem.
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 pb-20">
                        <Button
                            type="button"
                            variant={ButtonVariant.solid_accent}
                            id="request-code"
                            size={ButtonSize.xl}
                            className="w-full"
                            onClick={handleNotifications}
                        >
                            Benachrichtige mich
                        </Button>
                        <Button
                            type="button"
                            variant={ButtonVariant.soft_neutral}
                            id="skip"
                            size={ButtonSize.xl}
                            className="w-full"
                            onClick={ignoreNotifications}
                        >
                            Überspringen
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;
