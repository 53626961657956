import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TWILIO_MAX_UPLOAD_SIZE } from 'gcs-common/constants/fileSizes';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { showFilePicker } from '../../slices/uiState/uiStateSlice';
import styles from './styles.module.scss';
import { getShowFilePicker } from '../../slices/uiState/uiStateSelectors';
import { useFilePicker } from '../../hooks/useFilePicker';
import { MESSAGE_INPUT_ID } from '../../constants/componentConstants';

const FileInputButtons = ({
  stageFileCallback = () => {},
  isSendingDisabled = false,
}) => {
  const dispatch = useDispatch();
  const isFilePickerVisible = useSelector(getShowFilePicker);
  const { openCamera } = useFilePicker(TWILIO_MAX_UPLOAD_SIZE, stageFileCallback);

  const handleShowKeyboard = () => {
    const messageInput = document.getElementById(MESSAGE_INPUT_ID);
    messageInput.focus();
  };

  if (isFilePickerVisible) {
    return (
      <button
        disabled={isSendingDisabled}
        type="button"
        className={styles.addImageButton}
        onClick={handleShowKeyboard}
      >
        <IconComponent Icon={ICON.KEYBOARD} color={ICON_COLOR.DARKEST_GREY} />
      </button>
    );
  }

  return (
    <>
      <button
        disabled={isSendingDisabled}
        type="button"
        className={styles.addImageButton}
        onClick={() => dispatch(showFilePicker())}
        id="showFilePickerButton"
      >
        <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.DARKEST_GREY} />
      </button>
      <button
        disabled={isSendingDisabled}
        type="button"
        className={styles.addImageButton}
        onClick={openCamera}
      >
        <IconComponent Icon={ICON.PHOTO} color={ICON_COLOR.DARKEST_GREY} />
      </button>
    </>
  );
};

FileInputButtons.propTypes = {
  stageFileCallback: PropTypes.func,
  isSendingDisabled: PropTypes.bool,
};

export default FileInputButtons;
