/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key,jsx-a11y/interactive-supports-focus */
import { useDispatch, useSelector } from 'react-redux';
import {
  searchInputUpdated, resetProcessFilter,
} from 'gcs-common/slices/processes/processesSlice';
import {
  getFilter,
  getProcessSearchInput,
} from 'gcs-common/slices/processes/processesSelectors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import SearchBar from '../../SearchBar/SearchBar';

const ProcessSearch = () => {
  const dispatch = useDispatch();
  const filter = useSelector(getFilter);
  const searchInput = useSelector(getProcessSearchInput);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isOnline: boolean = useSelector(getIsOnline);

  const onSearchInputChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = $event.target;
    dispatch(searchInputUpdated({ searchInput: value }));
  };

  const resetFilter = () => dispatch(resetProcessFilter());

  return (
    <SearchBar
      disabled={!isOnline}
      searchInput={searchInput}
      filter={filter}
      onSearchInputChange={onSearchInputChange}
      resetFilter={resetFilter}
      placeholder="Vorgangsnummer, Erfassungsdatum, Auftragsnummer oder -text"
    />
  );
};

export default ProcessSearch;
