import {
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageDateCreated, getMessageFooterText,
  getMessageIndex,
  getMessageIsFavorite,
  getMessageIsFromMe,
  getMessageShouldShowOptionsMenu,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { channelInputReplyMessageStaged } from 'gcs-common/slices/messageInput/messageInputSlice';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import LinkPreview from '../../LinkPreview/LinkPreview';
import MessageName from '../../MessageName/MessageName';
import styles from './styles.module.scss';
import SwipeableListItem from '../../SwipeableListItem/SwipeableListItem';
import ReplyIcon from '../../../img/icons/forward_red.svg?react';
import LongPressItem from '../../LongPressListItem/LongPressItem';
import {
  getMessageIsSwipeDisabled,
  getIsMessageSelected,
} from '../../../slices/uiState/uiStateSelector';
import { addSelectedMessageIds } from '../../../slices/uiState/uiStateSlice';

const BaseMessage = ({ messageId, children }) => {
  const dispatch = useDispatch();
  const isSwipingRef = useRef(null);

  const fromMe = useSelector(getMessageIsFromMe(messageId));
  const selected = useSelector(getIsMessageSelected(messageId));
  const isFromMe = useSelector(getMessageIsFromMe(messageId));
  const isFavorite = useSelector(getMessageIsFavorite(messageId));
  const messageIndex = useSelector(getMessageIndex(messageId));
  const dateCreated = useSelector(getMessageDateCreated(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const swipeDisabled = useSelector(getMessageIsSwipeDisabled(selectedChannelId, messageId));
  const shouldShowOptionsMenu = useSelector(getMessageShouldShowOptionsMenu(
    selectedChannelId,
    messageId,
  ));

  const formattedTimestamp = useMemo(() => dateCreated && dateCreated.format('LT'), [dateCreated]);
  const messageFooterText = useSelector(getMessageFooterText(messageId));

  const onLongPressed = useCallback(() => {
    // Note: using an array here, as im expecting whatsapp-like behaviour
    // (selecting several messages) soon
    if (!isSwipingRef.current && !selected) {
      dispatch(addSelectedMessageIds({ messageId }));
    }
  }, [dispatch, messageId, selected]);

  const onMessageSwiped = useCallback(() => {
    dispatch(scrollToMessageId({
      messageId, channelId: selectedChannelId, messageIndex,
    }));
    dispatch(channelInputReplyMessageStaged({ channelId: selectedChannelId, messageId }));
  }, [dispatch, selectedChannelId, messageId, messageIndex]);

  const setIsSwiping = useCallback((isSwiping) => {
    isSwipingRef.current = isSwiping;
  }, []);

  return (
    <LongPressItem
      timeout={1000}
      disabled={!shouldShowOptionsMenu}
      onLongPress={onLongPressed}
      className={styles.messageWrapper}
    >
      <SwipeableListItem
        threshold={0.3}
        swipeDelay={20}
        onSwipe={onMessageSwiped}
        onIsSwiping={() => setIsSwiping(true)}
        onStopSwipe={() => setIsSwiping(false)}
        disabled={swipeDisabled}
        action={<ReplyIcon className={styles.replySwipeIcon} />}
      >
        <li
          className={`${styles.msg} ${fromMe && styles.me}`}
        >
          <div className={styles.msgContent}>
            {!isFromMe && (
              <MessageName
                channelId={selectedChannelId}
                messageId={messageId}
              />
            )}
            {children}
            <LinkPreview messageId={messageId} />
            <div className={styles.msgFooter}>
              {isFavorite
              && (
                <IconComponent
                  Icon={ICON.STAR_FILLED}
                  color={ICON_COLOR.PRIMARY}
                  size={ICON_SIZE.SMALL}
                  style={{ marginRight: '4px' }}
                  alt="Favoriten icon"
                />
              )}
              {messageFooterText && (
                <div className={styles.messageFooterText}>
                  {messageFooterText}
                </div>
              )}
              <span>{formattedTimestamp}</span>
            </div>
          </div>
        </li>
      </SwipeableListItem>
    </LongPressItem>
  );
};

BaseMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default BaseMessage;
