import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelInputCleared } from 'gcs-common/slices/messageInput/messageInputSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { resetSelectedMessageIds } from '../../slices/uiState/uiStateSlice';
import styles from './styles.module.scss';

const ForwardChannelListHeader = () => {
  const navigate = useNavigate();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(channelInputCleared({ channelId: selectedChannelId }));
    dispatch(resetSelectedMessageIds());
    navigate(-1);
  }, [dispatch, selectedChannelId, navigate]);

  return (
    <div className={styles.header}>
      <IconComponent Icon={ICON.FORWARD} color={ICON_COLOR.WHITE} />
      <div className={styles.headerText}>
        Nachricht weiterleiten
      </div>
      <button
        className={styles.closeButton}
        type="button"
        onClick={onClose}
      >
        <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.WHITE} />
      </button>
    </div>
  );
};

ForwardChannelListHeader.propTypes = {};

export default ForwardChannelListHeader;
