import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentUserImage, getHasCurrentUserImage } from 'gcs-common/slices/currentUser/currentUserSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';

import PersistedImage from '../../PersistedImage/PersistedImage';
import styles from './styles.module.scss';

function ProfilePictureDisplay(
  {
    size = 160,
    hideBottomLabel = false,
    imgWrapperClassName = '',
    placeholderWrapperClassName = '',
    placeholder = <div />,
    disabled = false,
    toggleOverlay,
    isUpdatingProfilePicture = false,
  },
) {

  const currentUserImage = useSelector(getCurrentUserImage);
  const hasCurrentUserImage = useSelector(getHasCurrentUserImage);
  const wh = `${size}px`;

  const imagePreview = useMemo(() => {
    if (isUpdatingProfilePicture) {
      return (
        <div className={styles.loadingIndicatorOverlay}>
          <LoadingIndicator />
        </div>
      );
    }
    if (!hasCurrentUserImage) {
      return (
        <button
          type="button"
          className={`${styles.profilePicPlaceholder} ${placeholderWrapperClassName}`}
        >
          {placeholder}
        </button>
      );
    }
    return (
      <PersistedImage
        alt="profile"
        className={styles.profilePicImg}
        remoteUrl={currentUserImage}
      />
    );
  }, [
    hasCurrentUserImage,
    isUpdatingProfilePicture,
    currentUserImage,
    placeholder,
    placeholderWrapperClassName,
  ]);

  return (
    <>
      <div className={styles.profilePicContainer}>
        <div
          role="button"
          className={`${styles.profilePicWrapper} ${imgWrapperClassName}`}
          style={{ width: wh, height: wh }}
          onClick={toggleOverlay}
          tabIndex={0}
        >
          {imagePreview}
        </div>
        {(!hideBottomLabel && !disabled)
        && <div role="button" tabIndex={0} className={styles.andernPlaceHolder} onClick={toggleOverlay}> Ändern</div>
        }
      </div>
    </>
  );
}

ProfilePictureDisplay.propTypes = {
  size: PropTypes.number,
  hideBottomLabel: PropTypes.bool,
  imgWrapperClassName: PropTypes.string,
  placeholderWrapperClassName: PropTypes.string,
  placeholder: PropTypes.node,
  disabled: PropTypes.bool,
  toggleOverlay: PropTypes.func.isRequired,
  isUpdatingProfilePicture: PropTypes.bool,
};

export default ProfilePictureDisplay;
