import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCraftsmanConnection, getCraftsmanConnections, getdeleteCraftsmanConnectionLoading,
  getIsManualCraftsmanConnection,
  getUpdateCraftsmanConnectionToCustomerError,
  getUpdateCraftsmanConnectionToCustomerFailed,
  getUpdateCraftsmanConnectionToCustomerLoading,
} from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteCraftsmanConnection,
  updateCraftsmanConnectionToCustomer,
} from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { resetEditError } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSlice';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { getAppName, getDefaultBrand } from 'gcs-common/slices/theme/themeSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import OpConnectionStatus from '../../../OpConnectionStatus/OpConnectionStatus';
import CraftsmanConnectionPermissions from '../CraftsmanConnectionPermissions/CraftsmanConnectionPermissions';
import Button from '../../../Button/Button';
import SettingsInput from '../../SettingsInput/SettingsInput';


const CraftsmanAccountEditScreen = () => {
  const { connectionId } = useParams();
  const connectionDetail = useSelector(getCraftsmanConnection(connectionId));
  const craftsmanConnections = useSelector(getCraftsmanConnections);
  const editConnectionIsLoading = useSelector(getUpdateCraftsmanConnectionToCustomerLoading);
  const editConnectionFailed = useSelector(getUpdateCraftsmanConnectionToCustomerFailed);
  const editCraftsmanConnectionError = useSelector(getUpdateCraftsmanConnectionToCustomerError);
  const isDeleting = useSelector(getdeleteCraftsmanConnectionLoading);
  const appName = useSelector(getAppName);
  const defaultBrand = useSelector(getDefaultBrand);
  const opAccountDetails = connectionDetail && connectionDetail.opAccount
    ? connectionDetail.opAccount
    : { username: null, password: null };

  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));

  const [username, setUsername] = useState(opAccountDetails.username);
  const [password, setPassword] = useState(null);

  const isManual = useSelector(getIsManualCraftsmanConnection(connectionId));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onResetError = useCallback(() => {
    dispatch(resetEditError());
  }, [dispatch]);

  const onGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    return () => {
      dispatch(resetEditError());
    };
  }, [dispatch]);

  const onDeleteConnection = useCallback(async () => {
    dispatch(deleteCraftsmanConnection({ connectionId }));
  }, [dispatch, connectionId]);

  const onChangeUsername = useCallback((value) => {
    setUsername(value);
  }, []);

  const onChangePassword = useCallback((value) => {
    setPassword(value);
  }, []);

  const updateConnectionToCompany = useCallback(() => {
    dispatch(updateCraftsmanConnectionToCustomer({
      connectionId, username, password, brand: defaultBrand,
    }));
  }, [connectionId, username, password, defaultBrand, dispatch]);
  const buttonsComponent = useMemo(
    () => (
      <div className={styles.buttons}>
        {editConnectionFailed ? (
          <Button onClick={onResetError} className={styles.button}>
            Nochmal versuchen
          </Button>
        ) : (
          <div className={styles.buttons}>
            {(isManual && !editConnectionIsLoading)
            && (
              <button
                type="button"
                className={styles.primaryButton}
                onClick={updateConnectionToCompany}
                disabled={!username || !password}
              >
                Aktualisieren
              </button>
            )}
            { craftsmanConnections && Object.keys(craftsmanConnections).length !== 1
              && (
              <Button disabled={isDeleting} className={styles.button} onClick={onDeleteConnection}>
                Verknüpfung entfernen
              </Button>
              )
          }
          </div>

        )}
      </div>
    ),
    [
      isDeleting,
      editConnectionFailed, editConnectionIsLoading, isManual,
      onResetError, password, updateConnectionToCompany, username,
      onDeleteConnection, craftsmanConnections],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button type="button" onClick={onGoBack} className={styles.back}>
          <IconComponent
            Icon={ICON.ARROW}
            transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
            alt="Zurück"
            style={{ position: 'absolute', left: '20px' }}
          />
          <div className={styles.headline}>
            <b>{connectionDetail?.customerNumber}</b>
            Aktualisieren
          </div>
        </button>
      </div>
      <div className={styles.body}>
        <div className={styles.accountSection}>
          <div className={styles.description}>
            {`Bitte tragen Sie ihre ${appName} ONLINE PLUS Anmeldedaten ein:`}
          </div>
          <SettingsInput
            className={styles.input}
            label="Benutzer"
            initialValue={username}
            onChange={onChangeUsername}
          />
          <SettingsInput
            className={styles.input}
            label="Passwort"
            type="password"
            initialValue={password}
            onChange={onChangePassword}
          />
        </div>
        <CraftsmanConnectionPermissions connectionId={connectionId} />

        <div>
          {editConnectionIsLoading && (
            <div>
              <LoadingIndicator padding="0" />
            </div>
          )}
          {editConnectionFailed
            && (
            <div className={styles.gcopError}>
              {(isOpMaintenanceEnabled && editCraftsmanConnectionError)
                ? opMaintenanceMessage
                : editCraftsmanConnectionError
              }
            </div>
            )}
          {(!editConnectionFailed && !editConnectionIsLoading && !isManual)
            && (
              <>
                <div className={styles.title}>
                  VERBINDUNGSZUSTAND
                </div>
                <div className={styles.connectionState}>
                  <OpConnectionStatus withText connectionId={connectionId} />
                  <button
                    type="button"
                    className={styles.btn}
                    onClick={updateConnectionToCompany}
                    disabled={editConnectionIsLoading || !username || !password}
                  >
                    Aktualisieren
                  </button>
                </div>
              </>
            )}

        </div>
        {isDeleting && <LoadingIndicator />}
        {buttonsComponent}
      </div>
    </div>
  );
};

export default CraftsmanAccountEditScreen;
