import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'gcs-common/design-system/ui/dialog';
import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import StarRating from 'gcs-common/design-system/ui/star-rating';

interface TicketRatingModalProps {
  comment: string;
  rating: number;
  handleRating: (rating: number, comment: string) => Promise<void>;
  handleCancel: () => void;
  handleDelete: () => Promise<void>;
  open: boolean;
  isLoading: boolean;
}

const TicketRatingModal = (props: TicketRatingModalProps) => {
  const {
    comment, rating, handleRating, handleCancel, handleDelete, open, isLoading,
  } = props;

  const [ratingValue, setRatingValue] = useState(rating);
  const [commentValue, setCommentValue] = useState(comment);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    setRatingValue(rating);
    setCommentValue(comment);
    setFormChanged(false);
  }, [rating, comment]);

  return (
    <Dialog open={open}>
      <DialogContent hideDefaultCloseButton>
        <DialogHeader>
          <DialogTitle>Wie haben wir uns geschlagen?</DialogTitle>
          <DialogDescription>
            Hinterlasse eine Bewertung und einen Kommentar zu dieser Ersatzteilanfrage.
          </DialogDescription>
          <StarRating
            ratingValue={ratingValue}
            setRatingValue={(newRating) => {
              setRatingValue(newRating);
              setFormChanged(true);
            }}
            isEditable
          />
        </DialogHeader>
        <textarea
          className="box-border h-[120px] w-full rounded-md border border-solid border-neutral-alpha-9 p-3 text-base text-neutral-12"
          onChange={(e) => {
            setCommentValue(e.target.value);
            if (e.target.value !== comment) {
              setFormChanged(true);
            } else {
              setFormChanged(false);
            }
          }}
          value={commentValue}
          placeholder="Kommentar (optional)"
        >
          {commentValue}
        </textarea>
        <div className="flex w-full flex-col gap-y-4 py-4">
          <Button
            type="button"
            variant={ButtonVariant.solid_accent}
            isLoading={isLoading}
            size={ButtonSize.xl}
            onClick={() => {
              handleRating(ratingValue, commentValue || '').then(() => {}).catch(() => {});
            }}
            disabled={!formChanged || !ratingValue}
          >
            Bewerten
          </Button>
          {rating && (
            <Button
              type="button"
              variant={ButtonVariant.solid_error}
              size={ButtonSize.xl}
              isLoading={isLoading}
              onClick={() => {
                handleDelete().then(() => {}).catch(() => {});
                setRatingValue(rating);
                setCommentValue(comment);
              }
              }
            >
              Bewertung löschen
            </Button>
          )}
          <Button
            type="button"
            size={ButtonSize.xl}
            variant={ButtonVariant.soft_neutral}
            onClick={() => {
              handleCancel();
              setRatingValue(rating);
              setCommentValue(comment);
              setFormChanged(false);
            }}
          >
            Abbrechen
          </Button>
        </div>


      </DialogContent>
    </Dialog>
  );
};

export default TicketRatingModal;
