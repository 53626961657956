import { combineReducers } from '@reduxjs/toolkit';
import { clearStore } from 'gcs-common/slices/store/storeActions';
import { clearPersistenceWhiteList } from 'gcs-common/slices/auth/authThunks/logout-effect';
import { filterObjectIncludeMatchingKeys } from 'gcs-common/helper/objectHelpers';
import { commonReducers } from 'gcs-common/slices/commonReducers';
import persistenceSlice from 'gcs-common/slices/persistence/persistenceSlice';
import uiStateSlice from './slices/uiState/uiStateSlice';
import initializationSlice from './slices/initialization/initializationSlice';
import tutorialSlice from './slices/tutorial/tutorialSlice';
import messageVisibilityLoaderSlice from './slices/messageVisibilityLoader/messageVisibilityLoaderSlice';
import initSlice from './slices/initSlice/initSlice';
import mediaSlice from './slices/media/mediaSlice';
import deviceSlice from './slices/device/deviceSlice';
import localSettingsSlice from './slices/localSettings/localSettingsSlice';

const createBaseReducers = () => combineReducers({
  ...commonReducers,
  uiState: uiStateSlice,
  initialization: initializationSlice,
  tutorial: tutorialSlice,
  init: initSlice,
  messageVisibilityLoader: messageVisibilityLoaderSlice,
  persistence: persistenceSlice,
  media: mediaSlice,
  device: deviceSlice,
  localSettings: localSettingsSlice,
});

const createRootReducer = () => (state, action) => {
  // Note: We reset the whole state to the initial-state here
  // if needed, some parts can be also ignored
  // this is based on https://stackoverflow.com/a/35641992/8029510
  if (action.type === clearStore.type) {
    const nextState = filterObjectIncludeMatchingKeys(state, clearPersistenceWhiteList);
    return createBaseReducers()(nextState, action);
  }
  return createBaseReducers()(state, action);
};

export default createRootReducer;

export const rootReducer = createRootReducer();
