import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';
import { getSafeAreaInsets } from '../slices/uiState/uiStateSelectors';
import { setSafeAreaInsets } from '../slices/uiState/uiStateSlice';

const useSafeAreaInsets = () => {
  const dispatch = useDispatch();
  const safeAreaInsets = useSelector(getSafeAreaInsets);

  useEffect(() => {
    if (IS_NATIVE) {
      const calculateSafeAreaInsets = async () => {
        const { insets } = await SafeArea.getSafeAreaInsets();
        dispatch(setSafeAreaInsets(insets));
      };

      if (!safeAreaInsets) {
        calculateSafeAreaInsets();
      }
    }
  }, [dispatch, safeAreaInsets]);
};

export default useSafeAreaInsets;
