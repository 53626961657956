import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { getPictureFromCamera } from 'gcs-common/helper/capacitorImageHelper';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useSignedUploadMedia } from 'gcs-common/clients/api/entities/media/media-helper';
import { updateCurrentUser } from 'gcs-common/slices/currentUser/currentUserThunks/updateCurrentUser';
import { setIsUpdateProfilePicturePending } from 'gcs-common/slices/currentUser/currentUserSlice';
import ProfilePictureEdit from '../ProfilePictureEdit/ProfilePictureEdit';
import styles from './styles.module.scss';

const ProfilePictureOverlay = (
  {
    toggleOverlay,
    disabled,
    isUpdatingProfilePicture,
    isRegistration = false,
  },
) => {
  const [picture, setPicture] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [savedImage, setSavedImage] = useState('');


  const dispatch = useDispatch();
  const uploadSignedMedia = useSignedUploadMedia();

  const themeIdentifier = useSelector(getThemeIdentifier);
  const currentUserId = useSelector(getCurrentUserId);

  const takePhoto = useCallback(async () => {
    const file = await getPictureFromCamera('Camera', themeIdentifier, false, false);
    setPicture(file);
    setOpenEdit(true);
  }, [themeIdentifier]);

  const chooseFromGallery = useCallback(async () => {
    const file = await getPictureFromCamera('Photos', themeIdentifier, false, false);
    setPicture(file);
    setOpenEdit(true);
  }, [themeIdentifier]);

  const getUpdatedProfilePicture = useCallback((newPicture) => {
    setSavedImage(newPicture);
  }, []);

  const onSaveImage = useCallback(async () => {
    setOpenEdit(false);
    toggleOverlay();
    dispatch(setIsUpdateProfilePicturePending({ isPending: true }));
    const uploadedImageUrl = await uploadSignedMedia.uploadSignedMedia(savedImage,
      currentUserId);
    if (uploadedImageUrl) {
      await dispatch(updateCurrentUser({ values: { image_url: uploadedImageUrl } }));
    }
    dispatch(setIsUpdateProfilePicturePending({ isPending: false }));
  }, [uploadSignedMedia, savedImage, currentUserId, toggleOverlay, dispatch]);

  const onCancel = useCallback(() => {
    setOpenEdit(false);
    toggleOverlay();
  }, [toggleOverlay]);

  return (
    <>
      {openEdit && (
        <ProfilePictureEdit
          isUpdatingProfilePicture={isUpdatingProfilePicture}
          disabled={disabled}
          picture={picture}
          getUpdatedProfilePicture={getUpdatedProfilePicture}
          isRegistration={isRegistration}
        />
      )}
      <div className={`${styles.overlayWrapper} ${isRegistration && styles.registrationPosition}`}>
        {!openEdit && (
        <div className={styles.overlayButtons}>
          <div className={styles.buttonGroup}>
            <button
              type="button"
              className={styles.selectPhotoButton}
              onClick={chooseFromGallery}
              disabled={disabled}
            >
              Fotogalerie
            </button>
            <button
              type="button"
              className={styles.takePhotoButton}
              onClick={takePhoto}
              disabled={disabled}
            >
              Foto aufnehmen
            </button>
          </div>
          <button
            type="button"
            className={styles.secondaryButton}
            onClick={toggleOverlay}
          >
            Abbrechen
          </button>
        </div>
        )}
        {openEdit && (
        <div className={styles.editButtons}>
          <button
            type="button"
            className={styles.secondaryButton}
            disabled={!picture || isUpdatingProfilePicture}
            onClick={onSaveImage}
          >
            Speichern
          </button>
          <button
            type="button"
            className={styles.secondaryButton}
            onClick={onCancel}
          >
            Abbrechen
          </button>
        </div>
        )}
      </div>
    </>
  );
};

ProfilePictureOverlay.propTypes = {
  disabled: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  isUpdatingProfilePicture: PropTypes.bool.isRequired,
  isRegistration: PropTypes.bool,
};

export default ProfilePictureOverlay;
