import videoFileIcon from 'gcs-common/img/video_file.svg';
import styles from './styles.module.scss';

const VideoLocalThumbnail = () => {
  return (
    <div className={styles.videoThumbnail}>
      <img src={videoFileIcon} alt="video-indicator" className={styles.icon} />
    </div>
  );
};

export default VideoLocalThumbnail;

VideoLocalThumbnail.propTypes = {
};
