/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key,jsx-a11y/interactive-supports-focus */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProcessSearchInput,
} from 'gcs-common/slices/processes/processesSelectors';
import { processSelected, productSelected, resetProcessFilter } from 'gcs-common/slices/processes/processesSlice';
import { getSelectedProcess } from 'gcs-common/clients/api/entities/processes/processes-api-selectors';
import ProcessSearch from './ProcessSearch/ProcessSearch';
import SuggestionList from './SuggestionList/SuggestionList';
import RenderHeader from './RenderHeader/RenderHeader';
import ProcessList from './ProcessList/ProcessList';
import ProcessDetail from '../ProcessDetail/ProcessDetail';
import styles from './styles.module.scss';
import Page from '../Page/Page';
import InternetConnectionBanner from '../InternetConnectionBanner/InternetConnectionBanner';

const ProcessSelect = () => {
  const selectedProcess = getSelectedProcess();
  const searchInput = useSelector(getProcessSearchInput);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(processSelected({ selectedProcessNumber: undefined }));
      dispatch(productSelected({ selectedProductNumber: undefined }));
      dispatch(resetProcessFilter());
    };
  }, [dispatch]);

  return (
    <Page
      className={styles.processWrapper}
    >
      {
        (selectedProcess)
          ? (
            <ProcessDetail process={selectedProcess} />
          )
          : (
            <>
              <RenderHeader />
              <ProcessSearch />
              <InternetConnectionBanner />
              {searchInput.length > 0
                ? <SuggestionList />
                : (
                  <ProcessList />
                )
              }
            </>
          )
      }
    </Page>
  );
};

export default ProcessSelect;
