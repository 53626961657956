import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

function ChannelSearchInput({
  type,
  getSearchInput,
  setSearchInput,
  light = false,
  placeholder,
}) {
  const searchInput = useSelector(getSearchInput);
  const dispatch = useDispatch();
  const onSearchInputChange = useCallback((event) => {
    dispatch(setSearchInput({ [type]: event.target.value }));
  }, [dispatch, setSearchInput, type]);
  useEffect(() => {
    return () => {
      dispatch(setSearchInput({ [type]: '' }));
    };
  }, [dispatch, setSearchInput, type]);

  return (
    <div className={type === 'forwardSearchInput' ? styles.searchForward : styles.search}>
      <input
        className={`${light ? styles.lightBackground : styles.darkBackground} ${styles.searchInput}`}
        placeholder={placeholder || 'Kontakte durchsuchen'}
        type="search"
        value={searchInput}
        onChange={onSearchInputChange}
      />
    </div>
  );
}

ChannelSearchInput.propTypes = {
  getSearchInput: PropTypes.func.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  light: PropTypes.bool,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default ChannelSearchInput;
