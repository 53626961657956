import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICON, ICON_SIZE, ICON_TRANSFORM, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import {
  getTicketsFilters,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import orderBy from 'lodash-es/orderBy';
import { backToTicketsOverview, ticketsFiltersUpdated } from 'gcs-common/slices/tickets/ticketsSlice';
import { Virtuoso } from 'react-virtuoso';
import { ETS_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { hasSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { useQueryClient } from '@tanstack/react-query';
import { convertETSTicketsFiltersToQuery, generateETSTicketsFilters, getGetETSTicketsQueryKey } from 'gcs-common/clients/api/entities/tickets/tickets-api-helpers';
import { useGetETSTicketNotificationsSelector, useGetETSTicketNotificationsCountSelector } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { useGetETSTicketsQuery } from 'gcs-common/clients/api/entities/tickets/tickets-api';
import TicketButton from '../TicketButton/TicketButton';
import styles from './styles.module.scss';
import TicketCard from './TicketCard/TicketCard';
import NoTicketsOverview from './NoTicketsOverview/NoTicketsOverview';
import ChatConnectionBanner from '../../ChatConnectionBanner/ChatConnectionBanner';
import { closeTicketMediaFullScreen } from '../../../slices/uiState/uiStateSlice';
import FileShareHeader from '../../FileShare/FileShareHeader/FileShareHeader';

const TicketsOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const ticketNotifications = useGetETSTicketNotificationsSelector();
  const ticketNotificationsCount = useGetETSTicketNotificationsCountSelector();
  const sharedFiles = useSelector(hasSharedFiles);
  const filters = useSelector(getTicketsFilters);

  useEffect(() => {
    dispatch(ticketsFiltersUpdated(
      generateETSTicketsFilters({
        channelId: undefined, pageIndex: 0, pageSize: 1000,
      }),
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetching: ticketsLoading } = useGetETSTicketsQuery({
    query: convertETSTicketsFiltersToQuery(filters),
  });

  const unsortedTickets = data ? data.data : undefined;

  // reset state of tikcet media when it routes back to this component.
  dispatch(closeTicketMediaFullScreen());

  useEffect(() => {
    // synchronize router with redux,
    // since components downstream may rely selectors like getSelectedTicket
    // @ts-expect-error redux
    dispatch(backToTicketsOverview());
  }, [dispatch]);

  const tickets = useMemo(() => orderBy(
    unsortedTickets ?? [],
    [({ id }) => id in ticketNotifications, 'jiraLastSyncedAt', 'createdAt'],
    ['desc', 'desc', 'desc'],
  ), [unsortedTickets, ticketNotifications]);

  const fetchData = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: getGetETSTicketsQueryKey(convertETSTicketsFiltersToQuery(filters)),
    }).then(() => {}).catch(() => {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData, ticketNotificationsCount]);

  const showTicketForm = () => {
    navigate(ETS_ROUTES.TICKET_FORM);
  };
  const navigateBack = () => navigate(-1);

  return (
    <div className={styles.ticketsOverviewContainer}>
      {sharedFiles && (<FileShareHeader />)}

      <div className={styles.ticketsHeader}>
        <button
          type="button"
          onClick={navigateBack}
        >
          <IconComponent
            Icon={ICON.ARROW}
            transform={ICON_TRANSFORM.ROTATE_180}
            size={ICON_SIZE.LARGE}
            color={ICON_COLOR.LIGHT_GREY}
          />
        </button>
        <div>Ersatzteil-Anfragen</div>
      </div>
      <ChatConnectionBanner />
      <div className={styles.ticketsListContainer}>
        {ticketsLoading
          && tickets.length < 1
          && (<LoadingIndicator />)}

        {!ticketsLoading
          && tickets.length === 0
          && <NoTicketsOverview />
        }

        {!ticketsLoading
          && tickets.length > 0
          && (
            <div className={styles.ticketsList}>
              <Virtuoso
                data={tickets}
                itemContent={(_, ticket) => (
                  <TicketCard
                    id={ticket.id}
                    key={ticket.id}
                    status={ticket.status}
                    title={ticket.title}
                    previewImageUrl={ticket.previewImageUrl}
                    createdAt={ticket.createdAt}
                    updatedAt={ticket.updatedAt}
                  />
                )}
              />
            </div>
          )
        }
      </div>
      {!sharedFiles && (<TicketButton onClick={showTicketForm} />)}
    </div>
  );
};

export default TicketsOverview;
