
import { useSelector } from 'react-redux';
import QuickOrder from '../QuickOrder/QuickOrder';
import {
  getIsCheckoutFinished,
} from '../../slices/quickOrdering/quickOrderingSelectors';
import CheckoutFinished from '../CheckoutFinished/CheckoutFinished';
import { getHasFeatureFlag } from '../../slices/currentUser/currentUserSelectors';
import FEATURE_FLAGS from '../../helper/featureFlagsHelper';

const QuickOrdering = () => {

  const isCheckoutFinished = useSelector(getIsCheckoutFinished);

  const hasFeatureFlag = useSelector(getHasFeatureFlag(FEATURE_FLAGS.QUICKORDERING_ENABLED));
  if (!hasFeatureFlag) {
    return 'Diese Feature kann mit Deiner Version von BEEM noch nicht angezeigt werden';
  }

  if (isCheckoutFinished) {
    return <CheckoutFinished />;
  }
  return <QuickOrder />;

};

export default QuickOrdering;
