import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { captureException } from '@sentry/react';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Capacitor } from '@capacitor/core';
import { isVideo } from 'gcs-common/helper/fileHelper';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { compressImages } from 'gcs-common/helper/capacitorImageHelper';
import { showSimpleNotification } from '../slices/notification/notificationThunks';
import stagePictureFromCamera from '../slices/media/mediaThunks/stagePictureFromCamera';


export const useFilePicker = (
  fileSizeLimit,
  stageFileCallback,
  allowedFileTypes = [],
  unsupportedFileMessage = 'Dateityp Nicht unterstützt',
) => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();

  const checkFileSize = useCallback((file) => {
    if (file.size > fileSizeLimit && !isVideo(file)) {
      const errorMessage = getErrorMsg(
        'files.wrongFileSize',
        file.name,
        fileSizeLimit / 1000000,
      );
      return errorMessage;
    }
    return null;
  }, [fileSizeLimit]);

  const filterAllowedFiles = useCallback((files) => {
    if (allowedFileTypes.length === 0 || !files) {
      return { filteredFiles: files, hasUnsupportedFiles: false };
    }
    const filteredFiles = files.filter((file) => allowedFileTypes.includes(file.mimeType));
    const hasUnsupportedFiles = filteredFiles.length !== files.length;
    return { filteredFiles, hasUnsupportedFiles };
  }, [allowedFileTypes]);

  const processResult = useCallback(async (files) => {
    const { filteredFiles, hasUnsupportedFiles } = filterAllowedFiles(files);
    if (hasUnsupportedFiles) {
      dispatch(showSimpleNotification({
        notification: unsupportedFileMessage,
      }));
    }

    const compressFiles = await compressImages(filteredFiles);
    const localFiles = compressFiles
      .map((file) => ({
        ...file,
        type: file.mimeType,
        lastModified: file.modifiedAt,
        webPath: Capacitor.convertFileSrc(file.path),
      }));

    let firstErrorMessage = null;
    localFiles.forEach((file) => {
      const errorMessage = checkFileSize(file);
      if (errorMessage && !firstErrorMessage) {
        firstErrorMessage = getErrorMsg('files.readError', file.name);
      } else {
        stageFileCallback({ channelId: selectedChannelId, file });
      }
    });

    if (firstErrorMessage) {
      dispatch(openErrorDialog({ dialogErrorMsg: firstErrorMessage }));
    }
  }, [
    dispatch, checkFileSize, selectedChannelId,
    stageFileCallback, filterAllowedFiles, unsupportedFileMessage,
  ]);

  const openGallery = useCallback(async () => {
    try {
      const { files } = await FilePicker.pickMedia({
        readData: false,
        skipTranscoding: false,
      });

      if (!files) return;

      processResult(files);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      captureException(error);
    }
  }, [processResult]);

  const openFileExplorer = useCallback(async () => {
    try {
      const { files } = await FilePicker.pickFiles({
        readData: false,
      });

      if (!files) return;

      processResult(files);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      captureException(error);
    }
  }, [processResult]);

  const openCamera = useCallback(async () => {
    await dispatch(stagePictureFromCamera({
      fileSizeLimit, stageFileCallback,
    }));
  }, [dispatch, fileSizeLimit, stageFileCallback]);

  return { openGallery, openFileExplorer, openCamera };
};
