import { UploadFile, Visibility } from '@project-lary/react-material-symbols';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { useState } from 'react';
import { cn } from '../utils/tailwind-merge';

export const inputFieldStyle = 'error:bg-error-alpha-3 flex h-10 w-full rounded-md border border-neutral-alpha-5 bg-white text-sm file:border-0 file:text-sm file:font-medium read-only:border-neutral-alpha-6 read-only:bg-neutral-alpha-3 disabled:cursor-not-allowed disabled:border-neutral-alpha-9 disabled:bg-neutral-alpha-6 disabled:opacity-50 ring-1 ring-neutral-5 focus:ring-accent-9';

const inputVariants = cva(inputFieldStyle, {
  variants: {
    inputSize: {
      base: 'h-8',
      l: 'h-10',
    },
  },
  defaultVariants: {
    inputSize: 'l',
  },
});

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  Icon?: React.ElementType;
  fileName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({
    className, id, type, inputSize, Icon, fileName, ...props
  }, ref) => {
    if (type === 'file') {
      return (
        <>
          <input id={id} type={type} hidden ref={ref} {...props} />

          <div className="flex items-center">
            <label
              htmlFor={id}
              className="mr-2 flex h-10 cursor-pointer items-center gap-1 rounded-md border border-neutral-alpha-5 p-2 font-medium antialiased hover:border-neutral-alpha-8"
            >
              <UploadFile className="text-xl text-neutral-12" />
              Datei auswählen
            </label>
            <span id="fileInputLabel">{fileName || 'Keine Datei ausgewählt'}</span>
          </div>
        </>
      );
    }
    if (type === 'password') {
      // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
      const [showPassword, setShowPassword] = useState(false);
      const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };
      return (
        <div className="relative">
          <div
            onMouseEnter={toggleShowPassword}
            onMouseLeave={toggleShowPassword}
            className={cn(
              'absolute right-0 flex h-full items-center pr-2 text-xl text-neutral-11 hover:text-neutral-12',
              className,
            )}
          >
            <Visibility className="size-5" />
          </div>
          <input
            type={showPassword ? 'text' : 'password'}
            className={cn(inputVariants({ inputSize, className }), {
              'pl-8': !!Icon,
            })}
            ref={ref}
            id={id}
            {...props}
          />
        </div>
      );
    }
    return (
      <div className="relative">
        {Icon && <InputIcon Icon={Icon} />}
        <input
          type={type}
          className={cn(inputVariants({ inputSize, className }), {
            'pl-8': !!Icon,
          })}
          ref={ref}
          id={id}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = 'Input';

interface InputIconProps {
  Icon: React.ElementType;
  className?: string;
}

function InputIcon({ Icon, className }: InputIconProps) {
  return (
    <div className={cn('absolute left-0 flex h-full items-center pl-2 text-xl text-neutral-11', className)}>
      <Icon className="size-5" />
    </div>
  );
}

export { Input, InputIcon };
