import { useCallback } from 'react';
import {
  getCurrentFriendlyName, getCurrentUserId,
  getCurrentUserCompany,
  getIsAgent,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { clearStore } from 'gcs-common/slices/store/storeActions';
import { getUserImage, getUserStatus } from 'gcs-common/slices/user/userSelectors';
import CUSTOM_EVENT_TYPES from 'gcs-common/constants/CustomEventTypes';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { getFavicon } from 'gcs-common/slices/theme/themeSelectors';
import { useNavigate } from 'react-router';
import { useLogoutMutation } from 'gcs-common/clients/api/entities/auth/auth-api';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { setSettingsMenuClosed } from '../../slices/uiState/uiStateSlice';
import { trackCustomEvent } from '../../slices/customMobile/trackCustomEventThunk';
import styles from './styles.module.scss';
import SettingsButton from './SettingsButton/SettingsButton';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import Button from '../Button/Button';
import { unregisterPush } from '../../customHooks/usePushNotifications';

const Settings = () => {
  const isAgent = useSelector(getIsAgent);
  const currentUserId = useSelector(getCurrentUserId);
  const status = useSelector(getUserStatus(currentUserId));
  const userImage = useSelector(getUserImage(currentUserId));
  const currentFriendlyName = useSelector(getCurrentFriendlyName);
  const currentCompany = useSelector(getCurrentUserCompany);
  const logoIcon = useSelector(getFavicon);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutMutation = useLogoutMutation();

  async function onLogout() {
    await dispatch(trackCustomEvent(CUSTOM_EVENT_TYPES.LOGOUT_INITIATED));
    await logoutMutation.mutateAsync();
    await unregisterPush();
  }

  const showTerms = () => {
    navigate('/settings/terms-of-use');
  };

  const showProfileSettings = () => {
    navigate('/profile');
  };

  const showChatSettings = () => {
    navigate('/settings/chatSettings');
  };

  const showAccountSettings = () => {
    navigate('/settings/accountSettings');
  };

  const showCraftsmanConnectionsOverview = () => {
    navigate('/craftsmanAccounts');
  };

  const resetStore = useCallback(() => {
    dispatch(clearStore());
  }, [dispatch]);

  const onCloseSettings = useCallback(() => {
    dispatch(setSettingsMenuClosed());
  }, [dispatch]);

  if (logoutMutation.isPending) {
    return <LoadingIndicatorFullScreen loadingText="Sie werden ausgeloggt..." />;
  }

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.settingsHeader}>
        <Button type="button" onClick={onCloseSettings} className={styles.settingsBack}>
          <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="Zurück" />
          <div className={styles.settingsTitle}>Einstellungen</div>
        </Button>
        <img className={styles.gcLogoIcon} src={logoIcon} alt="Zurück" />
      </div>
      <div className={styles.profileSection}>
        <div className={styles.imageSection}>
          <ImageWithStatus
            className={styles.userImage}
            imageUrl={userImage}
            userStatus={status}
          />
        </div>
        <div className={styles.profileSectionDetails}>
          <div className={styles.userName}>{currentFriendlyName}</div>
          <div className={styles.company}>{currentCompany}</div>
          <button
            type="button"
            onClick={showProfileSettings}
            className={styles.changeProfileButton}
          >
            Profil bearbeiten
          </button>
        </div>

      </div>
      {!isAgent && (
        <>
          <SettingsButton
            icon={<IconComponent Icon={ICON.HOUSE} />}
            onClick={showCraftsmanConnectionsOverview}
            text="Verknüpfungen bearbeiten"
          />
        </>
      )}
      <SettingsButton
        icon={<IconComponent Icon={ICON.SECURITY} />}
        text="Nutzungsbedigungen & Datenschutzrichtlinien"
        onClick={showTerms}
      />
      <SettingsButton
        icon={<IconComponent Icon={ICON.CHAT} />}
        text="Chat Einstellungen"
        onClick={showChatSettings}
      />
      <SettingsButton
        icon={<IconComponent Icon={ICON.ACCOUNT_SETTINGS} />}
        text="Account Einstellungen"
        onClick={showAccountSettings}
      />
      {(import.meta.env.DEV) && (
      <SettingsButton
        icon={<></>}
        onClick={resetStore}
        text="[DEBUG] RESET STORE"
      />
      )}
      <div className={styles.logoutContainer}>
        <button type="button" className={styles.logoutButton} onClick={onLogout}>
          Logout
        </button>

      </div>
    </div>
  );
};

export default Settings;
