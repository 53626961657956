import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../client';
import { Parameters, RequestBody } from '../../../../../types/typeHelper';
import { openErrorDialog } from '../../../../slices/errorDialogSlice/errorDialogSlice';
import { useHandleQueryError } from '../../../../helper/errorMessages';
import { ERROR_DIALOG } from '../../../../constants/errorDialogConstants';

export enum ConnectionMembersQueryKeys {
  GET_CONNECTION_MEMBERS = 'GET_CONNECTION_MEMBERS',
}

export function useGetConnectionMembers(query: Parameters<'/chat/connection-members', 'get'>['query']) {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    queryKey: [
      ConnectionMembersQueryKeys.GET_CONNECTION_MEMBERS,
      query,
    ],
    enabled: !!(query.userId && query.houseId),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/connection-members', {
        params: {
          query,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.NONE,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
  });
}


export function useUpdateConnectionMemberMutation() {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation({
    mutationFn: async ({ params, body }: {
      params: Parameters<'/chat/connection-members/{connection_member_id}', 'put'>,
      body: RequestBody<'/chat/connection-members/{connection_member_id}', 'put'>,
    }) => {
      return client.PUT('/chat/connection-members/{connection_member_id}', {
        params, body,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey:
            [ConnectionMembersQueryKeys.GET_CONNECTION_MEMBERS] });
    },
  });
}

export function useCreateConnectionMemberMutation() {
  const client = useApiClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async ({ body }: { body: RequestBody<'/chat/connection-members', 'post'> }) => {
      const response = await client.POST('/chat/connection-members', { body });
      return response.data;
    },
    onError: async (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message }));
    },
  });
}
