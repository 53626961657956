import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputQuotedMessage,
  getChannelInputIsSendingDisabled,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { channelInputCleared, channelInputFileStaged } from 'gcs-common/slices/messageInput/messageInputSlice';
import { TWILIO_MAX_UPLOAD_SIZE } from 'gcs-common/constants/fileSizes';
import MessageInputField from './MessageInputField/MessageInputField';
import styles from './styles.module.scss';
import StageSelectedItem from './StageSelectedItem/StageSelectedItem';
import QuotedMessage from '../QuotedMessage/QuotedMessage';
import SendButton from './SendButton/SendButton';
import {
  getShowMagicCubeButton,
  getShowSendButton,
} from '../../slices/messageInput/messageInputSelector';
import MagicCubeButton from './MagicCubeButton/MagicCubeButton';
import FileInputButtons from '../FileInputButtons/FileInputButtons';
import { getShowFilePicker } from '../../slices/uiState/uiStateSelectors';
import FilePicker from './FilePicker/FilePicker';


const MessageInputArea = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const quotedMessageId = useSelector(getChannelInputQuotedMessage(selectedChannelId));
  const isSendingDisabled = useSelector(getChannelInputIsSendingDisabled(selectedChannelId));
  const showSendButton = useSelector(getShowSendButton(selectedChannelId));
  const showShowMagicCubeButton = useSelector(getShowMagicCubeButton(selectedChannelId));
  const showFilePicker = useSelector(getShowFilePicker);

  const dispatch = useDispatch();

  const resetQuotedMessage = useCallback(() => {
    dispatch(channelInputCleared({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  const stageFileCallback = useCallback(async ({ channelId, file }) => {
    await dispatch(channelInputFileStaged({ channelId, file }));
  }, [dispatch]);

  return (
    <div className={styles.inputArea} data-testid="message-input-area">
      <div className={`${styles.writeField}`}>
        <div className={styles.writeInputWrapper}>
          <StageSelectedItem />
          {quotedMessageId && (
            <div className={styles.replyWrapper}>
              <QuotedMessage
                onCancel={resetQuotedMessage}
                messageId={quotedMessageId}
                disableClickMedia
              />
            </div>
          )}
          <div className={styles.writeForm}>
            <FileInputButtons
              stageFileCallback={stageFileCallback}
              isSendingDisabled={isSendingDisabled}
            />
            <div className={styles.inputFieldWrapper}>
              <MessageInputField />
            </div>
            {showShowMagicCubeButton && <MagicCubeButton />}
            {showSendButton && <SendButton />}
          </div>
        </div>
      </div>
      {showFilePicker
      && (
      <FilePicker
        fileSizeLimit={TWILIO_MAX_UPLOAD_SIZE}
        stageFileCallback={stageFileCallback}
      />
      )
      }
    </div>


  );
};

export default MessageInputArea;
