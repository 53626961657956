import { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIsRegistered, getIsAgent, getIsCurrentUserHasConnections } from 'gcs-common/slices/currentUser/currentUserSelectors';
import fetchCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUser';
import { getGccApiClientInit } from 'gcs-common/slices/gccApiConnection/gccApiConnectionSelectors';
import { initGccApi } from 'gcs-common/slices/gccApiConnection/gccApiConnectionThunks';
import GccApiForbiddenError from 'gcs-common/clients/gccApiClient/errors/GccApiForbiddenError';
import GccApiUnauthorizedError from 'gcs-common/clients/gccApiClient/errors/GccApiUnauthorizedError';
import AppMustUpgradeError from 'gcs-common/clients/gccApiClient/errors/AppMustUpgradeError';
import useUserlane from 'gcs-common/hooks/useUserlane';
import { getApiBaseUrl } from 'gcs-common/slices/theme/themeSelectors';
import { REGISTRATION_ROUTES } from 'gcs-common/constants/routes';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import usePauseResumeEventListener from '../../customHooks/usePauseResumeEventListener';

import { showSimpleNotification } from '../../slices/notification/notificationThunks';
import { openAppMustUpdateDialog } from '../../slices/uiState/uiStateSlice';
import httpRequest from '../../helper/httpRequest';

function LoggedInRoot() {

  const registered = useSelector(getIsRegistered);
  const gccApiClientInit = useSelector(getGccApiClientInit);
  const isAgent = useSelector(getIsAgent);
  const isCurrentUserHasConnections = useSelector(getIsCurrentUserHasConnections);
  const apiBaseUrl = useSelector(getApiBaseUrl);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  usePauseResumeEventListener();
  // NOTE: To show a changelog, enable,
  // set content ("setInfoContent(<SideMenuInfo />)")
  // add route "/changelog" <Changelog content={infoContent} {...props} />
  // and go to route ("history.push('/changelog'))
  // const [infoContent, setInfoContent] = useState(null);

  const onApiRequestError = useCallback((e) => {
    if (e instanceof GccApiForbiddenError) {
      dispatch(showSimpleNotification('Zugriff nicht erlaubt'));
    }
    if (e instanceof GccApiUnauthorizedError) {
      dispatch(showSimpleNotification('Zugriff nicht erlaubt'));
    }
    if (e instanceof AppMustUpgradeError) {
      dispatch(openAppMustUpdateDialog({ config: e.mustUpgradeConfig }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!gccApiClientInit) {
      dispatch(initGccApi({
        onRequestError: onApiRequestError,
        onHttpRequest: async (options) => httpRequest(options),
        apiBaseUrl,
      }));
    }
  }, [
    dispatch, gccApiClientInit, onApiRequestError, apiBaseUrl,
  ]);

  useEffect(() => {
    if (gccApiClientInit) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch, gccApiClientInit]);

  useEffect(() => {
    /**
     * TODO SMOOT-1750 Move the registration setup screens
     * (Terms, Privacy, GCOP, etc.) to Universal Login
     */
    if (registered === false) {
      if (isAgent || isCurrentUserHasConnections) {
        navigate(REGISTRATION_ROUTES.REGISTRATION_PROFILE);
      } else {
        navigate(REGISTRATION_ROUTES.REGISTRATION);
      }
    }
  }, [registered, dispatch, navigate, isAgent, isCurrentUserHasConnections]);

  useUserlane();

  if (!gccApiClientInit) {
    return <LoadingIndicator />;
  }

  return <Outlet />;
}

export default LoggedInRoot;
