import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import menuIcon from '../../img/icons/menu.svg';
import SideMenuMessage from '../SideMenu/SideMenuMessage/SideMenuMessage';
import Button from '../Button/Button';
import { setSideMenuOpen } from '../../slices/uiState/uiStateSlice';
import styles from './styles.module.scss';

const NoChannels = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuButtonClick = useCallback(() => {
    dispatch(setSideMenuOpen());
  }, [dispatch]);

  const handleNewChatButtonClick = () => {
    navigate('/newChat');
  };

  return (
    <div className={styles.noChannelsWrapper}>
      <div className={styles.top}>
        <button
          className={styles.menuButton}
          onClick={handleMenuButtonClick}
          type="button"
        >
          <img alt="logo" src={menuIcon} />
        </button>
      </div>
      <SideMenuMessage
        iconStyles={styles.warningMessageIcon}
        text="Sie haben noch keinen Chat. Klicken Sie “Neuen Chat starten”."
        wrapperStyles={styles.warningMessageWrapper}
      />
      <Button
        className={styles.newChannelButton}
        onClick={handleNewChatButtonClick}
        type="button"
      >
        Neuen Chat starten
      </Button>
    </div>
  );
};

export default NoChannels;

NoChannels.propTypes = {};
