import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
} from 'gcs-common/slices/processes/processesSelectors';
import {
  processSelected,
  productSelected,
} from 'gcs-common/slices/processes/processesSlice';
import {
  getSelectedChannelId,

} from 'gcs-common/slices/channels/channelsSelectors';
import { channelInputCleared } from 'gcs-common/slices/messageInput/messageInputSlice';
import { getChannelOPUserId, getPrimaryHouseForChannel } from 'gcs-common/slices/channel/channelSelectors';
import { getSelectedProduct, useGetProcessProductsList } from 'gcs-common/clients/api/entities/processes/processes-api-selectors';
import {
  convertOrderStatusToString,
  convertProcessTypeToString,
  getUniqueProcessNumber,
  getUniqueProductNumber,
} from 'gcs-common/helper/processTextHelper';
import { ICON, ICON_COLOR, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { FetchProcessDetailsResponseData, FetchProcessesResponseData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import RenderButtons from './RenderButtons/RenderButtons';
import ProductListItem from './ProductListItem/ProductListItem';
import ProductDetail from './ProductDetail/ProductDetail';

import styles from './styles.module.scss';
import InternetConnectionBanner from '../InternetConnectionBanner/InternetConnectionBanner';

interface ProcessDetailProps {
  process: FetchProcessesResponseData[number];
}

const ProcessDetail = (props: ProcessDetailProps) => {
  const { process: selectedProcess } = props;

  const {
    ProcessNumber: Number,
    ProcessSuffix: Suffix,
    OrderStatusText,
    OrderStatus,
    CreationDate: CreateDate,
    CustomerOrderText,
    CustomerOrderNumber,
    ProcessType,
    ProcessTypeDescription,
  } = selectedProcess;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  const selectedProduct = getSelectedProduct();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelOpUserId: string = useSelector(getChannelOPUserId(selectedChannelId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getPrimaryHouseForChannel(selectedChannelId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);

  const dispatch = useDispatch();

  const { data: products, isFetching } = useGetProcessProductsList({
    house_id: houseId,
    process_number: Number,
    suffix: Suffix,
    user_id: isAgent ? channelOpUserId : undefined,
  });

  const List = products || [];

  const requestClose = () => {
    if (selectedProduct) {
      dispatch(productSelected({ selectedProductNumber: undefined }));
    } else {
      dispatch(processSelected({ selectedProcessNumber: undefined }));
      dispatch(channelInputCleared({ channelId: selectedChannelId }));
    }
  };

  const handleSelectProduct = (product: FetchProcessDetailsResponseData['items'][number]) => {
    dispatch(productSelected({
      selectedProductNumber: getUniqueProductNumber(product.ArticleNumber, product.Id),
    }));
  };

  return (
    <div className={styles.selectedProcessWrapper}>
      <div className={styles.selectedProcessHeader}>
        <button type="button" onClick={requestClose}>
          <IconComponent
            Icon={ICON.ARROW}
            color={ICON_COLOR.LIGHT_GREY}
            transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
          />
        </button>
      </div>
      <InternetConnectionBanner />
      {(
        selectedProduct
          ? (
            <ProductDetail
              product={selectedProduct}
            />
          )
          : (
            <>
              <div className={styles.selectedProcessInfo}>
                <div>
                  {moment(CreateDate).format('dddd,  L')}
                </div>
                <div className={styles.processName}>
                  {`${convertProcessTypeToString(ProcessType, ProcessTypeDescription)}: `}
                  <span>{getUniqueProcessNumber(Number, Suffix)}</span>
                </div>
                <div className={styles.processHint}>
                  Auftragsnummer:
                  {' '}
                  {CustomerOrderNumber}
                </div>
                <div className={styles.processHint}>
                  Auftragstext:
                  {' '}
                  {CustomerOrderText}
                </div>
                <div className={styles.processState}>{`Status: ${convertOrderStatusToString(OrderStatus, OrderStatusText, ProcessType) || 'Nicht verfügbar'}`}</div>
              </div>
              <div className={styles.selectedProcessProductList}>
                {List && List.map(
                  (product, index) => (
                    <ProductListItem
                      key={(product.ArticleNumber ? product.ArticleNumber : index) + product.Id}
                      product={product}
                      onSelectProduct={() => handleSelectProduct(product)}
                    />
                  ),
                )}
              </div>
            </>
          )
      )}
      <div className={styles.buttonContainer}>
        {isFetching && <div className={styles.loader}><LoadingIndicator /></div>}
        {!isFetching && (
          <RenderButtons
            process={selectedProcess}
            product={selectedProduct}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessDetail;
