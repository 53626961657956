import { Response, Parameters } from '../../../../../types/typeHelper';

export type FetchProcessesResponseData = Response<'/chat/processes', 'get'>;
export type FetchProcessDetailsResponseData = Response<'/chat/process-details', 'get'>;
export type FetchProcessesFilterSuggestionsData = Response<'/chat/process-filter-suggestions', 'get'>;

export enum ProcessesClientQueryKeys {
  GET_PROCESSES = 'GET_PROCESSES',
  GET_PROCESS_DETAILS = 'GET_PROCESS_DETAILS',
  GET_PROCESSES_FILTER_SUGGESTIONS = 'GET_PROCESSES_FILTER_SUGGESTIONS',
}

export const getGetProcessesQueryKey = (query: Omit<Parameters<'/chat/processes', 'get'>['query'], 'page'>) => [
  ProcessesClientQueryKeys.GET_PROCESSES, query];

export const getGetProcessDetailsQueryKey = (query: Parameters<'/chat/process-details', 'get'>['query']) => [
  ProcessesClientQueryKeys.GET_PROCESS_DETAILS, query];

export const getGetProcessesFilterSuggestionsQueryKey = (query: Parameters<'/chat/process-filter-suggestions', 'get'>['query']) => [
  ProcessesClientQueryKeys.GET_PROCESSES_FILTER_SUGGESTIONS, query];
