import { Formik } from 'formik';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { captureMessage } from '@sentry/react';
import * as yup from 'yup';
import {
  getSelectedOrderId,
} from '../../slices/quickOrdering/quickOrderingSelectors';
import { ORDER_STATUS } from '../../slices/quickOrdering/quickOrderingConstants';
import { channelInputOrderStaged } from '../../slices/messageInput/messageInputSlice';
import { getSelectedChannelId } from '../../slices/channels/channelsSelectors';
import { getMemberCraftsmenIdsForChannel } from '../../slices/channel/channelSelectors';
import { openErrorDialog } from '../../slices/errorDialogSlice/errorDialogSlice';
import { getIsAgent } from '../../slices/currentUser/currentUserSelectors';
import styles from './styles.module.scss';
import { ApiUpdateOrderBody, useGetOrderQuery, useSubmitOrderMutation, useUpdateOrderMutation } from '../../clients/api/entities/order-api';
import QuickOrderForm from '../QuickOrderForm/QuickOrderForm';
import { goToCheckoutFinished } from '../../slices/quickOrdering/quickOrderingSlice';

const quickOrderValidationSchema = yup.object().shape({
  id: yup.string(),
  title: yup.string(),
  customerOrderNumber: yup.string(),
  customerOrderText: yup.string(),
  status: yup.mixed<ORDER_STATUS>().oneOf(Object.values(ORDER_STATUS)).required(),
  processNumber: yup.string(),
  deliveryAddress: yup.object().shape({
    name: yup.string(),
    city: yup.string(),
    zip: yup.string(),
    street: yup.string(),
    streetNumber: yup.string(),
  }),
  orderPositions: yup.array().of(yup.object().shape({
    id: yup.string(),
    kbn: yup.string(),
    quantityUnit: yup.string(),
    description: yup.string(),
    externalArticleId: yup.string(),
    picturePath: yup.string(),
    quantity: yup.number().positive('Die Menge muss größer als 0 sein.').required('Bitte geben Sie eine Menge ein').typeError('Bitte geben Sie eine Zahl ein'),
    netPrice: yup.number().positive('Der Preis muss größer als 0 sein.').required('Bitte geben Sie einen Preis ein').typeError('Bitte geben Sie eine Zahl ein'),
    opNetPrice: yup.number().nullable(),
  })).required(),
});

type InferredQuickOrderFormValues = yup.InferType<typeof quickOrderValidationSchema>;

// Overwrite the type to make netPrice optional but still have validation
export type QuickOrderFormValues = Omit<InferredQuickOrderFormValues, 'orderPositions'> & {
  orderPositions: (Omit<InferredQuickOrderFormValues['orderPositions'][number], 'netPrice' | 'quantity' | 'opNetPrice'> & {
    netPrice?: string;
    opNetPrice?: string;
    quantity: string;
  })[];
};

const QuickOrder = () => {
  const dispatch = useDispatch();

  const selectedOrderId = useSelector(getSelectedOrderId);
  const { data: selectedOrder } = useGetOrderQuery({ order_id: selectedOrderId! });
  const updateOrderMutation = useUpdateOrderMutation();
  const submitOrderMutation = useSubmitOrderMutation();

  const initialValues : QuickOrderFormValues = {
    id: selectedOrder?.id || undefined,
    title: selectedOrder?.title || '',
    processNumber: selectedOrder?.processNumber || '',
    customerOrderNumber: selectedOrder?.customerOrderNumber || '',
    customerOrderText: selectedOrder?.customerOrderText || '',
    status: selectedOrder?.status as ORDER_STATUS || ORDER_STATUS.LOCAL_DRAFT,
    deliveryAddress: {
      name: selectedOrder?.deliveryAddress?.name || '',
      city: selectedOrder?.deliveryAddress?.city || '',
      zip: selectedOrder?.deliveryAddress?.zip || '',
      street: selectedOrder?.deliveryAddress?.street || '',
      streetNumber: selectedOrder?.deliveryAddress?.streetNumber || '',
    },
    orderPositions: selectedOrder?.orderPositions || [],
  };

  const channelId = useSelector(getSelectedChannelId);
  const craftsmanIds: string[] = useSelector(getMemberCraftsmenIdsForChannel(channelId));

  const isAgent = useSelector(getIsAgent);

  const insertOrderToChat = useCallback((values: QuickOrderFormValues) => {
    const {
      customerOrderNumber,
      customerOrderText,
      deliveryAddress,
      title,
      orderPositions,
    } = values;

    if (!craftsmanIds || craftsmanIds.length !== 1) {
      dispatch(openErrorDialog({ dialogErrorMsg: 'Die Bestellung kann nur in einem Channel mit Handwerker hinzugefügt werden.' }));
      captureMessage('Craftsman not found', { level: 'error' });
      throw new Error('Craftsman not found');
    }
    const craftsmanId = craftsmanIds[0];

    dispatch(channelInputOrderStaged({
      channelId,
      textPlaceholder: 'Füge eine Nachricht hinzu (optional)',
      order: {
        craftsmanId,
        title,
        customerOrderText,
        customerOrderNumber,
        deliveryAddress,
        orderPositions,
      },
    }));
  }, [channelId, craftsmanIds, dispatch]);

  const onUpdateOrder = async (
    { initValues, values }:
    { initValues: QuickOrderFormValues, values: QuickOrderFormValues },
  ) => {
    const updates: ApiUpdateOrderBody = {};

    ([
      'customerOrderNumber',
      'customerOrderText',
    ] satisfies (keyof ApiUpdateOrderBody)[]).forEach((key) => {
      if (initValues[key] !== values[key]) {
        updates[key] = values[key];
      }
    });

    (['name', 'city', 'zip', 'street', 'streetNumber'] satisfies (keyof NonNullable<ApiUpdateOrderBody['deliveryAddress']>)[]).forEach((key) => {
      if (initValues.deliveryAddress[key] !== values.deliveryAddress[key]) {
        if (!updates.deliveryAddress) updates.deliveryAddress = {};
        updates.deliveryAddress[key] = values.deliveryAddress[key];
      }
    });

    const updatedPositions: { id: string, quantity: string }[] = [];
    const deletedPositions: string[] = [];

    // Updating existing positions
    initValues.orderPositions.forEach((initialPos) => {
      const newPos = values.orderPositions.find(pos => pos.id === initialPos.id);
      if (newPos && initialPos.id && initialPos.quantity !== newPos.quantity) {
        updatedPositions.push({
          id: initialPos.id,
          quantity: newPos.quantity,
        });
      }
      if (!newPos && initialPos.id) {
        deletedPositions.push(initialPos.id);
      }
    });

    // Setting updates
    if (updatedPositions.length > 0) {
      updates.orderPositions = updatedPositions;
    }
    if (deletedPositions.length > 0) {
      updates.deletedOrderPositions = deletedPositions;
    }

    if (Object.keys(updates).length > 0) {
      await updateOrderMutation.mutateAsync({
        params: { path: { order_id: values.id! } },
        body: updates,
      });
    }

    if (!selectedOrderId) {
      return;
    }

    submitOrderMutation.mutateAsync({
      params: {
        path: {
          order_id: selectedOrderId,
        },
      },
    }).then(() => {
      dispatch(goToCheckoutFinished());
    });


  };

  const onSubmit = async (values: QuickOrderFormValues) => {

    if (values.status === ORDER_STATUS.LOCAL_DRAFT) {
      insertOrderToChat(values);
    } else if (values.status === ORDER_STATUS.OPEN) {
      onUpdateOrder({ initValues: initialValues, values });
    } else {
      throw new Error('Invalid order status');
    }

  };

  if (selectedOrderId && !selectedOrder) {
    return null;
  }

  if (!isAgent && !selectedOrderId) {
    return (
      <div className={styles.craftsmanInfo}>
        Schnellbestellungen erhalten sie von ihrem Agenten als Nachricht.
        Klicken sie diese im Chat an, um eine Bestellung zu tätigen.
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={quickOrderValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <QuickOrderForm
          {...formikProps}
          isLoading={updateOrderMutation.isPending || submitOrderMutation.isPending}
        />
      )}
    </Formik>
  );
};

export default QuickOrder;
