/* eslint-disable react/jsx-pascal-case */
import { Check } from '@project-lary/react-material-symbols';
import de from 'react-phone-number-input/locale/de.json';
import React, { useState } from 'react';
import * as RPNInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'gcs-common/design-system/ui/command';
import { Button, ButtonVariant } from 'gcs-common/design-system/ui/button';
import { Input, InputProps } from 'gcs-common/design-system/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'gcs-common/design-system/ui/popover';
import { ScrollArea } from 'gcs-common/design-system/ui/scroll-area';
import { Label } from 'gcs-common/design-system/ui/label';
import { cn } from '../utils/tailwind-merge';

type PhoneInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> &
Omit<RPNInput.Props<typeof RPNInput.default>, 'onChange'> & {
  onChange?: (value: RPNInput.Value) => void;
  label?: string;
  message?: string;
  error?: boolean;
  searchable?: boolean;
};

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> = React.forwardRef<
React.ElementRef<typeof RPNInput.default>,
PhoneInputProps
>(({
  className, label, defaultCountry = 'DE', error, message, searchable, onChange, ...props
}, ref) => {
  return (
    <div>
      <Label htmlFor={props.id}>{label}</Label>

      <RPNInput.default
        ref={ref}
        className={cn('flex w-full rounded-md text-base ring-1', className, { '': error })}
        flagComponent={FlagComponent}
        countrySelectProps={{ options: de, searchable }}
        countrySelectComponent={CountrySelect}
        inputComponent={InputComponent}
        defaultCountry={defaultCountry}
        /**
         * Handles the onChange event.
         *
         * react-phone-number-input might trigger the onChange event as undefined
         * when a valid phone number is not entered. To prevent this,
         * the value is coerced to an empty string.
         *
         * @param {E164Number | undefined} value - The entered value
         */
        onChange={(value) => onChange?.((value as any) || '')}
        {...props}
      />
      {message && (
        <div className={cn('font-regular mt-2 text-sm text-neutral-11', { 'text-error-alpha-11': error })}>
          {message}
        </div>
      )}
    </div>
  );
});
PhoneInput.displayName = 'PhoneInput';

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => (
  <Input className={cn('w-full grow rounded-s-none text-neutral-11 ring-0', className)} {...props} ref={ref} />
));
InputComponent.displayName = 'InputComponent';

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
  searchable?: boolean;
};

const CountrySelect = ({
  value, onChange, options, searchable,
}: CountrySelectProps) => {
  const handleSelect = React.useCallback(
    (country: RPNInput.Country) => {
      onChange(country);
    },
    [onChange],
  );
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} modal>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant={ButtonVariant.surface_neutral}
          className="bg-transparent flex h-full flex-row items-center justify-center rounded-md px-3"
        >
          <FlagComponent country={value} countryName={value} />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="mt-3 p-0">
        <Command>
          <CommandList className="z-10 w-[310px] overflow-hidden ring-1 ring-neutral-5 lg:w-[345px]">
            <ScrollArea className="h-72">
              {searchable && <CommandInput placeholder="Land suchen..." tabIndex={0} />}
              <CommandEmpty>Kein Land gefunden.</CommandEmpty>
              <CommandGroup>
                {options
                  .filter((x) => x.value)
                  .map((option) => (
                    <CommandItem
                      className="gap-2"
                      key={option.value}
                      onSelect={() => {
                        setIsOpen(false);
                        handleSelect(option.value);
                      }}
                    >
                      <FlagComponent country={option.value} countryName={option.label} />
                      <span className="flex-1 text-sm">{option.label}</span>
                      {option.value && (
                        <span className="text-foreground/50 text-sm">
                          {`+${RPNInput.getCountryCallingCode(option.value)}`}
                        </span>
                      )}
                      <Check className={cn('ml-auto size-4', option.value === value ? 'opacity-100' : 'opacity-0')} />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  const Flag = flags[country];

  return (
    <span className="bg-foreground/20 flex h-4 w-6 overflow-hidden rounded-sm">
      {Flag && <Flag title={countryName} />}
    </span>
  );
};
FlagComponent.displayName = 'FlagComponent';

export { PhoneInput };
