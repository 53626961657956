import { getShowFilePicker } from '../uiStateSelectors';
import { hideFilePicker } from '../uiStateSlice';


const hideFilePickerFromDom = ({ targetDomElement }) => (dispatch, getState) => {
  const isFilePickerVisible = getShowFilePicker(getState());
  if (isFilePickerVisible) {
    let current = targetDomElement;
    do {
      const { id } = current;
      if (id && id === 'showFilePickerButton') {
        return;
      }
      // eslint-disable-next-line no-cond-assign
    } while (current = current.parentNode);
    dispatch(hideFilePicker());
  }
};

export default hideFilePickerFromDom;
