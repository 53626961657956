import Modal from 'react-modal';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { getDialogError } from 'gcs-common/slices/errorDialogSlice/errorDialogSelectors';
import { ERROR_DIALOG } from 'gcs-common/constants/errorDialogConstants';
import styles from './styles.module.scss';

const ErrorDialog = () => {
  const {
    dialogErrorButtonText,
    dialogErrorMsg,
    dialogErrorTitle,
    errorDialog,
  } = useSelector(getDialogError);
  const dispatch = useDispatch();
  const onCloseErrorDialog = useCallback(() => {
    dispatch(closeErrorDialog());
  }, [dispatch]);

  if (!dialogErrorMsg || errorDialog === ERROR_DIALOG.NONE) {
    return <div data-testid="empty-error-dialog" />;
  }

  return (
    <Modal
      isOpen={!!dialogErrorMsg}
      onRequestClose={onCloseErrorDialog}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      contentLabel={dialogErrorTitle ?? 'Error Modal'}
    >

      <div data-testid="popupmodal" className={styles.dialogErrorMsg}>{dialogErrorMsg}</div>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="dialogButtons">
        <button
          type="button"
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={`dialogButton ${styles.dialogButton}`}
          onClick={onCloseErrorDialog}
        >
          {dialogErrorButtonText ?? 'OK'}
        </button>
      </div>

    </Modal>
  );
};

export default ErrorDialog;
