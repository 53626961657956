
// Promise.all but with concurrency limiting. Array of functions (not promises)
// must be passed
const batchedPromiseAll = async (functions, batchSize) => {
  const functionsCopy = [...functions];
  while (functionsCopy.length) {
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(functionsCopy.splice(0, batchSize).map(f => f()));
  }
};

export default batchedPromiseAll;
