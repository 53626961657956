import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import ZoomWrapper from 'gcs-common/components/ZoomWrapper/ZoomWrapper';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import { closeTicketMediaFullScreen } from '../../../../../slices/uiState/uiStateSlice';

interface MediaFullscreenProps {
  mediaFullScreenUrl: string;
}

const MediaFullscreen = (props: MediaFullscreenProps) => {
  const { mediaFullScreenUrl } = props;
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeTicketMediaFullScreen());
  };
  return (
    <div className={styles.mediaFullscreenWrapper}>
      <button type="button" onClick={() => close()}>
        <div className={styles.icon}>
          <IconComponent
            Icon={ICON.CLOSE}
            color={ICON_COLOR.WHITE}
          />
        </div>

      </button>
      <div className={styles.img}>
        <ZoomWrapper>
          <img
            ref={imageRef}
            alt="Bild aus Nachricht"
            src={mediaFullScreenUrl}
          />
        </ZoomWrapper>
      </div>
    </div>
  );
};

export default MediaFullscreen;
