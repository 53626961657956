import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputIsSendingFiles,
  getChannelInputStagedFiles,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { channelInputCleared, channelInputFileUnstaged } from 'gcs-common/slices/messageInput/messageInputSlice';
import { isImage } from 'gcs-common/helper/fileHelper';
import { selectedChannelIdChanged } from 'gcs-common/slices/channels/channelsSlice';
import { getSelectedChannelForSharing } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import {
  channelForFileSharingSelected, filesShareClosed,
  sharedFilesCleared,
} from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSlice';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import LocalFilePreview from '../LocalFilePreview/LocalFilePreview';
import ThumbnailPreview from './ThumbnailPreview/ThumbnailPreview';

function FilePreviewer({ multiple = true }) {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const loading = useSelector(getChannelInputIsSendingFiles(selectedChannelId));
  const files = useSelector(getChannelInputStagedFiles(selectedChannelId));
  const channelForFilesSharing = useSelector(getSelectedChannelForSharing);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [imageIndex, setCurrentImageIndex] = useState(0);

  const innerRef = useRef(null);

  useEffect(() => {
    if (loading && channelForFilesSharing.length) {
      dispatch(channelForFileSharingSelected({ channelId: '' }));
      dispatch(sharedFilesCleared());
      dispatch(filesShareClosed());
      navigate('/');
    }
  }, [dispatch, navigate, channelForFilesSharing, loading]);

  useEffect(() => {
    if (multiple) {
      setCurrentImageIndex(files.length - 1);
    }
  }, [files, multiple]);

  const onRemoveImage = useCallback((file) => {
    dispatch(channelInputFileUnstaged({ channelId: selectedChannelId, file }));
    if (files.length === 1 && channelForFilesSharing.length) {
      dispatch(channelForFileSharingSelected({ channelId: '' }));
      dispatch(selectedChannelIdChanged({ selectedChannelId: null }));
    }
  }, [dispatch, selectedChannelId, files, channelForFilesSharing]);

  const onClose = useCallback(() => {
    dispatch(channelInputCleared({ channelId: selectedChannelId }));
    dispatch(channelForFileSharingSelected({ channelId: '' }));
    dispatch(selectedChannelIdChanged({ selectedChannelId: null }));
  }, [dispatch, selectedChannelId]);

  const onClick = useCallback((index) => {
    setCurrentImageIndex(index);
  }, []);

  const currentImage = files[imageIndex];

  return (
    <div className={styles.filePreviewer} ref={innerRef} data-testid="file-previewer">
      {loading
        && (
          <div className={styles.previewOverlay} />
        )
        }
      <div
        className={styles.previewMain}
      >
        <div className={styles.filePreviewHeader}>
          <div className={styles.fileTypeLogo}>
            <IconComponent Icon={isImage ? ICON.IMAGE : ICON.FILE} color={ICON_COLOR.WHITE} />
          </div>
          {currentImage && (
            <div className={styles.headerText}>
              {currentImage.name}
            </div>
          )}
          <button
            disabled={loading}
            className={styles.closeButton}
            type="button"
            onClick={onClose}
          >
            <IconComponent
              Icon={ICON.CLOSE}
              color={ICON_COLOR.WHITE}
            />
          </button>
        </div>
        <SwipeableViews
          style={{ height: '100%' }}
          containerStyle={{ height: '100%' }}
          index={Math.max(0, imageIndex)}
          slideStyle={{ display: 'flex' }}
          onChangeIndex={onClick}
        >
          {files.map(file => (
            <div
              className={styles.flexibleImagePreview}
              key={file.name}
            >
              <LocalFilePreview
                className={isImage(file)
                  ? styles.mainPreview : styles.mainPreviewFile}
                alt="preview"
                file={file}
              />
            </div>
          ))
            }
        </SwipeableViews>
      </div>
      {files.length > 1
      && (
      <ThumbnailPreview
        files={files}
        currentIndex={imageIndex}
        loading={loading}
        onClick={onClick}
        onRemoveImage={onRemoveImage}
      />
      )
      }
    </div>

  );
}

FilePreviewer.propTypes = {
  multiple: PropTypes.bool,
};

export default FilePreviewer;
