import { ICON, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { ETS_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { useGetETSTicketNotificationsCountSelector } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import TicketsChannelItemNotificationsBadge from './TicketsChannelItemNotificationsBadge/TicketsChannelItemNotificationsBadge';

const TicketsChannelItem = () => {
  const navigate = useNavigate();
  const ticketNotificationsCount = useGetETSTicketNotificationsCountSelector();

  return (
    <button
      type="button"
      className={styles.ticketsChannelItem}
      onClick={() => {
        navigate(ETS_ROUTES.TICKETS);
      }}
    >
      <IconComponent Icon={ICON.ETS_MOBILE} size={ICON_SIZE.X_LARGE} className={styles.etsIcon} />

      <span className={styles.etsContent}>
        <span className={styles.etsText}>Ersatzteil-Anfragen</span>
        <span className={styles.etsNewBadge}>Beta</span>
      </span>

      {ticketNotificationsCount > 0
        && <TicketsChannelItemNotificationsBadge count={ticketNotificationsCount} />}

      <IconComponent Icon={ICON.ARROW} size={ICON_SIZE.X_LARGE} />
    </button>
  );
};

export default TicketsChannelItem;
