import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getAuthorNameColor,
  getMessageAuthorName,
  getMessageAuthorId,
} from 'gcs-common/slices/messages/messageSelector';
import { addUserToColorMap } from 'gcs-common/slices/channels/channelsSlice';
import { getUserLoaded } from 'gcs-common/slices/user/userSelectors';
import { fetchUser } from 'gcs-common/slices/users/usersThunks/fetchUser';
import styles from './styles.module.scss';

const MessageName = ({ messageId }) => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const authorName = useSelector(getMessageAuthorName(messageId, selectedChannelId));
  const authorId = useSelector(getMessageAuthorId(messageId));
  const isAuthorLoaded = useSelector(getUserLoaded(authorId));
  const usernameColor = useSelector(getAuthorNameColor(selectedChannelId, authorId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthorLoaded && authorId) {
      dispatch(fetchUser({ id: authorId }));
    }
  }, [authorId, dispatch, isAuthorLoaded]);

  useEffect(() => {
    if (!usernameColor) {
      // it is necessary to do this computation on demand
      // and not per selector since at any time messages from previously
      // unknown authors can be added at the beginning (load prev messages)
      // or the end (receive new message) of the channelMessages
      dispatch(addUserToColorMap({ channelId: selectedChannelId, id: authorId }));
    }
  }, [authorId, selectedChannelId, dispatch, usernameColor]);
  // do not show ugly flashing of default color if color is not yet defined but rather hide name
  const visibility = usernameColor ? 'visible' : 'hidden';
  return (
    <div style={{ color: usernameColor, visibility }} className={styles.nameHeader}>
      {authorName}
    </div>
  );
};

MessageName.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default MessageName;
