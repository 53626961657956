import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getChannelCountForChannelFilter } from 'gcs-common/slices/channelList/channelListSelectors';
import { getSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelTypeFilter';
import {
  CHANNEL_FILTER_TYPES,
} from 'gcs-common/helper/channelTypeHelper';
import { setSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSlice';
import { getHasMessagesUnreadForChannelFilter } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import { getSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import styles from './styles.module.scss';

const ChannelTypeSelectionTab = ({ filterType }) => {

  const channelTypeFilterCount = useSelector(getChannelCountForChannelFilter(filterType));
  const selectedChannelTypeFilter = useSelector(getSelectedChannelTypeFilter);
  const hasMessagesUnread = useSelector(getHasMessagesUnreadForChannelFilter(filterType));
  const isFilesSharing = useSelector(getSharedFiles).length;
  const dispatch = useDispatch();

  const selectChannelType = useCallback((selectedType) => {
    dispatch(setSelectedChannelTypeFilter({ selectedChannelTypeFilter: selectedType }));
  }, [dispatch]);

  const text = useMemo(() => {
    if (filterType === CHANNEL_FILTER_TYPES.DIRECT_CHATS) {
      return `Direkte Chats${channelTypeFilterCount !== undefined && !isFilesSharing ? ` (${channelTypeFilterCount})` : ''}`;
    }
    return `TeamChats${channelTypeFilterCount !== undefined && !isFilesSharing ? ` (${channelTypeFilterCount})` : ''}`;

  }, [filterType, channelTypeFilterCount, isFilesSharing]);

  return (
    <div className={styles.channelListTypeSelectorWrapper} key={filterType}>
      <button
        onClick={() => selectChannelType(filterType)}
        className={filterType === selectedChannelTypeFilter
          ? styles.selectedChannelFilterTypes
          : styles.channelTypeSelector}
        type="button"
      >
        <div className={styles.flexContainer}>
          {text}
          <div className={styles.dotContainer}>
            <div className={`${styles.dot} ${hasMessagesUnread ? styles.active : ''}`} />
          </div>
        </div>
      </button>
    </div>
  );
};

ChannelTypeSelectionTab.propTypes = {
  filterType: PropTypes.string.isRequired,
};

export default ChannelTypeSelectionTab;
