import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const RenderHeader = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.header}>
      <IconComponent Icon={ICON.CUBE} color={ICON_COLOR.PRIMARY} />
      <div className={styles.label}>
        ONLINE PLUS Vorgänge
      </div>
      <button
        type="button"
        onClick={() => navigate(-1)}
      >
        <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.LIGHT_GREY} />
      </button>
    </div>
  );
};
export default RenderHeader;
