import reactStringReplace from 'react-string-replace';
import { FetchProcessDetailsResponseData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import styles from './styles.module.scss';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

interface ProductListItemProps {
  product: FetchProcessDetailsResponseData['items'][number];
  onSelectProduct: VoidFunction;
}

const ProductListItem = (props: ProductListItemProps) => {
  const { product, onSelectProduct } = props;
  if (!product.ArticleNumber) {
    /* This is to remove <br> tags from GC Online Plus, also replace; with newline */
    const description1RawString = product.ArticleDescription1;
    let description1ReactNode = reactStringReplace(description1RawString, '<br>', () => (<br />));
    description1ReactNode = reactStringReplace(description1ReactNode, ';', () => (<br />));

    const description2RawString = product.ArticleDescription2;
    let description2ReactNode = reactStringReplace(description2RawString, '<br>', () => (<br />));
    description2ReactNode = reactStringReplace(description2ReactNode, ';', () => (<br />));

    return (
      <>
        <div className={styles.productTextOnly}>
          {description1ReactNode}
        </div>
        <div className={styles.productTextOnly}>
          {description2ReactNode}
        </div>
      </>
    );
  }

  return (
    <button
      type="button"
      onClick={() => onSelectProduct()}
      className={styles.productSmallWrapper}
    >
      <div className={styles.productArticleWrapper}>
        <div className={styles.productArticleNumber}>
          {product.ArticleNumber}
        </div>
      </div>
      <div className={styles.productDescription}>
        <div>{product.ArticleDescription1}</div>
        <div>{product.ArticleDescription2}</div>
        {/* <ProductQuantity
          product={product}
        /> */}
      </div>
    </button>
  );
};

export default ProductListItem;
