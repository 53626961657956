import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { pdfjs, Document, Page } from 'react-pdf';
import { useSwipeable } from 'react-swipeable';
import { useDispatch } from 'react-redux';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { captureException } from '@sentry/react';
import styles from './styles.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const PdfPreviewer = ({ pdfFile }) => {
  const [pdfNumPages, setPdfNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setPdfNumPages(numPages);
    setPageNumber(1);
  }, [setPdfNumPages, setPageNumber]);

  const changePage = useCallback((offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }, [setPageNumber]);

  const onSwipe = useSwipeable({
    onSwipedUp: () => {
      if (pageNumber < pdfNumPages) {
        changePage(1);
      }
    },
    onSwipedDown: () => {
      if (pageNumber > 1) {
        changePage(-1);
      }
    },
  });

  return (
    <div className={styles.pdfPreviewerWrapper} {...onSwipe}>
      <Document
        className={styles.pdfPreview}
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<div className={styles.msgPdfPreviewer}>Lade pdf...</div>}
        error={
          <div className={styles.msgPdfPreviewer}>PDF-Vorschau konnte nicht geladen werden</div>
        }
        onLoadError={(e) => {
          dispatch(openErrorDialog({ dialogErrorMsg: 'PDF-Vorschau konnte nicht geladen werden' }));
          captureException(e);
        }}
        onSourceError={(e) => captureException(e)}
      >
        <Page
          className={styles.pdfPreview}
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          loading={<div className={styles.msgPdfPreviewer}>Lade Seite...</div>}
          error={<div className={styles.msgPdfPreviewer}>Fehler beim Laden der Seite</div>}
          onLoadError={(e) => captureException(e)}
          onRenderError={(e) => captureException(e)}
        />
      </Document>
    </div>
  );
};

PdfPreviewer.propTypes = {
  // eslint-disable-next-line
  pdfFile: PropTypes.object.isRequired,
};

export default PdfPreviewer;
