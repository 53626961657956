import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  FormLabel,
} from 'gcs-common/design-system/ui/form';
import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import { Input } from 'gcs-common/design-system/ui/input';
import ProfilePicture from 'gcs-common/design-system/ui/profile-picture';
import { useSelector } from 'react-redux';
import { CameraProvider } from 'gcs-common/design-system/camera/camera-provider';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useSignedUploadMedia } from 'gcs-common/clients/api/entities/media/media-helper';
import { useUpdateCurrentUserMutation } from 'gcs-common/clients/api/entities/current-user/current-user-api';
import { useQueryParams } from 'gcs-common/design-system/hooks/use-query-params';
import OnlinePlusLoginReturnButton from '../OnlinePlusReturnButton/OnlinePlusReturnButton';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';

const FormSchema = z.object({
  friendlyName: z.string().min(1, { message: 'Der Benutzername darf nicht leer sein.' }),
  userCompany: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  imageUrl: z.string(),
});

const OnlinePlusProfileForm = () => {

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      friendlyName: '',
      userCompany: '',
      imageUrl: '',
      firstName: '',
      lastName: '',
    },
  });

  const { queryParams } = useQueryParams(['company', 'username']);
  const { company, username } = queryParams;

  const currentUserId = useSelector(getCurrentUserId);
  const updateUser = useUpdateCurrentUserMutation();
  const uploadSignedMedia = useSignedUploadMedia();

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const uploadedImageUrl = await uploadSignedMedia.uploadSignedMedia(data.imageUrl,
      currentUserId);
    const { friendlyName, firstName, lastName, userCompany } = data;
    await updateUser.mutateAsync({
      body: {
        imageUrl: uploadedImageUrl || '',
        friendlyName,
        userCompany,
        firstName,
        lastName,
      },
    });
  };

  return (
    <CameraProvider>
      <p className="text-2xl">
        Hallo
        {' '}
        {username}
        , willkommen bei
        {' '}
        {company || 'BEEM'}
      </p>
      <p className="text-base text-neutral-11">
        Lassen Sie uns Ihr Profil einrichten.
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6 flex h-full flex-col justify-between gap-4 text-left">
          <div className="flex grow flex-col gap-5">
            <FormField
              control={form.control}
              name="imageUrl"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ProfilePicture
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="friendlyName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Anzeigename</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      className="h-[40px] w-full px-2 placeholder:text-right"
                      placeholder="Erforderlich"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Vorname</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      className="h-[40px] w-full px-2 placeholder:text-right"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nachname</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      className="h-[40px] w-full px-2 placeholder:text-right"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="userCompany"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Firmenname</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      className="h-[40px] w-full px-2"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="text-center text-base text-neutral-11">
            <span>
              Mit einem Klick auf ‘Zustimmen’ stimmen Sie unseren
              {' '}
              <TermsAndConditions />
              {' '}
              zu.
            </span>
          </div>

          <div className="flex flex-col gap-4 pb-20">
            <Button
              type="submit"
              variant={ButtonVariant.solid_accent}
              id="request-code"
              size={ButtonSize.xl}
              className="w-full"
              isLoading={updateUser.isPending || uploadSignedMedia.isProcessing}
            >
              Zustimmen
            </Button>
            <OnlinePlusLoginReturnButton />
          </div>
        </form>
      </Form>
    </CameraProvider>
  );
};

export default OnlinePlusProfileForm;
