import { useDispatch, useSelector } from 'react-redux';
import {
  getProcessSearchInput,
} from 'gcs-common/slices/processes/processesSelectors';
import { suggestionSelected } from 'gcs-common/slices/processes/processesSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { PROCESSES_SUGGESTION_TYPE } from 'gcs-common/constants/processesSuggestionType';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { getChannelOPUserId, getPrimaryHouseForChannel } from 'gcs-common/slices/channel/channelSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useGetProcessesFilterSuggestions } from 'gcs-common/clients/api/entities/processes/processes-api';
import { FetchProcessesFilterSuggestionsData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import styles from './styles.module.scss';


const SuggestionList = () => {
  const searchInput = useSelector(getProcessSearchInput);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelOpUserId: string = useSelector(getChannelOPUserId(selectedChannelId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getPrimaryHouseForChannel(selectedChannelId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);

  const { data: suggestions, isFetching: suggestionsLoading } = useGetProcessesFilterSuggestions({
    query: {
      house_id: houseId,
      user_id: isAgent ? channelOpUserId : undefined,
      query_string: searchInput,
    },
  });

  const dispatch = useDispatch();

  const selectSuggestion = (suggestion: FetchProcessesFilterSuggestionsData[number]) => {
    dispatch(suggestionSelected({ suggestion }));
  };

  if (suggestionsLoading) {
    return (
      <div className={styles.center}>
        <LoadingIndicator key="loading-indicator" />
      </div>
    );
  }

  if (searchInput.length > 0 && (!suggestions || suggestions.length <= 0)) {
    return (
      <div className={styles.errorMessage}>
        {`Keine Vorschläge für die Suche nach '${searchInput}'.`}
      </div>
    );
  }

  return (
    <div className={styles.suggestionList}>
      {suggestions
        && suggestions.map((suggestion) => (
          <button
            type="button"
            onClick={() => selectSuggestion(suggestion)}
            className={styles.suggestionItem}
            key={`${suggestion.Suggestion}-${suggestion.Type}-${suggestion.ProcessNumber}`}
          >
            <div className={styles.suggestion}>
              {suggestion.Suggestion}
            </div>
            <div className={styles.suggestionType}>
              {PROCESSES_SUGGESTION_TYPE[suggestion.Type]}
            </div>
          </button>
        ))
      }
    </div>
  );
};

export default SuggestionList;
