import React, { useEffect, useImperativeHandle, useState } from 'react';
import { TriangleAlert, X } from 'lucide-react';
import { useCamera } from './camera-provider';
import { CameraProps, defaultErrorMessages } from './camera-types';

export const CameraView = React.forwardRef<unknown, CameraProps>(
  (
    { errorMessages = defaultErrorMessages, videoReadyCallback = () => null },
    ref,
  ) => {
    const {
      playerRef,
      canvasRef,
      containerRef,
      notSupported,
      permissionDenied,
      activeDeviceId,
      initCameraStream,
      takePhoto,
      stopStream,
    } = useCamera();

    useImperativeHandle(ref, () => ({
      takePhoto,
      stopCamera: stopStream,
    }));

    useEffect(() => {
      async function init() {
        await initCameraStream();
      }
      init();
    }, [activeDeviceId]);

    return (
      <div
        ref={containerRef}
        className="bg-muted"
      >
        <div className="w-full">
          <WarningMessage
            message={errorMessages.noCameraAccessible!}
            show={notSupported}
          />
          <WarningMessage
            message={errorMessages.permissionDenied!}
            show={permissionDenied}
          />
          <video
            className="h-60 w-full object-cover"
            ref={playerRef}
            id="video"
            muted
            autoPlay
            playsInline
            onLoadedData={videoReadyCallback}
          />
          <canvas className="hidden" ref={canvasRef} />
        </div>
      </div>
    );
  },
);

CameraView.displayName = 'CameraView';

function WarningMessage({ message, show }: { message: string; show: boolean }) {
  const [toShow, setShow] = useState(show);
  return toShow ? (
    <div className="bg-yellow-50 rounded-md p-4">
      <div className="flex">
        <div className="shrink-0">
          <TriangleAlert
            className="text-yellow-400 size-5"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-yellow-800 text-sm font-medium">
            Attention needed
          </h3>
          <div className="text-yellow-700 mt-2 text-sm">
            <p>{message}</p>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-m-1.5">
            <button
              type="button"
              className="bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50 inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
              onClick={() => setShow(false)}
            >
              <span className="sr-only">Dismiss</span>
              <X className="size-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
