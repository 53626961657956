import { useDispatch } from 'react-redux';
import { useCurrentlyRenderedData } from '@virtuoso.dev/message-list';
import PropTypes from 'prop-types';
import { messageVisibilityChanged } from '../../slices/messageVisibilityLoader/messageVisibilityLoaderThunks';

// workaround to get access to currently visible data via useCurrentlyRenderedData hook
export const MessageVisibilityObserver = ({ children }) => {
  const dispatch = useDispatch();

  const currentlyVisibleMessages = useCurrentlyRenderedData();

  if (currentlyVisibleMessages.length > 0) {
    dispatch(messageVisibilityChanged({
      firstIndex: currentlyVisibleMessages[0].index,
      lastIndex: currentlyVisibleMessages[currentlyVisibleMessages.length - 1].index,
    }));
  }

  return (
    <>
      {children}
    </>
  );
};

MessageVisibilityObserver.propTypes = {
  children: PropTypes.node,
};
