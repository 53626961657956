import { PROCESSES_SUGGESTION_TYPE } from 'gcs-common/constants/processesSuggestionType';
import { ProcessesState } from 'gcs-common/slices/processes/processesSlice';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { IS_IOS } from 'gcs-common/constants/deviceConstants';
import { useDebouncedCallback } from 'use-debounce';
import styles from './styles.module.scss';
import exitIcon from '../../img/icons/close.svg';

interface SearchBarProps {
  filter: ProcessesState['filter'];
  onSearchInputChange: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
  searchInput: string;
  resetFilter: VoidFunction;
  placeholder?: string;
  disabled?: boolean;
}

const SearchBar = (props: SearchBarProps) => {
  const {
    filter = null,
    onSearchInputChange,
    searchInput,
    resetFilter,
    placeholder = 'Vorgangsnummer, Erfassungsdatum, Auftragsnummer oder -text',
    disabled = false,
  } = props;

  const [inputValue, setInputValue] = useState(searchInput);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  useEffect(() => {
    setInputValue('');
  }, [selectedChannelId]);

  const [updateSearchInputValueDebounced] = useDebouncedCallback((
    $event: ChangeEvent<HTMLInputElement>,
  ) => {
    onSearchInputChange($event);
  }, 500);

  const onChange = ($event: ChangeEvent<HTMLInputElement>) => {
    const { value } = $event.target;
    setInputValue(value);
    updateSearchInputValueDebounced($event);
  };

  return (
    <div className={styles.search}>
      <div className={styles.searchInputWrapper}>
        <input
          disabled={disabled}
          className={`${styles.searchInput} ${!IS_IOS && styles.searchInputIcon}`}
          placeholder={placeholder}
          type="search"
          value={inputValue}
          onChange={onChange}
        />
        {IS_IOS
          && (
            <button
              type="button"
              className={styles.searchInputReset}
              onClick={resetFilter}
            >
              <img
                alt="reset-search-icon"
                src={exitIcon}
              />
            </button>
          )
        }
      </div>
      {filter
        && (
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            onClick={resetFilter}
            className={styles.filter}
          >
            <div>
              <div className={styles.suggestion}>
                {filter.name}
              </div>
              <div className={styles.suggestionType}>
                {PROCESSES_SUGGESTION_TYPE[filter.type]}
              </div>
            </div>
            <img
              alt="exit"
              src={exitIcon}
              className={styles.filterClose}
            />
          </div>
        )
      }
    </div>
  );
};

export default SearchBar;
