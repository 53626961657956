/* eslint-disable camelcase */
import { useSelector } from 'react-redux';
import { ICON } from 'gcs-common/constants/IconConstants';
import {
  getMemberDetailsSelectedChannelId,
  getMemberDetailsSelectedUserId,
} from 'gcs-common/slices/memberDetails/memberDetailsSelectors';
import {
  getChannelCompanyName,
  getChannelCustomerNumber,
  getPrimaryHouseForChannel,
} from 'gcs-common/slices/channel/channelSelectors';
import {
  getUserName,
  getUserImage,
  getUserEmail,
  getUserPhoneNumber,
  getUserIsAgent, getUserStatus,
} from 'gcs-common/slices/user/userSelectors';
import MemberPermissions from 'gcs-common/components/MemberPermissions/MemberPermissions';
import styles from './styles.module.scss';
import MemberContactItem from './MemberContactItem/MemberContactItem';
import MemberHeader from './MemberHeader/MemberHeader';


const MemberDetails = () => {
  const memberDetailsSelectedChannelId = useSelector(
    getMemberDetailsSelectedChannelId,
  );
  const selectedUserId = useSelector(getMemberDetailsSelectedUserId);
  const selectedUserPhoneNumber = useSelector(
    getUserPhoneNumber(selectedUserId),
  );
  const channelCompanyName = useSelector(
    getChannelCompanyName({
      userId: selectedUserId,
      channelId: memberDetailsSelectedChannelId,
    }),
  );
  const channelCustomerNumber = useSelector(
    getChannelCustomerNumber({
      userId: selectedUserId,
      channelId: memberDetailsSelectedChannelId,
    }),
  );
  const selectedUserFriendlyName = useSelector(getUserName(selectedUserId));
  const selectedUserImage = useSelector(getUserImage(selectedUserId));
  const selectedUserEmail = useSelector(getUserEmail(selectedUserId));
  const selectedUserIsAgent = useSelector(getUserIsAgent(selectedUserId));
  const selectedUserIsCraftsman = !selectedUserIsAgent;
  const userStatus = useSelector(getUserStatus(selectedUserId));

  const houseId = useSelector(
    getPrimaryHouseForChannel(memberDetailsSelectedChannelId),
  );

  return (
    <div className={styles.memberDetailsContainer}>
      <MemberHeader
        name={selectedUserFriendlyName}
        image={selectedUserImage}
        selectedUserIsAgent={selectedUserIsAgent}
        userStatus={userStatus}
      />

      <div className={styles.detailsSectionTitle}>Kontaktinformation</div>

      <div className={styles.detailsWrapper}>
        {channelCustomerNumber && selectedUserIsCraftsman && (
          <MemberContactItem title={channelCustomerNumber} Icon={ICON.STORE} />
        )}

        {channelCompanyName && selectedUserIsCraftsman && (
          <MemberContactItem title={channelCompanyName} Icon={ICON.STORE} />
        )}

        {selectedUserEmail && (
          <MemberContactItem title={selectedUserEmail} Icon={ICON.EMAIL} />
        )}

        {selectedUserPhoneNumber && (
          <MemberContactItem title={selectedUserPhoneNumber} Icon={ICON.CALL} />
        )}
      </div>
      {selectedUserIsCraftsman && <MemberPermissions houseId={houseId} userId={selectedUserId} />}
    </div>
  );
};

export default MemberDetails;
