import { QueryKey, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { trackGAEvent } from '../../../../slices/googleAnalytics/googleAnalyticsThunks';
import { FETCH_PROCESSES_ERRORS } from '../../../../slices/processes/processesConstants';
import { DEFAULT_ERROR_MESSAGE, getErrorMsg, throwError, useHandleQueryError } from '../../../../helper/errorMessages';
import { getCraftsmanBusinessUnit, getIsAgent } from '../../../../slices/currentUser/currentUserSelectors';
import { Parameters, Response } from '../../../../../types/typeHelper';
import {
  FetchProcessesFilterSuggestionsData,
  FetchProcessesResponseData,
  getGetProcessDetailsQueryKey,
  getGetProcessesFilterSuggestionsQueryKey,
  getGetProcessesQueryKey,
} from './processes-api-helpers';
import { useApiClient } from '../../client';
import { ERROR_DIALOG } from '../../../../constants/errorDialogConstants';

interface UseGetProcessesProps {
  query: Omit<Parameters<'/chat/processes', 'get'>['query'], 'page'>;
}

export function useGetProcesses({ query }: UseGetProcessesProps) {
  const client = useApiClient();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const businessUnit: string = useSelector(getCraftsmanBusinessUnit);

  return useInfiniteQuery<FetchProcessesResponseData | undefined,
  Error, FetchProcessesResponseData | undefined,
  QueryKey, number>({
    enabled: !!query.house_id,
    queryKey: getGetProcessesQueryKey(query),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (!lastPage || (lastPage && lastPage.length === 0)) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    queryFn: async ({ pageParam }) => {
      const { data, error } = await client.GET('/chat/processes', {
        params: {
          query: {
            ...query,
            page: pageParam,
          },

        },
      });
      if (!error) {
        return data;
      }
      // @ts-expect-error error type created by backend doesn't match error type in openapi-fetch
      const errorCode: string = error.error_code;
      let errorMessage;
      const errorsDifferentMessage: string[] = [
        FETCH_PROCESSES_ERRORS.BLOCKED_ACCOUNT,
        FETCH_PROCESSES_ERRORS.NO_OP_ACCOUNT,
        FETCH_PROCESSES_ERRORS.MISSING_CREDENTIALS,
        FETCH_PROCESSES_ERRORS.SEARCH_DISABLED,
        FETCH_PROCESSES_ERRORS.SERVICE_NOT_ACTIVATED,
      ];

      if (errorsDifferentMessage.includes(errorCode)) {
        errorMessage = getErrorMsg(`${errorCode}.${isAgent ? 'agent' : 'craftsman'}`, businessUnit, businessUnit);
      } else {
        errorMessage = getErrorMsg(errorCode, businessUnit, businessUnit);
      }
      if (!errorMessage) errorMessage = DEFAULT_ERROR_MESSAGE;

      return throwError(errorMessage, errorCode);
    },
    select: (data) => {
      const processes: FetchProcessesResponseData = [];
      if (!data || !data.pages) return processes;
      return data.pages
        .filter((page) => !!page)
        .reduce((acc, page) => acc.concat(page), []);
    },
  });
}

interface UseGetProcessDetailsProps<T> {
  query: Parameters<'/chat/process-details', 'get'>['query'];
  select?: (data: Response<'/chat/process-details', 'get'> | undefined) => T;
}

export function useGetProcessDetails<T= Response<'/chat/process-details', 'get'>>({ query,
  select }: UseGetProcessDetailsProps<T>) {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    enabled: !!query.house_id && !!query.process_number && !!query.suffix,
    queryKey: getGetProcessDetailsQueryKey(query),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/process-details', {
        params: {
          query,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.NONE,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
    select,
  });
}

interface UseGetProcessesFilterSuggestionsProps {
  query: Parameters<'/chat/process-filter-suggestions', 'get'>['query'];
}

export function useGetProcessesFilterSuggestions({ query }: UseGetProcessesFilterSuggestionsProps) {
  const client = useApiClient();
  const dispatch = useDispatch();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    enabled: !!query.house_id || !!query.query_string,
    queryKey: getGetProcessesFilterSuggestionsQueryKey(query),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/process-filter-suggestions', {
        params: {
          query,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.NONE,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      // Needs better way of handling events in react-query
      dispatch(trackGAEvent('MAGIC_CUBE', 'SEARCHED_ORDER'));
      return data;
    },
    select: (data) => {
      if (!data) return [];
      // deduplicate based on Suggestion + Type
      const seen: { [key: string]: boolean } = {};
      const uniqueSuggestions: FetchProcessesFilterSuggestionsData = [];
      data.forEach((item) => {
        const suggestionId = `${item.Suggestion}-${item.Type}`;
        if (seen[suggestionId] !== true) {
          seen[suggestionId] = true;
          uniqueSuggestions.push(item);
        }
      });
      return uniqueSuggestions;
    },
  });
}
