import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import {
  getCurrentUser,
  getIsAgent,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { PHONE_OR_MAIL_REGEX } from 'gcs-common/constants/formValidation';
import deleteCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/deleteCurrentUser';
import { cleanPhoneNumber } from 'gcs-common/helper/formHelper';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import Button from '../../Button/Button';
import DeletionConfirmationModel from './DeletionConfirmationModal/DeletionConfirmationModel';
import SettingsInput from '../SettingsInput/SettingsInput';
import { getShowDeletionConfirmationModal } from '../../../slices/uiState/uiStateSelector';
import {
  closeDeletionConfirmationModal,
  openDeletionConfirmationModal,
} from '../../../slices/uiState/uiStateSlice';

const APPLE_DUMMY_ACCOUNT = [
  '800800800',
  '+49800800800',
  'appstoretest@project-lary.com',
  'felix+dummyauth@project-lary.com',
];

const AccountSettings = () => {
  const [userIdentifierInput, setUserIdentifierInput] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUser);
  const isAgent = useSelector(getIsAgent);
  const showDeletionConfirmationModal = useSelector(getShowDeletionConfirmationModal);

  const onDeleteConfirmationOpened = useCallback(() => {
    dispatch(openDeletionConfirmationModal());
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.SHOW_DELETE_CONFIRMATION()));
  }, [dispatch]);

  const onDeleteAccountClicked = useCallback(() => {
    dispatch(deleteCurrentUser());
  }, [dispatch]);

  const onDeleteConfirmationClosed = useCallback(() => {
    dispatch(closeDeletionConfirmationModal());
  }, [dispatch]);

  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const inputValid = useMemo(() => {
    let phoneNumber = currentUser?.phoneNumber;
    if (phoneNumber && userIdentifierInput) {
      phoneNumber = cleanPhoneNumber(phoneNumber);
      if (phoneNumber.toLowerCase() === cleanPhoneNumber(userIdentifierInput).toLowerCase()) {
        return true;
      }
    }
    return currentUser?.email?.toLowerCase() === userIdentifierInput?.toLowerCase();
  }, [currentUser, userIdentifierInput]);

  const isInvalidEmailOrPhone = useMemo(() => {
    return !inputValid && PHONE_OR_MAIL_REGEX.test(userIdentifierInput);
  }, [inputValid, userIdentifierInput]);

  const header = useMemo(() => {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className={styles.header} onClick={onGoBack}>
        <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="Zurück" />
        <div className={styles.headline}>
          Account Einstellungen
        </div>
      </div>
    );
  }, [onGoBack]);

  const errorMessage = useMemo(() => {
    if (APPLE_DUMMY_ACCOUNT.find(a => a === userIdentifierInput)) {
      return 'Test-Accounts können nicht gelöscht werden.';
    }
    if (isInvalidEmailOrPhone) {
      return `${!isAgent ? 'Mobilnummer oder ' : ''}E-Mail Adresse passt nicht zu den Accountinformationen`;
    }
    return null;
  }, [userIdentifierInput, isInvalidEmailOrPhone, isAgent]);

  return (
    <div className={styles.mainWrapper}>
      {header}
      <div className={styles.title}>
        BEEM ACCOUNT DATEN LÖSCHEN
      </div>
      <div className={styles.contentWrapper}>
        <p>
          <strong>
            Wenn Sie Ihren Account löschen, werden folgende Daten dauerhaft gelöscht:
          </strong>
        </p>
        <ul>
          <li>
            - BEEM Accountinformationen und Profilbild
          </li>
          <li>
            - Ihr kompletter Nachrichtenverlauf in allen Chats
          </li>
        </ul>
        <p>
          Sie werden aus allen Kanälen und Gruppenchats entfernt.
          {' '}
          <strong>
            Eine Wiederherstellung der
            Daten ist nachdem Löschen nicht mehr möglich.
          </strong>
        </p>
        <div className={styles.inputWrapper}>
          <SettingsInput
            initialValue={userIdentifierInput}
            label={`${!isAgent ? 'Mobilnummer oder ' : ''}E-Mail Adresse eingeben*`}
            placeholder={`${!isAgent ? 'Mobilnummer oder ' : ''}E-Mail Adresse`}
            labelClassName={styles.inputLabel}
            onChange={setUserIdentifierInput}
            showEditIcon={false}
            errorMessage={errorMessage}
          />
        </div>
      </div>
      {showDeletionConfirmationModal && (
      <DeletionConfirmationModel
        onClose={onDeleteConfirmationClosed}
        isOpen
        onDeleteAccountClicked={onDeleteAccountClicked}
      />
      )}
      <Button
        disabled={!inputValid}
        onClick={onDeleteConfirmationOpened}
        className={styles.deleteAccountButton}
      >
        Account löschen
      </Button>
    </div>
  );
};

export default AccountSettings;
