import { useCallback, useRef } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputText,
  getChannelInputIsSendingDisabled, getChannelInputPlaceholder,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { sendCurrentMessageState } from 'gcs-common/slices/messageInput/messageInputThunks/sendCurrentMessageState';
import { changeInputText } from 'gcs-common/slices/messageInput/messageInputThunks/changeInputText';
import { getSendMessageOnEnter } from '../../../slices/localSettings/localSettingsSelectors';
import { MESSAGE_INPUT_ID } from '../../../constants/componentConstants';
import styles from './styles.module.scss';

const MessageInputField = () => {

  const msgInputRef = useRef(null);

  const selectedChannelId = useSelector(getSelectedChannelId);
  const inputText = useSelector(getChannelInputText(selectedChannelId));
  const inputPlaceholder = useSelector(getChannelInputPlaceholder(selectedChannelId));
  const sendingDisabled = useSelector(getChannelInputIsSendingDisabled(selectedChannelId));
  const sendMessageOnEnter = useSelector(getSendMessageOnEnter);
  const dispatch = useDispatch();

  const onMessageChanged = useCallback((event) => {
    const input = event.target.value;
    dispatch(changeInputText({ channelId: selectedChannelId, inputText: input }));
  }, [dispatch, selectedChannelId]);

  const onKeyDown = useCallback((event) => {

    if (
      event.keyCode === 13
      && event.shiftKey === false
      && !sendingDisabled
      && sendMessageOnEnter
    ) {
      event.preventDefault();

      dispatch(sendCurrentMessageState({ channelId: selectedChannelId }));
    }
  }, [dispatch, selectedChannelId, sendingDisabled, sendMessageOnEnter]);

  const onFocus = useCallback(() => {
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.BEGIN_FOCUS()));
  }, [dispatch]);

  return (
    <TextareaAutosize
      id={MESSAGE_INPUT_ID}
      ref={msgInputRef}
      maxRows={6}
      onChange={onMessageChanged}
      value={inputText}
      enterKeyHint={sendMessageOnEnter ? 'send' : 'enter'}
      className={styles.writeInput}
      placeholder={inputPlaceholder}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
    />

  );
};

export default MessageInputField;
