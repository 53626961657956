import { useSelector } from 'react-redux';
import { getChatConnectionState } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import CHAT_CONNECTION_STATE, { ChatConnectionStateValue } from 'gcs-common/constants/ConnectionState';
import { ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import styles from './styles.module.scss';

const ChatConnectionBanner = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const connectionStatus: ChatConnectionStateValue = useSelector(getChatConnectionState);
  switch (connectionStatus) {
    case CHAT_CONNECTION_STATE.CONNECTING:
      return (
        <div className={styles.reconnectingWrapper}>
          <Spinner size={ICON_SIZE.XX_SMALL} color={ICON_COLOR.WHITE} />
          &nbsp;Verbindung wiederherstellen
        </div>
      );
    case CHAT_CONNECTION_STATE.DISCONNECTED:
    case CHAT_CONNECTION_STATE.DISCONNECTING:
    case CHAT_CONNECTION_STATE.DENIED:
      return (
        <div className={styles.offlineWrapper}>
          <Spinner size={ICON_SIZE.XX_SMALL} color={ICON_COLOR.WHITE} />
          &nbsp;Sie sind offline, wir versuchen Sie wieder zu verbinden.
        </div>
      );
    default:
      return null;
  }
};

export default ChatConnectionBanner;
