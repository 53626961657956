import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getInvalidFileDeleted, getLocalUrl } from '../../slices/media/mediaSelectors';
import deleteInvalidFile from '../../slices/media/mediaThunks/deleteInvalidFile';

/*
 * shows a local image (with given key)
 * Assumes that the key exists AND that the local url is already loaded
 * It is the responsibility of the parent component to ensure that the local url is in the state
 */
const LocalImage = ({ localKey, alt, onLoad: passedOnload, ...props }) => {

  const localUrl = useSelector(getLocalUrl(localKey));
  const alreadyTriedToDeleteFile = useSelector(getInvalidFileDeleted(localKey));
  const dispatch = useDispatch();

  const retry = useCallback(() => {
    if (!alreadyTriedToDeleteFile) {
      dispatch(deleteInvalidFile({ key: localKey }));
    }
  }, [alreadyTriedToDeleteFile, localKey, dispatch]);

  const onLoad = useCallback((event) => {
    const { naturalHeight, naturalWidth } = event.target;
    if (naturalHeight === 0 && naturalWidth === 0) {
      retry();
      return;
    }
    if (passedOnload) {
      passedOnload(event);
    }

  }, [retry, passedOnload]);

  return (
    <img
      alt={alt}
      src={localUrl}
      {...props}
      onError={retry}
      onLoad={onLoad}
    />
  );
};
LocalImage.propTypes = {
  localKey: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

export default LocalImage;
