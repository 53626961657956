import PropTypes from 'prop-types';

import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import { useCurrentlyRenderedVirtuosoData } from '../../hooks/useCurrentlyRenderedVirtuosoData';

const ReadNotification = ({ messageId }) => {

  const message = useCurrentlyRenderedVirtuosoData({ messageId });

  if (message?.showPendingIcon) {
    return (
      <div className={styles.iconsContainer}>
        <IconComponent Icon={ICON.CLOCK} size={ICON_SIZE.SMALL} />
      </div>
    );
  }

  if (message?.isRead) {
    return (
      <div className={styles.iconsContainer}>
        <IconComponent
          Icon={ICON.CHECKMARK_DOUBLE}
          color={ICON_COLOR.NEW_CHANNEL_BUTTON}
          size={ICON_SIZE.SMALL}
        />
      </div>
    );
  }

  if (message?.isDelivered) {
    return (
      <div className={styles.iconsContainer}>
        <IconComponent
          Icon={ICON.CHECKMARK_DOUBLE}
          color={ICON_COLOR.DARK_GREY}
          size={ICON_SIZE.SMALL}
        />
      </div>
    );
  }

  return null;
};

ReadNotification.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default ReadNotification;
