// as from https://malcoded.com/posts/react-swipeable-list/
// https://gist.github.com/estaub/91e54880d77a9d6574b829cb0d3ba021

import { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function SwipeableListItem(
  {
    children,
    style = undefined,
    action = undefined,
    disabled = false,
    swipeDelay,
    onSwipe,
    onIsSwiping = undefined,
    onStopSwipe = undefined,
    threshold,
  },
) {

  const listElementRef = useRef(null);
  const backgroundRef = useRef(null);

  const dragStartXRef = useRef(0);
  const rightRef = useRef(0);
  const draggedRef = useRef(false);

  const updatePosition = useCallback(() => {
    if (draggedRef.current) {
      requestAnimationFrame(updatePosition);
    }
    if (rightRef.current > (swipeDelay || 30)) {
      if (listElementRef && listElementRef.current) {
        listElementRef.current.style.transform = `translateX(${rightRef.current}px)`;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onIsSwiping && onIsSwiping();
    } else if (listElementRef && listElementRef.current) {
      listElementRef.current.style = null;
    }

    // Fade the opacity
    const opacity = (Math.abs(rightRef.current / 2) / 100).toFixed(2);
    if (backgroundRef && backgroundRef.current) {
      if (opacity < 1 && opacity.toString() !== backgroundRef.current.style.opacity) {
        backgroundRef.current.style.opacity = opacity.toString();
      }
      if (opacity >= 1) {
        backgroundRef.current.style.opacity = '1';
      }
    }
  }, [onIsSwiping, swipeDelay]);

  const onDragStart = useCallback((clientX) => {
    draggedRef.current = true;
    dragStartXRef.current = clientX;
    listElementRef.current.className = 'listItem';
    requestAnimationFrame(updatePosition);
  }, [updatePosition]);

  const onTouchMove = useCallback((evt) => {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - dragStartXRef.current;
    if (left > (swipeDelay || 30)) {
      rightRef.current = left;
    }
  }, [swipeDelay]);

  const onSwiped = useCallback(() => {
    if (onSwipe && !disabled) {
      onSwipe();
    }
  }, [onSwipe, disabled]);

  const onStopSwiped = useCallback(() => {
    if (onStopSwipe && !disabled) {
      onStopSwipe();
    }
  }, [onStopSwipe, disabled]);

  const onDragEnd = useCallback(() => {
    if (draggedRef.current) {
      draggedRef.current = false;
      if (rightRef.current > (listElementRef.current.offsetWidth * threshold || 0.3)) {
        onSwiped();
        rightRef.current = 0;
      } else {
        rightRef.current = 0;
        onStopSwiped();
      }

      listElementRef.current.style.transform = `translateX(${rightRef.current}px)`;
    }
  }, [onSwiped, onStopSwiped, threshold]);

  const onDragStartTouch = useCallback((evt) => {
    if (!disabled) {
      const touch = evt.targetTouches[0];
      onDragStart(touch.clientX);
      window.addEventListener('touchmove', onTouchMove);
    }
  }, [onDragStart, onTouchMove, disabled]);

  const onDragEndTouch = useCallback(() => {
    window.removeEventListener('touchmove', onTouchMove);
    onDragEnd();
  }, [onDragEnd, onTouchMove]);

  useEffect(() => {
    window.addEventListener('touchend', onDragEndTouch);
    return () => {
      window.removeEventListener('touchend', onDragEndTouch);
    };
  }, [onDragEndTouch]);

  return (
    <div className={`${styles.wrapper} ${style || ''}`}>
      <div className={styles.background} ref={backgroundRef}>
        {action || <span>Delete</span>}
      </div>
      <div className={styles.listItem} ref={listElementRef} onTouchStart={onDragStartTouch}>
        {children}
      </div>
    </div>
  );
}

export default SwipeableListItem;

SwipeableListItem.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onIsSwiping: PropTypes.func,
  onStopSwipe: PropTypes.func,
  onSwipe: PropTypes.func.isRequired,
  style: PropTypes.string,
  swipeDelay: PropTypes.number.isRequired,
  threshold: PropTypes.number.isRequired,
};
