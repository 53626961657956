import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../client';
import { RequestBody } from '../../../../../types/typeHelper';
import { openErrorDialog } from '../../../../slices/errorDialogSlice/errorDialogSlice';

export function useRequestUserCodeMutation() {
  const client = useApiClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ body }: { body: RequestBody<'/user_code', 'post'> }) => {
      await client.POST('/user_code', { body });
    },
    onError: async (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message }));
    },
  });
}
