type NestedObject = { [key: string]: NestedObject | string };

export const isObjectEmpty = (obj: Object) => {
  return Object.entries(obj).length === 0;
};

export const filterObjectByKey = (object: Object, key: string) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([_key]) => _key !== key));
};

export const filterObjectIncludeMatchingKeys = (object: Object, keysArr: string[]) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([_key]) => keysArr.includes(_key)));
};

export const filterObjectExcludeMatchingKeys = (object: Object, keysArr: string[]) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([_key]) => !keysArr.includes(_key)));
};

export const filterObjectByValue = (object: Object, filterFunc:(arg0: any)=>boolean) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([, v]) => filterFunc(v)));
};

export const getEntitiesForKeys = (entities: Object, keys: string[]) => {
  return Object.entries(entities).reduce<string[]>((users, [key, value]) => {
    if (keys.includes(key)) {
      users.push(value);
    }
    return users;
  }, []);
};

export const getByPath = (obj: NestedObject, path: string) => {
  // @ts-expect-error fix when needed
  return path.split('.').reduce((res, fieldName: string) => {
    return res[fieldName]!;
  }, obj);
};

export const setByPath = (obj: NestedObject, path: string, value: string) => {
  const fieldNames = path.split('.');
  const newObj = { ...obj };

  if (fieldNames.length === 1) {
    newObj[fieldNames[0]!] = value;
  } else {
    // @ts-expect-error fix when needed
    newObj[fieldNames[0]!] = setByPath(obj[fieldNames[0]!], fieldNames.slice(1).join('.'), value);
  }
  return newObj;
};

export const countObjectValueArrayEntries = (obj: Object) => {
  return Object.values(obj)
    .map(entry => entry.length)
    .reduce((old, next) => old + next, 0);
};

export const filterObjectToExcludeNull = (obj: Object): Object => {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [key, filterObjectToExcludeNull(value)];
    }).filter(([_, value]) => value !== undefined && value !== null),
  );
};

export const convertArrayToObject = <T extends Record<string, any>, K extends keyof T>(
  arr: T[], keyField: K) => {
  return arr.reduce((acc, current) => {
    const key = current[keyField];
    acc[key] = current;
    return acc;
  }, {} as Record<string, T>);
};
