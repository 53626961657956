import SubmitUserCode from 'gcs-common/components/SubmitUserCode/SubmitUserCode';
import UserCodeMobileWrapper from '../UserCodeMobileWrapper';

const SubmitUserCodeMobile = () => {

  return (
    <UserCodeMobileWrapper>
      <SubmitUserCode />
    </UserCodeMobileWrapper>
  );
};

export default SubmitUserCodeMobile;
