import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import { v4 as uuidv4 } from 'uuid';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { MAX_TICKET_ATTACHMENT_SIZE } from 'gcs-common/slices/tickets/ticketsConstants';
import { getSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import { createFileFromUrl } from 'gcs-common/helper/fileHelper';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import TicketAttachments from './TicketAttachments/TicketAttachments';
import FileInputButtons from '../../FileInputButtons/FileInputButtons';
import FilePicker from '../../MessageInputArea/FilePicker/FilePicker';
import { getShowFilePicker } from '../../../slices/uiState/uiStateSelectors';
import { MESSAGE_INPUT_ID } from '../../../constants/componentConstants';

interface TicketFormProps {
  setDescription: Dispatch<SetStateAction<string>>;
  onClick: () => Promise<void>;
  isDisabled: boolean;
  attachments: File[];
  setAttachments: Dispatch<SetStateAction<File[]>>;
  description: string;
  isSubmitting: boolean
}

const TicketForm = (props: TicketFormProps) => {
  const {
    setDescription, onClick, isDisabled, attachments, setAttachments, description, isSubmitting,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const showFilePicker: boolean = useSelector(getShowFilePicker);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const sharedFiles: (File & { id: string })[] = useSelector(getSharedFiles);
  const allowedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/heic',
  ];
  const unsupportedFileMessage = 'Bitte nur Bilder auswählen.';

  const stageFileCallback = useCallback(({ file }:
  { channelId: string; file: (File & { id: string }) }) => {
    Object.assign(file, { id: uuidv4() });
    setAttachments(prevAttachments => [...prevAttachments, file]);
  }, [setAttachments]);

  useEffect(() => {
    const convertToFiles = async () => {
      if (sharedFiles && sharedFiles.length > 0) {
        const newAttachments = await Promise.all(sharedFiles.map(async (fileData) => {
          // @ts-expect-error future file migration
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const file = await createFileFromUrl(fileData.webPath, fileData.name, fileData.type);
          Object.assign(file, { id: uuidv4() });
          return file;
        }));
        setAttachments((prevAttachments) => [...prevAttachments, ...newAttachments]);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    convertToFiles();
  }, [sharedFiles, setAttachments]);

  return (
    <>
      {attachments && attachments.length > 0 && (
        <TicketAttachments
          attachments={attachments}
          setAttachments={setAttachments}
        />
      )}

      <div className={styles.ticketFormContainer}>
        <div className={styles.ticketForm}>
          <div className={styles.inputElementWrapper}>
            <FileInputButtons
            // @ts-expect-error future file migration
              stageFileCallback={stageFileCallback}
              isSendingDisabled={isSubmitting}
            />
            <div className={styles.inputElements}>
              <TextareaAutosize
                id={MESSAGE_INPUT_ID}
                readOnly={isSubmitting}
                className={styles.writeInput}
                onChange={(e) => setDescription(e.currentTarget.value)}
                maxRows={6}
                value={isSubmitting ? '' : description}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            </div>
            <button
              type="button"
              disabled={isDisabled}
              onClick={() => { onClick().then(() => {}).catch(() => {}); }}
              className={styles.createTicketButton}
            >
              <IconComponent
                Icon={ICON.SEND}
                color={ICON_COLOR.WHITE}
                style={{ marginLeft: '2px' }}
                alt="send icon"
              />
            </button>
          </div>
        </div>
        {showFilePicker
        && (
        <FilePicker
          fileSizeLimit={MAX_TICKET_ATTACHMENT_SIZE}
          // @ts-expect-error future file migration
          stageFileCallback={stageFileCallback}
          // @ts-expect-error future file migration
          allowedFileTypes={allowedFileTypes}
          unsupportedFileMessage={unsupportedFileMessage}
        />
        )}
      </div>
    </>
  );
};

export default TicketForm;
