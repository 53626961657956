/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ASYNC_STATE from 'gcs-common/constants/AsyncState';
import loadLocalFileUrl from './mediaThunks/loadLocalFileUrl';
import downloadFile from './mediaThunks/downloadFile';
import initiateMessageMediaDownload from './mediaThunks/initiateMessageMediaDownload';
import initiateEtsMediaDownload from './mediaThunks/initiateEtsMediaDownload';
import deleteInvalidFile from './mediaThunks/deleteInvalidFile';

const asyncState = {
  loadLocalFileUrl: {

  }, // { [key]: { status } }
  downloadFile: {}, // { [key]: { status } }
  initiateMessageMediaDownload: {}, // { [messageMediaRelativePath]: { status } }
  initiateEtsMediaDownload: {}, // { [localKey]: { status } }
  deleteInvalidFile: {}, // { [key]: { status } }
};
const initialState = {

  localFileUrls: {}, // { [key]: localUrl }
  deletedInvalidFiles: {}, // { [key]: true/false }
  ...asyncState,
};

const persistWhitelist = [
  'localFileUrls',
];

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
  },
  extraReducers: {
    [loadLocalFileUrl.pending]: (state, action) => {
      const { key } = action.meta.arg;
      if (!state.loadLocalFileUrl[key]) {
        state.loadLocalFileUrl[key] = {};
      }
      state.loadLocalFileUrl[key].status = ASYNC_STATE.LOADING;
    },
    [loadLocalFileUrl.fulfilled]: (state, action) => {
      const { key } = action.meta.arg;
      const { url } = action.payload;
      state.loadLocalFileUrl[key].status = ASYNC_STATE.SUCCEEDED;
      if (url) {
        state.localFileUrls[key] = url;
      }
    },
    [loadLocalFileUrl.rejected]: (state, action) => {
      const { key } = action.meta.arg;
      state.loadLocalFileUrl[key].status = ASYNC_STATE.FAILED;
    },
    [downloadFile.pending]: (state, action) => {
      const { key } = action.meta.arg;
      if (!state.downloadFile[key]) {
        state.downloadFile[key] = {};
      }
      state.downloadFile[key].status = ASYNC_STATE.LOADING;
    },
    [downloadFile.fulfilled]: (state, action) => {
      const { key } = action.meta.arg;
      const { url } = action.payload;
      state.downloadFile[key].status = ASYNC_STATE.SUCCEEDED;
      state.localFileUrls[key] = url;
    },
    [downloadFile.rejected]: (state, action) => {
      const { key } = action.meta.arg;
      state.downloadFile[key].status = ASYNC_STATE.FAILED;
    },
    [initiateMessageMediaDownload.pending]: (state, action) => {
      const { messageMediaRelativePath } = action.meta.arg;
      if (!state.initiateMessageMediaDownload[messageMediaRelativePath]) {
        state.initiateMessageMediaDownload[messageMediaRelativePath] = {};
      }
      state.initiateMessageMediaDownload[messageMediaRelativePath].status = ASYNC_STATE.LOADING;
    },
    [initiateMessageMediaDownload.rejected]: (state, action) => {
      const { messageMediaRelativePath } = action.meta.arg;
      state.initiateMessageMediaDownload[messageMediaRelativePath].status = ASYNC_STATE.FAILED;
    },
    [initiateMessageMediaDownload.fulfilled]: (state, action) => {
      const { messageMediaRelativePath } = action.meta.arg;
      state.initiateMessageMediaDownload[messageMediaRelativePath].status = ASYNC_STATE.SUCCEEDED;
    },
    [initiateEtsMediaDownload.pending]: (state, action) => {
      const { localKey } = action.meta.arg;
      if (!state.initiateEtsMediaDownload[localKey]) {
        state.initiateEtsMediaDownload[localKey] = {};
      }
      state.initiateEtsMediaDownload[localKey].status = ASYNC_STATE.LOADING;
    },
    [initiateEtsMediaDownload.rejected]: (state, action) => {
      const { localKey } = action.meta.arg;
      state.initiateEtsMediaDownload[localKey].status = ASYNC_STATE.FAILED;
    },
    [initiateEtsMediaDownload.fulfilled]: (state, action) => {
      const { localKey } = action.meta.arg;
      state.initiateEtsMediaDownload[localKey].status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteInvalidFile.pending]: (state, action) => {
      const { key } = action.meta.arg;
      if (!state.deleteInvalidFile[key]) {
        state.deleteInvalidFile[key] = {};
      }
      state.deleteInvalidFile[key].status = ASYNC_STATE.LOADING;
    },
    [deleteInvalidFile.rejected]: (state, action) => {
      const { key } = action.meta.arg;
      state.deleteInvalidFile[key].status = ASYNC_STATE.FAILED;
    },
    [deleteInvalidFile.fulfilled]: (state, action) => {
      const { key } = action.meta.arg;
      state.deleteInvalidFile[key].status = ASYNC_STATE.SUCCEEDED;
      state.deletedInvalidFiles[key] = true;
      delete state.localFileUrls[key];
    },

  },
});

export { persistWhitelist as mediaPersistWhitelist };

// eslint-disable-next-line no-empty-pattern
export const {
} = mediaSlice.actions;

export default mediaSlice.reducer;
