import styles from './styles.module.scss';

interface TicketsChannelItemNotificationsBadgeProps {
  count: number;
}

const TicketsChannelItemNotificationsBadge = (props: TicketsChannelItemNotificationsBadgeProps) => {
  const { count } = props;
  return (
    <span className={styles.ticketNotificationsCount}>
      {count}
    </span>
  );
};

export default TicketsChannelItemNotificationsBadge;
