import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import {
  filterByBrand,
  filterBySelectedChannelTypeFilter,
  filterBySearch,
  filterBySelectedSalesSpaceChannelFilter,
  filterByHiddenStatus, filterByUnreadMessages,
} from '../channelFilter';
import sortChannels from '../channelSorter';
import {
  getChannelListChannelIds,
  getChannels,
} from '../../channels/channelsSelectors';
import {
  getUsers,
} from '../../users/usersSelectors';
import { getCurrentUserId, getIsAgent } from '../../currentUser/currentUserSelectors';
import { getSearchInput } from './getSearchInput';
import { getIsChatConnected } from '../../chatConnection/chatConnectionSelectors';
import { CHANNEL_FILTER_TYPES } from '../../../helper/channelTypeHelper';
import { getSelectedChannelBrand } from './getSelectedChannelBrand';
import { getSelectedChannelTypeFilter } from './getSelectedChannelTypeFilter';
import {
  getSelectedSalesSpaceChannelFilter,
} from '../../salesSpaceChannels/salesSpaceChannelsSelectors/getSelectedSalesSpaceChannelFilter';
import { getChannelMembers, getMembers } from '../../members/membersSelectors';
import { getUnreadMessagesFilterSelected } from './getUnreadMessagesFilterSelected';
import { getSalesSpaceUnreadMessagesFilterSelected } from '../../salesSpaceChannels/salesSpaceChannelsSelectors/getSalesSpaceUnreadMessagesFilterSelected';
import { UnreadMessagesFilter } from '../../../helper/channelOrderingHelper';
import { getSelectedSalesSpaceChannelOrder } from '../../salesSpaceChannels/salesSpaceChannelsSelectors/getSelectedSalesSpaceChannelOrder';
import { getSelectedChannelOrder } from './getSelectedChannelOrder';
// eslint-disable-next-line import/no-cycle
import { getChannelNotificationDates } from '../../tickets/ticketsSelectors';

export const getDisplayChannelsIds = createSelector(
  createStructuredSelector({
    allChannelIds: getChannelListChannelIds,
    searchInput: getSearchInput,
    selectedDirectChannelOrder: getSelectedChannelOrder,
    selectedSalesSpaceChannelOrder: getSelectedSalesSpaceChannelOrder,
    selectedChannelBrand: getSelectedChannelBrand,
    selectedChannelTypeFilter: getSelectedChannelTypeFilter,
    selectedSalesSpaceChannelFilter: getSelectedSalesSpaceChannelFilter,
    unreadMessagesFilterSelected: getUnreadMessagesFilterSelected,
    unreadSalesSpaceMessagesFilterSelected: getSalesSpaceUnreadMessagesFilterSelected,
    currentUserId: getCurrentUserId,
    channelMembers: getChannelMembers,
    members: getMembers,
    users: getUsers,
    isAgent: getIsAgent,
    channels: getChannels,
    isConnected: getIsChatConnected,
    channelNotificationDates: getChannelNotificationDates,
  }),
  ({
    allChannelIds,
    searchInput,
    selectedDirectChannelOrder,
    selectedSalesSpaceChannelOrder,
    selectedChannelBrand,
    selectedChannelTypeFilter,
    selectedSalesSpaceChannelFilter,
    unreadMessagesFilterSelected,
    unreadSalesSpaceMessagesFilterSelected,
    currentUserId,
    channelMembers,
    members,
    users,
    isAgent,
    channels,
    isConnected,
    channelNotificationDates,
  }) => {
    if (!allChannelIds) {
      return [];
    }

    // Channel title or subtitle must contain search string (case independent).
    let filteredChannelIds = allChannelIds
      .filter(filterBySearch({
        searchInput,
        channels,
        currentUserId,
        channelMembers,
        members,
        users,
        isAgent,
      }));

    if (isAgent) {
      filteredChannelIds = filteredChannelIds
        .filter(filterBySelectedChannelTypeFilter({
          channels,
          selectedChannelTypeFilter,
        }));
    }

    if (selectedChannelTypeFilter === CHANNEL_FILTER_TYPES.DIRECT_CHATS) {
      filteredChannelIds = filteredChannelIds
        .filter(filterByBrand({ channelBrand: selectedChannelBrand, channels }));
    }

    if (selectedChannelTypeFilter === CHANNEL_FILTER_TYPES.TEAM_CHATS) {
      filteredChannelIds = filteredChannelIds.filter(filterBySelectedSalesSpaceChannelFilter({
        isAgent,
        selectedSalesSpaceChannelFilter,
        channelMembers,
        members,
        currentUserId,
        users,
        isConnected,
      }));
    }

    // Filter archived channels only while not searching
    if (!searchInput) {
      filteredChannelIds = filteredChannelIds
        .filter(filterByHiddenStatus({
          currentUserId,
          users,
        }));
    }

    // eslint-disable-next-line max-len
    const unreadMessagesFilter = selectedChannelTypeFilter === CHANNEL_FILTER_TYPES.TEAM_CHATS ? unreadSalesSpaceMessagesFilterSelected : unreadMessagesFilterSelected;
    if (unreadMessagesFilter === UnreadMessagesFilter.UNREAD) {
      filteredChannelIds = filteredChannelIds.filter(filterByUnreadMessages({
        channels,
        currentUserId,
        members,
        channelMembers,
      }));
    }

    // eslint-disable-next-line max-len
    const sortingDirection = selectedChannelTypeFilter === CHANNEL_FILTER_TYPES.TEAM_CHATS ? selectedSalesSpaceChannelOrder : selectedDirectChannelOrder;
    return sortChannels({
      sortingDirection,
      channelIds: filteredChannelIds,
      channels,
      currentUserId,
      channelMembers,
      members,
      users,
      isAgent,
      channelNotificationDates,
    });
  },
);
