import { useSelector } from 'react-redux';
import { getLogo } from 'gcs-common/slices/theme/themeSelectors';
import styles from './styles.module.scss';

const LoginLogo = () => {

  const beemLogo = useSelector(getLogo);
  return (
    <img alt="gc" className={styles.logoImg} src={beemLogo} />
  );
};

export default LoginLogo;
