import { Navigate, createBrowserRouter } from 'react-router-dom';
import {
  CHAT_PATHS, CHAT_ROUTES, LOGIN_ROUTES, REGISTRATION_ROUTES, CODE_ROUTES,
} from 'gcs-common/constants/routes';
import NotificationPage from 'gcs-common/components/NotificationPage/NotificationPage';
import App from '../components/App/App';
import LoggedInRoot from '../components/LoggedInRoot/LoggedInRoot';
import ChatRoot from '../components/ChatRoot/ChatRoot';
import TermsAndPrivacy from '../components/TermsAndPrivacy/TermsAndPrivacy';
import AccountSettings from '../components/Settings/AccountSettings/AccountSettings';
import ChatSettings from '../components/Settings/ChatSettings/ChatSettings';
import NewGroupChat from '../components/NewGroupChat/NewGroupChat';
import NewChat from '../components/NewChat/NewChat';
import Profile from '../components/Settings/Profile/Profile';
import CraftsmanConnectionsOverview from '../components/Settings/CraftsmanAccounts/CraftsmanConnectionsOverview/CraftsmanConnectionsOverview';
import CraftsmanAccountAddScreen from '../components/Settings/CraftsmanAccounts/CraftsmanAccountAddScreen/CraftsmanAccountAddScreen';
import CraftsmanAccountEditScreen from '../components/Settings/CraftsmanAccounts/CraftsmanAccountEditScreen/CraftsmanAccountEditScreen';
import TutorialPage from '../components/TutorialPage/TutorialPage';
import Settings from '../components/Settings/Settings';
import TicketsOverview from '../components/Tickets/TicketsOverview/TicketsOverview';
import TicketDetails from '../components/Tickets/TicketDetails/TicketDetails';
import TicketCreation from '../components/Tickets/TicketsCreation/TicketsCreation';
import ChatWrapper from '../components/ChatWrapper/ChatWrapper';
import FileShare from '../components/FileShare/FileShare';
import FavoritesPage from '../components/FavoritesPage/FavoritesPage';
import Search from '../components/Search/Search';
import ProcessSelect from '../components/ProcessSelect/ProcessSelect';
import Forward from '../components/Forward/Forward';
import ChannelProfile from '../components/ChannelProfile/ChannelProfile';
import QuickOrderingPage from '../components/QuickOrderingPage/QuickOrderingPage';
import ChatUserProfile from '../components/Chat/ChatUserProfile/ChatUserProfile';
import Gallery from '../components/Gallery/Gallery';
import ErrorBoundaryFallback from '../components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import AuthProtectedRouteMobile from '../components/App/AuthProtectedRouteMobile/AuthProtectedRouteMobile';
import LoggedInProtectedRouteMobile from '../components/App/LoggedInProtectedRouteMobile';
import LoginRootMobile from '../components/Login/LoginRootMobile';
import RequestUserCodeMobile from '../components/UserCodes/RequestUserCodeMobile/RequestUserCodeMobile';
import SubmitUserCodeMobile from '../components/UserCodes/SubmitUserCodeMobile/SubmitUserCodeMobile';
import OnlinePlusLoginMobile from '../components/OnlinePlus/OnlinePlusLoginMobile/OnlinePlusLoginMobile';
import OnlinePlusProfileFormMobile from '../components/OnlinePlus/OnlinePlusProfileFormMobile/OnlinePlusProfileFormMobile';

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      {
        element: <AuthProtectedRouteMobile />,
        children: [
          {
            element: (
              <LoggedInProtectedRouteMobile>
                <LoggedInRoot />
              </LoggedInProtectedRouteMobile>
            ),
            children: [
              {
                path: '/',
                element: <ChatRoot />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={CHAT_ROUTES.CHAT} replace />,
                  },
                  {
                    path: 'tutorial',
                    element: <TutorialPage />,
                  },
                  {
                    path: 'craftsmanAccounts/edit/:connectionId',
                    element: <CraftsmanAccountEditScreen />,
                  },
                  {
                    path: 'craftsmanAccounts/add',
                    element: <CraftsmanAccountAddScreen />,
                  },
                  {
                    path: 'craftsmanAccounts',
                    element: <CraftsmanConnectionsOverview />,
                  },
                  {
                    path: 'profile',
                    element: <Profile />,
                  },
                  {
                    path: 'newChat',
                    element: <NewChat />,
                  },
                  {
                    path: 'newGroupChat',
                    element: <NewGroupChat />,
                  },
                  {
                    path: 'settings/terms-of-use',
                    element: <TermsAndPrivacy withTerms withPrivacy />,
                  },
                  {
                    path: 'settings/chatSettings',
                    element: <ChatSettings />,
                  },
                  {
                    path: 'settings/accountSettings',
                    element: <AccountSettings />,
                  },
                  {
                    path: 'settings',
                    element: <Settings />,
                  },
                  {
                    path: 'shareFiles',
                    element: <FileShare />,
                  },
                  {
                    path: 'chat/:routeChannelId?',
                    element: <ChatWrapper />,
                    children: [
                      {
                        path: CHAT_PATHS.FAVORITES,
                        element: <FavoritesPage />,
                      },
                      {
                        path: CHAT_PATHS.SEARCH,
                        element: <Search />,
                      },
                      {
                        path: CHAT_PATHS.PROCESSES,
                        element: <ProcessSelect />,
                      },
                      {
                        path: CHAT_PATHS.MESSAGE_FORWARD,
                        element: <Forward />,
                      },
                      {
                        path: CHAT_PATHS.CHANNEL_PROFILE,
                        element: <ChannelProfile />,
                      },
                      {
                        path: CHAT_PATHS.QUICK_ORDER,
                        element: <QuickOrderingPage />,
                      },
                      {
                        path: CHAT_PATHS.USER_PROFILE,
                        element: <ChatUserProfile />,
                      },
                      {
                        path: `${CHAT_PATHS.GALLERY}/:messageId`,
                        element: <Gallery />,
                      },
                    ],

                  },
                  {
                    path: 'tickets/new',
                    element: <TicketCreation />,
                  },
                  {
                    path: 'tickets/:ticketId',
                    element: <TicketDetails />,
                  },
                  {
                    path: 'tickets',
                    element: <TicketsOverview />,
                  },
                  {
                    path: REGISTRATION_ROUTES.REGISTRATION_PROFILE,
                    element: <OnlinePlusProfileFormMobile />,
                  },
                  {
                    path: REGISTRATION_ROUTES.REGISTRATION,
                    element: <OnlinePlusLoginMobile />,
                  },
                ],
              },
            ],
          },
          {
            path: LOGIN_ROUTES.LOGIN,
            element: <LoginRootMobile />,
          },
          {
            path: CODE_ROUTES.REQUEST_CODE,
            element: <RequestUserCodeMobile />,
          },
          {
            path: CODE_ROUTES.SUBMIT_CODE,
            element: <SubmitUserCodeMobile />,
          },
          {
            path: CHAT_ROUTES.NOTIFICATION,
            element: <NotificationPage />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

export default router;
