import { Dialog, DialogTrigger, DialogContent } from 'gcs-common/design-system/ui/dialog';
import Anchor from 'gcs-common/design-system/ui/anchor';
import { useState } from 'react';
import { getTermsText } from 'gcs-common/slices/theme/themeSelectors';
import { useSelector } from 'react-redux';


const TermsAndConditions = () => {
  const [showDialog, setShowDialog] = useState(false);
  const termsText = useSelector(getTermsText);

  return (
    <Dialog
      open={showDialog}
      onOpenChange={(open) => setShowDialog(open)}
    >
      <DialogTrigger asChild>
        <Anchor>
          Nutzungsbedingungen
        </Anchor>
      </DialogTrigger>
      <DialogContent className="h-96 overflow-y-scroll">
        {termsText}
      </DialogContent>
    </Dialog>
  );

};

export default TermsAndConditions;
