import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';

type GAEvent = {
  category: string;
  action: string;
};

const useTrackOncePerSession = (event: GAEvent, eventKey: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const eventFired = sessionStorage.getItem(eventKey);

    if (!eventFired) {
      dispatch(trackGALegacyEvent(event));
      // Record the event in session storage to prevent it from firing again
      sessionStorage.setItem(eventKey, 'true');
    }
  }, [dispatch, event, eventKey]);
};

export default useTrackOncePerSession;
