import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getSubstituteNotificationText } from 'gcs-common/slices/messages/messageSelector';
import styles from './styles.module.scss';

function SubstituteNotification({ messageId }) {
  const substituteNotificationText = useSelector(getSubstituteNotificationText(messageId));
  return (
    <div className={styles.chatNotification}>
      {substituteNotificationText}
    </div>
  );
}

SubstituteNotification.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default SubstituteNotification;
