import { ReactNode } from 'react';
import IconButton from 'gcs-common/design-system/ui/icon-button';
import { ArrowBackIos } from '@project-lary/react-material-symbols';
import withConfirmation, { type ConfirmModalProps } from 'gcs-common/design-system/hoc/confirmation-hoc';
import { useLogoutMutation } from 'gcs-common/clients/api/entities/auth/auth-api';
import { getIsRegistered } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';

const LogOutIconWithConfirm = withConfirmation((props: ConfirmModalProps) => (
  <IconButton
    Icon={ArrowBackIos}
    className="flex cursor-pointer rounded-md bg-white"
    size="l"
    {...props}
  />
));

const OnlinePlusMobileWrapper = ({ children }: { children: ReactNode }) => {
  const logoutMutation = useLogoutMutation();
  const logoutUser = async () => {
    await logoutMutation.mutateAsync();
  };
  const isRegistered = useSelector(getIsRegistered);

  if (isRegistered) {
    return <Navigate to={CHAT_ROUTES.NOTIFICATION} replace />;
  }
  return (
    <div className="flex h-full flex-col justify-between overflow-y-auto bg-white">
      <div className="flex justify-start px-4 pt-1">
        <LogOutIconWithConfirm
          title="Möchten Sie den Registrierungsprozess unterbrechen?"
          confirmLabel="JA"
          closeLabel="NEIN"
          confirm={logoutUser}

        >
          <div>
            Sie können sich jederzeit erneut einloggen um Ihren Account zu vervollständigen.
          </div>

        </LogOutIconWithConfirm>

      </div>
      <div className="h-[120px]" />
      <div className="flex grow flex-col px-6 text-center">
        {children}
      </div>
    </div>
  );
};

export default OnlinePlusMobileWrapper;
