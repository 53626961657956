import { useDispatch } from 'react-redux';
import { formatString } from './stringFormatHelper';
import { administrationErrorMessages } from './administrationErrorMessages';
import { ERROR_DIALOG } from '../constants/errorDialogConstants';
import { openErrorDialog } from '../slices/errorDialogSlice/errorDialogSlice';

const errorMessages = {
  login: {
    too_many_attempts: 'Zu viele Login-Versuche. Versuche es später noch einmal',
    unauthorized: 'Nutzer nicht zugelassen',
    validate_mail_required: 'E-Mail wird benötigt',
    validate_mail_format: 'Ungültige E-Mail Adresse',
    validate_mobile_number_required: 'Handynummer wird benötigt',
    validate_mobile_number_format: 'Ungültige Handynummer',
    validate_default_required: 'Handynummer oder E-Mail wird benötigt',
    validate_default_format: 'Ungültige Handynummer oder E-Mail',
    validate_verification_code_required: 'Der Verifizierungscode ist leer.',
    validate_verification_code_format: 'Der Verifizierungscode sollte aus 6 Ziffern bestehen.',
    unknown_mail: 'Es konnte kein User mit dieser E-Mail gefunden werden. Falls Sie noch keinen Account haben und sich registrieren möchten, wenden Sie sich an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers.',
    unknown_phone_number: 'Es konnte kein User mit dieser Handynummer gefunden werden. Falls Sie noch keinen Account haben und sich registrieren möchten, wenden Sie sich an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers.',
    unknown_default: 'Es konnte kein User mit dieser E-Mail oder Handynummer gefunden werden. Falls Sie noch keinen Account haben und sich registrieren möchten, wenden Sie sich an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers.',
    wrong_verification_code: 'Der eingegebene Verifizierungs-Code ist ungültig',
    connection_not_enabled: 'Es ist ein Fehler beim Login-Dienst aufgetreten.',
    default: 'Login-Fehler. Versuche es später erneut',
  },
  get_auth0_login_code: {
    wrong_password: 'Falsches Passwort',
    wrong_otp: 'Der eingegebene Verifizierungs-Code ist ungültig',
  },
  passwordReset: {
    default: 'Beim zurücksetzen ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
  },
  get_processes: {
    service_not_activated: {
      craftsman: 'Diese Funktionalität ist derzeit in diesem Haus nicht verfügbar.',
      agent: 'Derzeit ist es noch nicht möglich, ONLINE PLUS Prozesse aus Häusern mit SAP HANA anzuzeigen.',
    },
    no_op_account: {
      craftsman: 'Es besteht keine Verknüpfung zu einem ONLINE PLUS Account.',
      agent: 'Eine ONLINE PLUS Verbindung für diesen Nutzer ist noch nicht eingerichtet.',
    },
    search_processes_disabled: {
      craftsman: 'Die Prozesssuche ist für diesen ONLINE PLUS Account deaktiviert. '
          + '\n\nBitte überprüfen Sie Ihre Verknüpfung in den Einstellungen oder wenden Sie sich an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers, um Ihre Berechtigungen zu ändern.',
      agent: 'Die Prozesssuche ist für diesen ONLINE PLUS Account deaktiviert.',
    },
    missing_credentials: {
      craftsman: 'Benutzer oder Passwort fehlt.',
      agent: 'Die ONLINE PLUS Verbindung für diesen Nutzer wurde durch den Nutzer in den Einstellungen deaktiviert oder sein Passwort wurde geändert.',
    },
    invalid_credentials: 'Benutzer oder Passwort falsch. Bitte nochmal versuchen.',
    blocked_gcop_account: {
      craftsman: 'Ihr %s ONLINE PLUS Account ist gesperrt. Bitte entsperren Sie Ihren Account über die %s ONLINE PLUS Webseite oder App und versuchen Sie es erneut.',
      agent: 'Der verknüpfte ONLINE PLUS Account ist gesperrt. Sie sehen die Vorgänge wieder, sobald der Fachhandwerker den ONLINE PLUS Account wieder freigeschaltet hat.',
    },
    customer_blocked: {
      craftsman: 'Der von Ihnen eingegebene %s ONLINE PLUS Account ist gesperrt. Bitte entsperren Sie den Account über die %s ONLINE PLUS Webseite oder App.',
      agent: 'Der verknüpfte ONLINE PLUS Account ist gesperrt. Sie sehen die Vorgänge wieder, sobald der Fachhandwerker den ONLINE PLUS Account wieder freigeschaltet hat.',
    },
    disabled_process_search: 'Die Prozesssuche ist für diesen ONLINE PLUS Account deaktiviert.',
    gcop_problems: 'Es besteht keine Verknüpfung zu ONLINE PLUS. '
    + 'Wir arbeiten bereits and einer Problemlösung. \n'
    + 'Vielen Dank für Ihr Verständnis.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  load_media: {
    default: 'Datei konnte nicht heruntergeladen werden. Bitte überprüfen Sie ihre Netzwerverbindung und versuchen Sie es später erneut.',
  },
  files: {
    wrongFileSize: 'Die Datei %s überschreitet die maximale Größe von %s MB.',
    wrongFileType: 'Die Datei %s hat nicht einen der folgenden Dateitypen: %S.',
  },
  validate_house_custom_message: {
    default: 'Beim validieren der Nachricht ist ein Fehler aufgetreten. Bitte korrigieren Sie den Text und versuchen Sie es erneut.',
  },
  fetch_channels: {
    default: 'Beim Laden der Channel is ein Fehler aufgetreten',
  },
  delete_company: {
    execution_in_progress: 'Der Kunde wird derzeit archiviert, keine Änderungen möglich.',
  },
  delete_company_user: {
    execution_in_progress: 'Der Nutzer wird derzeit archiviert, keine Änderungen möglich.',
  },
  create_connection_member: {
    invalid_username: 'Ihr %s-Haus ist leider noch nicht für den %s-Chat verfügbar.\n\n'
      + 'Bitte wenden Sie sich bei Fragen an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers.',
    missing_payload: 'Benutzer oder Passwort fehlt. Bitte nochmal versuchen.',
    invalid_credentials: 'Benutzer oder Passwort falsch. Bitte nochmal versuchen.',
    blocked_gcop_account: 'Der von Ihnen eingegebene %s ONLINE PLUS Account ist gesperrt. Bitte entsperren Sie den Account über die %s ONLINE PLUS Webseite oder App.',
    customer_blocked: 'Der von Ihnen eingegebene %s ONLINE PLUS Account ist gesperrt. Wenden Sie sich bei Problemen mit der Anmeldung an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers.',
    gcop_problems: 'Es gibt Verbindungsprobleme zu %s ONLINE PLUS. Bitte versuchen Sie es erneut.',
    connected_house: 'Ein ONLINE PLUS-Konto ist bereits mit diesem Haus verbunden. Entfernen Sie die aktuelle Verbindung und fügen Sie die neue ONLINE PLUS-Verbindung hinzu.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  craftnote_login: {
    invalid_username: 'Ihr %s-Haus ist leider noch nicht für den %s-Chat verfügbar.\n\n'
      + 'Bitte wenden Sie sich bei Fragen an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers.',
    missing_payload: 'Benutzer oder Passwort fehlt. Bitte nochmal versuchen.',
    invalid_credentials: 'Benutzer oder Passwort falsch. Bitte nochmal versuchen.',
    blocked_gcop_account: 'Der von Ihnen eingegebene %s ONLINE PLUS Account ist gesperrt. Bitte entsperren Sie den Account über die %s ONLINE PLUS Webseite oder App.',
    customer_blocked: 'Der von Ihnen eingegebene %s ONLINE PLUS Account ist gesperrt. Bitte entsperren Sie den Account über die %s ONLINE PLUS Webseite oder App.',
    gcop_problems: 'Es gibt Verbindungsprobleme zu %s ONLINE PLUS. Bitte versuchen Sie es erneut.',
    connected_house: 'Ein ONLINE PLUS-Konto ist bereits mit diesem Haus verbunden. Entfernen Sie die aktuelle Verbindung und fügen Sie die neue ONLINE PLUS-Verbindung hinzu.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  update_connection_member: {
    invalid_username: 'Ihr %s-Haus ist leider noch nicht für den %s-Chat verfügbar.\n\n'
      + 'Bitte wenden Sie sich bei Fragen an Ihren Digital Coach oder Außendienstmitarbeiter Ihres Großhändlers.',
    missing_payload: 'Benutzer oder Passwort fehlt. Bitte nochmal versuchen.',
    invalid_credentials: 'Benutzer oder Passwort falsch. Bitte nochmal versuchen.',
    blocked_gcop_account: 'Der von Ihnen eingegebene %s ONLINE PLUS Account ist gesperrt. Bitte entsperren Sie den Account über die %s ONLINE PLUS Webseite oder App.',
    customer_blocked: 'Der von Ihnen eingegebene %s ONLINE PLUS Account ist gesperrt. Bitte entsperren Sie den Account über die %s ONLINE PLUS Webseite oder App.',
    gcop_problems: 'Es gibt Verbindungsprobleme zu %s ONLINE PLUS. Bitte versuchen Sie es erneut.',
    different_customer_from_different_houses: 'Um ein weiteres Haus hinzuzufügen, fügen Sie die ONLINE PLUS-Verbindung auf der Seite Einstellungen > Verknüpfung bearbeiten hinzu.',
    different_customer_from_same_house: 'Ein ONLINE PLUS-Konto ist bereits mit diesem Haus verbunden. Entfernen Sie die aktuelle Verbindung und fügen Sie die neue ONLINE PLUS-Verbindung hinzu.',
    not_accepted_documents: 'Bitte melden Sie sich zunächst in ONLINE PLUS an, um Dokumente zu bestätigen. Probieren Sie dann die Verknüpfung erneut.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  delete_connection_member: {
    last_connection_member: 'Wenn nur eine ONLINE PLUS-Verbindung besteht, kann diese nicht entfernt werden.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  add_erp_customer_user: {
    existing: 'Dieser Craftsman hat schon eine Verbindung im selben Haus.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  delete_channel_member: {
    last_member_cannot_be_deleted: 'Das letzte Mitglied kann nicht aus einem Chat entfernt werden.',
    main_contact_cannot_be_deleted: 'Der Hauptkontakt kann nicht gelöscht werden.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  leaveSalesSpace: {
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  joinSalesSpace: {
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  create_channel_member: {
    user_not_in_assignment: 'Benutzer nicht der Kommunikationszuweisung.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  auth_error: {
    token_expired: 'Ihr Token ist abgelaufen. Bitte schließen Sie das Fenster und öffnen Sie es erneut.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  create_channel: {
    forbidden: 'Der Channel konnte nicht erstellt werden. Keine Berechtigung',
    no_common_house: 'Der Channel konnte nicht erstellt werden. Bitte wählen Sie nur Nutzer aus, die dem gleichen Haus zugeordnet sind.',
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  agent_user_service: {
    agent_email_already_in_use: 'Ein Mitarbeiter mit dieser E-Mail-Adresse existiert bereits.',
    default: 'Fehler beim Aktualisieren.',
  },
  connect_house_to_craftnote_organization: {
    craftnote_api_key_invalid: 'Der Craftnote API Key ist ungültig.',
    craftnote_organizazion_exists: 'Diese Craftnote Organization Id existiert bereits.',
  },
  chat: {
    default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
  },
  ets: {
    create_ticket: {
      default: 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.',
      no_connection_id: 'Es können keine Tickets für gelöschte Nutzer angelegt werden.',
    },
  },
  value_error: {
    any_str: {
      max_length: 'Die Nachricht konnte nicht gesendet werden, da die maximale Anzahl von Zeichen überschritten ist.',
    },
  },
};

// added seperately to be type-safe instead of using getter function
const DEFAULT_ERROR_MESSAGE = 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.';

// @ts-expect-error refactor error messages
const getErrorMsg = (code, ...args): string | undefined => {
  if (!code) {
    return undefined;
  }

  const codeParts = code.split('.');
  // @ts-expect-error refactor error messages
  const message = codeParts.reduce((red, key) => {
    return red && red[key];
  }, { ...errorMessages, ...administrationErrorMessages });

  return (message && message.length > 0) ? formatString(message, ...args) : undefined;
};

// custom error constructor to be able to add the error code in the name field
const throwError = (message: string, name: string) => {
  const error = new Error(message);
  error.name = name;
  throw error;
};


interface UseHandleQueryErrorProps {
  errorDialog: keyof typeof ERROR_DIALOG;
  // TODO infer error type from backend
  error: {
    error_code: string,
    description: string
  }
}

const useHandleQueryError = () => {
  const dispatch = useDispatch();
  return ({ errorDialog, error }: UseHandleQueryErrorProps) => {
    if (!error) return;
    const errorMessageFromCode = getErrorMsg(error.error_code);
    const defaultErrorMessage = getErrorMsg('chat.default');
    const errorMessageFromApiResonse = error.description;
    const errorMessage = errorMessageFromCode || errorMessageFromApiResonse || defaultErrorMessage;
    if (errorDialog !== ERROR_DIALOG.NONE) {
      dispatch(openErrorDialog({
        dialogErrorMsg: errorMessage,
        dialogErrorCode: error.error_code,
        errorDialog,
      }));
    }
    throw new Error(errorMessage);
  };
};


export {
  getErrorMsg,
  throwError,
  DEFAULT_ERROR_MESSAGE,
  useHandleQueryError,
};
