import { FetchProcessesFilterSuggestionsData } from '../clients/api/entities/processes/processes-api-helpers';

type ProcessesSuggestionType = {
  [key in FetchProcessesFilterSuggestionsData[number]['Type']]: string;
};

export const PROCESSES_SUGGESTION_TYPE: ProcessesSuggestionType = {
  CustomerOrderNumber: 'Auftragsnummer',
  CustomerOrderText: 'Auftragstext',
  // Left blank on purpose, not supposed to be shown
  ProcessNumber: '',
  CreationDate: '',
};
