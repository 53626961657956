import BaseModel from './BaseModel';

export class UserStatusModel extends BaseModel {
  text;

  type;

  userId;

  constructor(
    {
      id,
      text,
      type,
      userId,
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
      ...rest
    },
  ) {
    super(id, createdAt, updatedAt, createdBy, updatedBy, rest);
    this.text = text;
    this.type = type;
    this.userId = userId;
  }
}

export default class UserModel extends BaseModel {
  externalId;

  externalIdentity;

  email;

  phoneNumber;

  friendlyName; // self-assigned "nickname"

  salutation;

  userName;

  userType;

  status;

  userCompany;

  imageUrl;

  archived;

  salesSpacesUpdated;

  privacyAccepted;

  // FOR AGENTS ONLY
  permissions;

  disabled;

  tenantId;

  firstName; // assigned in user management / crm

  lastName; // assigned in user management / crm

  // FOR CRAFTSMAN ONLY
  businessUnitId;

  businessUnitName;

  craftsmanType;

  online;

  hiddenChannels;

  craftsmanName; // assigned in user management

  constructor(
    {
      id,
      externalId,
      online,
      externalIdentity,
      email,
      phoneNumber,
      salutation,
      firstName,
      lastName,
      friendlyName,
      userType,
      status,
      userCompany,
      imageUrl,
      archived,
      salesSpacesUpdated,
      privacyAccepted,
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
      // FOR AGENTS ONLY TODO: Needed?
      permissions,
      disabled,
      tenantId,
      // FOR CRAFTSMAN ONLY
      businessUnitId,
      businessUnitName,
      craftsmanType,
      hiddenChannels,
      craftsmanName,
    },
  ) {
    super(id, createdAt, updatedAt, createdBy, updatedBy);
    this.externalId = externalId;
    this.externalIdentity = externalIdentity;
    this.online = online;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.salutation = salutation;
    this.firstName = firstName;
    this.lastName = lastName;
    this.friendlyName = friendlyName;
    this.craftsmanName = craftsmanName;
    this.userType = userType;
    this.status = status;
    this.userCompany = userCompany;
    this.imageUrl = imageUrl;
    this.archived = archived;
    this.salesSpacesUpdated = salesSpacesUpdated;
    this.privacyAccepted = privacyAccepted;
    this.permissions = permissions;
    this.disabled = disabled;
    this.tenantId = tenantId;
    this.businessUnitId = businessUnitId;
    this.businessUnitName = businessUnitName;
    this.craftsmanType = craftsmanType;
    this.hiddenChannels = hiddenChannels;
  }
}
