import { friendlyTimeOrDate } from 'gcs-common/helper/dateHelper';
import etsMobileSvg from 'gcs-common/icons/ets-mobile.svg';
import { ETS_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { ReactEventHandler, useCallback, useEffect } from 'react';
import { FetchETSTicketsResponseData, refetchETSTicketDetails } from 'gcs-common/clients/api/entities/tickets/tickets-api-helpers';
import { useGetETSTicketNotificationSelector } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import { useGetETSTicketRatingQuery } from 'gcs-common/clients/api/entities/tickets/tickets-api';
import styles from './styles.module.scss';
import TicketStatus from './TicketStatus/TicketStatus';
import TicketNotificationBadge from './TicketNotificationBadge/TicketNotificationBadge';

interface TicketCardProps {
  id: string;
  status: keyof FetchETSTicketsResponseData['status'];
  title: string;
  previewImageUrl: string | undefined;
  createdAt: string | undefined;
  updatedAt: string | undefined;
}

const TicketCard = (props: TicketCardProps) => {
  const {
    id, status, title, previewImageUrl = null, createdAt = undefined, updatedAt = undefined,
  } = props;
  const navigate = useNavigate();

  const ticketNotification = useGetETSTicketNotificationSelector(id);
  const { data: ticketRating } = useGetETSTicketRatingQuery({ path: { ticket_id: id } });

  const selectTicket = () => {
    navigate(ETS_ROUTES.TICKET_DETAILS(id));
  };

  const refetchData = useCallback(() => {
    if (!id) return;
    refetchETSTicketDetails(id);
  }, [id]);

  useEffect(() => {
    refetchData();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }, [refetchData, ticketNotification?.updatedAt]);

  const handleImageError: ReactEventHandler<HTMLImageElement> = (e) => {
    e.currentTarget.src = etsMobileSvg;
  };

  return (
    <div role="button" tabIndex={-1} title={title} onClick={selectTicket} className={styles.ticket}>
      {ticketNotification && <TicketNotificationBadge />}

      <img src={previewImageUrl || etsMobileSvg} alt="Ersatzteil-Anfrage" aria-hidden onError={handleImageError} className={styles.ticketImage} />

      <div className={styles.ticketCardGrow}>
        <div className="mb-1 flex justify-between">
          <div className={styles.ticketTitle} title={title}>
            {title}
          </div>
          <div className={styles.ticketDate}>
            {friendlyTimeOrDate(updatedAt || createdAt)}
          </div>
        </div>
        <div className="flex w-full justify-between">
          <TicketStatus status={status} />
          {/* @ts-expect-error rating status not hard-coded inside component */}
          {status === 'done' && !ticketRating?.rating && <TicketStatus status="rating" />}
        </div>
      </div>
    </div>
  );

};

export default TicketCard;
