import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import initiateEtsMediaDownload from '../slices/media/mediaThunks/initiateEtsMediaDownload';
import loadLocalFileUrl from '../slices/media/mediaThunks/loadLocalFileUrl';
import {
  getHasLocalUrl,
  getMessageMediaDownloadLoading,
  getMessageMediaDownloadFailed, getAwaitingLoadLocalFileUrl,
} from '../slices/media/mediaSelectors';

/*
 * hook is responsible for downloading message media
 */
const useDownloadETSMedia = ({
  ticketId,
  title,
  attachmentId,
  attachmentUrl,
  automaticDownload = true,
}) => {
  const dispatch = useDispatch();
  const localKey = `${ticketId}-${attachmentId}-${title}`;
  const mediaHasLocalUrl = useSelector(getHasLocalUrl(localKey));
  const awaitingLoadLocalUrl = useSelector(getAwaitingLoadLocalFileUrl(localKey));
  const messageMediaDownloadLoading = useSelector(getMessageMediaDownloadLoading(
    attachmentUrl,
  ));
  const messageFileDownloadFailed = useSelector(getMessageMediaDownloadFailed(
    localKey,
  ));

  const loading = !localKey && (
    awaitingLoadLocalUrl
    || messageMediaDownloadLoading
  );

  useEffect(() => {
    // check if local file from message can be found
    if (!mediaHasLocalUrl) {
      dispatch(loadLocalFileUrl({ key: localKey }));
    }
  }, [localKey, dispatch, mediaHasLocalUrl, awaitingLoadLocalUrl]);

  useEffect(() => {
    // if File is not found locally, automatically try to download file
    if (
      !mediaHasLocalUrl
      // make sure we have already checked if there is a local url
      && !awaitingLoadLocalUrl
      // dont automatically try a second time if it has failed already
      && !messageFileDownloadFailed
      && automaticDownload
    ) {
      dispatch(initiateEtsMediaDownload({ localKey, attachmentUrl }));
    }
  }, [
    localKey,
    awaitingLoadLocalUrl,
    dispatch,
    mediaHasLocalUrl,
    automaticDownload,
    messageFileDownloadFailed,
    attachmentUrl,
  ]);

  const download = useCallback(() => {
    // this function enabled the user to manually try to download the file
    dispatch(initiateEtsMediaDownload({ localKey, attachmentUrl, showError: true }));
  }, [dispatch, localKey, attachmentUrl]);

  return { loading, localKey, download };
};

export default useDownloadETSMedia;
