import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCraftsmanConnection, getIsManualCraftsmanConnection } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { useNavigate } from 'react-router';
import OpConnectionStatus from '../OpConnectionStatus/OpConnectionStatus';

import styles from './styles.module.scss';

export const ConnectionComponent = ({ connectionId }) => {
  const connectionMember = useSelector(getCraftsmanConnection(connectionId));
  const navigate = useNavigate();
  const goToConnectionDetails = () => {
    navigate(`/craftsmanAccounts/edit/${connectionMember.id}`);
  };

  const isManual = useSelector(getIsManualCraftsmanConnection(connectionId));

  return (
    <div className={styles.connectionComponent}>
      <div className={styles.left}>
        {!isManual && <OpConnectionStatus width="20px" height="20px" connectionId={connectionMember.id} />}
        <div>
          {`${connectionMember.connection.customerNumber}
         ${isManual ? '' : `(${connectionMember.opAccount.username})`}`}
        </div>
      </div>
      <button
        type="button"
        className={styles.primaryButton}
        onClick={goToConnectionDetails}
      >
        {isManual ? 'OP Konto' : 'Bearbeiten'}
      </button>
    </div>
  );
};

ConnectionComponent.propTypes = {
  connectionId: PropTypes.string.isRequired,
};
