import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getMessageSearchErrorMessage,
  getMessageSearchInput,
  getMessageSearchIsLoading, getMessageSearchResults,
} from 'gcs-common/slices/messageSearch/messageSearchSelectors';
import { searchChannelMessages } from 'gcs-common/slices/messageSearch/messageSearchThunks';
import { setMessageSearchInput } from 'gcs-common/slices/messageSearch/messageSearchSlice';
import {
  getChannelOldestLoadedMessage,
} from 'gcs-common/slices/messages/messagesSelectors';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { getChannelHasMoreMessages } from 'gcs-common/slices/channel/channelSelectors';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import SearchResultMessage from '../SearchResultMessage/SearchResultMessage';

const MIN_INPUT_LENGTH = 3;

const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const searchInput = useSelector(getMessageSearchInput);
  const searchLoading = useSelector(getMessageSearchIsLoading);
  const searchResultMessageIds = useSelector(getMessageSearchResults);
  const channelOldestMessage = useSelector(getChannelOldestLoadedMessage(selectedChannelId));
  const messageSearchIsLoading = useSelector(getMessageSearchIsLoading);
  const channelHasMoreMessages = getChannelHasMoreMessages(selectedChannelId);
  const messageSearchErrorMessage = useSelector(getMessageSearchErrorMessage);
  const isChatConnected = useSelector(getIsChatConnected);

  const shouldSearch = searchInput.length >= MIN_INPUT_LENGTH;

  const backToChat = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(setMessageSearchInput({ searchInput: '' }));
  }, [dispatch, selectedChannelId]);

  useEffect(() => {
    if (shouldSearch) {
      dispatch(searchChannelMessages({
        channelId: selectedChannelId,
        searchString: searchInput,
      }));
    }
  }, [dispatch, searchInput, selectedChannelId, shouldSearch, channelOldestMessage]);

  const onInputChange = useCallback((event) => {
    dispatch(setMessageSearchInput({ searchInput: event.target.value }));
  }, [dispatch]);

  let results = null;

  if (searchInput.length === 0) {
    results = <div className={styles.noResult}>Gib oben einen Suchbegriff ein.</div>;
  } else if (!shouldSearch) {
    results = (
      <div className={styles.noResult}>
        Bitte gebe drei oder mehr Zeichen ein, um mit der Suche zu beginnen
      </div>
    );

  } else if (searchLoading) {
    results = (
      <div className={styles.noResult}>
        <Spinner size={ICON_SIZE.XX_SMALL} />
        &nbsp;
        Suche...
      </div>
    );
  } else if (searchResultMessageIds.length === 0) {
    results = <div className={styles.noResult}>Keine Ergebnisse gefunden</div>;
  } else {
    // render messages
    results = (
      <>
        {messageSearchIsLoading && (
          <div className={styles.noResult}>
            <Spinner size={ICON_SIZE.XX_SMALL} />
            &nbsp;
            Suche...
          </div>
        )}
        {searchResultMessageIds.map(messageId => {
          return (
            <SearchResultMessage
              key={messageId}
              messageId={messageId}
            />
          );
        })}
      </>
    );
  }

  return (
    <div className={styles.searchWrapper}>
      <div className={styles.searchInputWrapper}>
        <button
          type="button"
          onClick={backToChat}
          className={styles.exitButton}
        >
          <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.LIGHT_GREY} />
        </button>
        <div className={styles.search}>
          <input
            className={styles.searchInput}
            placeholder="Suchwort"
            type="search"
            value={searchInput}
            onChange={onInputChange}
          />
        </div>

      </div>
      {messageSearchErrorMessage && (
        <div className={styles.errorMsg}>
          Es ist ein Fehler aufgetreten.
          &nbsp;Möglicherweise konnten nicht alle Suchergebnisse geladen werden.
        </div>
      )}
      {(!isChatConnected && channelHasMoreMessages) && (
        <div className={styles.errorMsg}>
          Sie sind nicht mit dem Internet verbunden.
          &nbsp;Möglicherweise können nicht alle Suchergebnisse geladen werden.
        </div>
      )}
      <div className={styles.searchResultList}>
        {results}
      </div>
    </div>
  );
};

Search.propTypes = {};

export default Search;
