import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMediaMessageIcon,
  getMessageMediaFilename,
  getMessageThumbnail,
} from 'gcs-common/slices/messages/messageSelector';
import circledArrowDown from 'gcs-common/img/circled_arrow_down.svg';
import { ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import PropTypes from 'prop-types';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import openFile from '../../slices/media/mediaThunks/openFile';
import useDownloadMessageMedia from '../../hooks/useDownloadMessageMedia';

const MediaMessageFile = ({ messageId, CustomTextComponent, maxHeight, disableClick = false }) => {
  const mediaThumbnail = useSelector(getMessageThumbnail(messageId));
  const filename = useSelector(getMessageMediaFilename(messageId));
  const fileIcon = useSelector(getMediaMessageIcon(messageId));
  const { loading, localKey, download } = useDownloadMessageMedia({ messageId });
  const dispatch = useDispatch();

  const onOpenFile = useCallback(async () => {
    await dispatch(openFile({ messageId }));
  }, [dispatch, messageId]);
  const downloadDesc = filename || 'Datei herunterladen';
  const bgStyle = mediaThumbnail ? { backgroundImage: `url(${mediaThumbnail})` } : {};

  const innerContent = (
    <>
      <div
        style={bgStyle}
        className={styles.backgroundImage}
      />
      <div
        className={styles.infoSection}
      >
        <div className={styles.fileIcon}>
          <IconComponent Icon={fileIcon} color={ICON_COLOR.DARK_GREY} />
        </div>
        <div className={styles.fileName}>
          {CustomTextComponent ? <CustomTextComponent text={downloadDesc} /> : downloadDesc}
        </div>
        {!localKey && (
        <div className={styles.actionIcon}>
          {!localKey && !loading && !disableClick && (
            // Unfortunately, we cannot use a button here, since
            // the top level element is also a button and we cannot nest buttons.
            // changing the top-level tagName on-the-fly also does not work since
            // it will cause flickering
            <div
              tabIndex={0}
              role="button"
              onClick={download}
              className={styles.downloadButton}
            >
              <img src={circledArrowDown} alt="Downlaod" />
            </div>
          )}
          {loading && (
          <div className={styles.loadingIcon}>
            <Spinner size={ICON_SIZE.XX_SMALL} color={ICON_COLOR.WHITE} />
          </div>
          )}
        </div>
        )}
      </div>
    </>
  );

  // unfortunaltey we cannot wrap the innerContent in a div based on
  // if the file is already loaded because changing the top level
  // tag name on the fly causes flickering
  if (disableClick) {
    return (
      <div
        className={styles.wrapperButton}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        {innerContent}
      </div>
    );
  }
  return (
    <button
      type="button"
      onClick={onOpenFile}
      className={styles.wrapperButton}
      disabled={!localKey}
      style={{ maxHeight: `${maxHeight}px` }}
    >
      {innerContent}
    </button>
  );

};

MediaMessageFile.propTypes = {
  CustomTextComponent: PropTypes.element,
  messageId: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  disableClick: PropTypes.bool,
};

export default MediaMessageFile;
