import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

function DeletedMessage() {
  return (
    <div className={styles.txtMsgBodyDeleted}>
      <IconComponent
        Icon={ICON.DELETE}
        color={ICON_COLOR.LIGHT_GREY}
        size={ICON_SIZE.SMALL}
        style={{ marginRight: '4px' }}
      />
      Nachricht wurde gelöscht.
    </div>
  );
}

export default DeletedMessage;
