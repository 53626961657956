import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

interface TicketButtonProps {
  onClick: VoidFunction;
}

const TicketButton = (props: TicketButtonProps) => {
  const { onClick } = props;
  return (
    <div className={styles.ticketButtonWrapper}>
      <button
        className={styles.ticketButton}
        onClick={onClick}
        type="button"
      >
        <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.WHITE} size={ICON_SIZE.LARGE} />
      </button>
    </div>
  );
};

export default TicketButton;
