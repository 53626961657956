import { whitelistToTransforms } from 'gcs-common/helper/reduxPersistHelper';
import { currentUserPersistWhitelist } from 'gcs-common/slices/currentUser/currentUserSlice';
import { favoritesPersistWhitelist } from 'gcs-common/slices/favorites/favoritesSlice';
import { usersPersistWhitelist } from 'gcs-common/slices/users/usersSlice';
import { channelsPersistWhitelist } from 'gcs-common/slices/channels/channelsSlice';
import { messagesPersistWhitelist } from 'gcs-common/slices/messages/messagesSlice';
import { persistencePersistWhitelist } from 'gcs-common/slices/persistence/persistenceSlice';
import { offlinePersistWhitelist } from 'gcs-common/offline/offlinePersistWhitelist';
import { googleAnalyticsPersistWhitelist } from 'gcs-common/slices/googleAnalytics/googleAnalyticsSlice';
import { userHousesPersistWhitelist } from 'gcs-common/slices/houses/housesSlice';
import { membersPersistWhitelist } from 'gcs-common/slices/members/membersSlice';
import { membersDetailsPersistWhitelist } from 'gcs-common/slices/memberDetails/memberDetailsSlice';
import { localSettingsPersistWhitelist } from '../slices/localSettings/localSettingsSlice';
import { mediaPersistWhitelist } from '../slices/media/mediaSlice';
import { tutorialPersistWhitelist } from '../slices/tutorial/tutorialSlice';
import { uiStatePersistWhitelist } from '../slices/uiState/uiStateSlice';

const persistedSlices = {
  currentUser: currentUserPersistWhitelist,
  favorites: favoritesPersistWhitelist,
  users: usersPersistWhitelist,
  memberDetails: membersDetailsPersistWhitelist,
  members: membersPersistWhitelist,
  channels: channelsPersistWhitelist,
  messages: messagesPersistWhitelist,
  persistence: persistencePersistWhitelist,
  tutorial: tutorialPersistWhitelist,
  media: mediaPersistWhitelist,
  uiState: uiStatePersistWhitelist,
  offline: offlinePersistWhitelist,
  googleAnalytics: googleAnalyticsPersistWhitelist,
  localSettings: localSettingsPersistWhitelist,
  userHouses: userHousesPersistWhitelist,
};

export const persistenceKeyPrefix = 'reduxPersist:'; // This is the default,
// but we want to export it

const transforms = whitelistToTransforms(persistedSlices);

const initializePersistenceConfig = (persistenceStorageEngine) => {
  return {
    transforms,
    keyPrefix: persistenceKeyPrefix,
    whitelist: Object.keys(persistedSlices),
    storage: persistenceStorageEngine,
  };
};

export default initializePersistenceConfig;
