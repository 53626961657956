import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBreadcrumb, captureException, captureMessage } from '@sentry/react';
import changeSelectedChannelId from 'gcs-common/slices/channels/channelsThunks/changeSelectedChannelId';
import { CHAT_ROUTES, ETS_ROUTES } from 'gcs-common/constants/routes';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import { Badge } from '@capawesome/capacitor-badge';
import { PushNotifications } from '@capacitor/push-notifications';
import { useNavigate, useLocation } from 'react-router';
import { FCM } from '@capacitor-community/fcm';
import { Device } from '@capacitor/device';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { delay } from 'gcs-common/helper/delay';
import { refetchETSTicketDetails } from 'gcs-common/clients/api/entities/tickets/tickets-api-helpers';
import { getIsRegistered } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { IS_IOS, IS_NATIVE } from 'gcs-common/constants/deviceConstants';
import { setSideMenuClosed } from '../slices/uiState/uiStateSlice';
import {
  resetPushBadge,
  registerPush,
} from '../slices/pushNotification/pushNotificationThunks';

const browserEmulationToken = 'browser-emulated-3240234y236732782';
const browserEmulationUuid = '677ae7ce-4f87-4c14-b7ee-b50965e0c736';

const checkPushPermission = async (isRegistered, pathname) => {
  const permission = await PushNotifications.checkPermissions();
  // request notifications permissions if user is logged in and has blocked push notifications.
  if (permission.receive !== 'granted' && isRegistered && pathname === CHAT_ROUTES.CHAT) {
    const requestedPermission = await PushNotifications.requestPermissions();
    return requestedPermission.receive !== 'denied';
  }
  return permission.receive === 'granted';
};

// call this function to unregister the push notifications when the user logs out
export const unregisterPush = async () => {
  if (!IS_NATIVE) {
    return;
  }

  try {
    await PushNotifications.removeAllListeners();
    await PushNotifications.unregister();
    await Badge.clear();
  } catch (e) {
    debugLogger(`Error happend during unregisterPush: ${e}`);
    captureException(e);
  }
};

const usePushNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOnline = useSelector(getIsOnline);
  const isRegistered = useSelector(getIsRegistered);
  const { pathname } = useLocation();
  useEffect(() => {
    // Note: Dont unsubscribe
    document.addEventListener('resume', () => dispatch(resetPushBadge()), false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!IS_NATIVE) {
        dispatch(registerPush(browserEmulationToken, browserEmulationUuid));
        return;
      }

      try {
        const hasPermission = await checkPushPermission(isRegistered, pathname);

        const message = `Push-Permission was ${hasPermission ? 'granted' : 'denied'}`;

        const sentryBreadcrumb = {
          level: 'info',
          category: 'push',
          message,
          timestamp: new Date().toISOString(),
        };

        addBreadcrumb(sentryBreadcrumb);

        if (!hasPermission) {
          return;
        }

        // Warning: Dont use async/await here!
        PushNotifications.addListener(
          'registration',
          ({ value: nativeToken }) => {
            (async () => {
              let token = nativeToken; // Android token
              // eslint-disable-next-line no-use-before-define
              dispatch(resetPushBadge());
              if (IS_IOS) {
                // Note: We need to use the FCM Plugin here, as otherwise we will get
                // for iOS the APNS token
                // important to wait a bit because otherwise Firebase Token will be invalid
                await delay(5);
                const fcmToken = await FCM.getToken();
                ({ token } = fcmToken);
              } else {
                // Note: As here https://developer.android.com/training/notify-user/channels
                // described, Android needs to be subscribed to at least to one Channel!
                // The cordova-plugin does this automatically, here we have to do it manually.
                // As Id we use the 'fcm_default_channel' cordova used.
                // This must be also set in the firebase-push-strings.xml and referenced in
                // the AndroidManifest.xml
                await PushNotifications.createChannel({
                  id: 'fcm_default_channel',
                  name: 'Nachrichten',
                  description: 'Nachrichten die über den Chat empfangen werden',
                  importance: 5,
                  visibility: 1,
                  lights: true,
                  vibration: true,
                });
              }
              if (isOnline && token) {
                const deviceId = await Device.getId();
                const deviceInfo = await Device.getInfo();
                const uuid = deviceId?.uuid;
                dispatch(registerPush({ token, uuid, deviceInfo }));
              }
            })();
          },
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener(
          'registrationError',
          (error) => {
            debugLogger(error);
            captureMessage(JSON.stringify(error), {
              level: 'error',
            });

          },
        );

        await PushNotifications.register();
      } catch (e) {

        debugLogger(`Error happend during registerPush: ${e}`);

        captureException(e);
      }
    })();

    if (IS_NATIVE) {
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (pushNotification) => {
          if (!pushNotification) {
            return;
          }
          const channelId = pushNotification?.notification?.data?.channel_id;
          const ticketId = pushNotification?.notification?.data?.ticket_id;

          if (channelId) {
            dispatch(changeSelectedChannelId(
              { selectedChannelId: channelId },
            ));
            navigate(CHAT_ROUTES.CHANNEL(channelId));
          }

          if (ticketId) {
            refetchETSTicketDetails(ticketId);
            navigate(ETS_ROUTES.TICKET_DETAILS(ticketId));
          }

          dispatch(setSideMenuClosed());
        },
      );
    }
  }, [dispatch, navigate, isOnline, isRegistered, pathname]);
};

export default usePushNotifications;
