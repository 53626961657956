import {
  channelInputForwardMessagesStaged,
  channelInputProcessStaged,
  channelInputProductStaged,
  channelInputReplyMessageStaged,
} from '../slices/messageInput/messageInputSlice';
import { setLeftBarTab, setToolbarTab } from '../slices/uiState/uiStateSlice';
import { taskReminderHidden } from '../slices/tasks/tasksSlice';
import {
  updateAgentAvailabilityStatus,
  updateAgentStatus,
  updateAvailabilityDate,
} from '../slices/currentAgent/currentAgentThunks';
import updateProfilePicture from '../slices/currentUser/currentUserThunks/updateProfilePicture';
import { addFavorite } from '../slices/favorites/favoritesThunks/addFavorite';
import { removeFavorite } from '../slices/favorites/favoritesThunks/removeFavorite';
import { trackGAEvent } from '../slices/googleAnalytics/googleAnalyticsThunks';
import { addFavoriteOffline } from '../slices/favorites/favoritesActions/addFavoriteOffline';
import { removeFavoriteOffline } from '../slices/favorites/favoritesActions/removeFavoriteOffline';
import { deleteMessageOffline } from '../slices/messageInput/messageInputActions/deleteMessageOffline';
import { sendMessagesOffline } from '../slices/messageInput/messageInputActions/sendMessagesOffline';
import scrollToMessageId from '../slices/chat/chatThunks/scrollToMessageId';
import { selectedChannelIdChanged } from '../slices/channels/channelsSlice';
import { processSelected, productSelected, suggestionSelected } from '../slices/processes/processesSlice';
import { changeFileListTab } from '../slices/fileList/fileListSlice';
import changeLoggedIn from '../slices/auth/authActions/changeLoggedIn';
import deleteCurrentUser from '../slices/currentUser/currentUserThunks/deleteCurrentUser';
import { updateCurrentUser } from '../slices/currentUser/currentUserThunks/updateCurrentUser';

export const getRegisteredGoogleAnalyticsEvents = (dispatch) => {
  const track = (category, action) => dispatch(trackGAEvent(category, action));

  return [
    {
      reduxActionType: channelInputReplyMessageStaged.type,
      event: () => track('MESSAGE_ACTION', 'QUICK_REPLY'),
    },
    {
      reduxActionType: changeLoggedIn.type,
      event: ({ payload: { loggedIn } }) => {
        if (loggedIn) {
          track('LOGIN', 'END_LOGIN');
        }
      },
    },
    {
      reduxActionType: selectedChannelIdChanged.type,
      event: () => track('CHANNEL', 'SWITCHED'),
    },
    {
      reduxActionType: suggestionSelected.type,
      event: () => track('MAGIC_CUBE', 'SELECT_SUGGESTION'),
    },
    {
      reduxActionType: channelInputForwardMessagesStaged.type,
      event: () => track('FORWARD_MESSAGE', 'OPENED'),
    },
    {
      reduxActionType: updateProfilePicture.fulfilled.type,
      event: () => track('SETTINGS', 'CHANGE_IMAGE'),
    },
    {
      reduxActionType: scrollToMessageId.fulfilled.type,
      event: () => track('MESSAGE', 'SCROLL_TO_MESSAGE'),
    },
    {
      reduxActionType: deleteMessageOffline.type,
      event: () => track('MESSAGE', 'MESSAGE_DELETED'),
    },
    {
      reduxActionType: sendMessagesOffline.type,
      event: ({ meta: { messages } }) => {
        messages.forEach(message => {
          const { messageType, isForwarded } = message;

          if (isForwarded) {
            track('MESSAGE', 'FORWARD_MESSAGE_SENT');
          } else if (messageType) {
            track('MESSAGE', `${messageType}_MESSAGE_SENT`);
          } else {
            track('MESSAGE', 'MESSAGE_SENT');
          }
        });
      },
    },
    {
      reduxActionType: addFavorite.fulfilled.type,
      event: () => track('FAVORITE', 'FAVORITE_ADD'),
    },
    {
      reduxActionType: removeFavorite.fulfilled.type,
      event: () => track('FAVORITE', 'FAVORITE_REMOVE'),
    },
    {
      reduxActionType: addFavoriteOffline.type,
      event: () => track('FAVORITE', 'FAVORITE_ADD'),
    },
    {
      reduxActionType: changeFileListTab.type,
      event: ({ payload: { fileListTab } }) => track('SAVED_FILES', `CHANGED_TO_${fileListTab}`),
    },
    {
      reduxActionType: setToolbarTab.type,
      event: ({ payload: { tab } }) => track('TODO', `OPEN_${tab}_MENU`),
    },
    {
      reduxActionType: removeFavoriteOffline.type,
      event: () => track('FAVORITE', 'FAVORITE_REMOVE'),
    },
    {
      reduxActionType: updateAvailabilityDate.fulfilled.type,
      event: ({ meta: { arg: { type } } }) => track('ABSENCE_MANAGER', `CHANGE_DATE_TYPE:${type}`),
    },
    {
      reduxActionType: updateAgentStatus.fulfilled.type,
      event: ({ meta: { arg: { statusType } } }) => {
        track('STATUS_CHANGE', statusType);
      },
    },
    {
      reduxActionType: updateAgentAvailabilityStatus.fulfilled.type,
      event: ({ meta: { arg: { statusType } } }) => track('ABSENCE_MANAGER', `ADD_NOTICE:${statusType}`),
    },
    {
      reduxActionType: setLeftBarTab.type,
      event: ({ payload: { leftBarTab } }) => track(leftBarTab, 'OPENED'),

    },
    {
      reduxActionType: taskReminderHidden.type,
      event: () => track('TODO', 'HIDE_REMINDER'),
    },
    {
      reduxActionType: updateCurrentUser.fulfilled.type,
      event: () => track('PROFILE', 'UPDATE'),
    },
    {
      reduxActionType: channelInputProductStaged.type,
      event: () => track('MAGIC_CUBE', 'PRODUCT_INSERTED_TO_CHAT'),
    },
    {
      reduxActionType: channelInputProcessStaged.type,
      event: () => track('MAGIC_CUBE', 'PROCESS_INSERTED_TO_CHAT'),
    },
    {
      reduxActionType: productSelected.type,
      event: () => track('MAGIC_CUBE', 'PRODUCT_SELECTED'),
    },
    {
      reduxActionType: processSelected.type,
      event: () => track('MAGIC_CUBE', 'PROCESS_SELECTED'),
    },
    {
      reduxActionType: deleteCurrentUser.fulfilled.type,
      event: () => track('USER', 'DELETION'),
    },
  ];
};
