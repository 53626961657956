import styles from './styles.module.scss';
import { KebabMenuButton } from './KebabMenuButton/KebabMenuButton';
import { HamburgerMenuButton } from './HamburgerMenuButton/HamburgerMenuButton';
import { ChannelDetailsButton } from './ChannelDetailsButton/ChannelDetailsButton';

const ChatHeader = () => {

  return (
    <>
      <div className="flex flex-row">
        <HamburgerMenuButton />
        <ChannelDetailsButton className="grow" />
        <KebabMenuButton />
      </div>
      <div className={styles.stripe} />
    </>
  );
};

export default ChatHeader;
