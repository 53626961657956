import { useDispatch, useSelector } from 'react-redux';
import { SNACKBAR_TEXT, BLOCK_OR_REPORT_MODAL_TEXT } from 'gcs-common/constants/textString';
import { getShowReportUserModal } from '../../slices/uiState/uiStateSelectors';
import { hideReportUserModal, showSnackbar } from '../../slices/uiState/uiStateSlice';
import BlockOrReportModal from './BlockOrReportModal';

const ReportUserDialog = () => {

  const isOpen = useSelector(getShowReportUserModal);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideReportUserModal());
  };
  const onConfirm = () => {
    dispatch(hideReportUserModal());
    dispatch(showSnackbar({ message: SNACKBAR_TEXT.USER_REPORTED, icon: 'reverse' }));
  };

  return (
    <BlockOrReportModal
      dialogText={BLOCK_OR_REPORT_MODAL_TEXT.REPORT_USER}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export default ReportUserDialog;
