import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { getPictureFromCamera } from 'gcs-common/helper/capacitorImageHelper';

const stagePictureFromCamera = (
  { fileSizeLimit, stageFileCallback },
) => async (dispatch, getState) => {
  const selectedChannelId = getSelectedChannelId(getState());
  const themeIdentifier = getThemeIdentifier(getState());
  const photoPrefix = themeIdentifier === 'agent' ? 'beem' : `${themeIdentifier}-beem`;

  const file = await getPictureFromCamera('Camera', photoPrefix, true, true, true);

  if (!file) {
    return;
  }

  if (file.size > fileSizeLimit) {
    const errorMessage = getErrorMsg(
      'files.wrongFileSize',
      file.name,
      fileSizeLimit / 1000000,
    );
    dispatch(openErrorDialog({ dialogErrorMsg: errorMessage }));
    return;
  }
  stageFileCallback({ channelId: selectedChannelId, file });
};

export default stagePictureFromCamera;
