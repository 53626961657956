import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import orderBy from 'lodash-es/orderBy';
import { useMemo } from 'react';
import { convertArrayToObject } from '../../../../helper/objectHelpers';
import {
  useGetETSTicketQuery,
  useGetETSTicketAttachmentsQuery,
  useGetETSTicketCommentsQuery,
  useGetETSTicketPartsQuery,
  useGetETSTicketNotificationsQuery,
  useGetETSTicketRatingQuery,
} from './tickets-api';
import {
  FetchETSTicketsResponseData,
  TicketsClientQueryKeys,
  FetchETSTicketCommentsResponseData,
  FetchETSTicketAttachmentsResponseData,
  FetchETSTicketPartsResponseData,
  FetchETSTicketRatingResponseData,
} from './tickets-api-helpers';

interface UseGetETSTicketSelectorProps {
  ticketId: string | undefined;
}

export const useGetETSTicketSelector = ({ ticketId }: UseGetETSTicketSelectorProps) => {
  const { data: ticketData } = useGetETSTicketQuery({ path: { ticket_id: ticketId || '' } });

  if (!ticketId) return undefined;

  const queryClient = useQueryClient();

  const queries = queryClient.getQueriesData({
    queryKey: [TicketsClientQueryKeys.GET_ETS_TICKETS],
  });

  const ticketFromList = queries.map((query) => {
    const queryData = query[1] as FetchETSTicketsResponseData;
    const tickets = queryData ? queryData.data : [] as FetchETSTicketsResponseData['data'];
    return tickets;
  })
    .flat()
    .find((curTicket) => curTicket.id === ticketId);

  return ticketData || ticketFromList;
};

export const useGetETSTicketNotificationSelector = (ticketId: string | undefined) => {
  const { data } = useGetETSTicketNotificationsQuery();

  if (!ticketId || !data) return undefined;

  return data.notifications.find((curNotification) => curNotification.ticketId === ticketId);
};

export enum TicketMessageType {
  COMMENT = 'comment',
  BOT_MESSAGE = 'botMessage',
  MEDIA = 'media',
  NEW_MESSAGE_INDICATOR = 'newMessagesIndicator',
  PART = 'part',
  RATING = 'rating',
}

export type CommentMessage = { type: TicketMessageType.COMMENT } & FetchETSTicketCommentsResponseData['comments'][number];
export type BotCommentMessage = { type: TicketMessageType.BOT_MESSAGE } & FetchETSTicketCommentsResponseData['comments'][number];
export type MediaMessage = { type: TicketMessageType.MEDIA } & FetchETSTicketAttachmentsResponseData['attachments'][number];
export type NewMessagesIndicator = {
  type: TicketMessageType.NEW_MESSAGE_INDICATOR, createdAt: string, id: string
};
export type PartMessage = { type: TicketMessageType.PART } & FetchETSTicketPartsResponseData['parts'][number];
export type RatingMessage = { type: TicketMessageType.RATING } & FetchETSTicketRatingResponseData;

export type TicketMessage = CommentMessage
| BotCommentMessage
| MediaMessage
| NewMessagesIndicator
| PartMessage
| RatingMessage;

interface UseGetETSTicketMessagesSelectorProps {
  ticketId: string;
  currentUserId: string;
}

export const useGetETSTicketMessagesSelector = ({
  ticketId, currentUserId,
}: UseGetETSTicketMessagesSelectorProps) => {
  const ticket = useGetETSTicketSelector({ ticketId });
  const ticketNotification = useGetETSTicketNotificationSelector(ticketId);
  const { data: commentsData } = useGetETSTicketCommentsQuery({ path: { ticket_id: ticketId } });
  const { data: attachmentsData } = useGetETSTicketAttachmentsQuery({
    path: { ticket_id: ticketId },
  });
  const { data: parts } = useGetETSTicketPartsQuery({ path: { ticket_id: ticketId } });
  const { data: ratingData } = useGetETSTicketRatingQuery({ path: { ticket_id: ticketId } });
  const rating = ratingData || { comment: '', rating: 0 };

  const comments = commentsData?.comments;
  const attachments = attachmentsData?.attachments;

  if (!ticket || !comments || !attachments || !parts) return [];
  const initialCommentMessage: CommentMessage = {
    type: TicketMessageType.COMMENT,
    createdAt: ticket.createdAt || '',
    authorId: currentUserId,
    html: ticket.description,
    authorImageUrl: '',
    authorName: '',
    fromBeem: false,
    id: '',
    isBot: false,
    updatedAt: undefined,
  };

  let result: TicketMessage[] = [initialCommentMessage];

  result.push(
    ...comments.filter(c => !c.isBot).map(comment => {
      const message: CommentMessage = {
        type: TicketMessageType.COMMENT,
        ...comment,
      };
      return message;
    }),
  );

  result.push(
    ...comments.filter(c => c.isBot).map(comment => {
      const botCommentMessage: BotCommentMessage = {
        type: TicketMessageType.BOT_MESSAGE,
        ...comment,
      };
      return botCommentMessage;
    }),
  );

  result.push(
    ...attachments.map(attachment => {
      const mediaMessage: MediaMessage = {
        type: TicketMessageType.MEDIA,
        ...attachment,
      };
      return mediaMessage;
    }),
  );

  if (ticketNotification) {
    // last date that the user interact with the ticket. Weonly display the dividir
    // before comments that are not from the user, and we stack them behind the divider
    // depending on its creation date
    const unreadIndicatorDate = new Date(
      Math.max(
        new Date(ticketNotification.lastViewedAt).getTime(),
        ...result.filter(x => ((x.type === TicketMessageType.BOT_MESSAGE || x.type === TicketMessageType.COMMENT) && x.fromBeem && !x.isBot) || (x.type === TicketMessageType.MEDIA && x.fromBeem)).map(x => ('createdAt' in x ? new Date(x.createdAt).getTime() : 0)),
      ),
    ).toISOString();

    result.push({
      type: TicketMessageType.NEW_MESSAGE_INDICATOR,
      createdAt: moment(unreadIndicatorDate).toISOString(),
      id: ticketNotification.id,
    });
  }

  // createdAt will always exist as parts are not added to array yet. added for TS safety
  result = orderBy(result, x => ('createdAt' in x ? new Date(x.createdAt) : new Date()), 'asc');

  if (ticket.status === 'done') {
    result.push(
      ...[...(parts.parts || []), ...(parts.alternatives || [])].map(part => {
        const partMessage: PartMessage = {
          type: TicketMessageType.PART,
          ...part,
        };
        return partMessage;
      }),
    );
    result.push(
      {
        type: TicketMessageType.RATING,
        ...rating,
      },
    );
  }

  return result;
};

interface UseGetETSTicketMessagesLoadingSelectorProps {
  ticketId: string;
}

export const useGetETSTicketMessagesLoadingSelector = ({
  ticketId,
}: UseGetETSTicketMessagesLoadingSelectorProps) => {
  const { isFetching: commentsLoading } = useGetETSTicketCommentsQuery({
    path: { ticket_id: ticketId },
  });
  const { isFetching: attachmentsLoading } = useGetETSTicketAttachmentsQuery({
    path: { ticket_id: ticketId },
  });
  const { isFetching: partsLoading } = useGetETSTicketPartsQuery({
    path: { ticket_id: ticketId },
  });
  const { isFetching: ticketLoading } = useGetETSTicketQuery({
    path: { ticket_id: ticketId },
  });
  const { isFetching: notificatonsLoading } = useGetETSTicketNotificationsQuery();

  return commentsLoading || attachmentsLoading || partsLoading
  || ticketLoading || notificatonsLoading;
};

export const useGetETSTicketNotificationsSelector = () => {
  const { data: ticketNotificationsData } = useGetETSTicketNotificationsQuery();
  const ticketNotifications = useMemo(() => (
    ticketNotificationsData ? convertArrayToObject(ticketNotificationsData.notifications, 'ticketId') : {}),
  [ticketNotificationsData]);
  return ticketNotifications;
};

export const useGetETSTicketNotificationsCountSelector = () => {
  const ticketNotifications = useGetETSTicketNotificationsSelector();
  const ticketNotificationsCount = useMemo(() => Object.keys(ticketNotifications).length,
    [ticketNotifications]);
  return ticketNotificationsCount;
};

export const useGetETSTicketNotificationsForChannelSelector = (selectedChannelId: string) => {
  const { data: ticketNotificationsData } = useGetETSTicketNotificationsQuery();
  if (!selectedChannelId) return [];
  const ticketNotifications = ticketNotificationsData ? ticketNotificationsData.notifications : [];
  return ticketNotifications
    .filter(({ channelId }) => selectedChannelId === channelId)
    .map(({ id }) => id);
};

export const useGetETSTicketNotificationsCountForChannelSelector = (selectedChannelId: string) => {
  const ticketNotifications = useGetETSTicketNotificationsForChannelSelector(selectedChannelId);
  return ticketNotifications.length;
};
