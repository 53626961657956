/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import avatarPlaceholder from 'gcs-common/img/user.svg';
import FeatureFlagProtectedRoute from 'gcs-common/components/ProtectedRoutes/FeatureFlagProtectedRoute';
import { LastOnlineStatusBadge } from 'gcs-common/components/LastOnlineStatusBadge/LastOnlineStatusBadge';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import styles from './styles.module.scss';
import UserStatusPropType from '../../../models/UserStatusPropType';

const MemberHeader = ({
  name = '', image = '', selectedUserIsAgent, userStatus,
}) => {
  const currentUserIsAgent = useSelector(getIsAgent);

  return (
    <div className={styles.profilePictureContainer}>
      <div className={styles.profilePicture}>
        {image ? (
          <img src={image} alt="selected user" />
        ) : (
          <img src={avatarPlaceholder} alt="selected user" />
        )}
      </div>
      <div className={styles.friendlyName}>{name}</div>
      <FeatureFlagProtectedRoute enabledFlag={FEATURE_FLAGS.LAST_ONLINE_BADGE_ENABLED}>
        {!selectedUserIsAgent
          && currentUserIsAgent
          && (
          <LastOnlineStatusBadge
            userStatus={userStatus}
          />
          )}
      </FeatureFlagProtectedRoute>
    </div>
  );
};

MemberHeader.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  selectedUserIsAgent: PropTypes.bool,
  userStatus: UserStatusPropType,
};

export default MemberHeader;
