import { useDispatch, useSelector } from 'react-redux';
import { getCurrentFriendlyName, getCurrentUserId, getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getUserImage, getUserStatus } from 'gcs-common/slices/user/userSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import ChannelItem from './ChannelItem/ChannelItem';
import { setSettingsMenuOpen } from '../../slices/uiState/uiStateSlice';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import ChannelListAgent from './ChannelGroup/ChannelListAgent/ChannelListAgent';
import ChannelListCraftsman from './ChannelGroup/ChannelListCraftsman/ChannelListCraftsman';
import { getIsSideMenuOpen } from '../../slices/uiState/uiStateSelectors';
import NewChatButton from './NewChatButton/NewChatButton';

const SideMenu = () => {

  const dispatch = useDispatch();

  const isAgent = useSelector(getIsAgent);
  const currentFriendlyName = useSelector(getCurrentFriendlyName);
  const currentUserId = useSelector(getCurrentUserId);
  const status = useSelector(getUserStatus(currentUserId));
  const userImage = useSelector(getUserImage(currentUserId));
  const sideMenuOpen = useSelector(getIsSideMenuOpen);

  return (
    <div className={styles.sideMenu}>
      <div className={styles.profileWrapper}>
        <ChannelItem
          label={currentFriendlyName}
          description="Einstellungen"
          onClick={() => {
            dispatch(setSettingsMenuOpen());
          }}
          iconLeft={(
            <ImageWithStatus
              imageUrl={userImage}
              userStatus={status}
              className={styles.sideMenuImageWithStatus}
            />
                    )}
          iconRight={<IconComponent Icon={ICON.ARROW} color={ICON_COLOR.LIGHT_GREY} />}
        />
      </div>

      <div className={styles.listWrapper}>

        {isAgent
          ? <ChannelListAgent />
          : <ChannelListCraftsman />
          }
      </div>
      {sideMenuOpen && <NewChatButton />}
    </div>
  );
};

export default SideMenu;
