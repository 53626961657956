import PropTypes from 'prop-types';

import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const MemberContactItem = ({ title, Icon }) => {
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.detailItem}>
        <IconComponent Icon={Icon} style={{ padding: '4px' }} />
        <div className={styles.detailName}>{title}</div>
      </div>
    </div>
  );
};

MemberContactItem.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
};

export default MemberContactItem;
