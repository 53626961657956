import { createSelector } from 'reselect';
import ASYNC_STATE from 'gcs-common/constants/AsyncState';
import { toHasError, toIsLoading } from 'gcs-common/helper/reduxLoadingStatusHelper';

const getMediaState = state => state.media || {};

export const getDownloadFilePending = (key) => state => (
  !state.media.downloadFile[key]
);

export const getDownloadFileLoading = (key) => createSelector(
  getMediaState,
  (mediaState) => toIsLoading(mediaState.downloadFile[key]),
);

export const getDownloadFileFailed = (key) => createSelector(
  getMediaState,
  (mediaState) => toHasError(mediaState.downloadFile[key]),
);

export const getAwaitingDownloadFile = (key) => createSelector(
  getDownloadFilePending(key),
  getDownloadFileLoading(key),
  (pending, loading) => pending || loading,
);

export const getInitiateMessageMediaDownloadLoading = (messageMediaRelativePath) => state => (
  state.media.initiateMessageMediaDownload[messageMediaRelativePath]?.status === ASYNC_STATE.LOADING
);
export const getInitiateMessageMediaDownloadFailed = (messageMediaRelativePath) => state => (
  state.media.initiateMessageMediaDownload[messageMediaRelativePath]?.status === ASYNC_STATE.FAILED
);

export const getInitiateEtsMediaDownloadLoading = (key) => createSelector(
  getMediaState,
  (mediaState) => toIsLoading(mediaState.initiateEtsMediaDownload[key]),
);

export const getInitiateEtsMediaDownloadFailed = (key) => createSelector(
  getMediaState,
  (mediaState) => toHasError(mediaState.initiateEtsMediaDownload[key]),
);

export const getLoadLocalFileUrlLoading = (key) => state => (
  state.media.loadLocalFileUrl[key]?.status === ASYNC_STATE.LOADING
);

export const getLoadLocalFileUrlPending = (key) => state => (
  !state.media.loadLocalFileUrl[key] // if this property does not exists it must be pending
);

export const getAwaitingLoadLocalFileUrl = (key) => createSelector(
  getLoadLocalFileUrlPending(key),
  getLoadLocalFileUrlLoading(key),
  (pending, loading) => pending || loading,
);


export const getLocalUrl = (key) => state => (
  state.media.localFileUrls[key]
);

export const getInvalidFileDeleted = (key) => state => (
  !!state.media.deletedInvalidFiles[key]
);

export const getMessageMediaDownloadLoading = (messageMediaRelativePath) => createSelector(
  getInitiateMessageMediaDownloadLoading(messageMediaRelativePath),
  getDownloadFileLoading(messageMediaRelativePath),
  (initiateLoading, loading) => {
    return initiateLoading || loading;
  },
);

export const getMessageMediaDownloadFailed = (messageMediaRelativePath) => createSelector(
  getInitiateMessageMediaDownloadFailed(messageMediaRelativePath),
  getDownloadFileFailed(messageMediaRelativePath),
  (initiateFailed, failed) => {
    return initiateFailed || failed;
  },
);

export const getEtsMediaDownloadLoading = (key) => createSelector(
  getInitiateEtsMediaDownloadLoading(key),
  getDownloadFileLoading(key),
  (initiateLoading, loading) => {
    return initiateLoading || loading;
  },
);

export const getEtsMediaDownloadFailed = (key) => createSelector(
  getInitiateEtsMediaDownloadFailed(key),
  getDownloadFileFailed(key),
  (initiateFailed, failed) => {
    return initiateFailed || failed;
  },
);

export const getHasLocalUrl = (key) => createSelector(
  getLocalUrl(key),
  (localUrl) => {
    return !!localUrl;
  },
);
