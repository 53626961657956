import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import {
  getLatestMessageIdForSelectedChannel,
  getMessagesForChannel,
} from 'gcs-common/slices/messages/messagesSelectors';
import {
  getChannelHasMore,
} from 'gcs-common/slices/uiState/uiStateSelectors';
import {
  VirtuosoMessageList,
  VirtuosoMessageListLicense,
} from '@virtuoso.dev/message-list';
import { useEffect, useRef } from 'react';
import loadPreviousMessages from 'gcs-common/slices/messages/messagesThunks/loadPreviousMessages';
import { getShouldScrollToMessageId } from 'gcs-common/slices/chat/chatSelectors';
import {
  getChannelLastDeliveredMessageIdFromMe,
  getChannelLastMessageIdReadAndFromMe,
  getOtherMemberTyping,
} from 'gcs-common/slices/channel/channelSelectors';
import {
  getMessageShowUnreadNumberMessageId,
} from 'gcs-common/slices/messages/messageSelector';
import { resetScrollToMessageId } from 'gcs-common/slices/chat/chatSlice';
import fetchMessages from 'gcs-common/slices/messages/messagesThunks/fetchMessages';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';

import styles from './styles.module.scss';
import {
  useSynchronizeVirtuoso,
  useSynchronizeVirtuosoDeliveredIcon,
  useSynchronizeVirtuosoPendingIcon,
  useSynchronizeVirtuosoReadIcon,
  useSynchronizeVirtuosoTypingIndicator,
  useSynchronizeVirtuosoUnreadDivider,
} from './virtuosoMessageScrollerHooks';
import MessageWrapper from '../MessageWrapper/MessageWrapper';

let initMessagesLoaded = false;
const licenceKey = import.meta.env.VITE_VIRTUOSO_LICENCE_KEY;

const VirtualMessageScroller = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const hasMore = useSelector(getChannelHasMore(selectedChannelId));
  const messages = useSelector(getMessagesForChannel(selectedChannelId));

  const virtuosoRef = useRef(undefined);

  // typing
  const otherUserTyping = useSelector(getOtherMemberTyping(selectedChannelId));
  const latestMessageId = useSelector(getLatestMessageIdForSelectedChannel);

  // unread divider
  const unreadDividerMessageId = useSelector(
    getMessageShowUnreadNumberMessageId(selectedChannelId),
  );

  // checkmark
  const lastDeliveredId = useSelector(getChannelLastDeliveredMessageIdFromMe(selectedChannelId));
  const lastReadId = useSelector(getChannelLastMessageIdReadAndFromMe(selectedChannelId));

  // scrolling
  const scrollToMessageId = useSelector(getShouldScrollToMessageId);

  const dispatch = useDispatch();

  useSynchronizeVirtuoso(virtuosoRef.current?.data, messages);

  useSynchronizeVirtuosoTypingIndicator(
    virtuosoRef.current?.data, latestMessageId, otherUserTyping,
  );

  useSynchronizeVirtuosoUnreadDivider(
    virtuosoRef.current?.data, latestMessageId, unreadDividerMessageId,
  );

  useSynchronizeVirtuosoDeliveredIcon(
    virtuosoRef.current?.data, lastDeliveredId,
  );

  useSynchronizeVirtuosoPendingIcon(
    virtuosoRef.current?.data, lastDeliveredId,
  );

  useSynchronizeVirtuosoReadIcon(
    virtuosoRef.current?.data, lastReadId,
  );

  useEffect(() => {
    if (!initMessagesLoaded) {
      initMessagesLoaded = true;
      dispatch(fetchMessages({
        channelId: selectedChannelId,
        singlePage: true,
      }));
    }

  }, [dispatch, selectedChannelId]);


  useEffect(() => {
    if (scrollToMessageId) {
      // workaround, because we need to wait a little bit until the chat is ready and we can scroll
      // when we land here after page navigation
      setTimeout(() => {
        const scrollToIndex = virtuosoRef?.current?.data.findIndex(m => {
          return m.id === scrollToMessageId;
        });

        const onDone = () => {
          dispatch(resetScrollToMessageId());
        };

        virtuosoRef?.current?.scrollToItem({
          index: scrollToIndex,
          align: 'start-no-overflow',
          behavior: 'auto',
          done: onDone,
        });
      }, 100);
    }
  }, [dispatch, scrollToMessageId]);


  const generateKey = ({ data: message }) => {
    return message.id;
  };

  const onScroll = (location) => {
    // offset is 0 at the top, -totalScrollSize + viewportHeight at the bottom
    if (location.listOffset > -100 && hasMore) {
      dispatch(loadPreviousMessages());
    }
  };

  if (!messages) {
    return (
      <div className={styles.virtualMessageLoader}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      <VirtuosoMessageListLicense licenseKey={licenceKey}>
        <VirtuosoMessageList
          initialData={messages}
          onScroll={onScroll}
          ref={virtuosoRef}
          style={{ flex: 1 }}
          computeItemKey={generateKey}
          initialLocation={{ index: 'LAST', align: 'end' }}
          ItemContent={({ data: message }) => {
            return (
              <MessageWrapper messageId={message.id} />
            );
          }}
        />
      </VirtuosoMessageListLicense>
    </>
  );
};

export default VirtualMessageScroller;
