import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGroupChat } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import { ICON } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import GroupChatIcon from '../../../img/icons/group_chat.svg?react';

import styles from './styles.module.scss';

const NewChatGroupItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSelected, setIsSelected] = useState(false);

  // This will add the background then redirect to the new group chat page after 500ms
  // so the user can see the background before the redirect and it's consistent
  // with other channels/items buttons
  const handleClick = () => {
    setIsSelected(true);
    setTimeout(() => {
      dispatch(setGroupChat({ groupChat: true }));
      navigate('/newGroupChat');
    }, 200);
  };

  return (
    <button
      className={`${styles.newGroupWrapper} ${isSelected ? styles.selected : ''}`.trim()}
      onClick={handleClick}
      type="button"
    >
      <GroupChatIcon />
      <span className={styles.newGroupHeadline}>Neue Gruppe erstellen</span>
      <div className={styles.newChatNext}>
        <IconComponent Icon={ICON.ARROW} />
      </div>
    </button>
  );
};

export default NewChatGroupItem;
