import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, FormikProps } from 'formik';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import SelectArticles from './SelectArticles/SelectArticles';
import OrderButton from '../OrderButton/OrderButton';
import {
  ORDER_STATUS,
  ORDER_STATUS_COLOR, ORDER_STATUS_DESCRIPTION_AGENT, ORDER_STATUS_DESCRIPTION_CUSTOMER,
} from '../../slices/quickOrdering/quickOrderingConstants';
import { getIsMobile } from '../../slices/init/initSelectors';
import { ICON } from '../../constants/IconConstants';
import { getIsAgent } from '../../slices/currentUser/currentUserSelectors';
import { createNewOrder } from '../../slices/quickOrdering/quickOrderingSlice';
import type { QuickOrderFormValues } from '../QuickOrder/QuickOrder';
import OrderPosition from './OrderPosition/OrderPosition';

type QuickOrderFormProps = FormikProps<QuickOrderFormValues> & { isLoading: boolean };

const QuickOrderForm = ({ values, resetForm, isLoading } : QuickOrderFormProps) => {

  const isMobile = useSelector(getIsMobile);
  const isAgent = useSelector(getIsAgent);
  const hasBackButton = isMobile;
  const hasCloseButton = !isMobile && isAgent;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orderPositions } = values;
  const { status, processNumber } = values;

  const onGoBack = () => {
    navigate(-1);
  };

  const onClose = () => {
    dispatch(createNewOrder());
    resetForm();
  };

  const nameEditable = status === ORDER_STATUS.LOCAL_DRAFT;
  const customerOrderNumberEditable = status === ORDER_STATUS.LOCAL_DRAFT
      || (status === ORDER_STATUS.OPEN && !isAgent);
  const customerOrderTextEditable = customerOrderNumberEditable;
  const orderPositionsEditable = status === ORDER_STATUS.LOCAL_DRAFT;
  const orderAddressEditable = status === ORDER_STATUS.LOCAL_DRAFT
      || (status === ORDER_STATUS.OPEN && !isAgent);
  const articleQuantityEditable = status === ORDER_STATUS.LOCAL_DRAFT
      || (status === ORDER_STATUS.OPEN && !isAgent);
  const priceEditable = status === ORDER_STATUS.LOCAL_DRAFT && isAgent;

  const getIsOrderPositionsRemovable = () => {
    if (isAgent) {
      return status === ORDER_STATUS.LOCAL_DRAFT;
    }
    return status === ORDER_STATUS.OPEN && orderPositions.length > 1;
  };

  let ctaText = null;
  if (status === ORDER_STATUS.LOCAL_DRAFT) {
    ctaText = 'In Chat einfügen';
  } else if (status === ORDER_STATUS.OPEN && !isAgent) {
    ctaText = 'Jetzt Bestellen';
  }

  return (
    <Form className={styles.container}>
      <div className={styles.headerWrapper}>
        {hasBackButton && (
        <div>
          <button
            type="button"
            onClick={onGoBack}
          >
            <ICON.ARROW style={{
              transform: 'rotate(180deg)',
              width: '32px',
              height: '32px',
            }}
            />
          </button>
        </div>
        )}
        <div className={styles.h1}>Schnellbestellung</div>
        {hasCloseButton && (
        <button
          type="button"
          onClick={onClose}
          style={{
            marginLeft: 'auto',
            marginRight: '20px',
            cursor: 'pointer',
          }}
        >
          <ICON.CLOSE style={{
            width: '32px',
            height: '32px',
          }}
          />
        </button>
        )}

      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '-10px',
          marginBottom: '20px',
          alignItems: 'center',
        }}
      >
        <div style={{
          backgroundColor: ORDER_STATUS_COLOR[status],
          height: '10px',
          width: '10px',
          borderRadius: '50%',
          marginRight: '10px',
          marginBottom: '2px',
          flex: '0 0 auto',
        }}
        />
        <div
          className={styles.h1}
          style={{
            color: ORDER_STATUS_COLOR[status],
          }}
        >
          {isAgent
            ? ORDER_STATUS_DESCRIPTION_AGENT[status] : ORDER_STATUS_DESCRIPTION_CUSTOMER[status]}
        </div>
      </div>
      {
        status === ORDER_STATUS.SUBMITTED && processNumber && (
          <div className={styles.processNumber}>
            {`Vorgangsnummer: ${processNumber}`}
          </div>
        )
      }
      {status === ORDER_STATUS.LOCAL_DRAFT && (
        <div
          className={styles.hint}
        >
          Hinweis: Der Kunde kann alle Felder und die Anzahl
          der zu bestellenden Artikel vor dem Bestellen noch einmal bearbeiten.
          {' '}
        </div>
      )}
      <div className={styles.inputTitle}>Bezeichnung</div>
      <Field
        className={styles.input}
        type="text"
        name="title"
        disabled={!nameEditable}
      />
      <div className={styles.inputTitle}>Auftragsnummer</div>
      <Field
        className={styles.input}
        type="text"
        name="customerOrderNumber"
        disabled={!customerOrderNumberEditable}
      />
      <div className={styles.inputTitle}>Auftragstext</div>
      <Field
        className={styles.input}
        type="text"
        name="customerOrderText"
        disabled={!customerOrderTextEditable}
      />
      <div className={styles.inputTitle}>Ausgewählte Lieferadresse</div>
      <Field
        className={styles.addressInput}
        type="text"
        name="deliveryAddress.name"
        placeholder="Name"
        disabled={!orderAddressEditable}
      />
      <div className={styles.addressInputGroup}>
        <Field
          className={styles.addressInput}
          type="text"
          name="deliveryAddress.street"
          placeholder="Straße"
          disabled={!orderAddressEditable}
        />
        <Field
          className={`${styles.addressInput} ${styles.addressInputNumber}`}
          type="text"
          name="deliveryAddress.streetNumber"
          placeholder="Nr"
          disabled={!orderAddressEditable}
        />
      </div>
      <div className={styles.addressInputGroup} style={{ marginBottom: '16px' }}>
        <Field
          className={`${styles.addressInput} ${styles.addressInputZip}`}
          type="text"
          name="deliveryAddress.zip"
          placeholder="PLZ"
          disabled={!orderAddressEditable}
        />
        <Field
          className={styles.addressInput}
          type="text"
          name="deliveryAddress.city"
          placeholder="Stadt"
          disabled={!orderAddressEditable}
        />
      </div>
      {orderPositionsEditable && (
        <>
          <div className={styles.inputTitle}>Artikelauswahl</div>
          <SelectArticles />
        </>
      )}
      {orderPositions.length === 0 && (
        <div className={styles.noArticles}>Noch keine Artikel ausgewählt</div>
      )}
      {orderPositions.map((orderPosition, index) => {
        return (
          <OrderPosition
            key={orderPosition.externalArticleId}
            index={index}
            articleQuantityEditable={articleQuantityEditable}
            priceEditable={priceEditable}
            orderPositionsRemovable={getIsOrderPositionsRemovable()}
          />
        );
      })}
      <div className={styles.totalPrice}>
        {`Bestellsumme: ${orderPositions.reduce((acc, pos) => acc + parseFloat(pos.quantity) * parseFloat(pos.netPrice || '0'), 0)?.toFixed(2)} €`}
      </div>
      {(ctaText) && (
        <OrderButton
          type="submit"
          className={styles.submitButton}
          disabled={orderPositions.length === 0 || isLoading}
        >
          {ctaText}
        </OrderButton>
      )}
      {/* for some reason, padding bottom on the button does not work */}
      <span style={{ minHeight: '20px' }} />
    </Form>
  );
};

export default QuickOrderForm;
