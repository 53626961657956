import { useSelector } from 'react-redux';
import { getAppName, getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { THEME } from 'gcs-common/constants/themes';
import { CODE_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import Page from '../Page/Page';
import LoginLogo from './LoginLogo/LoginLogo';
import Button from '../Button/Button';
import MessageLikeTextBox from '../MessageLikeTextBox/MessageLikeTextBox';

const LoginMobile = () => {
  const themeIdentifier = useSelector(getThemeIdentifier);

  const isDTG = themeIdentifier === THEME.DTG;
  const isAgent = themeIdentifier === THEME.AGENT;

  const navigate = useNavigate();

  const appName = useSelector(getAppName);

  function handleLogin() {
    navigate(CODE_ROUTES.REQUEST_CODE);
  }

  function handleRegistration() {
    navigate(`${CODE_ROUTES.REQUEST_CODE}?registration=true`);
  }

  return (
    <Page className={styles.wrapper}>
      <div className={styles.bkgTop}>
        <div className={styles.logo}>
          <LoginLogo />
        </div>
        <MessageLikeTextBox className={`${styles.headline} ${isDTG ? styles.headlineDTG : ''}`}>
          {`Willkommen bei ${appName}${!isAgent ? ' BEEM!' : ''} `}
        </MessageLikeTextBox>
      </div>
      <div className={`${styles.bkgMiddle} ${isDTG ? styles.bkgMiddleDTG : ''}`} />
      <div className={`${styles.bkgBottom} ${isDTG ? styles.bkgBottomDTG : ''}`}>
        <div>
          <div className={styles.buttonsWrapper}>
            <Button
              className={styles.primaryButton}
              onClick={handleLogin}
            >
              Login
            </Button>
            {!isAgent && (
              <Button
                className={styles.secondaryButton}
                onClick={handleRegistration}
              >
                Registrieren
              </Button>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default LoginMobile;
