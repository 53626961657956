/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addBreadcrumb, captureException } from '@sentry/react';
import { Badge } from '@capawesome/capacitor-badge';
import { PushNotifications } from '@capacitor/push-notifications';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import { getTotalNumberOfUnreadNotifications } from 'gcs-common/slices/uiState/uiStateSelectors';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';

const browserEmulationToken = 'browser-emulated-3240234y236732782';
const browserEmulationUuid = '677ae7ce-4f87-4c14-b7ee-b50965e0c736';

const checkPushPermission = async () => {
  const permission = await PushNotifications.checkPermissions();
  return permission.receive === 'granted';
};

export const registerPush = createAsyncThunk(
  'pushNotification/registerPush',
  async (
    { token, uuid, deviceInfo },
    { extra: { gccApiClient } },
  ) => {
    if (!IS_NATIVE) {
      await gccApiClient.registerPush(browserEmulationToken, browserEmulationUuid);
      return;
    }

    try {
      const hasPermission = await checkPushPermission();

      const message = `Push-Permission was ${hasPermission ? 'granted' : 'denied'}`;

      const sentryBreadcrumb = {
        level: 'info',
        category: 'push',
        message,
        timestamp: new Date().toISOString(),
      };

      addBreadcrumb(sentryBreadcrumb);

      if (!hasPermission) {
        return;
      }


      await gccApiClient.registerPush(token, uuid, deviceInfo);

    } catch (e) {
      debugLogger(`Error happend during registerPush: ${e}`);
      captureException(e);
    }
  },
);

export const resetPushBadge = createAsyncThunk(
  'pushNotification/resetPushBadge',
  async (_, { getState }) => {
    if (!IS_NATIVE) {
      return;
    }
    const totalUnreadNotificationsCount = getTotalNumberOfUnreadNotifications(getState());

    await PushNotifications.removeAllDeliveredNotifications();
    await Badge.set({ count: totalUnreadNotificationsCount });
  },
);
