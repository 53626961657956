import { useDispatch, useSelector } from 'react-redux';
import { BLOCK_OR_REPORT_MODAL_TEXT, SNACKBAR_TEXT } from 'gcs-common/constants/textString';
import { getShowReportMessageModal } from '../../slices/uiState/uiStateSelectors';
import { hideReportMessageModal, resetSelectedMessageIds, showSnackbar } from '../../slices/uiState/uiStateSlice';
import BlockOrReportModal from './BlockOrReportModal';

const ReportMessageDialog = () => {

  const isOpen = useSelector(getShowReportMessageModal);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideReportMessageModal());
  };
  const onConfirm = () => {
    dispatch(hideReportMessageModal());
    dispatch(showSnackbar({ message: SNACKBAR_TEXT.MESSAGE_REPORTED, icon: 'reverse' }));
    dispatch(resetSelectedMessageIds());
  };

  return (
    <BlockOrReportModal
      dialogText={BLOCK_OR_REPORT_MODAL_TEXT.REPORT_MESSAGE}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export default ReportMessageDialog;
