/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { ApiFetchOrderData, ApiSubmitOrderResponse } from '../../clients/api/entities/order-api';

export type QuickOrderingState = {
  selectedOrderId: string | null
  isCreateNewOrder: boolean
  isCheckoutFinished: boolean;
  orders: Record<string, ApiFetchOrderData>
};

const initialState: QuickOrderingState = {
  selectedOrderId: null,
  isCreateNewOrder: true,
  isCheckoutFinished: false,
  orders: {},
};

const quickOrderingSlice = createSlice({
  name: 'quickOrdering',
  initialState,
  reducers: {
    selectOrder: (state, action: PayloadAction<{ orderId: string }>) => {
      const { orderId } = action.payload;
      state.selectedOrderId = orderId;
      state.isCreateNewOrder = false;
      state.isCheckoutFinished = false;
    },
    createNewOrder: (state) => {
      state.isCreateNewOrder = true;
      state.selectedOrderId = null;
    },
    goToCheckoutFinished: (state) => {
      state.isCheckoutFinished = true;
    },
    orderUpdated: (
      state,
      action: PayloadAction<{ orderId: string, order: ApiSubmitOrderResponse }>,
    ) => {
      const { orderId, order } = action.payload;
      state.orders[orderId] = order;
    },
  },
});

export const {
  selectOrder,
  createNewOrder,
  goToCheckoutFinished,
  orderUpdated,
} = quickOrderingSlice.actions;
export default quickOrderingSlice.reducer;
