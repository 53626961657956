import { useSelector } from 'react-redux';
import { getFirstChannelId } from 'gcs-common/slices/channelList/channelListSelectors';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const NewChatButton = () => {
  const navigate = useNavigate();
  const firstChannelId = useSelector(getFirstChannelId);
  const hasChannels = !!firstChannelId;

  return (
    <div className={`${styles.newChannelButtonWrapper} ${!hasChannels ? styles.withBackground : ''}`.trim()}>
      <button
        className={styles.newChannelButton}
        onClick={() => navigate(CHAT_ROUTES.NEW_CHAT)}
        type="button"
      >
        <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.WHITE} size={ICON_SIZE.LARGE} />
      </button>
    </div>
  );
};

export default NewChatButton;
