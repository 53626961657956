import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const SideMenuItem = ({ label, iconRight, onClick, totalUnreadMessages = 0 }) => (

  <button className={styles.menuItem} onClick={onClick} type="button">
    <div className={styles.labelWrapper}>
      <span className={styles.label}>{label}</span>
    </div>
    <div className={styles.iconRight}>{iconRight}</div>
    {totalUnreadMessages > 0 && (
      <div className={styles.unreadMessages} />
    )}
  </button>
);

SideMenuItem.propTypes = {
  label: PropTypes.string,
  iconRight: PropTypes.element,
  onClick: PropTypes.func,
  totalUnreadMessages: PropTypes.number,
};

export default SideMenuItem;
