import OnlinePlusLogin from 'gcs-common/components/OnlinePlusLogin/OnlinePlusLogin';
import OnlinePlusMobileWrapper from '../OnlinePlusMobileWrapper';

const OnlinePlusLoginMobile = () => {
  return (
    <OnlinePlusMobileWrapper>
      <OnlinePlusLogin />
    </OnlinePlusMobileWrapper>
  );
};

export default OnlinePlusLoginMobile;
