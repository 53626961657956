import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';

import { useNavigate } from 'react-router';

const FeatureFlagProtectedRoute = ({ disabledFlag, enabledFlag, children, redirectTo }) => {
  const navigate = useNavigate();
  const hasDisabledFeatureFlag = useSelector(getHasFeatureFlag(disabledFlag));
  const hasEnabledFeatureFlag = useSelector(getHasFeatureFlag(enabledFlag));

  if (enabledFlag && hasEnabledFeatureFlag) {
    return (
      <>
        {children}
      </>
    );
  }

  if (enabledFlag && !hasEnabledFeatureFlag) {
    return null;
  }

  if (disabledFlag && hasDisabledFeatureFlag && redirectTo) {
    navigate('/');
  }

  if (disabledFlag && hasDisabledFeatureFlag) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

FeatureFlagProtectedRoute.propTypes = {
  disabledFlag: PropTypes.string,
  enabledFlag: PropTypes.string,
  children: PropTypes.element.isRequired,
  redirectTo: PropTypes.string,
};

export default FeatureFlagProtectedRoute;
