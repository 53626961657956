import { useState } from 'react';
import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import StarRating from 'gcs-common/design-system/ui/star-rating';
import { refetchETSTicketDetails } from 'gcs-common/clients/api/entities/tickets/tickets-api-helpers';
import { useAddETSTicketRatingMutation, useDeleteETSTicketRatingMutation } from 'gcs-common/clients/api/entities/tickets/tickets-api';
import TicketRatingModal from './TicketRatingModal/TicketRatingModal';
import styles from './styles.module.scss';

interface TicketRatingProps {
  ticketId: string;
  comment: string;
  rating: number;
}

const TicketRating = (props: TicketRatingProps) => {
  const {
    ticketId, comment, rating,
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const addETSTicketRating = useAddETSTicketRatingMutation();
  const deleteETSTicketRating = useDeleteETSTicketRatingMutation();
  const isAddLoading = addETSTicketRating.isPending;
  const isDeleteLoading = deleteETSTicketRating.isPending;
  const isLoading = isAddLoading || isDeleteLoading;

  const refetchData = () => {
    if (!ticketId) return;
    refetchETSTicketDetails(ticketId);
  };

  const handleRating = async (newRating: number, newComment: string) => {
    try {
      await addETSTicketRating.mutateAsync({
        path: {
          ticket_id: ticketId,
        },
        body: {
          rating: newRating,
          comment: newComment,
        },
      });
      refetchData();
    } catch (e) {
      // Nothing needed
    }
    setOpenModal(false);
  };

  const handleDelete = async () => {
    try {
      await deleteETSTicketRating.mutateAsync({
        path: {
          ticket_id: ticketId,
        },
      });
      refetchData();
    } catch (e) {
      // Nothing needed
    }
    setOpenModal(false);
  };


  const handleCancel = () => setOpenModal(false);

  return (
    <div className={styles.message}>
      <div className={styles.commentMsg}>
        <div>
          <div className={styles.msgHeader}>
            <div className={styles.nameHeader}>Ersatzteil-Team</div>
          </div>
          {!rating ? (
            <div>
              <p> Wie zufrieden warst du mit der Bearbeitung dieser Anfrage? </p>
              <Button
                type="button"
                size={ButtonSize.l}
                className="w-full"
                variant={ButtonVariant.solid_accent}
                onClick={() => setOpenModal(true)}
              >
                Bewerten
              </Button>
            </div>
          ) : (
            <div className="pb-2">
              <p>Danke für deine Rückmeldung zur Zufriedenheit!</p>
              <StarRating ratingValue={rating} />
              {comment && <div className="mb-4 mt-2 whitespace-pre-line break-words rounded-lg bg-neutral-3 p-2">{comment}</div>}
              <Button
                type="button"
                size={ButtonSize.m}
                variant={ButtonVariant.soft_neutral}
                onClick={() => setOpenModal(true)}
              >
                Bearbeiten
              </Button>
            </div>
          )}
          <TicketRatingModal
            rating={rating}
            comment={comment}
            handleRating={handleRating}
            handleCancel={handleCancel}
            handleDelete={handleDelete}
            open={openModal}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketRating;
