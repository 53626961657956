import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChannelInputStagedProcess,
  getChannelInputStagedProduct,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { channelInputReset } from 'gcs-common/slices/messageInput/messageInputSlice';
import styles from './styles.module.scss';
import closeIcon from '../../../img/icons/close.svg';
import ProcessDetailSmall from '../../ProcessDetailSmall/ProcessDetailSmall';
import ProductSelectedDisplay from '../../ProcessDetail/ProductSelectedDisplay/ProductSelectedDisplay';

const StageSelectedItem = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const channelInputStagedProcess = useSelector(getChannelInputStagedProcess(selectedChannelId));
  const channelInputStagedProduct = useSelector(getChannelInputStagedProduct(selectedChannelId));

  const dispatch = useDispatch();

  const resetMessage = useCallback(() => {
    dispatch(channelInputReset({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  if (!(channelInputStagedProduct || channelInputStagedProcess)) {
    return null;
  }

  return (
    <div className={styles.processDetailWrapper}>
      {channelInputStagedProcess
      && <ProcessDetailSmall process={channelInputStagedProcess} />}
      {channelInputStagedProduct
      && <ProductSelectedDisplay product={channelInputStagedProduct} />}
      <button type="button" onClick={resetMessage}>
        <img alt="close-icon" src={closeIcon} className={styles.closeButtonIcon} />
      </button>
    </div>
  );
};

export default StageSelectedItem;
