import PropTypes from 'prop-types';
import WarningIcon from '../../../img/icons/warning.svg?react';
import styles from './styles.module.scss';

const SideMenuMessage = ({ iconStyles, text = '', wrapperStyles }) => (
  <div className={`${styles.menuMessage} ${wrapperStyles || ''}`.trim()}>
    <WarningIcon className={`${styles.icon} ${iconStyles || ''}`.trim()} />
    <span className={styles.menuMessageText}>{text}</span>
  </div>
);

SideMenuMessage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  iconStyles: PropTypes.object,
  text: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  wrapperStyles: PropTypes.object,
};

export default SideMenuMessage;
