import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from './styles.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const PdfThumbnail = ({ pdfFile }) => {
  return (
    <div>
      <Document
        file={pdfFile}
        loading={<div className={styles.msgPdfThumbnail}>Lade...</div>}
        error={<div className={styles.msgPdfThumbnail}>Fehler...</div>}
      >
        <Page
          pageNumber={1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={60}
          renderMode="svg"
          loading={<div className={styles.msgPdfThumbnail}>Lade...</div>}
          error={<div className={styles.msgPdfThumbnail}>Fehler...</div>}
        />
      </Document>
    </div>
  );
};

PdfThumbnail.propTypes = {
  // eslint-disable-next-line
  pdfFile: PropTypes.object.isRequired,
};

export default PdfThumbnail;
