import IconComponent from 'gcs-common/components/Icon/Icon';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalNumberOfUnreadNotifications } from 'gcs-common/slices/uiState/uiStateSelectors';
import { useCallback, useEffect } from 'react';
import { Badge } from '@capawesome/capacitor-badge';
import UnreadCountDisplay from '../../Chat/UnreadCountDisplay';
import styles from './styles.module.scss';
import { setSideMenuOpen } from '../../../slices/uiState/uiStateSlice';

export function HamburgerMenuButton() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const totalUnreadNotificationsCount = useSelector(getTotalNumberOfUnreadNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-floating-promises
    Badge.set({ count: totalUnreadNotificationsCount });
  }, [totalUnreadNotificationsCount]);

  const handleMenuButtonClick = useCallback(() => {
    dispatch(setSideMenuOpen());
  }, [dispatch]);

  return (
    <button
      className={styles.menuButton}
      type="button"
      onClick={handleMenuButtonClick}
      data-testid="hamburger-menu-button"
    >
      {totalUnreadNotificationsCount > 0 && (
        <div className={styles.unreadMessages}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <UnreadCountDisplay unreadCount={totalUnreadNotificationsCount} />
        </div>
      )}
      <IconComponent Icon={ICON.MENU} color={ICON_COLOR.LIGHT_GREY} />
    </button>
  );
}
