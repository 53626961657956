import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAddCraftsmanConnectionError,
  getAddCraftsmanConnectionLoading,
} from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { addCraftsmanConnection } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import { resetAddError } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSlice';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getAppName, getDefaultBrand } from 'gcs-common/slices/theme/themeSelectors';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import SettingsInput from '../../SettingsInput/SettingsInput';

const CraftsmanAccountAddScreen = () => {

  const addCraftsmanError = useSelector(getAddCraftsmanConnectionError);
  const addConnectionLoading = useSelector(getAddCraftsmanConnectionLoading);
  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));
  const defaultBrand = useSelector(getDefaultBrand);
  const appName = useSelector(getAppName);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    return () => {
      dispatch(resetAddError());
    };
  }, [dispatch]);

  const addConnectionMember = async () => {
    const response = await dispatch(addCraftsmanConnection({
      brand: defaultBrand, username, password,
    }));
    if (response.error) {
      return;
    }
    onGoBack();
  };

  const onChangeUsername = useCallback((value) => {
    setUsername(value);
  }, []);

  const onChangePassword = useCallback((value) => {
    setPassword(value);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button type="button" onClick={onGoBack} className={styles.back}>
          <IconComponent
            Icon={ICON.ARROW}
            transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
            alt="Zurück"
            style={{ position: 'absolute', left: '20px' }}
          />
          <div className={styles.headline}>
            Verknüpfungen hinzufügen
          </div>
        </Button>
      </div>
      <div className={styles.body}>
        <div className={styles.title}>
          {`Bitte tragen Sie ihre ${appName} ONLINE PLUS Anmeldedaten ein:`}
        </div>
        <SettingsInput
          className={styles.input}
          label="Benutzer"
          initialValue={username}
          onChange={onChangeUsername}
        />
        <SettingsInput
          className={styles.input}
          label="Passwort"
          type="password"
          initialValue={password}
          onChange={onChangePassword}
        />
        <div className={styles.gcopError}>
          {(isOpMaintenanceEnabled && addCraftsmanError) ? opMaintenanceMessage : addCraftsmanError}
        </div>
        <button disabled={!(username && password && !addConnectionLoading)} className={styles.btn} onClick={addConnectionMember} type="button">
          Überprüfen und aktualisieren
        </button>
      </div>
    </div>
  );
};

export default CraftsmanAccountAddScreen;
