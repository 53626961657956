import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getChannelOPUserId, getPrimaryHouseForChannel } from 'gcs-common/slices/channel/channelSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { useGetProcesses } from 'gcs-common/clients/api/entities/processes/processes-api';
import { getFilter } from 'gcs-common/slices/processes/processesSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import { showFetchProcessesErrorModal } from '../../../slices/uiState/uiStateSlice';

const MagicCubeButton = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelOpUserId: string = useSelector(getChannelOPUserId(selectedChannelId));
  const filter = useSelector(getFilter);
  const { name: queryString, type: queryType } = filter || {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getPrimaryHouseForChannel(selectedChannelId));

  const {
    error,
  } = useGetProcesses(
    {
      query: {
        query_type: queryType,
        query_string: queryString,
        user_id: isAgent ? channelOpUserId : undefined,
        house_id: houseId,
      },
    },
  );

  const openProcesses = () => {
    if (error) {
      dispatch(showFetchProcessesErrorModal());
    } else {
      navigate(CHAT_ROUTES.PROCESSES(selectedChannelId));
    }
  };

  return (
    <button
      type="button"
      onClick={openProcesses}
      className={styles.magicCubeButton}
    >
      <IconComponent Icon={ICON.CUBE} color={ICON_COLOR.NEW_CHANNEL_BUTTON} alt="show processes" />
      {error && (
        <div className={styles.noAccountIndicator}>!</div>
      )}
    </button>
  );
};

export default MagicCubeButton;
