import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { getFavicon } from 'gcs-common/slices/theme/themeSelectors';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface InfoDialogProps {
  title: string;
  children?: ReactNode;
  noLogo?: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
}

const InfoDialog = (props: InfoDialogProps) => {
  const {
    title, children = '', noLogo = false, isOpen, onClose,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const logoIcon: string = useSelector(getFavicon);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      {!noLogo && (
      <div className={styles.logo}>
        <img alt="logo-img" className={styles.logoImg} src={logoIcon} />
      </div>
      )
      }
      <div className={styles.header}>
        {title}
      </div>
      <div className={styles.message}>
        {children}
      </div>
    </Modal>
  );
};

export default InfoDialog;
