import { convertPriceToString } from 'gcs-common/helper/processTextHelper';
import { FetchProcessDetailsResponseData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import styles from './styles.module.scss';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

interface ProductDetailsProps {
  product: FetchProcessDetailsResponseData['items'][number];
}

const ProductDetail = (props: ProductDetailsProps) => {
  const { product } = props;
  return (
    <div className={styles.productDetailWrapper}>
      <div className={styles.productDetail}>
        <div className={styles.productArticleNumber}>
          {product.ArticleNumber}
        </div>
        <div className={styles.productDescription}>
          <div>{product.ArticleDescription1}</div>
          <div>{product.ArticleDescription2}</div>
          {/* <ProductQuantity
            product={product}
          /> */}
          <span>{`Listenpreis: ${convertPriceToString(product?.GrossSalesPrice, product) || 'Nicht verfügbar'}`}</span>
          <span>{`Nettopreis: ${convertPriceToString(product?.NettoSalesPrice, product) || 'Nicht verfügbar'}`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
