import { useSearchParams } from 'react-router-dom';

type QueryParamsType<T extends string> = {
  [K in T]?: string;
};

export function useQueryParams<T extends string>(params: T[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = (): QueryParamsType<T> => {
    return params.reduce(
      (acc, paramName) => {
        acc[paramName] = searchParams.get(paramName) || undefined;
        return acc;
      },
      {} as QueryParamsType<T>,
    );
  };

  // setParams({ first: 'value', second: 'value' }) => '?first=value&second=value'
  // setParams({ first: 'value' }, true) => '?first=value'
  // setParams({}, true) => ''
  const setParams = (paramValues?: Partial<QueryParamsType<T>>, reset = false) => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams);

        if (reset) {
          for (const [_, value] of Object.entries(params)) {
            newParams.delete(value);
          }
        }

        if (paramValues) {
          for (const [key, value] of Object.entries(paramValues)) {
            if (value !== undefined && value !== '') {
              newParams.set(key, String(value));
            } else {
              newParams.delete(key);
            }
          }
        }

        return newParams;
      },
      { replace: true },
    );
  };

  return {
    queryParams: getParams(),
    setQueryParams: setParams,
  };
}
