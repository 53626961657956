import { debugLogger } from 'gcs-common/helper/debugLogger';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { createFileFromUrl } from 'gcs-common/helper/fileHelper';
import { captureException, captureMessage } from '@sentry/react';
import { FileCompressor } from '@capawesome-team/capacitor-file-compressor';
import { Capacitor } from '@capacitor/core';
import { mimeTypeIsImage } from 'gcs-common/helper/mediaHelper';

/**
 * The quality of the resulting image, expressed as a value from 0.0 to 1.0.
 * The value 0.0 represents the maximum compression (or the lowest quality)
 * while the value 1.0 represents the least compression (or the best quality).
 */
const IMAGE_COMPRESSION_QUALITY = 0.3;

export const compressFileIfImageOrReturnOriginal = async (file) => {
  try {
    if (!mimeTypeIsImage(file.mimeType) && file.format !== 'jpeg' && !mimeTypeIsImage(file.type)) {
      return file;
    }
    const { path: compressedPath } = await FileCompressor.compressImage({
      mimeType: 'image/jpeg',
      path: file.path || file.url,
      quality: IMAGE_COMPRESSION_QUALITY,
    });
    return {
      ...file,
      path: compressedPath,
      url: compressedPath,
      webPath: Capacitor.convertFileSrc(compressedPath),
      format: 'jpeg',
      mimeType: 'image/jpeg',
      type: 'image/jpeg',
    };
  } catch (e) {
    captureMessage(
      'Failed to compress image',
      {
        level: 'info',
        extra: { e },
      },
    );
    return file;
  }
};

export const compressImages = async (files) => {
  const promises = files
    .map(async (file) => {
      return compressFileIfImageOrReturnOriginal(file);
    });

  // eslint-disable-next-line @typescript-eslint/return-await
  return await Promise.all(promises);
};

export const getPictureFromCamera = async (
  source,
  photoPrefix,
  isSavingToGallery = true,
  returnFile = true,
  compressImage = false,
) => {
  try {
    let image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      saveToGallery: isSavingToGallery,
      resultType: CameraResultType.Uri,
      source: CameraSource[source],
    });

    if (compressImage) {
      image = await compressFileIfImageOrReturnOriginal(image);
    }

    debugLogger('WARNING: This does not work with live-reload!');

    if (returnFile) {
      const fileName = `${[
        photoPrefix,
        new Date().getTime(),
      ].join('_')}.${image.format}`;

      return await createFileFromUrl(image.webPath, fileName, `image/${image.format}`);
    }
    return image.webPath;
  } catch (e) {
    captureException(e);
    debugLogger('Error in getPictureFromCamera:', e);
    return undefined; // return undefined if no picture was taken
  }
};
