import { GalleryVerticalEnd, X } from 'lucide-react';
import { FC, useRef } from 'react';
import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import IconButton from 'gcs-common/design-system/ui/icon-button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'gcs-common/design-system/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'gcs-common/design-system/ui/select';
import { ScrollArea } from 'gcs-common/design-system/ui/scroll-area';
import { PhotoCamera, SyncAlt } from '@project-lary/react-material-symbols';
import Anchor from 'gcs-common/design-system/ui/anchor';
import { CameraView } from './camera-view';
import { CameraType } from './camera-types';
import { useCamera } from './camera-provider';
import { cn } from '../utils/tailwind-merge';

interface CameraProps {
  onClosed: () => void;
  onCapturedImages: (images: string[]) => void;
}

const Camera: FC<CameraProps> = ({ onClosed, onCapturedImages }) => {
  const camera = useRef<CameraType>();
  const {
    images, addImage, numberOfCameras, resetImages, stopStream, removeImage,
  } = useCamera();

  const handleCapture = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (camera.current) {
      const imageData = camera.current.takePhoto();
      if (imageData) {
        addImage(imageData);
      }
    }
  };

  const handleOnClosed = () => {
    stopStream();
    onClosed();
    if (images.length) {
      removeImage(0);
    }
  };
  const handleOnCapturedImages = (images: string[]) => {
    onCapturedImages(images);
    resetImages();
    handleOnClosed();
  };
  return (
    <div className=" flex flex-col items-center justify-center gap-5">
      <div className=" w-full">
        {images.length === 0 ? (
          <CameraView ref={camera} />
        ) : (
          <div className="bg-muted">
            <div className="w-full ">

              <img
                src={images[images.length - 1]}
                alt="Last Captured"
                className="w-full object-cover"
              />
            </div>
          </div>

        )}

      </div>
      <div className="flex w-full flex-col items-center justify-center gap-5">
        <div className="m-auto flex w-full flex-col items-center">
          {images.length > 0
            ? (

              <Button
                variant={ButtonVariant.solid_accent}
                id="caputure-foto"
                size={ButtonSize.l}
                className="w-full"
                onClick={() => {
                  handleOnCapturedImages(images);
                }}
              >
                Speichern
              </Button>
            )
            : (
              <IconButton
                Icon={PhotoCamera}
                variant="solid_neutral"
                className="size-[40px] rounded-xl"
                size="l"
                onClick={(e) => {
                  // @ts-ignore
                  handleCapture(e);
                }}
              />
            )
          }
        </div>

        <div>
          <Anchor className="no-underline" onClick={handleOnClosed}>Abbrechen</Anchor>
        </div>
      </div>

      <div
        className={cn(
          'absolute z-10 hidden w-full md:right-0 md:top-0  md:h-[calc(93vh_-_theme(spacing.12))] md:w-[20%]',
        )}
      >
        {images.length > 0 && (
          <div className="absolute bottom-0 left-2 md:bottom-auto md:left-auto md:right-14 md:top-0">
            <Gallery />
          </div>
        )}

        {numberOfCameras > 0 && (
          <div className="absolute bottom-0 right-6 z-10 md:bottom-0  md:right-14  md:top-auto">
            <SwitchCamera />
          </div>
        )}
      </div>
    </div>
  );
};

function SwitchCamera() {
  const { devices, setActiveDeviceId, switchCamera } = useCamera();

  if (devices.length === 2) {
    return (

      <IconButton
        Icon={SyncAlt}
        variant="solid_accent"
        className="fixed w-[30px] rounded-full "
        size="l"
        onClick={switchCamera}
      />
    );
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <IconButton
          Icon={SyncAlt}
          variant="solid_accent"
          className="fixed w-[30px] rounded-full "
          size="l"
        />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Switch Camera</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Select
            onValueChange={(value) => {
              setActiveDeviceId(value);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Choose Camera" />
            </SelectTrigger>
            <SelectContent>
              {devices.map((device) => (
                <SelectItem key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

function Gallery() {
  const { images, removeImage } = useCamera();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="rounded-full   p-4 opacity-40 hover:opacity-100 "
        >
          <GalleryVerticalEnd className="fixed size-6  " />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {images.length}
            {' '}
            Photos
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[calc(80vh-_theme(spacing.16))]">
          <div className="grid grid-cols-2 gap-2  ">
            {images.map((image, index) => (
              <div key={index} className="relative ">
                <img src={image} alt="captured" />
                <Button
                  className="absolute right-2 top-2 size-6 rounded-full p-2  opacity-40 hover:opacity-100  "
                  onClick={() => {
                    removeImage(index);
                  }}
                >
                  <X className="fixed size-4  " />
                </Button>
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

export default Camera;
