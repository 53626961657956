export const ROUTES = {
  ROOT: '/',
} as const;

export const ADMINISTRATION_ROUTES = {
  ADMINISTRATION: '/administration',
} as const;

export const LOGIN_ROUTES = {
  LOGIN: '/login',
  LOGIN_TERMS: '/login/terms',
  LOGIN_IMPRINT: '/login/imprint',
  LOGIN_PRIVACY: '/login/privacy',
  LOGIN_DELETE_ACCOUNT: '/login/daten-loeschen',
} as const;

export const CODE_ROUTES = {
  SUBMIT_CODE: '/submit-code',
  REQUEST_CODE: '/request-code',
} as const;

export const REGISTRATION_ROUTES = {
  REGISTRATION: '/registration',
  REGISTRATION_EXTERNAL: '/registration/external',
  REGISTRATION_PROFILE: '/registration/userprofile',
  REGISTRATION_TERMS: '/registration/terms',
  REGISTRATION_PRIVACY: '/registration/privacy',
} as const;

export const ROOT_ROUTES = {
  TUTORIAL: '/tutorial',
} as const;

export const CHAT_PATHS = {
  CHAT: 'chat',
  SEARCH: 'search',
  GALLERY: 'gallery',
  FAVORITES: 'favorites',
  PROCESSES: 'processes',
  QUICK_ORDER: 'quickorder',
  MESSAGE_FORWARD: 'forward',
  CHANNEL_PROFILE: 'channel',
  USER_PROFILE: 'userprofile',
  NEW_CHAT: 'newChat',
} as const;

export const CHAT_ROUTES = {
  CHAT: `/${CHAT_PATHS.CHAT}`,
  CHANNEL: (selectedChannelId : string) => `/chat/${selectedChannelId}`,
  SEARCH: (selectedChannelId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.SEARCH}`,
  GALLERY: (selectedChannelId: string, messageId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.GALLERY}/${messageId}`,
  FAVORITES: (selectedChannelId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.FAVORITES}`,
  PROCESSES: (selectedChannelId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.PROCESSES}`,
  QUICK_ORDER: (selectedChannelId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.QUICK_ORDER}`,
  MESSAGE_FORWARD: (selectedChannelId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.MESSAGE_FORWARD}`,
  CHANNEL_PROFILE: (selectedChannelId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.CHANNEL_PROFILE}`,
  USER_PROFILE: (selectedChannelId: string) => `/chat/${selectedChannelId}/${CHAT_PATHS.USER_PROFILE}`,
  NEW_CHAT: `/${CHAT_PATHS.NEW_CHAT}`,
  NOTIFICATION: `/${CHAT_PATHS.CHAT}/notifications`,
} as const;

export const ETS_ROUTES = {
  DASHBOARD: '/ticket-dashboard',
  TICKETS: '/tickets',
  TICKET_FORM: '/tickets/new',
  TICKET_DETAILS: (selectedTicketId : string, firstTime: boolean = false, loadingTitle: string | null = null) => `/tickets/${selectedTicketId}?firstTime=${firstTime}&loadingTitle=${loadingTitle}`,
} as const;
