import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { showUsercentricsSettings } from 'gcs-common/hooks/useUsercentrics';
import { getPrivacyText, getTermsText } from 'gcs-common/slices/theme/themeSelectors';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import NavigateBack from '../NavigateBack/NavigateBack';
import TermsButton from './TermsButton/TermsButton';

const TermsAndPrivacy = ({
  withTerms = false,
  withPrivacy = false,
}) => {

  const navigate = useNavigate();

  const termsText = useSelector(getTermsText);
  const privacyText = useSelector(getPrivacyText);

  const startingAccordion = useMemo(() => {
    if (withTerms && withPrivacy) return 'none';
    return withTerms ? 'terms' : 'privacy';
  }, [withTerms, withPrivacy]);

  const [accordionOpen, setAccordionOpen] = useState(startingAccordion);

  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const header = useMemo(() => {
    if (withTerms && withPrivacy) return 'Nutzungsbedignungen & Datenschutzrichtlinien';
    return withTerms ? 'Nutzungsbedignungen' : 'Datenschutzrichtlinien';
  }, [withTerms, withPrivacy]);

  return (
    <div className={styles.container}>
      <NavigateBack
        text={header}
        onBack={onGoBack}
      />
      <div className={styles.wrapper}>
        {withTerms && (
          <TermsButton
            handler={() => setAccordionOpen(accordionOpen === 'terms' ? 'none' : 'terms')}
            text="Nutzungsbedingungen"
            open={accordionOpen === 'terms'}
          />
        )}
        {withTerms && accordionOpen === 'terms'
        && (
          <div className={styles.textArea}>
            {termsText}
          </div>
        )}
        {withPrivacy && (
          <TermsButton
            handler={() => setAccordionOpen(accordionOpen === 'privacy' ? 'none' : 'privacy')}
            text="Datenschutzrichtlinien"
            open={accordionOpen === 'privacy'}
          />
        )}
        {withPrivacy && accordionOpen === 'privacy'
        && (
          <div className={styles.textArea}>
            {privacyText}
          </div>
        )}
        <button className={styles.cookieSettingsButton} onClick={showUsercentricsSettings} type="button">Datenschutzeinstellungen bearbeiten</button>
      </div>
    </div>
  );
};

TermsAndPrivacy.propTypes = {
  withTerms: PropTypes.bool,
  withPrivacy: PropTypes.bool,
};

export default TermsAndPrivacy;
