import { CHANNEL_FILTER_TYPES } from 'gcs-common/helper/channelTypeHelper';
import styles from './styles.module.scss';
import ChannelTypeSelectionTab from './ChannelTypeSelectionTab/ChannelTypeSelectionTab';

export default function ChannelTypeSelector() {

  return (
    <div className={styles.channelTypeSelectionBar}>
      <ChannelTypeSelectionTab
        filterType={CHANNEL_FILTER_TYPES.TEAM_CHATS}
      />
      <ChannelTypeSelectionTab
        filterType={CHANNEL_FILTER_TYPES.DIRECT_CHATS}
      />
    </div>

  );
}
