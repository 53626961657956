import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getTicketFirstTimeView,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import { ICON } from 'gcs-common/constants/IconConstants';
import { friendlyTimeOrDate } from 'gcs-common/helper/dateHelper';
import TicketCommentContent from 'gcs-common/components/Tickets/TicketComments/TicketCommentContent/TicketCommentContent';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

interface TicketBotMessageProps {
  createdAt: string;
  html: string;
}

const TicketBotMessage = (props: TicketBotMessageProps) => {
  const { createdAt, html } = props;
  const [loading, setLoading] = useState(true);
  const firstTime: boolean = useSelector(getTicketFirstTimeView());
  const parsedHtml = html.replace('<p><em>beem_bot:</em></p>\n\n', '');

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (firstTime) {
      timeoutId = setTimeout(() => {
        setLoading(false);
      }, 3000);

    } else {
      setLoading(false);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [firstTime]);

  return (
    <div className={styles.message} data-testid="bot-message">
      <div className={styles.commentMsg}>
        {loading && (<IconComponent Icon={ICON.LOADING_DOTS} />) }
        {!loading && (
          <div>
            <div className={styles.msgHeader}>
              <div className={styles.nameHeader}>Ersatzteil-Team</div>
            </div>
            <TicketCommentContent html={parsedHtml} />
            <div className={styles.msgFooter}>
              <span>{friendlyTimeOrDate(createdAt)}</span>
            </div>
          </div>
        ) }
      </div>
    </div>
  );
};

export default TicketBotMessage;
