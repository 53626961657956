import BlockUserDialog from '../../BlockOrReportUserDialog/BlockUserDialog';
import ReportUserDialog from '../../BlockOrReportUserDialog/ReportUserDialog';
import UserProfile from '../../UserProfile/UserProfile';

function ChatUserProfile() {
  return (
    <>
      <UserProfile />
      <BlockUserDialog />
      <ReportUserDialog />
    </>
  );
}

export default ChatUserProfile;
