import { useSelector } from 'react-redux';
import { CommentMessage } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import { friendlyTimeOrDate } from 'gcs-common/helper/dateHelper';
import TicketCommentContent from 'gcs-common/components/Tickets/TicketComments/TicketCommentContent/TicketCommentContent';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import styles from './styles.module.scss';

interface TicketMessageCommentProps extends CommentMessage {}

const TicketMessageComment = (props: TicketMessageCommentProps) => {
  const { authorId, createdAt, html } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentUserId: string = useSelector(getCurrentUserId);
  const isFromMe = authorId === currentUserId;

  return (
    <div className={`${styles.message} ${isFromMe ? styles.me : ''}`}>
      <div className={styles.commentMsg}>
        {!isFromMe && (
        <div className={styles.msgHeader}>
          <div className={styles.nameHeader}>Ersatzteil-Team</div>
        </div>
        )}
        <TicketCommentContent html={html} />
        <div className={styles.msgFooter}>
          <span>{friendlyTimeOrDate(createdAt)}</span>
        </div>
      </div>
    </div>
  );
};

export default TicketMessageComment;
