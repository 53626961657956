/* eslint-disable jsx-a11y/media-has-caption */

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getVideoDownloadReady, getVideoTitle, getVideoDownloadResolution } from 'gcs-common/slices/messages/messageSelector';
import { getVideoIsDownloading } from 'gcs-common/slices/videos/videosSelectors';
import { ICON, ICON_COLOR, ICON_TRANSFORM, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { closeVideoFullscreen, hideSnackbar } from '../../slices/uiState/uiStateSlice';
import styles from './styles.module.scss';
import VideoStreamPlayer from '../VideoStreamPlayer/VideoStreamPlayer';
import downloadVideo from '../../slices/media/mediaThunks/downloadVideo';
import { Spinner } from '../Spinner/Spinner';
import Snackbar from '../Snackbar/Snackbar';

const VideoFullscreen = ({ messageId, playbackId }) => {
  const dispatch = useDispatch();
  const videoTitle = useSelector(getVideoTitle(messageId));
  const isVideoDownloadReady = useSelector(getVideoDownloadReady(messageId));
  const isVideoDownloading = useSelector(getVideoIsDownloading);
  const videoDownloadResolution = useSelector(getVideoDownloadResolution(messageId));

  const onClose = useCallback(() => {
    dispatch(closeVideoFullscreen());
    dispatch(hideSnackbar());
  }, [dispatch]);

  const onDownload = async () => {
    if (!isVideoDownloadReady) {
      return;
    }
    dispatch(downloadVideo({ key: videoTitle, playbackId, videoDownloadResolution }));
  };

  const downloadIconDisplay = useCallback(() => {
    if (!isVideoDownloadReady) {
      return <Spinner />;
    }
    return (
      <IconComponent
        Icon={isVideoDownloading ? ICON.DOWNLOAD_ANIMATED : ICON.DOWNLOAD}
        color={ICON_COLOR.WHITE}
        size={ICON_SIZE.LARGE}
      />
    );

  }, [isVideoDownloadReady, isVideoDownloading]);

  // Keeps video from rerendering (and restarting autoplay) when download button is tapped
  const MemoizedVideoStreamPlayer = useMemo(() => {
    return <VideoStreamPlayer playbackId={playbackId} />;
  }, [playbackId]);

  return (
    <>
      <div className={styles.videoFullscreenWrapper}>
        <div className={styles.buttonsContainer}>
          <button type="button" onClick={onClose} className={styles.goBackIcon}>
            <IconComponent
              Icon={ICON.ARROW}
              transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
              color={ICON_COLOR.WHITE}
              size={ICON_SIZE.LARGE}
              alt="close"
            />
          </button>
          <button type="button" onClick={onDownload} className={styles.downloadButton}>
            {downloadIconDisplay()}
          </button>
        </div>
        {MemoizedVideoStreamPlayer}
        {/* If we don't render Snackbar here,
        it will be rendered in the background of the video player */}
        <Snackbar />
      </div>
    </>
  );
};

export default VideoFullscreen;

VideoFullscreen.propTypes = {
  playbackId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};
