import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';
import { getInvalidFileDeleted } from '../mediaSelectors';

const deleteInvalidFile = createAsyncThunk(
  'mediaThunks/deleteInvalidFile',
  async ({ key }, { extra: { blobStorageClient } }) => {
    captureMessage('Deleting an invalid file!', {
      tags: { key },
      level: 'error',
    });
    try {
      await blobStorageClient.deleteObject(key);
    } catch (error) {
      captureMessage('Could not delete invalid file', {
        level: 'error',
        tags: {
          error,
          key,
        },
      });
    }
    return { key };
  },
  { condition({ key }, { getState }) {
    // only try once so we don't end up in an infinite loop
    return !getInvalidFileDeleted(key)(getState());
  } },
);

export default deleteInvalidFile;
