import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { pad } from 'gcs-common/helper/paddedNumberHelper';
import { processSelected, productSelected } from 'gcs-common/slices/processes/processesSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { getUniqueProcessNumber, getUniqueProductNumber } from 'gcs-common/helper/processTextHelper';
import styles from './styles.module.scss';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

const ProductMessage = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedChannelId = useSelector(getSelectedChannelId);

  const setProduct = () => {
    navigate(CHAT_ROUTES.PROCESSES(selectedChannelId));
    dispatch(processSelected({
      selectedProcessNumber: getUniqueProcessNumber(
        product.containingProcess.ProcessNumber, product.containingProcess.ProcessSuffix,
      ),
    }));
    dispatch(productSelected({
      selectedProductNumber: getUniqueProductNumber(product.ArticleNumber, product.Id),
    }));
  };

  // Note: The OR operator here, to read the messages
  // that are already saved in twilio properly
  const articleNumber = product.ArticleNumber || product.ArtikelNummer;
  const articleDescription1 = product.ArticleDescription1 || product.Bezeichnung1;
  const articleDescription2 = product.ArticleDescription2 || product.Bezeichnung2;
  const processNumber = (
    product.containingProcess?.ProcessNumber || product.containingProcess?.Number
  );
  const processSuffix = (
    product.containingProcess?.ProcessSuffix || product.containingProcess?.Suffix
  );

  return (
    <div onClick={setProduct} role="presentation" className={styles.product}>
      <div className={styles.productArticleNumber}>
        {articleNumber}
      </div>
      <div className={styles.productDescription}>
        <div>{articleDescription1}</div>
        <div>{articleDescription2}</div>
        {/* <ProductQuantity
          product={product}
        /> */}
        <div className={styles.processNumber}>
          {product.containingProcess && `${processNumber}-${pad(processSuffix)}`}
        </div>
      </div>
    </div>
  );
};

ProductMessage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
};

export default ProductMessage;
