export const SNACKBAR_TEXT = {
  MESSAGE_REPORTED: 'Nachricht gemeldet',
  USER_REPORTED: 'Benutzer gemeldet',
  USER_BLOCKED: 'Benutzer blockiert',
};

export const BLOCK_OR_REPORT_MODAL_TEXT = {
  REPORT_MESSAGE: 'Möchten Sie diese Nachricht wirklich melden?',
  REPORT_USER: 'Möchten Sie diesen Nutzer wirklich melden?',
  BLOCK_USER: 'Möchten Sie diesen Nutzer wirklich blockieren?',
};
