import { WarningFilled } from '@project-lary/react-material-symbols';
import type * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import {
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import { cn } from '../utils/tailwind-merge';
import { Label } from './label';

const Form = FormProvider;

interface FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
}

interface FormMessageProps extends React.HTMLAttributes<HTMLParagraphElement> {
  showWarningIcon?: boolean;
}

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
  }: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

interface FormItemContextValue {
  id: string;
}

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = React.useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cn('space-y-1', className)} {...props} />
      </FormItemContext.Provider>
    );
  },
);
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
React.ElementRef<typeof LabelPrimitive.Root>,
React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & { isOptional?: boolean }
>(({ className, children, isOptional = false, ...props }, ref) => {
  const { formItemId } = useFormField();

  return (
    <Label ref={ref} className={cn('font-regular', className)} htmlFor={formItemId} {...props}>
      {children}
      {' '}
      {isOptional ? <span className="text-neutral-10"> (optional)</span> : null}
    </Label>
  );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
  ({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

    return (
      <Slot
        ref={ref}
        id={formItemId}
        aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
        aria-invalid={!!error}
        {...props}
      />
    );
  },
);
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return <p ref={ref} id={formDescriptionId} className={cn('text-xs text-neutral-10', className)} {...props} />;
  },
);
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<HTMLParagraphElement, FormMessageProps>(
  ({ className, children, showWarningIcon = true, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error
      ? Array.isArray(error)
        ? String(error.filter(Boolean)[0]?.message)
        : String(error?.message)
      : children;

    if (!body) {
      return null;
    }

    return (
      <div ref={ref} id={formMessageId} className={cn('font-regular text-error-9', className)} {...props}>
        <div className="flex items-center gap-1">
          {showWarningIcon && (
            <div>
              <WarningFilled className="text-lg" />
            </div>
          )}
          <div className="text-sm">{body}</div>
        </div>
      </div>
    );
  },
);
FormMessage.displayName = 'FormMessage';

const FormContainer = ({
  className,
  disabled,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & { disabled?: boolean }) => {
  return (
    <div
      className={cn(
        'rounded-md border border-neutral-4 bg-white px-4 py-5',
        {
          'cursor-not-allowed': disabled,
        },
        className,
      )}
      {...props}
    />
  );
};

const FormRow = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  const numChildren = React.Children.toArray(props.children)?.length;

  return (
    <div
      className={cn(
        'flex flex-col gap-4 md:flex-row [&>*]:grow',
        { 'md:w-1/2 md:pr-2': numChildren === 1 },
        { 'md:[&>*]:w-1/2': numChildren === 2 },
        className,
      )}
      {...props}
    />
  );
};

const FormButtonContainer = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => (
  <div className={cn('flex flex-col-reverse gap-2 py-4 sm:flex-row sm:justify-end', className)} {...props} />
);

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
  FormContainer,
  FormButtonContainer,
  FormRow,
};
