import { render } from '@testing-library/react';
import { Provider } from 'react-redux';
import { createAsyncThunk, Store } from '@reduxjs/toolkit';
import { MemoryRouter, useLocation } from 'react-router';
import ApiProvider from 'gcs-common/provider/ApiProvider';
import { reactQueryClient } from 'gcs-common/clients/api/client';
import { createMockPersister } from 'gcs-common/tests/helper/testHelper';
import { ReactNode } from 'react';
import initializeStore from '../initializeStore';

export const LOCATION_DISPLAY_ID = 'location-display';

const initGccApiTest = createAsyncThunk(
  'initGccApiTest',
  async (
    _,
    { rejectWithValue,
      // @ts-expect-error redux
      extra: { gccApiClient } },
  // eslint-disable-next-line @typescript-eslint/require-await
  ) => {
    const onErrorMiddleware = async () => { };
    const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    gccApiClient.init(onErrorMiddleware, rejectWithValue, apiBaseUrl);
  },
);

type NestedRecord = { [key: string]: string | NestedRecord };

interface RenderProps {
  preloadedState?: NestedRecord;
  routePath?: string;
  store?: Store;
}

export function renderWithProviders(
  ui: ReactNode,
  props: RenderProps = {},
) {
  const {
    preloadedState = {},
    routePath = '/',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    store = initializeStore({ preloadedState }),
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { dispatch } = store;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  dispatch(initGccApiTest());
  reactQueryClient.clear();
  // eslint-disable-next-line react/prop-types
  function Wrapper({ children }: { children: ReactNode }) {
    const persister = createMockPersister();
    return (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <Provider store={store}>
        <ApiProvider persister={persister}>
          <MemoryRouter initialEntries={[routePath]}>
            {children}
          </MemoryRouter>
        </ApiProvider>
      </Provider>
    );
  }

  const rendered = render(ui, { wrapper: Wrapper });

  // Override the rerender function to use the same wrapper
  const rerender = (newUi: ReactNode) => rendered.rerender(newUi);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return { store, ...rendered, rerender };
}

// used to display the current location in the test recommended by react-testing documentation
// https://testing-library.com/docs/example-react-router/
export const LocationDisplay = () => {
  const location = useLocation();
  return <div data-testid={LOCATION_DISPLAY_ID}>{location.pathname}</div>;
};

// helper to invisibly insert any data into a component so that we can expect it in a test
// similar to LocationDisplay
// eslint-disable-next-line react/prop-types
export const TestingDataDisplay = ({ text = '' }) => {
  return <div style={{ display: 'none' }}>{text}</div>;
};
