import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonType,
  PROCESS_BUTTON_TYPES,
  PROCESS_TYPES,
  PRODUCT_BUTTON_TYPES,
} from 'gcs-common/constants/MessageTypes';
import { formatString } from 'gcs-common/helper/stringFormatHelper';
import { pad } from 'gcs-common/helper/paddedNumberHelper';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  channelInputProcessStaged, channelInputProductStaged,
} from 'gcs-common/slices/messageInput/messageInputSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { useNavigate } from 'react-router';
import { FetchProcessDetailsResponseData, FetchProcessesResponseData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import closeIcon from '../../../img/icons/close.svg';
import Button from '../../Button/Button';
import styles from './styles.module.scss';

interface RenderButtonsProps {
  process: FetchProcessesResponseData[number];
  product: FetchProcessDetailsResponseData['items'][number] | undefined;
}

const RenderButtons = (props: RenderButtonsProps) => {
  const { process, product = undefined } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [buttonDefinitions, setButtonDefinitions] = useState<ButtonType[]>([]);
  const [isReclaim, setReclaim] = useState(false);
  const [prevDef, setPrefDef] = useState<ButtonType[]>([]);

  const isProduct = product !== undefined;

  useEffect(() => {
    // AGENTS CURRENTLY ONLY HAVE ONE BUTTON (ADD_TO_CHAT)!
    if (isAgent) {
      setButtonDefinitions(
        [isProduct ? PRODUCT_BUTTON_TYPES.ADD_TO_CHAT : PROCESS_BUTTON_TYPES.ADD_TO_CHAT],
      );
    } else {
      const processTypeKey = (Object.keys(PROCESS_TYPES) as Array<keyof typeof PROCESS_TYPES>)
        .find((key) => {
          return PROCESS_TYPES[key].type === process.ProcessTypeDescription;
        });
      const processType = processTypeKey ? PROCESS_TYPES[processTypeKey] : undefined;
      if (processType) {
        setButtonDefinitions(isProduct ? processType.productButtons : processType.processButtons);
      } else {
        // Default option, if type is not found
        setButtonDefinitions(
          [isProduct ? PRODUCT_BUTTON_TYPES.ADD_TO_CHAT : PROCESS_BUTTON_TYPES.ADD_TO_CHAT],
        );
      }
    }
  }, [isAgent, process, isProduct]);

  const isAddChat = (buttonType: ButtonType) => {
    return buttonType === PRODUCT_BUTTON_TYPES.ADD_TO_CHAT
      || buttonType === PROCESS_BUTTON_TYPES.ADD_TO_CHAT;
  };

  const handleReclaim = () => {
    setReclaim(true);
    setPrefDef(buttonDefinitions);
    setButtonDefinitions(Object.values(PRODUCT_BUTTON_TYPES.RECLAIM.children));
  };

  const handleActionClick = (buttonDefinition: ButtonType) => {
    if (buttonDefinition === PRODUCT_BUTTON_TYPES.RECLAIM) {
      handleReclaim();
    } else if (isProduct) {
      dispatch(channelInputProductStaged({
        channelId: selectedChannelId,
        subType: buttonDefinition.type,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        text: formatString(buttonDefinition.message, product.ArticleNumber),
        product: {
          ...product,
          containingProcess: process,
        },
      }));
    } else {
      dispatch(channelInputProcessStaged({
        channelId: selectedChannelId,
        subType: buttonDefinition.type,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        text: formatString(
          buttonDefinition.message,
          process.ProcessNumber,
          pad(process.ProcessSuffix),
        ),
        process,
      }));
    }
    navigate('/');
  };

  const stepBackReclaim = () => {
    setButtonDefinitions(prevDef);
    setReclaim(false);
  };

  const putExitIcon = () => {
    return (
      <button
        className={styles.exitReclaimButton}
        type="button"
        onClick={() => stepBackReclaim()}
      >
        <img
          src={closeIcon}
          className={styles.backButtonIcon}
          alt="close-icon"
        />
      </button>
    );
  };

  const buttons = buttonDefinitions.map(
    (buttonDefinition) => {
      return (
        <Button
          key={buttonDefinition.type}
          type="button"
          onClick={() => handleActionClick(buttonDefinition)}
          className={`${styles.btn} ${isAddChat(buttonDefinition) ? styles.addButton : ''}`}
        >
          {buttonDefinition.buttonText}
        </Button>
      );
    },
  );
  return (
    <>
      {isReclaim && putExitIcon()}
      {buttons}
    </>
  );
};

export default RenderButtons;
