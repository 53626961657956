import {
  useRef, useCallback, useState, useMemo, useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import SwipeableViews from 'react-swipeable-views';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { isImage } from 'gcs-common/helper/fileHelper';
import { fileListKey } from 'gcs-common/helper/mediaHelper';
import IconComponent from 'gcs-common/components/Icon/Icon';
import FileThumbnail from '../../../FilePreviewer/FileThumbnail/FileThumbnail';
import LocalFilePreview from '../../../LocalFilePreview/LocalFilePreview';
import styles from './styles.module.scss';

interface TicketAttachmentsProps {
  attachments: File[];
  setAttachments: Dispatch<SetStateAction<File[]>>
}

const TicketAttachments = (props:TicketAttachmentsProps) => {
  const { attachments, setAttachments } = props;
  const fileInputRef = useRef<HTMLDivElement>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const onRemoveImage = useCallback((file: (File & { id: string })) => {
    setAttachments(prevAttachments => prevAttachments.filter(attachment => attachment !== file));
  }, [setAttachments]);

  const handleCurrentImageSelection = useCallback((index: number) => {
    setCurrentImageIndex(index);
  }, [setCurrentImageIndex]);

  const clearAttachments = useCallback(() => {
    setAttachments([]);
  }, [setAttachments]);

  const imagePreviews = useMemo(() => {
    return attachments && attachments.map((attachment, index) => (
      <FileThumbnail
        loading={false}
        file={attachment}
        key={fileListKey(attachment)}
        // @ts-expect-error future file migration
        alt="Bild Vorschau"
        selected={index === currentImageIndex}
        onClose={onRemoveImage}
        onClick={() => handleCurrentImageSelection(index)}
      />
    ));
  }, [attachments, currentImageIndex, handleCurrentImageSelection, onRemoveImage]);

  useEffect(() => {
    setCurrentImageIndex(attachments.length - 1);
    fileInputRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [attachments]);

  const currentImage = attachments[currentImageIndex];

  return (
    <>
      {attachments.length > 0 && (
        <div className={styles.previewMain}>
          <div className={styles.filePreviewHeader}>
            <div className={styles.fileTypeLogo}>
              <IconComponent
                Icon={currentImage && isImage(currentImage) ? ICON.IMAGE : ICON.FILE}
                color={ICON_COLOR.WHITE}
              />
            </div>
            {currentImage && (
              <div className={styles.headerText}>
                {currentImage.name}
              </div>
            )}
            <button type="button" onClick={() => clearAttachments()}>
              <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.WHITE} />
            </button>

          </div>
          <SwipeableViews
            style={{ height: '100%' }}
            containerStyle={{ height: '100%' }}
            index={Math.max(0, currentImageIndex)}
            slideStyle={{ display: 'flex', overflowY: 'hidden' }}
            onChangeIndex={handleCurrentImageSelection}
          >
            {attachments.map(attachment => (
              <LocalFilePreview
                key={fileListKey(attachment)}
                className={isImage(attachment)
                  ? styles.mainPreview : styles.mainPreviewFile}
                alt="preview"
                file={attachment}
              />
            ))}
          </SwipeableViews>
        </div>
      )}
      <div className={styles.imagePickerBottom} ref={fileInputRef}>
        {attachments.length > 1 && imagePreviews}
      </div>
    </>
  );
};

export default TicketAttachments;
