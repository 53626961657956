/* eslint-disable @typescript-eslint/no-use-before-define */
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelNewestLoadedMessageIndex,
  getChannelOldestLoadedMessageIndex,
} from 'gcs-common/slices/messages/messagesSelectors';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import range from 'gcs-common/helper/range';
import { MESSAGE_LOADING_DIRECTIONS } from 'gcs-common/clients/gccApiClient/gccApiClient';
import fetchMessages from 'gcs-common/slices/messages/messagesThunks/fetchMessages';
import {
  getMessageVisibilitySessionIndexBounds, getMessageVisibilitySessionPages,
} from './messageVisibilityLoaderSelectors';
import {
  addedMessageVisibilitySessionPage,
  setMessageVisibilitySessionIndexBounds,
} from './messageVisibilityLoaderSlice';

const PAGE_SIZE = 10;
/**
 * This function determines the page numbers (based on {PAGE_SIZE}) for all items
 * between {startListIndex} and {endListIndex} and loads the corresponding pages
 * * Example:
 * startListIndex: 47, endListIndex: 62 -> all messages with indices 30-90 will be load (3 pages)
 * @param localListStartIndex -> currently visible item at the start of the viewport index +1
 * @param localListEndIndex -> currently visible item at the end of the viewport index +1
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const messageVisibilityChanged = (
  { firstIndex, lastIndex },
) => async (dispatch, getState) => {

  if (!firstIndex || !lastIndex) {
    return;
  }

  const isChatConnected = getIsChatConnected(getState());

  if (!isChatConnected) {
    return;
  }

  const selectedChannelId = getSelectedChannelId(getState());

  // eslint-disable-next-line no-use-before-define
  dispatch(setSessionIndexBounds({ channelId: selectedChannelId }));

  [...new Set(
    range(firstIndex, lastIndex)// Create the range of messagesIndexes visible
    // eslint-disable-next-line no-use-before-define
      .map(indexToPage), // Calculate all the pages we currently "see"
  )] // Remove duplicates
    .reverse() // Load the "newer" pages first
    .forEach(page => { // Actual loading
      // eslint-disable-next-line no-use-before-define
      dispatch(loadExistingPage({ channelId: selectedChannelId, pageNumber: page }));
    });
};

/**
 * The first run of this function sets the
 * current oldest/newest-messageIndices as lower/upper-bound for page-loading
 * @param channelId
 */
const setSessionIndexBounds = ({ channelId }) => (dispatch, getState) => {

  const hasBounds = getMessageVisibilitySessionIndexBounds(channelId)(getState());

  if (!hasBounds) {
    const oldestLoadedMessageIndex = (
      getChannelOldestLoadedMessageIndex(channelId)(getState())
    );
    const newestLoadedMessageIndex = (
      getChannelNewestLoadedMessageIndex(channelId)(getState())
    );
    dispatch(setMessageVisibilitySessionIndexBounds({
      channelId,
      lowerLocalIndex: oldestLoadedMessageIndex,
      upperLocalIndex: newestLoadedMessageIndex,
    }));
  }
};

/**
 * This function loads a page with the size {PAGE_SIZE} for the corresponding channelId
 * If the page was already load in this session
 * or it would be outside the messageVisibilitySessionIndexBounds
 * it will be skipped.
 * If the calculated pageSize would exceed the currently already fetched messages, the page
 * will be trimmed
 * @param channelId
 * @param pageNumber
 * @returns {(function(*, *, {chatClient: *}): Promise<void>)|*}
 */
const loadExistingPage = ({ channelId, pageNumber }) => async (dispatch, getState) => {
  const loadedPages = getMessageVisibilitySessionPages(channelId)(getState());

  if (loadedPages.includes(pageNumber)) {
    return;
  }

  // eslint-disable-next-line no-use-before-define
  const { pageStartIndex, pageEndIndex } = pageToMessageIndexRange(pageNumber);

  const {
    lowerLocalIndex,
    upperLocalIndex,
  } = getMessageVisibilitySessionIndexBounds(channelId)(getState());

  // Dont load messages outside of the initially set bounds
  if (pageStartIndex >= upperLocalIndex || pageEndIndex <= lowerLocalIndex) {
    return;
  }

  const startIndex = Math.max(pageStartIndex, lowerLocalIndex);
  const endIndex = Math.min(pageEndIndex, upperLocalIndex);

  // startReachedPageNumber/endReachedPageNumber: We only want to load messages which were already
  // present, so we stop loading messages after the oldest/newest present message to avoid
  // race-conditions with the "normal" page-loader
  dispatch(addedMessageVisibilitySessionPage({
    channelId,
    page: pageNumber,
  }));

  await dispatch(fetchMessages({
    channelId,
    fromMessageIndex: startIndex,
    toMessageIndex: endIndex,
    prepend: false,
    direction: MESSAGE_LOADING_DIRECTIONS.FORWARD,
    includeAnchorMessage: true,
    throwIfToMessageIndexNotFound: false,
  }));
};

const indexToPage = index => {
  return Math.floor(index / PAGE_SIZE);
};

const pageToMessageIndexRange = pageNumber => {
  return {
    pageStartIndex: pageNumber * PAGE_SIZE,
    pageEndIndex: (pageNumber * PAGE_SIZE) + PAGE_SIZE - 1,
  };
};
