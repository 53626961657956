import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import { captureException } from '@sentry/react';
import preloadImage from 'gcs-common/helper/preloadImage';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { getDownloadFileLoading } from '../mediaSelectors';

const downloadFile = createAsyncThunk(
  'media/downloadFile',
  async (
    { key, remoteUrl, showError = false },
    { dispatch, extra: { blobStorageClient } },
  ) => {
    try {
      const url = await blobStorageClient.saveRemoteObject(key, remoteUrl);
      try {
        await preloadImage(url);
      } catch (e) {
        // unfortunately we have no (reliable) way to determine the contentType before
        // sine th cordova file plugin does not always save it.
        // therefore there will be an error for all non-images
      }
      return { url };
    } catch (e) {
      if (showError) {
        dispatch(openErrorDialog({ dialogErrorMsg: getErrorMsg('load_media.default') }));
      }
      if (e.message !== 'Failed to fetch') {
        captureException(e);
      }
      throw e;
    }

  },
  {
    condition({ key }, { getState }) {
      return !getDownloadFileLoading(key)(getState());
    },
  },
);

export default downloadFile;
