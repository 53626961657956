import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDebugAppTheme } from 'gcs-common/slices/theme/themeSlice';
import { getAppTheme, getAppThemes } from 'gcs-common/slices/theme/themeSelectors';
import styles from './styles.module.scss';

const ChangeTheme = () => {
  const appTheme = useSelector(getAppTheme);
  const appThemes = useSelector(getAppThemes);

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('debugAppTheme', appTheme);
  }, [appTheme]);

  const nextAppTheme = useMemo(() => {
    const currentIndex = Object.keys(appThemes).indexOf(appTheme);
    if (currentIndex + 1 === Object.keys(appThemes).length || currentIndex === -1) {
      return Object.values(appThemes)[0];
    }
    return Object.values(appThemes)[currentIndex + 1];
  }, [appTheme, appThemes]);

  const onChangeAppTheme = useCallback(() => {
    dispatch(setDebugAppTheme({ debugAppTheme: nextAppTheme.identifier }));
  }, [dispatch, nextAppTheme]);

  if (import.meta.env.PROD) {
    return null;
  }

  if (import.meta.env.VITE_HIDE_DEBUG_DEV_TOOLS) {
    return null;
  }

  return (
    <button
      data-theme={`${nextAppTheme.identifier}-theme`}
      className={styles.themeButton}
      type="button"
      onClick={onChangeAppTheme}
    >
      {nextAppTheme.appName}
      <br />
      <span style={{ fontSize: 10 }}>[debug]</span>
    </button>
  );
};

export default ChangeTheme;
