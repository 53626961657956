import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

interface TicketCreationLoadingProps {
  loadingText: string;
}

const TicketCreationLoading = (props: TicketCreationLoadingProps) => {
  const { loadingText } = props;
  return (
    <div className={styles.creationLoadingContainer}>
      <LoadingIndicator />
      <p>
        { loadingText }
      </p>
    </div>
  );
};

export default TicketCreationLoading;
