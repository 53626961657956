/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { FetchETSTicketNotificationsResponseData } from '../../clients/api/entities/tickets/tickets-api-helpers';
import { newTicketFromMessages } from './ticketsThunks';
import { TICKET_VIEWS } from './ticketsConstants';
import { Parameters, Schema } from '../../../types/typeHelper';

type Prefill = {
  description: string | undefined,
  files?: {
    url: string;
    name: string;
    type: string;
  }[],
} | undefined;

export interface TicketsState {
  filters: {
    pageIndex: Parameters<'/chat/ets/tickets', 'get'>['query']['index'],
    pageSize: Parameters<'/chat/ets/tickets', 'get'>['query']['size'],
    sortedColumnId: Parameters<'/chat/ets/tickets', 'get'>['query']['sorted_column_id'],
    sortOrder: Parameters<'/chat/ets/tickets', 'get'>['query']['sort_order'],
    status: Parameters<'/chat/ets/tickets', 'get'>['query']['status'],
    startDate: Parameters<'/chat/ets/tickets', 'get'>['query']['start_date'],
    endDate: Parameters<'/chat/ets/tickets', 'get'>['query']['end_date'],
    erpCustomerIds: Parameters<'/chat/ets/tickets', 'get'>['query']['erp_customer_ids'],
    search: Parameters<'/chat/ets/tickets', 'get'>['query']['search'],
    channelId: Parameters<'/chat/ets/tickets', 'get'>['query']['channel_id'],
    creatorId: Parameters<'/chat/ets/tickets', 'get'>['query']['creator_id'],
  };
  dashboard: {
    selectedTicketId: string | undefined,
  };
  drafts: {
    [key: string]: {
      description?: string | undefined;
      customerOrderText?: string | undefined,
      attachments?: File[] | undefined,
      erpCustomerId?: string | undefined,
      inputChannel?: Schema<'EtsInputChannelEnum'>,
    }
  },
  ticketView: {
    [key: string]: {
      view: typeof TICKET_VIEWS[keyof typeof TICKET_VIEWS],
      ticketId?: string,
      firstTime?: boolean,
      prefill?: Prefill,
    } | undefined,
  },
  ticketNotifications: FetchETSTicketNotificationsResponseData['notifications'];
}

const initialState: TicketsState = {
  ticketView: {}, // selected view per channel [channelId] => { view: 'form' }
  // TODO remove later when feasible
  // Currently coupled with other redux selectors and cannot use react-query
  ticketNotifications: [], // notifications array [...ticketNotifications]
  drafts: {}, // ticket form drafts by channel id [channelId] => {...formValues}
  dashboard: {
    selectedTicketId: undefined, // ticket id selected I.E: 980d002d-9425-487c-b197-00b263a4c080
  },
  filters: {
    pageIndex: 0,
    pageSize: 10,
    sortedColumnId: undefined,
    sortOrder: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    erpCustomerIds: undefined,
    search: undefined,
    channelId: undefined,
    creatorId: undefined,
  },
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    ticketIdSelected: (state, action: PayloadAction<{
      channelId?: string, ticketId: string, firstTime: boolean }>) => {
      const { channelId, ticketId, firstTime } = action.payload;

      state.ticketView[channelId || ''] = {
        view: TICKET_VIEWS.DETAILS,
        ticketId,
        firstTime,
      };
    },
    createNewTicketSelected: (state, action: PayloadAction<{ channelId?: string | undefined }>) => {
      const { channelId } = action.payload || {};

      state.ticketView[channelId || ''] = {
        view: TICKET_VIEWS.FORM,
      };
    },
    ticketFormChanged: (state, action: PayloadAction<{
      channelId?: string | undefined,
      ticketId?: string | undefined,
      formValues?: Partial<TicketsState['drafts'][string] | undefined>
    }>) => {
      const { channelId, ticketId, formValues } = action.payload || {};

      if (!formValues) {
        delete state.drafts[channelId || ticketId || ''];
      } else {
        state.drafts[channelId || ticketId || ''] = formValues;
      }
    },
    backToTicketsOverview: (state, action: PayloadAction<{ channelId?: string | undefined }>) => {
      const { channelId } = action.payload || {};

      state.ticketView[channelId || ''] = undefined;
    },
    clearFormPrefill: (state, action: PayloadAction<{ channelId?: string | undefined }>) => {
      let { channelId } = action.payload;
      channelId ||= '';

      const ticketView = state.ticketView[channelId];
      if (ticketView) {
        ticketView.prefill = undefined;
      }
    },
    selectDashboardTicket: (state, action:PayloadAction<string>) => {
      const { payload } = action;
      state.dashboard.selectedTicketId = payload;
    },
    ticketsFiltersUpdated: (state, action: PayloadAction<Partial<TicketsState['filters']>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    // TODO remove later when feasible
    // Currently coupled with other redux selectors and cannot use react-query
    setTicketNotifications: (state, action: PayloadAction<TicketsState['ticketNotifications']>) => {
      state.ticketNotifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(newTicketFromMessages.fulfilled, (state, action: PayloadAction<{
      messages?: { text: string }[]
      files: { url: string, name: string, type: string }[]
    }, string, { arg:{ channelId?: string | undefined } }>) => {
      const { payload: { messages, files }, meta: { arg: { channelId } } } = action;
      state.ticketView[channelId || ''] = {
        view: TICKET_VIEWS.FORM,
        prefill: {
          description: messages?.map((message) => message.text).filter(text => !!text).join('\n\n'),
          files,
        },
      };
    });
  },
});

export const {
  ticketIdSelected,
  backToTicketsOverview,
  createNewTicketSelected,
  ticketFormChanged,
  clearFormPrefill,
  selectDashboardTicket,
  ticketsFiltersUpdated,
  setTicketNotifications,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
