/* eslint-disable class-methods-use-this */
import { addBreadcrumb, captureMessage } from '@sentry/react';
import imageCompression from 'browser-image-compression';
import writeBlob from 'capacitor-blob-writer';
import { Directory, Filesystem } from '@capacitor/filesystem';
import BaseClient from 'gcs-common/clients/baseClient';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import batchedPromiseAll from 'gcs-common/helper/batchedPromiseAll';
import { Capacitor } from '@capacitor/core';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';
import BrowserMockBlobStorageClient from './browserMockBlobStorageClient';

/*
 Simple key-value blob storage like an s3 bucket
 */
class BlobStorageClient extends BaseClient {

  init() {
    // noop
  }

  /*
   * Public API
   */
  directory = Directory.Data;

  basePath = '/';

  /**
   * Returns an object as a blob
   * type (e.g. image/png, application/pdf) should always be given
   * because the cordova/capacitor file plugin tends to incorrectly save the type for persisted file
   */
  async getObjectBlob(key, type) {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    debugLogger('[blobStorageClient] getObjectBlob', key);
    const { uri } = await this._getFileUri(key);
    const localFileUrl = Capacitor.convertFileSrc(uri);
    return this._localUrlAsBlob(localFileUrl, type);
  }

  /**
   * Returns an object as a base64 string
   */
  async getObjectBase64(key) {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    debugLogger('[blobStorageClient] getObjectBase64', key);
    const { data: base64Data } = await this._readFile(key);
    return base64Data;
  }

  /**
   * Returns an url which points to the requested object
   * DOMUrl indicated whether this should be a url that can be requested from the DOM
   * i.e. the src attributes of <img /> elements. Other native plugins probably require the
   * "native" path which is returned when DOMUrl is false
   */
  async getObjectUrl(key, { DOMUrl = true } = {}) {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    const { uri } = await this._getFileUri(key);
    if (!DOMUrl) {
      debugLogger('[blobStorageClient] getObjectUrl', key, uri);
      return uri;
    }
    const domUrl = Capacitor.convertFileSrc(uri);
    debugLogger('[blobStorageClient] getObjectUrl DOMUrl', key, domUrl);
    return domUrl;
  }

  /**
   * Saves an object from a remote url and returns the local Url
   * An explanation of the DOMUrl Param can be found at the getObjectUrl function
   * Path indicates whether the returned url should be a file path or a local url
   */
  async saveRemoteObject(key, remoteUrl, path = 'filePath') {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    debugLogger('[blobStorageClient] saveRemoteObject', key, remoteUrl);
    if (!remoteUrl.startsWith('http')) {
      captureMessage(
        'Tried to download non-remote file in blobStorageClient',
        {
          level: 'error',
          tags: { remoteUrl },
        },
      );
    }

    const blob = await this._remoteUrlAsBlob(remoteUrl);
    const writtenFile = await this._writeBlob(key, blob);
    if (path === 'localUrl') {
      return writtenFile;
    }
    return Capacitor.convertFileSrc(writtenFile);
  }

  /**
   * Saves a js File object and returns the local url
   * - compressImg indicated whether the file should be compressed it is an image. This options
   * does nothing if file is not an image
   * - An explanation of the DOMUrl Param can be found at the getObjectUrl function
   */
  async saveLocalObject(key, jsFile, { compressImg = true } = {}) {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    debugLogger('[blobStorageClient] saveLocalObject', key, jsFile);
    const blob = await this._fileAsBlob(jsFile, { compressImg });
    const writtenFile = await this._writeBlob(key, blob);
    return Capacitor.convertFileSrc(writtenFile);
  }

  /**
   * deletes an existing object
   */
  async deleteObject(key) {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    await this._deleteFile(key);
  }

  /**
   * checks if an object exists
   */
  async objectExists(key) {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    debugLogger('[blobStorageClient] objectExists', key);
    return this._fileExists(key);
  }

  /**
   * returns information about an object if it exists
   */
  async objectStat(key) {
    // eslint-disable-next-line no-param-reassign
    key = this._sanitizeKey(key);
    debugLogger('[blobStorageClient] objectInfo', key);
    return this._getFileStat(key);
  }

  async clear() {
    const { files } = await this._getAllFiles();
    await batchedPromiseAll(files.map(({ name }) => async () => {
      const fileStat = await this._getFileStat(name);
      if (fileStat.type !== 'file' && fileStat.type !== 'NSFileTypeRegular') {
        debugLogger(
          'Did not delete File because it is not of type "file" or "NSFileTypeRegular"',
          fileStat,
        );
      } else {
        await this._deleteFile(name);
      }
    }), 15);
  }

  /**
   * Private API
   */

  async _remoteUrlAsBlob(remoteUrl) {
    const remoteImgResponse = await fetch(remoteUrl);
    if (!remoteImgResponse.ok) {
      const message = await remoteImgResponse.text();
      addBreadcrumb({
        level: 'error',
        message,
        timestamp: new Date().toISOString(),
        tags: { remoteUrl },
      });
      throw new Error('Fetching the file-url was unsuccessful');
    }
    return remoteImgResponse.blob();
  }

  async _localUrlAsBlob(localUrl, type) {
    if (!type) {
      debugLogger('_fetchFileAsBlob: Type missing');
      captureMessage(
        '_fetchFileAsBlob: Type missing',
        {
          level: 'error',
          tags: { remoteUrl: localUrl },
        },
      );
    }
    const blob = await this._remoteUrlAsBlob(localUrl);
    return new Blob([blob], { type });
  }

  async _fileAsBlob(file, { compressImg = false, type: typeFromOptions } = {}) {
    /* converts a cordova file object to a blob and compresses it if it is an Image */
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const fileReader = new FileReader();

    const { target: { result: byteArr } } = await new Promise((resolve, reject) => {
      fileReader.onloadend = resolve;
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(file);
    });

    const { type: typeFromFile } = file;

    let blob = new Blob([byteArr], { type: typeFromOptions || typeFromFile });
    if (compressImg && blob.type.match(/^image/)) {
      blob = await imageCompression(blob, options);
    }
    return blob;
  }

  _sanitizeKey(key) {
    return encodeURIComponent(key);
  }

  async _fileExists(key) {
    try {
      const file = await this._getFileStat(key);
      if (!file || file.length < 10) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  async _readFile(key) {
    return Filesystem.readFile({
      path: key,
      directory: this.directory,
    });
  }

  async _getFileUri(key) {
    return Filesystem.getUri({
      path: key,
      directory: this.directory,
    });
  }

  async _getFileStat(key) {
    return Filesystem.stat({
      path: key,
      directory: this.directory,
    });
  }

  async _writeBlob(key, data) {
    return writeBlob({
      path: key,
      directory: this.directory,
      blob: data,
      on_fallback(error) {
        // When writing fails indicates that fallback was used
        // NOTE: This does also not work with live-reload
        captureMessage(error);
        debugLogger('Error writing file_blob:', error);
      },
    });
  }

  async _deleteFile(key) {
    return Filesystem.deleteFile({
      path: key,
      directory: this.directory,
    });
  }

  async _getAllFiles() {
    return Filesystem.readdir({
      path: this.basePath,
      directory: this.directory,
    });
  }
}

// eslint-disable-next-line import/no-mutable-exports
let blobStorageClient;

if (!blobStorageClient) {
  if (!IS_NATIVE) {
    blobStorageClient = new BrowserMockBlobStorageClient();
  } else {
    blobStorageClient = new BlobStorageClient();
  }
}

export default blobStorageClient;
