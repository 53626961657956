import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { FetchProcessesResponseData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import { getUniqueProcessNumber } from 'gcs-common/helper/processTextHelper';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

interface ProcessDetailSmallProps {
  process: FetchProcessesResponseData[number];
}

const ProcessDetailSmall = (props: ProcessDetailSmallProps) => {
  const { process } = props;
  const {
    ProcessSuffix: Suffix,
    ProcessNumber: Number,
    ProcessTypeDescription: TypeText,
    CustomerOrderText,
  } = process;

  return (
    <div className={styles.process}>
      <IconComponent Icon={ICON.CUBE} color={ICON_COLOR.PRIMARY} />
      <div style={{ width: '80%' }}>
        <div className={styles.processHeader}>
          {`${TypeText}: `}
          <span>{getUniqueProcessNumber(Number, Suffix)}</span>
        </div>
        <div className={styles.processDetail}>
          Auftragstext:
          {' '}
          {CustomerOrderText}
        </div>
      </div>
    </div>
  );
};

export default ProcessDetailSmall;
