import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ChannelItem = (
  {
    label, description, iconLeft, iconRight, counter = 0, onClick, selected,
  },
) => {
  return (
    <button
      className={`${styles.sideMenuItem} ${selected ? styles.selected : ''}`}
      type="button"
      onClick={onClick}
    >
      {iconLeft}
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{label}</span>
        {description && <span className={styles.description}>{description}</span>}
      </div>
      <div className={styles.iconsRight}>
        {counter !== 0 && <div className={styles.counter}>{counter}</div>}
        {iconRight}
      </div>
    </button>
  );
};

ChannelItem.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  counter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ChannelItem;
