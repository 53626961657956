import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { getMessageIsFromMe } from '../messageSelector';

const messageUpdateImageDimensions = createAsyncThunk(
  'message/messageUpdateImageDimensions',
  async (
    { messageId, width, height },
    { getState, extra: { gccApiClient } },
  ) => {

    const messageIsFromMe = getMessageIsFromMe(messageId)(getState());
    if (!messageIsFromMe) {
      return;
    }

    await gccApiClient.updateMessage(messageId, {
      imgDimensions: {
        w: width,
        h: height,
      },
    });
  },
);

export default messageUpdateImageDimensions;
