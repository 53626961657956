import styles from './styles.module.scss';
import etsNoTicketsSvg from './ets-no-tickets.svg';

const NoTicketsOverview = () => {
  return (
    <div className={styles.noTicketsContainer}>
      <img className={styles.noTicketsImg} src={etsNoTicketsSvg} alt="Keine Ersatzteil-Anfragen" />

      <div className={styles.noTicketsHeader}>Keine Ersatzteil-Anfragen</div>

      <div className={styles.noTicketsText}>
        Erstelle jetzt deine erste
        Ersatzteilanfrage und wir kümmern
        uns blitzschnell darum.
      </div>
    </div>
  );
};

export default NoTicketsOverview;
