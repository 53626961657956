/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import fetchCurrentUser from './currentUserThunks/fetchCurrentUser';
import fetchCurrentUserErpCustomers from './currentUserThunks/fetchCurrentUserErpCustomers';
import updateProfilePicture from './currentUserThunks/updateProfilePicture';
import ASYNC_STATE from '../../constants/AsyncState';
import deleteCurrentUser from './currentUserThunks/deleteCurrentUser';
import { updateCurrentUser } from './currentUserThunks/updateCurrentUser';
import { loadTwilioCurrentUser } from '../chatConnection/chatConnectionThunks/twilioLoadingThunk';
import mergeObjects from '../../helper/mergeObjects';
import { toggleChannelVisibility } from '../channels/channelsThunks/toggleChannelVisibility';

const asyncState = {
  fetch: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  delete: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  update: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  fetchHouse: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  updateProfilePicture: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  toggleChannelVisibility: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  fetchCurrentUserErpCustomers: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
};


const initialState = {
  ...asyncState,
  currentUser: undefined,
  erpCustomers: {
    data: [],
    pagination: {},
    processingSequence: null,
  },
  isUpdateProfilePicturePending: false,
};

const persistWhitelist = [
  'currentUser',
];

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    resetDeleteCurrentUserError: (state) => {
      state.delete.error = null;
    },
    currentUserUpdated: (state, action) => {
      const { user } = action.payload;
      state.currentUser = mergeObjects(state.currentUser, user);
    },
    setIsUpdateProfilePicturePending: (state, action) => {
      const { isPending } = action.payload;
      state.isUpdateProfilePicturePending = isPending;
    },
  },
  extraReducers: {
    [fetchCurrentUser.pending]: (state) => {
      state.fetch.status = ASYNC_STATE.LOADING;
    },
    [fetchCurrentUser.fulfilled]: (state, action) => {
      const { currentUser } = action.payload;
      state.fetch.status = ASYNC_STATE.SUCCEEDED;
      state.currentUser = currentUser;
    },
    [fetchCurrentUser.rejected]: (state, action) => {
      state.fetch.status = ASYNC_STATE.FAILED;
      state.fetch.error = action.error;
    },
    [deleteCurrentUser.pending]: (state) => {
      state.delete.status = ASYNC_STATE.LOADING;
      state.delete.error = null;
    },
    [deleteCurrentUser.fulfilled]: (state) => {
      state.delete.status = ASYNC_STATE.SUCCEEDED;
      state.currentUser = undefined;
    },
    [deleteCurrentUser.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.delete.status = ASYNC_STATE.FAILED;
      state.delete.error = errorCode;
    },
    [updateCurrentUser.pending]: (state) => {
      state.update.status = ASYNC_STATE.LOADING;
    },
    [updateCurrentUser.fulfilled]: (state, action) => {
      // TODO check if this is needed
      // and needs to be migrated to users slice
      const { currentUser } = action.payload;
      state.update.status = ASYNC_STATE.SUCCEEDED;
      state.currentUser = mergeObjects(state.currentUser, currentUser);
    },
    [updateCurrentUser.rejected]: (state, action) => {
      state.update.status = ASYNC_STATE.FAILED;
      state.update.error = action.error;
    },
    [updateProfilePicture.pending]: (state) => {
      state.updateProfilePicture.status = ASYNC_STATE.LOADING;
    },
    [updateProfilePicture.fulfilled]: (state) => {
      state.updateProfilePicture.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateProfilePicture.rejected]: (state, action) => {
      state.updateProfilePicture.status = ASYNC_STATE.FAILED;
      state.updateProfilePicture.error = action.error;
    },
    [toggleChannelVisibility.pending]: (state) => {
      state.toggleChannelVisibility.status = ASYNC_STATE.LOADING;
    },
    [toggleChannelVisibility.fulfilled]: (state) => {
      state.toggleChannelVisibility.status = ASYNC_STATE.SUCCEEDED;
    },
    [toggleChannelVisibility.rejected]: (state, action) => {
      state.toggleChannelVisibility.status = ASYNC_STATE.FAILED;
      state.toggleChannelVisibility.error = action.error;
    },
    [loadTwilioCurrentUser.fulfilled]: (state, action) => {
      const currentUser = action.payload;
      state.currentUser = mergeObjects(state.currentUser, currentUser);
    },
    [fetchCurrentUserErpCustomers.pending]: (state) => {
      state.fetchCurrentUserErpCustomers = {
        status: ASYNC_STATE.LOADING,
        error: null,
      };
    },
    [fetchCurrentUserErpCustomers.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { processingSequence } } } = action;
      const { data, pagination } = payload;

      state.fetchCurrentUserErpCustomers = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };

      if (processingSequence && state.erpCustomers.processingSequence
        && processingSequence < state.erpCustomers.processingSequence) {
        return;
      }

      state.erpCustomers.data = data;
      state.erpCustomers.pagination = pagination;
      state.erpCustomers.processingSequence = processingSequence;
    },
    [fetchCurrentUserErpCustomers.rejected]: (state, action) => {
      const { errorCode } = action;
      state.fetchCurrentUserErpCustomers = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
      };
    },
  },
});

export { persistWhitelist as currentUserPersistWhitelist };
export const {
  resetDeleteCurrentUserError,
  currentUserUpdated,
  setIsUpdateProfilePicturePending,
} = currentUserSlice.actions;
export default currentUserSlice.reducer;
