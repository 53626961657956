import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';
import CRAFTSMAN_CHANNEL_SECTIONS from 'gcs-common/constants/CraftsmanChannelSections';
import styles from './styles.module.scss';
import ChannelListItemNormalWithId from '../../ChannelListItemNormalWithId/ChannelListItemNormalWithId';

const ChannelGroup = ({ sectionName = '', channelIds, className = '' }) => {

  if (!channelIds || channelIds.length === 0) {
    return null;
  }

  const maxHeightStyle = {
    maxHeight: `${channelIds.length * 93}px`, // Magic Number
  };

  return (
    <>
      {sectionName === CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS && (
      <div className={styles.channelSectionName}>
        EIGENE CHATS (
        { channelIds.length }
        )
      </div>
      )}
      <div className={`${className} ${styles.channelGroup}`} style={maxHeightStyle}>
        <Virtuoso
          data={channelIds}
          itemContent={(_, channelId) => (
            <ChannelListItemNormalWithId
              channelId={channelId}
            />
          )}
        />
      </div>
    </>

  );
};

ChannelGroup.propTypes = {
  channelIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  sectionName: PropTypes.string,
  className: PropTypes.string,
};

export default ChannelGroup;
