import {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useGesture } from '@use-gesture/react';
import AvatarEditor from 'react-avatar-editor';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const ProfilePictureEdit = ({ picture = '', getUpdatedProfilePicture, isRegistration = false }) => {
  const [newPicture, setNewPicture] = useState('');
  const [scaleValue, setScaleValue] = useState(1);
  const [rotation, setRotation] = useState(0);

  const imageRef = useRef();
  const editor = useRef(null);

  useEffect(() => {
    setNewPicture(picture);
  }, [picture]);

  useGesture({
    onPinch: ({ offset: [d] }) => {
      setScaleValue(1 + d / 5);
    },
  }, {
    target: imageRef,
    eventOptions: { passive: false },
  });

  const rotateRight = useCallback(() => {
    setRotation(rotation + 90);
  }, [rotation]);

  const rotateLeft = useCallback(() => {
    setRotation(rotation - 90);
  }, [rotation]);

  const updatePicture = useCallback(() => {
    if (editor.current === null) {
      getUpdatedProfilePicture(picture);
    } else {
      const editedPictureUrl = editor.current.getImageScaledToCanvas().toDataURL();
      getUpdatedProfilePicture(editedPictureUrl);
    }
  }, [editor, getUpdatedProfilePicture, picture]);

  return (
    <>
      <div className={`${styles.editBackground} ${isRegistration && styles.editBackgroundRegistration}`} />
      <div className={`${styles.editContainer} ${isRegistration && styles.editContainerRegistration}`}>
        <div className={styles.profileImage}>
          <div ref={imageRef} style={{ touchAction: 'none' }}>
            <AvatarEditor
              image={newPicture}
              border={0}
              color={[100, 100, 100, 0.5]} // RGBA
              scale={scaleValue}
              ref={editor}
              borderRadius={100}
              rotate={rotation}
              className={styles.avatarEditor}
              onImageChange={updatePicture}
              onImageReady={updatePicture}
            />
          </div>
        </div>
        <div className={styles.rotateButtonContainer}>
          <button type="button" onClick={rotateLeft}>
            <IconComponent Icon={ICON.ROTATE} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} />
          </button>
          <button type="button" onClick={rotateRight}>
            <IconComponent Icon={ICON.ROTATE} />
          </button>
        </div>
      </div>
    </>
  );
};

ProfilePictureEdit.propTypes = {
  picture: PropTypes.string,
  getUpdatedProfilePicture: PropTypes.func.isRequired,
  isRegistration: PropTypes.bool,
};

export default ProfilePictureEdit;
