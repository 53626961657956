import UserCodeForm from 'gcs-common/components/UserCodeForm/UserCodeForm';
import { useSelector } from 'react-redux';
import { Input } from 'gcs-common/design-system/ui/input';
import Anchor from 'gcs-common/design-system/ui/anchor';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'gcs-common/design-system/ui/form';
import { useFormContext } from 'react-hook-form';
import { THEME } from 'gcs-common/constants/themes';
import { getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { useNavigate } from 'react-router';
import { CODE_ROUTES } from 'gcs-common/constants/routes';
import { useQueryParams } from 'gcs-common/design-system/hooks/use-query-params';

const EmailInputWrapper = () => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name="email"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <Input
              {...field}
              type="text"
              placeholder="E-Mail"
              inputSize="l"
              className="pl-2 text-base"
              autoFocus
            />
          </FormControl>
          <FormMessage showWarningIcon={false} />
        </FormItem>
      )}
    />
  );
};

const AnchorWrapper = () => {
  const navigate = useNavigate();
  const { queryParams } = useQueryParams(['registration']);
  const { registration } = queryParams;
  const requestCodeWithPhone = () => {
    navigate(`${CODE_ROUTES.REQUEST_CODE}?method=phone${registration ? '&registration=true' : ''}`);
  };
  return (
    <Anchor onClick={() => requestCodeWithPhone()}>
      {registration ? 'Stattdessen mit Mobilnummer registrieren' : 'Stattdessen mit Mobilnummer anmelden'}
    </Anchor>
  );
};

const UserCodeEmailForm = () => {
  const theme = useSelector(getThemeIdentifier);

  return (
    <UserCodeForm>
      <EmailInputWrapper />
      {theme !== THEME.AGENT && (
        <AnchorWrapper />
      )}
    </UserCodeForm>
  );
};

export default UserCodeEmailForm;
