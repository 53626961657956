import { useGetCurrentUserIsLoggedIn } from 'gcs-common/clients/api/entities/current-user/current-user-api';
import { Outlet } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { useHandleUniversalLoginWebRedirect } from 'gcs-common/hooks/auth/useAuth0WebBrowserLoginFlow';
import styles from './styles.module.scss';


/**
 * Check if user is logged in by performing a request
 */
const AuthProtectedRouteMobile = () => {
  // required to handle browser based login flow for local development
  useHandleUniversalLoginWebRedirect();

  const { isPending } = useGetCurrentUserIsLoggedIn();

  if (isPending) {
    return (
      <div className={styles.root}>
        <div className={styles.loadingRoot}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  return <Outlet />;
};

export default AuthProtectedRouteMobile;
