import { StatusBar, Style } from '@capacitor/status-bar';
import { HAS_STATUS_BAR, IS_IOS } from 'gcs-common/constants/deviceConstants';
import isColorDarkHelper from './isColorDarkHelper';

/**
 * Converts a CSS Color Variable like var(--common-grey) into the hex color.
 */
const getHexFromCssColor = (cssColorVariable) => {
  const div = document.createElement('div');
  div.style.color = cssColorVariable;
  document.body.appendChild(div);

  const computedStyle = window.getComputedStyle(div);
  const rgbValue = computedStyle.color;

  document.body.removeChild(div);

  // Function to convert RGB to Hex
  function rgbToHex(rgb) {
    const rgbArr = rgb.match(/\d+/g).map(Number);
    return `#${rgbArr.map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    }).join('')}`;
  }

  return rgbToHex(rgbValue);
};

const setBgColor = async (top, bottom) => {
  // eslint-disable-next-line no-param-reassign
  top = getHexFromCssColor(top);
  // eslint-disable-next-line no-param-reassign
  bottom = getHexFromCssColor(bottom);
  if (!bottom) {
    // eslint-disable-next-line no-param-reassign
    bottom = top;
  }
  const isDarkTop = isColorDarkHelper(top);
  const isDarkBottom = isColorDarkHelper(bottom);

  // top
  document.getElementById('bg-top').style.backgroundColor = top;
  if (HAS_STATUS_BAR) {
    await StatusBar.setStyle({ style: isDarkTop ? Style.Dark : Style.Light });
    if (!IS_IOS) {
      await StatusBar.setBackgroundColor({ color: top });
    }
  }

  // bottom
  document.getElementById('bg-bottom').style.backgroundColor = 'transparent';

  // configure cordova-plugin-navigationbar-color
  if (window.NavigationBar) {
    window.NavigationBar.backgroundColorByHexString(bottom, !isDarkBottom);
  }
};
export default setBgColor;
