import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ICON, ICON_SIZE, ICON_TRANSFORM, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { createNewTicketSelected, ticketFormChanged } from 'gcs-common/slices/tickets/ticketsSlice';
import { TICKET_INPUT_CHANNELS } from 'gcs-common/slices/tickets/ticketsConstants';
import { getTicketFormDraft, getTicketsFilters } from 'gcs-common/slices/tickets/ticketsSelectors';
import { ETS_ROUTES } from 'gcs-common/constants/routes';
import { getSelectedHouseId } from 'gcs-common/slices/houses/housesSelectors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { getConnectionIdsForHouse, getCraftsmanConnection } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { fetchCraftsmanConnections } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import { useNavigate } from 'react-router';
import { useCreateETSTicketMutation, usePatchETSTicketStatusMutation } from 'gcs-common/clients/api/entities/tickets/tickets-api';
import { useQueryClient } from '@tanstack/react-query';
import { convertETSTicketsFiltersToQuery, getGetETSTicketsQueryKey, useUploadETSAttachments } from 'gcs-common/clients/api/entities/tickets/tickets-api-helpers';
import IconComponent from 'gcs-common/components/Icon/Icon';
import TicketForm from '../TicketForm/TicketForm';
import ChatConnectionBanner from '../../ChatConnectionBanner/ChatConnectionBanner';
import styles from './styles.module.scss';
import etsPipeCenter from './ets-pipe-center.svg';
import etsPipeLeft from './ets-pipe-left.svg';
import etsPipeRight from './ets-pipe-right.svg';
import TicketCreationLoading from './TicketCreationLoading/TicketCreationLoading';


const TicketCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createETSTicket = useCreateETSTicketMutation();
  const uploadETSAttachments = useUploadETSAttachments();
  const patchETSTicketStatus = usePatchETSTicketStatusMutation();
  const draft = useSelector(getTicketFormDraft(undefined));
  const filters = useSelector(getTicketsFilters);
  const [description, setDescription] = useState(draft?.description || '');
  const [isSendingDisabled, setSendingDisabled] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [attachments, setAttachments] = useState<File[]>(draft?.attachments || []);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isOnline: boolean = useSelector(getIsOnline);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getSelectedHouseId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const craftsmanConnectionIds: string[] = useSelector(getConnectionIdsForHouse(houseId));
  const craftsmanConnectionId = craftsmanConnectionIds?.[0];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const craftsmanConnection: { connection: { id: string } } = useSelector(
    getCraftsmanConnection(craftsmanConnectionId),
  );
  const connectionId = craftsmanConnection?.connection?.id;
  const loadingText = 'Ihre Ersatzteilanfrage wird erstellt, es dauert nur wenige Sekunden...';

  useEffect(() => {
    // synchronize router with redux,
    // since components downstream may rely selectors like getSelectedTicket
    dispatch(createNewTicketSelected({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isOnline) return;
    dispatch(fetchCraftsmanConnections());
  }, [dispatch, isOnline]);

  useEffect(() => {
    setSendingDisabled((description || '').trim().length < 1);
  }, [description]);

  useEffect(() => {
    dispatch(ticketFormChanged({ formValues: { description, attachments } }));
  }, [dispatch, description, attachments]);

  const submit = async () => {
    if (!isOnline) return;
    setSendingDisabled(true);
    setSubmitting(true);
    const title: string = description.trim().split('\n')[0]?.slice(0, 256) || '';

    let result: Awaited<ReturnType<typeof createETSTicket.mutateAsync>> | undefined;

    try {
      result = await createETSTicket.mutateAsync({
        body: {
          title,
          description: description.trim(),
          customerOrderText: undefined,
          erpCustomerId: connectionId,
          inputChannel: TICKET_INPUT_CHANNELS.craftsman.key,
        },
      });
    } catch (e) {
      // Error creating ticket
    }

    // Request is successful
    if (result && result.data) {
      if (attachments.length) {
        try {
          await uploadETSAttachments.uploadFiles(result.data.id, attachments);
        } catch (e) {
          // Error uploading one or more attachments
        }
      }
      try {
        await patchETSTicketStatus.mutateAsync({
          path: {
            ticket_id: result.data.id,
          },
        });
      } catch (e) {
        // Do nothing if patch fails. should be snyced from backend.
      }

      dispatch(ticketFormChanged({ formValues: undefined }));

      queryClient.invalidateQueries({
        queryKey: getGetETSTicketsQueryKey(convertETSTicketsFiltersToQuery(filters)),
      }).then(() => {}).catch(() => {});

      navigate(ETS_ROUTES.TICKET_DETAILS(result.data.id, true, description), { replace: true });
    }
    setSendingDisabled(false);
    setSubmitting(false);
  };

  const navigateBack = () => navigate(-1);

  return (
    <div className={styles.ticketsCreationContainer}>
      <div className={styles.ticketDetailsHeader}>
        <button
          type="button"
          onClick={navigateBack}
        >
          { isSubmitting
            ? (
              <IconComponent
                Icon={ICON.ARROW}
                size={ICON_SIZE.LARGE}
                transform={ICON_TRANSFORM.ROTATE_180}
                color={ICON_COLOR.LIGHT_GREY}
              />
            )
            : (
              <IconComponent
                Icon={ICON.CLOSE}
              />
            )}
        </button>
        <div className={styles.container}>
          <span className={styles.ticketTitle}>
            {isSubmitting ? description : 'Neue Anfrage'}
          </span>
        </div>
      </div>

      <ChatConnectionBanner />
      {(!attachments.length && !isSubmitting) && (
        <div className={styles.ticketContainerInfo}>
          <div className={styles.ticketCreationContentIcons}>
            <img
              src={etsPipeLeft}
              alt="ETS Pipe Links"
            />
            <img
              src={etsPipeCenter}
              alt="ETS Pipe Center"
            />
            <img
              src={etsPipeRight}
              alt="ETS Pipe Rechts"
            />
          </div>
          <div className={styles.ticketCreationContentText}>
            <p>Ein oder mehrere Bilder aufnehmen oder aus Mediathek auswählen</p>
            <p>
              Wähle verständliche Fotos und ergänze nützliche
              Details im Chat-Eingabefeld für schnelle Hilfe.
            </p>
          </div>
        </div>
      )}
      {isSubmitting ? (
        <TicketCreationLoading loadingText={loadingText} />
      ) : (
        <TicketForm
          setDescription={setDescription}
          onClick={submit}
          isDisabled={isSendingDisabled}
          attachments={attachments}
          setAttachments={setAttachments}
          description={description}
          isSubmitting={isSubmitting}
        />
      ) }

    </div>
  );
};

export default TicketCreation;
