import ReactGA from 'react-ga4';
import { captureException } from '@sentry/react';
import md5 from 'crypto-js/md5';
import TagManager from '@sooro-io/react-gtm-module';
import { getPlatform } from '../init/initSelectors';
import { getChannelType } from '../channel/channelSelectors';
import { getSelectedChannelId } from '../channels/channelsSelectors';
import {
  getCraftsmanBusinessUnit,
  getCurrentUserId,
} from '../currentUser/currentUserSelectors';
import { debugLogger } from '../../helper/debugLogger';
import PLATFORM from '../../constants/platformTypes';
import { getUserType } from '../user/userSelectors';
import { getGoogleAnalyticsClientId } from './googleAnalyticsSelectors';
import { setGoogleAnalyticsClientId } from './googleAnalyticsSlice';
import { getSelectedHouseId, getHouseName } from '../houses/housesSelectors';
import { getUsercentricsUserGaveConsent, USERCENTRICS_IDS } from '../../hooks/useUsercentrics';
import { getATTPermission } from '../attPermission/attPermissionSelectors';

const GTM_TRACKING_ID = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID;
const GA_TRACKING_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;

export const ANALYTICS_LABEL = {
  WEB: 'WEBAPP_APP',
  MOBILE: 'SMARTPHONE_APP',
};

export const CUSTOM_ANALYTICS_TAGS = {
  BusinessUnit: 'dimension1',
  Channel: 'dimension2',
  Application: 'dimension3',
  HouseName: 'dimension4',
  UserType: 'dimension5',
};

const initGA = () => (dispatch, getState) => {
  if (!GA_TRACKING_ID || GA_TRACKING_ID === '') {
    captureException(new Error('GA_TRACKING_ID empty or undefined'));
    return;
  }

  const googleAnalyticsUserGaveConsent = getUsercentricsUserGaveConsent(
    USERCENTRICS_IDS.GOOGLE_ANALTICS,
  );

  const attPermission = getATTPermission(getState());

  if (!googleAnalyticsUserGaveConsent || !attPermission) {
    debugLogger('Skipping initializing GA because user did not give consent');
    return;
  }

  const businessUnit = getCraftsmanBusinessUnit(getState());
  const currentUserId = getCurrentUserId(getState());
  const currentUserSelectedHouseId = getSelectedHouseId(getState());
  const houseName = getHouseName(currentUserSelectedHouseId)(getState());
  const userType = getUserType(currentUserId)(getState());

  debugLogger('Initializing GA', GA_TRACKING_ID, businessUnit, currentUserId, houseName);

  const platform = getPlatform(getState());


  if (platform === PLATFORM.MOBILE) {
    // NOTE: Safari (iOS) does not allow to set third-party-cookies
    // so we need to save the clientId "manually".
    // https://github.com/react-ga/react-ga/issues/324
    // https://www.simoahava.com/analytics/use-localstorage-client-id-persistence-google-analytics/
    const options = {
      gaOptions: { storage: 'none' },
    };
    const existingClientId = getGoogleAnalyticsClientId(getState());
    if (existingClientId) {
      options.gaOptions = { ...options.gaOptions, clientId: existingClientId };
    }
    ReactGA.initialize(GA_TRACKING_ID, options);
    ReactGA.ga((tracker) => {
      dispatch(setGoogleAnalyticsClientId({ clientId: tracker.get('clientId') }));
    });
    ReactGA.ga('set', 'checkStorageTask', () => { /* nothing */ });
    // GA cannot send from local protocol (file://) on iOS
    ReactGA.ga('set', 'checkProtocolTask', () => { /* nothing */ });
  } else {
    ReactGA.initialize(GA_TRACKING_ID);
  }

  TagManager.initialize({
    gtmId: GTM_TRACKING_ID,
  });

  ReactGA.set({ anonymizeIp: true });
  ReactGA.set({ [CUSTOM_ANALYTICS_TAGS.BusinessUnit]: businessUnit });
  ReactGA.set({ userId: md5(currentUserId) });
  ReactGA.set({ [CUSTOM_ANALYTICS_TAGS.HouseName]: houseName });
  ReactGA.set({ [CUSTOM_ANALYTICS_TAGS.UserType]: userType });
};

const isGAinitialized = () => {
  return ReactGA.isInitialized;
};

export const trackGAEvent = (category, action) => (dispatch, getState) => {
  const googleAnalyticsUserGaveConsent = getUsercentricsUserGaveConsent(
    USERCENTRICS_IDS.GOOGLE_ANALTICS,
  );

  const attPermission = getATTPermission(getState());

  if (!googleAnalyticsUserGaveConsent || !attPermission) {
    debugLogger('Skipping tracking GA Event because user did not give consent');
    return;
  }

  const selectedChannelId = getSelectedChannelId(getState());
  const channelType = getChannelType(selectedChannelId)(getState());

  if (channelType) {
    ReactGA.set({ [CUSTOM_ANALYTICS_TAGS.Channel]: selectedChannelId });
  }

  const platform = getPlatform(getState());
  const label = platform === PLATFORM.MOBILE ? ANALYTICS_LABEL.MOBILE : ANALYTICS_LABEL.WEB;

  if (!isGAinitialized()) {
    dispatch(initGA());
  }

  debugLogger('Tracking GA event', category, action, label);

  ReactGA.event({ category, action, label });
};


export const trackGAPageView = (url) => (dispatch, getState) => {
  const googleAnalyticsUserGaveConsent = getUsercentricsUserGaveConsent(
    USERCENTRICS_IDS.GOOGLE_ANALTICS,
  );

  const attPermission = getATTPermission(getState());

  if (!googleAnalyticsUserGaveConsent || !attPermission) {
    debugLogger('Skipping tracking GA Pageview because user did not give consent');
    return;
  }

  if (!isGAinitialized()) {
    dispatch(initGA());
  }

  debugLogger('Tracking GA Site', url);
  ReactGA.send({ hitType: 'pageview', page: url });
};

export const trackGALegacyEvent = ({ category, action }) => (dispatch) => {
  dispatch(trackGAEvent(category, action));
};
