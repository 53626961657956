import {
  useEffect, useCallback,
} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputForwardRecipientChannelId,
  getChannelInputStagedForwardMessageId,
  getChannelInputStagedForwardMessageIds,
  getChannelInputText,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { channelInputReset } from 'gcs-common/slices/messageInput/messageInputSlice';
import { changeInputText } from 'gcs-common/slices/messageInput/messageInputThunks/changeInputText';
import { sendCurrentMessageState } from 'gcs-common/slices/messageInput/messageInputThunks/sendCurrentMessageState';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import Page from '../Page/Page';
import QuotedMessage from '../QuotedMessage/QuotedMessage';
import SelectedMessageIndicator from '../SelectedMessageIndicator/SelectedMessageIndicator';
import ForwardChannelList from '../ForwardChannelList/ForwardChannelList';

const Forward = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const channelInputForwardRecipientChannelIds = useSelector(
    getChannelInputForwardRecipientChannelId(selectedChannelId),
  );

  const inputText = useSelector(getChannelInputText(selectedChannelId));

  const inputStagedForwardMessageId = useSelector(
    getChannelInputStagedForwardMessageId(selectedChannelId),
  );
  const inputStagedForwardMessageIds = useSelector(
    getChannelInputStagedForwardMessageIds(selectedChannelId),
  );

  useEffect(() => {
    if (!inputStagedForwardMessageIds.length) {
      dispatch(channelInputReset({ channelId: selectedChannelId }));
    }
    dispatch(changeInputText({ channelId: selectedChannelId, inputText }));
  }, [dispatch, navigate, inputStagedForwardMessageIds, selectedChannelId, inputText]);

  const onMessageChanged = useCallback((event) => {
    const input = event.target.value;
    dispatch(changeInputText({ channelId: selectedChannelId, inputText: input }));
  }, [dispatch, selectedChannelId]);

  const sendMessage = async (event) => {
    event.preventDefault();
    await dispatch(sendCurrentMessageState({ channelId: selectedChannelId }));
  };

  return (
    <Page className={styles.forwardMessageWrapper}>
      <ForwardChannelList />
      <form onSubmit={sendMessage} className={`${styles.writeField}`}>
        {inputStagedForwardMessageId ? (
          <div className={styles.replyWrapper}>
            <QuotedMessage
              messageId={inputStagedForwardMessageId}
              disableClickMedia
            />
          </div>
        ) : (<SelectedMessageIndicator selectedMessages={inputStagedForwardMessageIds} />)
        }

        <div className={`${styles.writeForm}`}>

          <div className={styles.inputFieldWrapper}>
            <TextareaAutosize
              id="forwardInput"
              maxRows={6}
              value={inputText}
              onChange={onMessageChanged}
              className={styles.writeInput}
              placeholder="Füge eine Nachricht hinzu (optional)"
            />
          </div>

          <button
            disabled={channelInputForwardRecipientChannelIds.length === 0}
            type="submit"
            className={styles.sendButton}
          >
            <IconComponent
              Icon={ICON.SEND}
              color={ICON_COLOR.WHITE}
              style={{ marginLeft: '2px' }}
              alt="send icon"
            />
          </button>
        </div>
      </form>
    </Page>
  );
};

export default Forward;
