import { createSelector } from '@reduxjs/toolkit';
import { getAppName, getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { IS_IOS } from 'gcs-common/constants/deviceConstants';

export const getIsSideMenuOpen = state => state.uiState.sideMenuOpen;
export const getIsSettingsMenuOpen = state => state.uiState.settingsMenuOpen;
export const getIsEtsTicketsMenuOpen = state => state.uiState.etsTicketsMenuOpen;
export const getIsHouseSwitcherActionSheetOpen = state => (
  state.uiState.houseSwitcherActionSheetOpen
);
export const getIsSalesSpaceChannelActionSheetOpen = state => (
  state.uiState.salesSpaceChannelActionSheetOpen
);
export const getShowNoGCOPAccountModal = state => state.uiState.showNoGCOPAccountModal;
export const getShowRegistrationExitModal = state => state.uiState.showRegistrationExitModal;
export const getshowFetchProcessesErrorModal = state => state.uiState.showFetchProcessesErrorModal;
export const getIsAppInForeground = state => state.uiState.isAppInForeground;
export const getShowAppMustUpgradeDialog = state => state.uiState.showAppMustUpdateDialog;
export const getShowBlockUserModal = state => state.uiState.showBlockUserModal;
export const getShowReportUserModal = state => state.uiState.showReportUserModal;
export const getShowReportMessageModal = state => state.uiState.showReportMessageModal;
export const getShowSnackbar = state => state.uiState.showSnackbar;
export const getShowFilePicker = state => state.uiState.showFilePicker;
export const getOnScreenKeyboardHeight = state => state.uiState?.onScreenKeyboardHeight || 0;
export const getSnackbarMessage = state => state.uiState.snackbarMessage;
export const getSnackbarIcon = state => state.uiState.snackbarIcon;
export const getSafeAreaInsets = state => state.uiState.safeAreaInsets;
const getShowAppMustUpgradeDialogConfig = state => state.uiState.showAppMustUpdateDialogConfig;

export const getAppMustUpgradeText = createSelector(
  getShowAppMustUpgradeDialogConfig, getAppName,
  (config, appName) => {
    const { text: template } = config;
    const formattedAppName = `${appName} ${appName !== 'BEEM' ? ' BEEM' : ''}`;
    let formatted = template?.replace('{{BRAND}}', formattedAppName);
    const store = IS_IOS ? 'Apple AppStore' : 'Google PlayStore';
    formatted = formatted?.replace('{{STORE}}', store);
    return formatted;
  },
);

export const getAppMustUpgradeLink = createSelector(
  getShowAppMustUpgradeDialogConfig, getThemeIdentifier,
  (config, identifier) => {
    const { links } = config;
    const appOS = IS_IOS ? 'ios' : 'android';
    return links && links[appOS][identifier];
  },
);
