import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_DIALOG } from '../../../../constants/errorDialogConstants';
import { openErrorDialog } from '../../../../slices/errorDialogSlice/errorDialogSlice';
import { Parameters } from '../../../../../types/typeHelper';
// eslint-disable-next-line import/no-cycle
import { useApiClient } from '../../client';
// eslint-disable-next-line import/no-cycle
import {
  convertETSTicketsFiltersToQuery,
  getGetETSTicketAttachmentsQueryKey,
  getGetETSTicketCommentsQueryKey,
  getGetETSTicketNotificationsQueryKey,
  getGetETSTicketPartsQueryKey,
  getGetETSTicketQueryKey,
  getGetETSTicketRatingQueryKey,
  getGetETSTicketsQueryKey,
  TicketsClientQueryKeys,
  useETSTicketsApiHelper,
} from './tickets-api-helpers';
import { getTicketsFilters } from '../../../../slices/tickets/ticketsSelectors';
import { setTicketNotifications } from '../../../../slices/tickets/ticketsSlice';
import { debugLogger } from '../../../../helper/debugLogger';
import { useHandleQueryError } from '../../../../helper/errorMessages';


// Queries

interface UseGetETSTicketsQueryProps {
  query: Parameters<'/chat/ets/tickets', 'get'>['query'];
}

export function useGetETSTicketsQuery({ query }: UseGetETSTicketsQueryProps) {
  const client = useApiClient();
  const queryClient = useQueryClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    enabled: (!!query.index || query.index === 0) && !!query.size,
    queryKey: getGetETSTicketsQueryKey(query),
    placeholderData: (prev) => prev,
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/ets/tickets', {
        params: {
          query,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.MODAL,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });

      // this part is from tickets displayed in channel details and mobile. keep?
      // as the size is fixed and it does not support pagination
      if (query.channel_id) {
        if (data) {
          if (data.pagination.total > query.size) {
            // TODO: how to log a warning to sentry in common?
            // eslint-disable-next-line max-len
            debugLogger('ETS: fetchTickets - tickets exceed page size - user will not see all data.', { channelId: query.channel_id, result: data.pagination });
          }
        }
      }

      // keep notifications & tickets in sync
      // (refetching tickets might have generated new notifications due to a server-side update)
      queryClient.invalidateQueries({ queryKey: getGetETSTicketNotificationsQueryKey() });

      return data;
    },
  });
}

interface UseGetTicketRatingQueryProps {
  path: Parameters<'/chat/ets/tickets/{ticket_id}/rating', 'get'>['path'];
}

export function useGetETSTicketRatingQuery({ path }: UseGetTicketRatingQueryProps) {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    enabled: !!path.ticket_id,
    queryKey: getGetETSTicketRatingQueryKey(path),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/ets/tickets/{ticket_id}/rating', {
        params: {
          path,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.MODAL,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
  });
}

interface UseGetETSTicketPartsQueryProps {
  path: Parameters<'/chat/ets/tickets/{ticket_id}/parts', 'get'>['path'];
}

export function useGetETSTicketPartsQuery({ path }: UseGetETSTicketPartsQueryProps) {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    enabled: !!path.ticket_id,
    queryKey: getGetETSTicketPartsQueryKey(path),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/ets/tickets/{ticket_id}/parts', {
        params: {
          path,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.MODAL,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
  });
}

export function useGetETSTicketNotificationsQuery() {
  const client = useApiClient();
  const dispatch = useDispatch();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    queryKey: getGetETSTicketNotificationsQueryKey(),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/current-user/ets-notifications');
      handleQueryError({
        errorDialog: ERROR_DIALOG.NONE, // periodic background task shouldn't trigger error popup
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      // TODO remove later when feasible
      // Currently coupled with other redux selectors and cannot use react-query
      dispatch(setTicketNotifications(data ? data.notifications : []));
      return data;
    },
  });
}

interface UseGetETSTicketQueryProps {
  path: Parameters<'/chat/ets/tickets/{ticket_id}', 'get'>['path'];
}

export function useGetETSTicketQuery({ path }: UseGetETSTicketQueryProps) {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    queryKey: getGetETSTicketQueryKey(path),
    enabled: !!path.ticket_id,
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/ets/tickets/{ticket_id}', {
        params: {
          path,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.MODAL,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
  });
}

interface UseGetETSTicketAttachmentsQueryProps {
  path: Parameters<'/chat/ets/tickets/{ticket_id}/attachments', 'get'>['path'];
}

export function useGetETSTicketAttachmentsQuery({ path }: UseGetETSTicketAttachmentsQueryProps) {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    queryKey: getGetETSTicketAttachmentsQueryKey(path),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/ets/tickets/{ticket_id}/attachments', {
        params: {
          path,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.MODAL,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
  });
}

interface UseGetETSTicketCommentsQueryProps {
  path: Parameters<'/chat/ets/tickets/{ticket_id}/comments', 'get'>['path'];
}

export function useGetETSTicketCommentsQuery({ path }:UseGetETSTicketCommentsQueryProps) {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    queryKey: getGetETSTicketCommentsQueryKey(path),
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/ets/tickets/{ticket_id}/comments', {
        params: {
          path,
        },
      });
      handleQueryError({
        errorDialog: ERROR_DIALOG.MODAL,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
  });
}

// Mutations

export function useCreateETSTicketMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const filters = useSelector(getTicketsFilters);
  const { createETSTicket } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.CREATE_ETS_TICKET],
    mutationFn: createETSTicket,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getGetETSTicketsQueryKey(convertETSTicketsFiltersToQuery(filters)),
      });
    },
  });
}

export function useAddETSTicketRatingMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { addETSTicketRating } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.ADD_ETS_TICKET_RATING],
    mutationFn: addETSTicketRating,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: getGetETSTicketRatingQueryKey(variables.path),
      });
    },
  });
}

export function usePatchETSTicketStatusMutation() {
  const dispatch = useDispatch();
  const { patchETSTicketStatus } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.PATCH_ETS_TICKET_STATUS],
    mutationFn: patchETSTicketStatus,
    retry: 3,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
  });
}

export function useAddETSTicketCommentMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { addETSTicketComment } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.ADD_ETS_TICKET_COMMENT],
    mutationFn: addETSTicketComment,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({ queryKey: getGetETSTicketCommentsQueryKey({
        ticket_id: variables.path.ticket_id,
      }) });
      queryClient.invalidateQueries({ queryKey: getGetETSTicketNotificationsQueryKey() });
    },
  });
}

export function useDeleteETSTicketCommentMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { deleteETSTicketComment } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.DELETE_ETS_TICKET_COMMENT],
    mutationFn: deleteETSTicketComment,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({ queryKey: getGetETSTicketCommentsQueryKey({
        ticket_id: variables.path.ticket_id,
      }) });
    },
  });
}

export function useDeleteETSTicketNotificationMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { deleteETSTicketNotification } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.DELETE_ETS_TICKET_NOTIFICATION],
    mutationFn: deleteETSTicketNotification,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
    onSettled: (_data, _error, _variables) => {
      queryClient.invalidateQueries({ queryKey: getGetETSTicketNotificationsQueryKey() });
    },
  });
}

export function useUploadETSTicketAttachmentsMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { uploadETSTicketAttachments } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.UPLOAD_ETS_TICKET_ATTACHMENTS],
    mutationFn: uploadETSTicketAttachments,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: getGetETSTicketAttachmentsQueryKey(variables.path),
      });
    },
  });
}

export function useDeleteETSTicketRatingMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { deleteETSTicketRating } = useETSTicketsApiHelper();
  return useMutation({
    mutationKey: [TicketsClientQueryKeys.DELETE_ETS_TICKET_RATING],
    mutationFn: deleteETSTicketRating,
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message, errorDialog: ERROR_DIALOG.MODAL }));
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: getGetETSTicketRatingQueryKey(variables.path),
      });
    },
  });
}
