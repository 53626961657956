import { cn } from 'gcs-common/design-system/utils/tailwind-merge';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelImage, getChannelMemberCount, getChannelStatus,
  getChannelSubtitle,
  getChannelTitle, getDirectChannelUserId, getIsHidden,
  getIsSalesSpaceChannelType,
} from 'gcs-common/slices/channel/channelSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { currentUserIdSelected } from 'gcs-common/slices/memberDetails/memberDetailsSlice';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import {
  ChatNavBarLastOnlineStatusBadge,
} from 'gcs-common/components/ChatNavBarLastOnlineStatusBadge/ChatNavBarLastOnlineStatusBadge';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import ImageWithStatus from '../../ImageWithStatus/ImageWithStatus';

export function ChannelDetailsButton({ className }) {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const channelTitle = useSelector(getChannelTitle(selectedChannelId));
  const channelSubtitle = useSelector(getChannelSubtitle(selectedChannelId));
  const isSalesSpaceChannel = useSelector(getIsSalesSpaceChannelType(selectedChannelId));
  const channelImage = useSelector(getChannelImage(selectedChannelId));
  const channelStatus = useSelector(getChannelStatus(selectedChannelId));
  const channelMemberCount = useSelector(getChannelMemberCount(selectedChannelId));
  const otherUserId = useSelector(getDirectChannelUserId(selectedChannelId));
  const isAgent = useSelector(getIsAgent);
  const isHidden = useSelector(getIsHidden(selectedChannelId));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onHeaderClicked = useCallback(() => {
    if (channelMemberCount > 2 || isSalesSpaceChannel) {
      dispatch(currentUserIdSelected({ userId: otherUserId, channelId: selectedChannelId }));
      navigate(CHAT_ROUTES.CHANNEL_PROFILE(selectedChannelId));
    } else {
      dispatch(currentUserIdSelected({ userId: otherUserId, channelId: selectedChannelId }));
      navigate(CHAT_ROUTES.USER_PROFILE(selectedChannelId));
    }
  }, [channelMemberCount, dispatch, otherUserId, selectedChannelId, isSalesSpaceChannel, navigate]);

  return (
    <button
      className={cn('flex flex-row p-4 gap-2 overflow-hidden', className)}
      type="button"
      onClick={onHeaderClicked}
    >
      <ImageWithStatus
        imageUrl={channelImage}
        userStatus={channelStatus}
        isSalesSpace={isSalesSpaceChannel}
        className="m-1"
        channelMemberCount={channelMemberCount}
        isAgent={isAgent}
      />
      <div className="flex flex-col justify-center gap-1 overflow-hidden">
        <div className="truncate text-left text-base font-medium">{channelTitle}</div>
        <div className="flex min-h-6 flex-row items-center gap-1">
          {isHidden ? (
            <div className={styles.hiddenIndicator}>Archiviert</div>
          ) : (
            <ChatNavBarLastOnlineStatusBadge selectedChannelId={selectedChannelId} />
          )}
          <div className="truncate text-left text-sm text-neutral-11">{channelSubtitle}</div>
        </div>
      </div>
    </button>
  );
}

ChannelDetailsButton.propTypes = {
  className: PropTypes.string,
};
