import { Preferences } from '@capacitor/preferences';
import type { PersistedClient, Persister } from '@tanstack/react-query-persist-client';

export function createMobilePersister(key = 'react-query') {
  return {
    persistClient: async (client: PersistedClient) => {
      await Preferences.set({
        key,
        value: JSON.stringify(client),
      });
    },
    restoreClient: async () => {
      const value = await Preferences.get({ key });

      if (!value.value) {
        return null;
      }

      return JSON.parse(value.value) as PersistedClient;
    },
    removeClient: async () => {
      await Preferences.remove({ key });
    },
  } as Persister;
}
