import RequestUserCode from 'gcs-common/components/RequestUserCode/RequestUserCode';
import UserCodeMobileWrapper from '../UserCodeMobileWrapper';

const RequestUserCodeMobile = () => {

  return (
    <UserCodeMobileWrapper>
      <RequestUserCode />
    </UserCodeMobileWrapper>
  );
};

export default RequestUserCodeMobile;
