import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../client';
import { RequestBody } from '../../../../../types/typeHelper';
import fetchCurrentUser from '../../../../slices/currentUser/currentUserThunks/fetchCurrentUser';
import { ERROR_DIALOG } from '../../../../constants/errorDialogConstants';
import { useHandleQueryError } from '../../../../helper/errorMessages';

export enum CurrentUserQueryKeys {
  GET_CURRENT_USER_IS_LOGGED_IN = 'GET_CURRENT_USER_IS_LOGGED_IN',
  GET_CURRENT_USER = 'GET_CURRENT_USER',
}

export function useGetCurrentUserIsLoggedIn() {
  const client = useApiClient();
  const handleQueryError = useHandleQueryError();
  return useQuery({
    queryKey: [
      CurrentUserQueryKeys.GET_CURRENT_USER_IS_LOGGED_IN,
    ],
    queryFn: async () => {
      const { data, error } = await client.GET('/chat/current-user/session', { credentials: 'include' });
      handleQueryError({
        errorDialog: ERROR_DIALOG.NONE,
        // @ts-expect-error mistatch between openapi-fetch error and backend error types
        error,
      });
      return data;
    },
    retry: false,
    retryOnMount: false,
    staleTime: 1000 * 60 * 30,
  });
}

// use this hook to get the current user, requires some more refactoring
// export function useGetCurrentUser() {
//   const client = useApiClient();
//   return useQuery({
//     queryKey: [
//       CurrentUserQueryKeys.GET_CURRENT_USER,
//     ],
//     queryFn: async () => {
//       const { data } = await client.GET('/chat/current-user');
//       return data;
//     },
//   });
// }


export function useUpdateCurrentUserMutation() {
  const dispatch = useDispatch();
  const client = useApiClient();
  return useMutation({
    mutationFn: async ({ body } : { body : RequestBody<'/chat/current-user', 'patch'> }) => {
      await client.PATCH('/chat/current-user', { body });
    },
    onSuccess: async () => {
      await dispatch(fetchCurrentUser());
    },
  });
}
