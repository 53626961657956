import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getUserName,
  getUserImage,
  getUserStatus, getUserIsAgent,
} from 'gcs-common/slices/user/userSelectors';
import { getMainContactId } from 'gcs-common/slices/channel/channelSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { getCurrentUserId, getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import IconComponent from 'gcs-common/components/Icon/Icon';
import FeatureFlagProtectedRoute from 'gcs-common/components/ProtectedRoutes/FeatureFlagProtectedRoute';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { LastOnlineStatusBadge } from 'gcs-common/components/LastOnlineStatusBadge/LastOnlineStatusBadge';
import styles from './styles.module.scss';
import ImageWithStatus from '../../ImageWithStatus/ImageWithStatus';

const MemberListItem = ({ userId, channelId, className = '', onClick }) => {
  const friendlyName = useSelector(getUserName(userId));
  const status = useSelector(getUserStatus(userId));
  const userImage = useSelector(getUserImage(userId));
  const currentUserId = useSelector(getCurrentUserId);
  const mainContactId = useSelector(getMainContactId(channelId));
  const selectedUserIsAgent = useSelector(getUserIsAgent(userId));
  const currentUserIsAgent = useSelector(getIsAgent);
  const userStatus = useSelector(getUserStatus(userId));
  const isMainContact = mainContactId === userId;

  const hideHoverEffect = currentUserId === userId ? styles.hideHoverEffect : '';

  return (
    <>
      <button
        className={`${styles.listItemButton} ${hideHoverEffect}`}
        type="button"
        onClick={onClick}
      >
        <div className={styles.listItemWrapper}>
          <ImageWithStatus
            imageUrl={userImage}
            userStatus={status}
            hasStar={isMainContact}
            onClick={onClick}
          />
          <div className={`${styles.detailName} ${className}`}>
            {friendlyName}
            {currentUserId === userId && ' (Du)'}
            <FeatureFlagProtectedRoute enabledFlag={FEATURE_FLAGS.LAST_ONLINE_BADGE_ENABLED}>
              {
                  !selectedUserIsAgent
                  && currentUserIsAgent
                  && (
                  <LastOnlineStatusBadge
                    userStatus={userStatus}
                  />
                  )}
            </FeatureFlagProtectedRoute>
          </div>
          {currentUserId !== userId && (
            <IconComponent
              Icon={ICON.ARROW}
              color={ICON_COLOR.DARKEST_GREY}
            />
          )}
        </div>
      </button>
    </>
  );
};

MemberListItem.propTypes = {
  userId: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MemberListItem;
