import favicon from './assets/favicon.svg';
import opIcon from './assets/gc-op-icon.svg';
import simpleLogo from './assets/simple_logo.svg';
import logo from './assets/logo.svg';
import chatLogo from './assets/chat_logo.svg';
import privacy from '../commonAssets/beemPrivacyText';
import terms from '../commonAssets/termsText';
import imprint from './assets/imprintText';
import deleteAccountText from '../commonAssets/deleteAccountText';

const config = {
  title: 'GC BEEM',
  description: 'BEEM ermöglicht es Fachhandwerkern, schnell, einfach und jederzeit Fragen an ihren persönlichen GC-Kontakt zu stellen – und das rechtssicher!',
  identifier: 'gc',
  appName: 'GC',
  defaultBrand: 'GC',
  url: 'gcbeem.de',
  apiBaseUrl: 'https://api.gcbeem.de',
  helpUrl: 'https://hilfe.getbeem.de/',
  iosStoreUrl: 'https://apps.apple.com/us/app/gc-beem/id1612670977',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=de.gcbeem',
  favicon,
  logo,
  chatLogo,
  simpleLogo,
  packageId: 'de.gcbeem',
  privacyText: privacy,
  termsText: terms,
  usercentricsId: 'pJ8NEtT2U',
  imprintText: imprint,
  deleteAccountText,
  welcomeText: 'Willkommen bei GC BEEM!',
  identifierPlaceholder: 'Mobilnummer oder E-Mail Adresse',
  mainText: 'Bitte geben Sie Ihre Mobilnummer oder E-Mail Adresse ein, um sich anzumelden.',
  opIcon,
};

export default config;
