import * as React from 'react';
import LoadingSpinner from './loading-spinner';
import { cn } from '../utils/tailwind-merge';

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  isLoading?: boolean;
}

const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ className, children, isLoading, ...props }, ref) => {
    return (
      <>
        {isLoading ? (
          <LoadingSpinner className="m-auto" />
        )
          : (
            <a
              ref={ref}
              className={cn('cursor-pointer text-base text-neutral-11 underline decoration-accent-9', className)}
              {...props}
            >
              {children}
            </a>
          )
        }


      </>

    );
  },
);

Anchor.displayName = 'Anchor';

export default Anchor;
