import { useCallback, useEffect, useRef } from 'react';
import { App } from '@capacitor/app';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { processSelected, productSelected } from 'gcs-common/slices/processes/processesSlice';
import { ROUTES, LOGIN_ROUTES, CHAT_ROUTES, REGISTRATION_ROUTES } from 'gcs-common/constants/routes';
import { useLocation, useNavigate } from 'react-router';
import { getSelectedProcess, getSelectedProduct } from 'gcs-common/clients/api/entities/processes/processes-api-selectors';
import { IS_IOS } from 'gcs-common/constants/deviceConstants';
import { showSimpleNotification } from '../slices/notification/notificationThunks';
import { getSelectedMessageIds } from '../slices/uiState/uiStateSelector';
import { resetSelectedMessageIds, showRegistrationExitModal } from '../slices/uiState/uiStateSlice';
import exitApp from '../slices/app/exitAppThunk';

const TIME_TO_EXIT = 2000;

const useBackButtonHandler = () => {
  const lastTimeBackPress = useRef(0);
  const { pathname } = useLocation();
  const selectedProcess = getSelectedProcess();
  const selectedProduct = getSelectedProduct();
  const selectedMessageIds = useSelector(getSelectedMessageIds);
  const selectedChannelId = useSelector(getSelectedChannelId);

  const navigationStateRef = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Warning: We need to pass the current state as ref,
    // otherwise it will be stale in the callback.
    navigationStateRef.current = {
      ...navigationStateRef.current,
      pathname,
      selectedMessageIds,
      selectedProduct,
      selectedProcess,
      selectedChannelId,
    };
  }, [pathname, selectedMessageIds, selectedProduct, selectedProcess, selectedChannelId]);

  const onBackButton = useCallback(() => {

    const {
      pathname: currentPath,
      selectedMessageIds: currentSelectedMessageIds,
      selectedProduct: currentSelectedProduct,
      selectedProcess: currentSelectedProcess,
      selectedChannelId: currentSelectedChannelId,
    } = navigationStateRef.current;

    switch (currentPath) {
      case ROUTES.ROOT:
      case LOGIN_ROUTES.LOGIN:
      case CHAT_ROUTES.CHANNEL(currentSelectedChannelId):

        if (currentSelectedMessageIds.length > 0) {
          dispatch(resetSelectedMessageIds());
          break;
        }
        if (new Date().getTime() - lastTimeBackPress.current < TIME_TO_EXIT) {
          dispatch(exitApp());
        } else {
          dispatch(showSimpleNotification(
            {
              notification: 'Bitte drücken Sie den Zurück-Button erneut um die App zu schließen',
            },
          ));
          lastTimeBackPress.current = new Date().getTime();
        }
        break;
      case CHAT_ROUTES.PROCESSES(currentSelectedChannelId):
        if (currentSelectedProduct) {
          dispatch(productSelected({ selectedProductNumber: undefined }));
        } else if (currentSelectedProcess) {
          dispatch(processSelected({ selectedProcessNumer: undefined }));
        } else {
          navigate(-1);
        }
        break;
      case REGISTRATION_ROUTES.REGISTRATION:
        dispatch(showRegistrationExitModal());
        break;
      default:
        navigate(-1);
        break;
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    (async () => {
      if (!IS_IOS) {
        const handle = await App.addListener('backButton', onBackButton);
        return () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          handle && handle.remove();
        };
      }
      return () => {
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useBackButtonHandler;
