import { Navigate } from 'react-router';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useQueryParams } from 'gcs-common/design-system/hooks/use-query-params';
import UserCodeEmailForm from 'gcs-common/components/UserCodeForm/UserCodeEmailForm/UserCodeEmailForm';
import UserCodePhoneForm from 'gcs-common/components/UserCodeForm/UserCodePhoneForm/UserCodePhoneForm';
import { useGetCurrentUserIsLoggedIn } from 'gcs-common/clients/api/entities/current-user/current-user-api';

const RequestUserCode = () => {
  const { queryParams } = useQueryParams(['method']);
  const { method } = queryParams;

  const { isSuccess } = useGetCurrentUserIsLoggedIn();

  if (isSuccess) {
    return <Navigate to={CHAT_ROUTES.CHAT} replace />;
  }


  return method === 'phone' ? <UserCodePhoneForm /> : <UserCodeEmailForm />;
};

export default RequestUserCode;
