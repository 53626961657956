// A common way to fetch requests from any place
// this can also easily customized and extended (using Capacitor Http for example)
const httpRequest = async ({ url, method, headers, body }) => {
  return fetch(url, {
    method,
    headers,
    body,
    credentials: 'include', // this makes sure that the cookies are sent
  });
};

export default httpRequest;
