import { render, renderHook, waitFor } from '@testing-library/react';
import { Provider } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MemoryRouter, useLocation } from 'react-router';
import ApiProvider from 'gcs-common/provider/ApiProvider';
import { reactQueryClient } from 'gcs-common/clients/api/client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { createStore } from 'gcs-common/slices/commonReducers';
import { ReactNode } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

const localStorageMock = (function newLocalStorageMock() {
  let store: { [key: string]: string | null } = {};

  return {
    length: 0,
    key(index: number) {
      return `${index} not-used`;
    },

    getItem(key: string) {
      return store[key]!;
    },

    setItem(key: string, value: string) {
      store[key] = value;
    },

    clear() {
      store = {};
    },

    removeItem(key: string) {
      delete store[key];
    },

    getAll() {
      return store;
    },
  };
}());

export const createMockPersister = () => {
  return createSyncStoragePersister({
    storage: localStorageMock,
  });
};

export const LOCATION_DISPLAY_ID = 'location-display';

export const initGccApiTest = createAsyncThunk(
  'initGccApiTest',
  async (
    _,
    { rejectWithValue,
      // @ts-expect-error redux
      extra: { gccApiClient } },
  ) => {
    const onErrorMiddleware = async () => { };
    const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
    gccApiClient.init(onErrorMiddleware, rejectWithValue, apiBaseUrl);
  },
);

// used to display the current location in the test recommended by react-testing documentation
// https://testing-library.com/docs/example-react-router/
export const LocationDisplay = () => {
  const location = useLocation();
  return <div data-testid={LOCATION_DISPLAY_ID}>{location.pathname}</div>;
};

// as described in redux documentation https://redux.js.org/usage/writing-tests
export function renderWithProviders(
  ui: ReactNode,
  {
    preloadedState = {},
    routePath = '/',
    store = createStore({ preloadedState }),
  } = {},
) {

  const { dispatch } = store;

  dispatch(initGccApiTest());
  reactQueryClient.clear();
  // eslint-disable-next-line react/prop-types
  function Wrapper() {
    const persister = createMockPersister();
    return (
      <Provider store={store}>
        <ApiProvider persister={persister}>
          <MemoryRouter initialEntries={[routePath]}>
            {ui}
            <LocationDisplay />
          </MemoryRouter>
        </ApiProvider>
      </Provider>
    );
  }

  return { store, ...render(<Wrapper />) };
}

const createWrapper = () => {
  const persister = createMockPersister();
  return ({ children }: { children: ReactNode }) => (
    <Provider store={createStore()}>
      <ApiProvider persister={persister}>
        {children}
      </ApiProvider>
    </Provider>
  );
};

export async function testReactQueryHook<T extends (...args: any[]) => UseQueryResult>(
  hookFn: T,
  hookProps: Parameters<T>,
  expectedValue: ReturnType<T>['data']) {

  const { result } = renderHook(
    () => hookFn(...hookProps),
    { wrapper: createWrapper() },
  );
  await waitFor(async () => expect(result.current.isSuccess).toBe(true));
  await waitFor(async () => expect(result.current.data).toEqual(expectedValue));

  return result;
}
