import { useSelector } from 'react-redux';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { getChannelsLoading } from 'gcs-common/slices/channels/channelsSelectors';
import { ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import styles from './styles.module.scss';

const ChannelsLoadingBanner = () => {
  const areChannelsLoading = useSelector(getChannelsLoading);
  const isChatConnected = useSelector(getIsChatConnected);
  // avoid showing the banner if the chat is not connected
  // but channels are still loading because channels loading
  // currently does not get aborted when the chat disconnects
  if (isChatConnected && areChannelsLoading) {
    return (
      <div className={styles.reconnectingWrapper}>
        <Spinner size={ICON_SIZE.XX_SMALL} color={ICON_COLOR.WHITE} />
        &nbsp;Synchronisiere Nachrichten...
      </div>
    );
  }
  return null;
};

export default ChannelsLoadingBanner;
