import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageBody,
  getMessageIsDeleted,
  getMessageReplyInfo,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import QuotedMessage from '../QuotedMessage';
import AsyncMessageLoader from '../../AsyncMessageLoader/AsyncMessageLoader';
import LinkifyBlank from '../../LinkifyBlank/LinkifyBlank';
import styles from './styles.module.scss';

const ReplyMessage = ({ messageId }) => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const isDeleted = useSelector(getMessageIsDeleted(messageId));
  const body = useSelector(getMessageBody(messageId));
  const { replyId, replyIndex } = useSelector(getMessageReplyInfo(messageId)) || {};
  const dispatch = useDispatch();
  const onMessageSelected = useCallback(() => {
    if (!isDeleted) {
      dispatch(scrollToMessageId({
        messageId: replyId,
        channelId: selectedChannelId,
      }));
    }
  }, [dispatch, isDeleted, replyId, selectedChannelId]);

  return (
    <AsyncMessageLoader
      channelId={selectedChannelId}
      messageIndex={replyIndex}
      messageId={replyId}
    >
      <div
        tabIndex={0}
        role="button"
        onClick={onMessageSelected}
      >
        <QuotedMessage messageId={replyId} disableClickMedia />
        <div className={styles.txtMsgBody}>
          <LinkifyBlank>{body}</LinkifyBlank>
        </div>
      </div>
    </AsyncMessageLoader>
  );
};

ReplyMessage.propTypes = { messageId: PropTypes.string.isRequired };

export default ReplyMessage;
