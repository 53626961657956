import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { useDispatch, useSelector } from 'react-redux';
import loadPreviousMessages from 'gcs-common/slices/messages/messagesThunks/loadPreviousMessages';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getChannelHasMoreMessages } from 'gcs-common/slices/channel/channelSelectors';
import { getImageMessageIds } from 'gcs-common/slices/messages/messagesSelectors';
import { ICON, ICON_COLOR, ICON_TRANSFORM, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import Page from '../Page/Page';
import GalleryImage from './GalleryImage/GalleryImage';
import GalleryShareButton from './GalleryShareButton/GalleryShareButton';

const RELOAD_INDEX = 3;

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

function Gallery() {

  const params = useParams();
  const { messageId } = params;
  const dispatch = useDispatch();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const channelHasMoreMessages = useSelector(getChannelHasMoreMessages(selectedChannelId));
  const proxyMessageIds = useSelector(getImageMessageIds);

  const [messageIds, setMessageIds] = useState(proxyMessageIds);

  const [currentIndex, setCurrentIndex] = useState(() => {
    const initIndex = messageIds?.findIndex(msgId => msgId === messageId);
    return initIndex > 0 ? initIndex : 0;
  });

  const currentMessageId = useMemo(() => {
    return messageIds && messageIds[currentIndex];
  }, [currentIndex, messageIds]);

  const changeIndex = useCallback((index) => {
    setCurrentIndex(index);
  }, []);

  const onLoadPreviousMessages = useCallback(() => {
    dispatch(loadPreviousMessages());
  }, [dispatch]);

  useEffect(() => {
    // recalculate the index and set index & messageIds at the same time
    // to avoid that the wrong image is rendered
    const index = proxyMessageIds?.findIndex(msgId => msgId === currentMessageId);
    setCurrentIndex(index);
    setMessageIds(proxyMessageIds);
  }, [currentMessageId, messageIds, proxyMessageIds]);

  useEffect(() => {
    if (channelHasMoreMessages && (RELOAD_INDEX >= currentIndex)) {
      onLoadPreviousMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const slideRenderer = useCallback(({ index }) => {
    const mediaMessageId = messageIds[index];
    return (
      <div className={styles.contentClass} key={mediaMessageId}>
        <GalleryImage
          messageId={mediaMessageId}
        />
      </div>
    );
  }, [messageIds]);

  if (!messageIds || messageIds.length === 0) {
    return <></>;
  }

  return (
    <Page className={styles.imageSwiperWrapper}>
      <div className={styles.imageSwiperHeader}>
        <Link to="..">
          <IconComponent
            Icon={ICON.ARROW}
            color={ICON_COLOR.WHITE}
            transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
            size={ICON_SIZE.LARGE}
          />
        </Link>
        <GalleryShareButton messageId={currentMessageId} />
      </div>
      <VirtualizeSwipeableViews
        className={styles.imageSwiperContent}
        containerStyle={{ height: '100%' }}
        onChangeIndex={changeIndex}
        index={currentIndex}
        slideCount={messageIds.length}
        slideRenderer={slideRenderer}
      />
    </Page>
  );
}

export default Gallery;
