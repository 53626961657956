import { useCallback, useEffect } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { useDispatch, useSelector } from 'react-redux';
import { HAS_KEYBOARD, IS_IOS } from 'gcs-common/constants/deviceConstants';
import { setKeyboardNotVisible, setKeyboardVisible } from '../slices/device/deviceSlice';
import { getOnScreenKeyboardHeight, getSafeAreaInsets } from '../slices/uiState/uiStateSelectors';
import { setOnScreenKeyboardHeight } from '../slices/uiState/uiStateSlice';


const useKeyboardListener = () => {
  const dispatch = useDispatch();
  const onScreenKeyboardHeight = useSelector(getOnScreenKeyboardHeight);
  const safeAreaInsets = useSelector(getSafeAreaInsets);

  const onKeyboardWillShow = useCallback(async (info) => {
    if (!info) return;

    dispatch(setKeyboardVisible());
    const { keyboardHeight } = info;
    if (!onScreenKeyboardHeight
      && keyboardHeight > 0
      && keyboardHeight !== onScreenKeyboardHeight) {
      const bottomInset = IS_IOS ? safeAreaInsets?.bottom || 0 : 0;
      dispatch(setOnScreenKeyboardHeight(keyboardHeight - bottomInset));
    }
  }, [dispatch, onScreenKeyboardHeight, safeAreaInsets]);

  const onKeyboardDidHide = useCallback(() => {
    dispatch(setKeyboardNotVisible());
  }, [dispatch]);


  useEffect(() => {
    if (HAS_KEYBOARD) {
      Keyboard.addListener('keyboardWillShow', onKeyboardWillShow);
      Keyboard.addListener('keyboardDidHide', onKeyboardDidHide);

      return () => {
        Keyboard.addListener('keyboardWillShow', onKeyboardDidHide);
        Keyboard.addListener('keyboardDidHide', onKeyboardWillShow);
      };
    }
    return () => { };
  }, [onKeyboardWillShow, onKeyboardDidHide]);

};

export default useKeyboardListener;
