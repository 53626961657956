import Modal from 'react-modal';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getFavicon } from 'gcs-common/slices/theme/themeSelectors';
import {
  getAppMustUpgradeLink,
  getAppMustUpgradeText,
  getShowAppMustUpgradeDialog,
} from '../../slices/uiState/uiStateSelectors';
import styles from './styles.module.scss';
import Button from '../Button/Button';

const AppMustUpdateDialog = () => {
  const showDialog = useSelector(getShowAppMustUpgradeDialog);
  const text = useSelector(getAppMustUpgradeText);
  const link = useSelector(getAppMustUpgradeLink);
  const logo = useSelector(getFavicon);

  const open = useCallback(() => {
    window.open(link, '_self');
  }, [link]);

  return (
    <Modal
      isOpen={showDialog}
    >
      <img alt="logo" className={styles.logoImg} src={logo} />
      <div className={styles.headline}>Neue Version verfügbar!</div>
      <p className={styles.text}>
        {text}
      </p>
      <Button
        className={styles.primaryButton}
        onClick={open}
      >
        Jetzt aktualisieren
      </Button>

    </Modal>
  );
};

export default AppMustUpdateDialog;
