import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsKeyboardVisible } from '../../slices/device/deviceSelectors';

const Page = ({ children, className = '', backgroundColor, rootMargins }) => {

  const isKeyboardVisible = useSelector(getIsKeyboardVisible);

  const setBodyBg = useCallback(() => {
    document.body.style.backgroundColor = backgroundColor || 'white';
  }, [backgroundColor]);

  const setRootMargins = useCallback(() => {
    const root = document.getElementById('root');
    if (rootMargins === 'none' || rootMargins === 'topOnly') {
      root.style.marginBottom = '0';
    } else {
      root.style.marginBottom = '';
    }

    if (rootMargins === 'none' || rootMargins === 'bottomOnly') {
      root.style.marginTop = '0';
    } else {
      root.style.marginTop = '';
    }
  }, [rootMargins]);

  useEffect(() => {
    setBodyBg();
  }, [setBodyBg]);

  useEffect(() => {
    setRootMargins();
  }, [setRootMargins]);

  useEffect(() => {
    if (isKeyboardVisible) {
      const root = document.getElementById('root');
      root.style.marginBottom = '0';
    } else {
      setRootMargins();
    }
  }, [isKeyboardVisible, setRootMargins]);

  return (
    <div className={className}>
      {children}
    </div>
  );
};

Page.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  rootMargins: PropTypes.string,
};

export default Page;
