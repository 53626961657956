import PropTypes from 'prop-types';
import { FILE_TYPE } from 'gcs-common/constants/fileTypes';
import { getFileType } from 'gcs-common/helper/fileHelper';
import LocalFilePreview from '../../LocalFilePreview/LocalFilePreview';
import PdfThumbnail from '../../PdfThumbnail/PdfThumbnail';
import styles from './styles.module.scss';
import VideoLocalThumbnail from '../../VideoLocalThumbnail/VideoLocalThumbnail';

const ThumbnailByFileType = ({ file, onClick }) => {
  const fileType = getFileType(file);

  switch (fileType) {
    case FILE_TYPE.IMAGE:
      return (
        <div className={styles.previewImg} role="button" tabIndex={0} onClick={onClick}>
          <LocalFilePreview alt="thumb-img-preview" file={file} />
        </div>
      );
    case FILE_TYPE.PDF:
      return (
        <div className={styles.previewFile} role="button" tabIndex={0} onClick={onClick}>
          <PdfThumbnail pdfFile={file.webPath} />
        </div>
      );
    case FILE_TYPE.VIDEO:
      return (
        <div className={styles.previewFile} role="button" tabIndex={0} onClick={onClick}>
          <VideoLocalThumbnail />
        </div>
      );
    default:
      return (
        <div className={styles.previewFile} role="button" tabIndex={0} onClick={onClick}>
          <LocalFilePreview alt="thumb-img-preview" file={file} />
        </div>
      );
  }

};

ThumbnailByFileType.propTypes = {
  file: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ThumbnailByFileType;
