import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import ReactSwipe from 'react-swipe';
import { useDispatch, useSelector } from 'react-redux';
import { getLogo } from 'gcs-common/slices/theme/themeSelectors';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import dotVisitedIcon from '../../img/icons/dot_visited.svg';
import dotActiveIcon from '../../img/icons/dot_active.svg';
import dotUnvisitedIcon from '../../img/icons/dot_unvisited.svg';
import Page from '../Page/Page';
import {
  FavoriteInfo,
  GCOPInfo,
  MediaInfo,
  SearchFeatureInfo,
  WebInfo,
  WelcomeInfo,
} from '../ChangelogInfoContent/ChangelogInfoContent';
import { addToSeenTutorialsPage } from '../../slices/tutorial/tutorialSlice';

const PAGES = {
  welcome: <WelcomeInfo />,
  media: <MediaInfo />,
  gcop: <GCOPInfo />,
  // This is temporary removed
  // status: <StatusInfo />,
  web: <WebInfo />,
  favorite: <FavoriteInfo />,
  searchfeature: <SearchFeatureInfo />,
};

const NUMBER_OF_PAGES = Object.keys(PAGES).length;

const TutorialPage = () => {

  const reactSwipeEl = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPos, setCurrentPos] = useState(0);

  const stepForward = () => {
    if (currentPos + 1 === NUMBER_OF_PAGES) {
      dispatch(addToSeenTutorialsPage({ page: Object.keys(PAGES)[currentPos] }));
      navigate('/');
    }
    reactSwipeEl.current.next();
  };

  const renderDots = useMemo(() => {
    const dots = [];
    for (let i = 0; i < NUMBER_OF_PAGES; i++) {
      let dot = dotUnvisitedIcon;
      if (i === currentPos) {
        dot = dotActiveIcon;
      }
      if (i < currentPos) {
        dot = dotVisitedIcon;
      }
      dots.push(<img alt="dot" key={i} src={dot} className={styles.dot} />);
    }
    return dots;
  }, [currentPos]);

  const onNextSlide = useCallback((index) => {
    setCurrentPos(index);
    dispatch(addToSeenTutorialsPage({ page: Object.keys(PAGES)[currentPos] }));
  }, [dispatch, currentPos]);

  const buttonText = currentPos + 1 !== NUMBER_OF_PAGES ? 'Weiter' : 'Beenden';
  const beemLogo = useSelector(getLogo);

  return (
    <Page className={styles.wrapper}>
      <div className={styles.header}>
        <img alt="logo" className={styles.logoImg} src={beemLogo} />
      </div>
      <ReactSwipe
        className={styles.content}
        childCount={NUMBER_OF_PAGES}
        swipeOptions={{
          continuous: false,
          startSlide: currentPos,
          transitionEnd: onNextSlide,
        }}
        ref={reactSwipeEl}
      >
        {Object.keys(PAGES).map(key => (
          <div key={key}>
            <div className={styles.swipeItemWrapper}>
              {PAGES[key]}
            </div>
          </div>
        ))}
      </ReactSwipe>
      <div className={styles.footer}>
        <div className={styles.dotWrapper}>
          {renderDots}
        </div>
        <button
          type="button"
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={`gc-btn-1 ${styles.btn}`}
          onClick={stepForward}
        >
          {buttonText}
        </button>
      </div>
    </Page>
  );
};

export default TutorialPage;
