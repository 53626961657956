import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedSalesSpaceChannelFilter,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors/getSelectedSalesSpaceChannelFilter';
import {
  SalesSpaceChannelFilter,
  SalesSpaceChannelOrder,
  UnreadMessagesFilter,
} from 'gcs-common/helper/channelOrderingHelper';
import updateSelectedSalesSpaceChannelOrder
  from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsThunks/sortChannels';
import {
  setSelectedSalesSpaceChannelFilter,
  setSalesSpaceUnreadMessagesFilterSelected,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSlice';
import {
  getSalesSpaceUnreadMessagesFilterSelected,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors/getSalesSpaceUnreadMessagesFilterSelected';
import unreadIndicatorIcon from 'gcs-common/img/unread_filter_indicator.svg';
import closeIcon from 'gcs-common/img/close_filter.svg';
import {
  getSelectedSalesSpaceChannelOrder,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors/getSelectedSalesSpaceChannelOrder';
import PopupMenuWithSections from '../../../PopupMenuWithSections/PopupMenuWithSections';
import channelSortIcon from '../../../../img/icons/sort.svg';
import styles from './styles.module.scss';

function SalesSpaceChannelSortAndFilter() {
  const [showMenu, setShowMenu] = useState(false);

  const selectedSalesSpaceChannelOrder = useSelector(getSelectedSalesSpaceChannelOrder);
  const selectedSalesSpaceChannelFilter = useSelector(getSelectedSalesSpaceChannelFilter);
  const unreadMessagesFilterSelected = useSelector(getSalesSpaceUnreadMessagesFilterSelected);

  const dispatch = useDispatch();

  const onChangeSalesSpaceChannelOrder = useCallback((option) => {
    dispatch(updateSelectedSalesSpaceChannelOrder({ newChannelOrder: option }));
  }, [dispatch]);

  const onToggleUnreadMessagesFilter = useCallback(() => {
    if (unreadMessagesFilterSelected === UnreadMessagesFilter.UNREAD) {
      dispatch(setSalesSpaceUnreadMessagesFilterSelected(
        { unreadMessagesFilterSelected: UnreadMessagesFilter.ALL },
      ));
    } else {
      dispatch(setSalesSpaceUnreadMessagesFilterSelected(
        { unreadMessagesFilterSelected: UnreadMessagesFilter.UNREAD },
      ));
    }

  }, [dispatch, unreadMessagesFilterSelected]);

  const onChangeSalesSpaceChannelFilter = useCallback((option) => {
    dispatch(setSelectedSalesSpaceChannelFilter({ selectedSalesSpaceChannelFilter: option }));
  }, [dispatch]);

  const onIndicatorClick = () => {
    if (showMenu) {
      setShowMenu(false);
    }
    onToggleUnreadMessagesFilter();
  };

  const menuSections = [{
    header: 'Ansichten',
    options: Object.values(SalesSpaceChannelFilter),
    selectionCallback: onChangeSalesSpaceChannelFilter,
    selectedOption: selectedSalesSpaceChannelFilter,
  }, {
    header: 'Filtern nach',
    // only show one option because it's a toggle
    options: [UnreadMessagesFilter.UNREAD],
    selectionCallback: onToggleUnreadMessagesFilter,
    selectedOption: unreadMessagesFilterSelected,
  }, {
    header: 'Sortieren',
    options: Object.values(SalesSpaceChannelOrder),
    selectionCallback: onChangeSalesSpaceChannelOrder,
    selectedOption: selectedSalesSpaceChannelOrder,
  }];

  return (
    <div
      className={styles.channelSortWrapper}
    >
      {unreadMessagesFilterSelected === UnreadMessagesFilter.UNREAD
            && (
            <div className={styles.indicatorWrapper} tabIndex={0} role="button" onClick={onIndicatorClick}>
              <div className={styles.filterActiveIndicator}>
                <img alt="Ungelesen-Filter aktiv" className={styles.channelSortIcon} src={unreadIndicatorIcon} />
                <img alt="Schließen" src={closeIcon} />
              </div>
            </div>
            )
        }
      <div
        className={styles.channelSort}
      >
        <div
          tabIndex={0}
          role="button"
          onKeyUp={() => setShowMenu(!showMenu)}
          onClick={() => setShowMenu(!showMenu)}
          className={styles.channelTextWrapper}
        >
          <span className={styles.channelSortText}>
            Filter
          </span>
          <img alt="Kontakte Sortieren" className={styles.channelSortIcon} src={channelSortIcon} />
        </div>
        <PopupMenuWithSections sections={menuSections} onHide={setShowMenu} isVisible={showMenu} />
      </div>
    </div>
  );
}

export default SalesSpaceChannelSortAndFilter;
