import { calculateMyMemberIdFromChannelAndUserId } from '../../helper/messageHelpers';
import { calculateChannelTypeForChannelFilter, CHANNEL_TYPE } from '../../helper/channelTypeHelper';
import {
  calculateChannelCorrespondent,
  calculateChannelIsFreeSalesSpaceChannel,
  calculateChannelMemberUserIds,
  calculateChannelPrimaryHouseId,
  calculateChannelSubtitle,
  calculateChannelTitle,
  calculateChannelType,
  calculateUnreadCount,
} from '../../helper/channelHelpers';
import CRAFTSMAN_CHANNEL_SECTIONS from '../../constants/CraftsmanChannelSections';
import { SalesSpaceChannelFilter } from '../../helper/channelOrderingHelper';


export const ChannelBrand = (brand = 'GC') => {
  return {
    ALLE: 'Alle',
    BRAND: brand,
    CRAFTNOTE: 'Craftnote',
  };
};


const matchesSearch = (search = '', haystackKeywords = []) => {
  if (!search) {
    return true;
  }
  const onlyValidKeywords = haystackKeywords.filter(e => !!e);
  return onlyValidKeywords.join('').toLowerCase().indexOf(search.toLowerCase()) > -1;
};


export const filterBySearch = ({
  searchInput,
  channels,
  currentUserId,
  channelMembers,
  members,
  users,
  isAgent,
}) => channelId => {
  if (!searchInput || searchInput.length === 0) return true;
  const channel = channels[channelId];
  const channelMemberIds = channelMembers[channelId];
  const channelMemberUserIds = calculateChannelMemberUserIds(channelMemberIds, members);
  const channelCorrespondent = calculateChannelCorrespondent({
    channelMemberUserIds,
    users,
    currentUserId,
  });
  const channelTitle = calculateChannelTitle({
    channelId,
    channel,
    users,
    channelMembers,
    members,
    currentUserId,
    isAgent,
  });

  const channelSubtitle = calculateChannelSubtitle({
    channelId,
    channel,
    channelCorrespondent,
    channelMembers,
    members,
    users,
    isAgent,
  });

  return matchesSearch(searchInput, [channelTitle, channelSubtitle]);

};


export const filterByBrand = ({ channelBrand, channels }) => (channelId) => {
  const channel = channels[channelId] || {};
  const { connectedToCraftnote } = channel;

  switch (channelBrand) {
    case ChannelBrand().ALLE:
      return true;
    case ChannelBrand().CRAFTNOTE:
      return connectedToCraftnote;
    default:
      return !connectedToCraftnote;
  }
};

export const filterBySelectedChannelTypeFilter = ({
  channels,
  selectedChannelTypeFilter,
}) => (channelId) => {
  const channelTypes = calculateChannelTypeForChannelFilter(selectedChannelTypeFilter);

  return channelTypes.includes(calculateChannelType(channels[channelId]));
};

export const filterByUnreadMessages = ({
  channels,
  currentUserId,
  members,
  channelMembers,
}) => (channelId) => {

  const channel = channels[channelId];
  const myMemberId = calculateMyMemberIdFromChannelAndUserId({
    channelMembers, members, channelId, currentUserId,
  });
  const myMember = members[myMemberId];

  const unreadMessagesCount = calculateUnreadCount({
    channel,
    member: myMember,
  });

  return unreadMessagesCount > 0;
};

export const filterBySelectedSalesSpaceChannelFilter = ({
  isAgent,
  selectedSalesSpaceChannelFilter,
  channelMembers,
  members,
  currentUserId,
  users,
  isConnected,
}) => (channelId) => {
  if (!isAgent) {
    return true;
  }

  if (selectedSalesSpaceChannelFilter === SalesSpaceChannelFilter.FREE_AND_OWN) {
    const memberIds = channelMembers[channelId];

    if (!memberIds) {
      return true;
    }

    const channelMemberUserIds = calculateChannelMemberUserIds(memberIds, members);

    const isOwn = channelMemberUserIds?.includes(currentUserId);

    if (isOwn) {
      return true;
    }

    const isFree = calculateChannelIsFreeSalesSpaceChannel({
      channelMemberUserIds,
      users,
      isConnected,
    });

    return isFree;
  }
  return true;
};


export const filterOwnChannels = ({
  channelMembers,
  members,
  currentUserId,
}) => (channelId) => {
  const memberIds = channelMembers[channelId];
  const channelMemberUserIds = calculateChannelMemberUserIds(memberIds, members);

  const isOwn = channelMemberUserIds?.includes(currentUserId);

  if (isOwn) {
    return true;
  }
  return false;
};

export const filterByPrimaryHouse = ({ channels, houseId, exclude = false }) => (channelId) => {
  const channel = channels[channelId];
  const channelPrimaryHouseId = calculateChannelPrimaryHouseId(channel);

  if (exclude) {
    return channelPrimaryHouseId && channelPrimaryHouseId !== houseId;
  }

  return channelPrimaryHouseId === houseId;
};

export const filterByChannelTypes = ({ channels, channelTypes = [] }) => (channelId) => {
  const channel = channels[channelId];

  return channelTypes.includes(channel?.channelType);
};


export const filterBySelectedCraftsmanSection = ({ section, channels }) => (channelId) => {
  const channel = channels[channelId];
  const channelType = calculateChannelType(channel);
  switch (section) {
    case CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS:
      return [CHANNEL_TYPE.DIRECT_CHAT, CHANNEL_TYPE.SALES_SPACE].includes(channelType);
    case CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS:
    default:
      return [CHANNEL_TYPE.INTERNAL_CRAFTSMAN].includes(channelType);
  }
};

export const filterByHiddenStatus = ({ currentUserId, users }) => (channelId) => {
  const [user] = Object.values(users).filter(u => u.id === currentUserId);
  return !user?.hiddenChannels?.includes(channelId);
};
