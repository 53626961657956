/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import PLATFORM from 'gcs-common/constants/platformTypes';
import { Capacitor } from '@capacitor/core';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';

const initialState = {
  platform: PLATFORM.MOBILE,
  isNative: IS_NATIVE,
  isIos: Capacitor.getPlatform() === 'ios',
};

const initializationSlice = createSlice({
  name: 'initialization',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default initializationSlice.reducer;
