import { useCallback } from 'react';
import { useField } from 'formik';
import { ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Image, { IMAGE_TYPE } from 'gcs-agent-web/src/components/Image/Image';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ORDER_STATUS } from '../../../slices/quickOrdering/quickOrderingConstants';
import InfoTooltip from '../../InfoTooltip/InfoTooltip';
import type { QuickOrderFormValues } from '../../QuickOrder/QuickOrder';
import { getIsAgent } from '../../../slices/currentUser/currentUserSelectors';

type OrderPositionProps = {
  index: number;
  articleQuantityEditable: boolean;
  priceEditable: boolean;
  orderPositionsRemovable: boolean;
};

const OrderPosition = ({
  index,
  articleQuantityEditable,
  priceEditable,
  orderPositionsRemovable,
} : OrderPositionProps) => {

  type OrderPositions = QuickOrderFormValues['orderPositions'];
  const [, { value: orderPosition, error }, { setValue: setOrderPosition }] = useField<OrderPositions[number]>(`orderPositions[${index}]`);
  const [, { value: orderPositions }, { setValue: setOrderPositions }] = useField<OrderPositions>('orderPositions');
  const [, { value: orderStatus }] = useField<QuickOrderFormValues['status']>('status');
  const {
    externalArticleId,
    kbn,
    quantityUnit,
    picturePath,
    description,
    quantity,
    netPrice,
    opNetPrice,
  } = orderPosition;

  const isAgent = useSelector(getIsAgent);

  // not good! find a proper way to handle error types in Formik / maybe switch to react hook form?
  const positionError = error as unknown as Record<string, string>;

  const removeArticle = useCallback(() => {
    setOrderPositions(orderPositions.filter((p) => {
      return p.externalArticleId !== externalArticleId;
    }));
  }, [externalArticleId, orderPositions, setOrderPositions]);

  const changeQuantity = useCallback((newQuantity: string) => {

    setOrderPosition({
      ...orderPosition,
      quantity: newQuantity,
    });
  }, [orderPosition, setOrderPosition]);

  const changePrice = (newPrice: string) => {
    setOrderPosition({
      ...orderPosition,
      netPrice: newPrice,
    });
  };

  const PriceTooltip = () => {
    if (!isAgent) {
      return null;
    }

    if (!opNetPrice) {
      return (
        <InfoTooltip
          text="Preis aus der WW90 konnte nicht abgerufen werden. Hat der Handwerker einen gültigen Online Plus Account verknüpft?"
          iconColor={ICON_COLOR.ERROR}
        />
      );
    }

    const showIsCustomPrice = orderStatus === ORDER_STATUS.LOCAL_DRAFT
    && netPrice && netPrice?.toString() !== opNetPrice?.toString();

    if (showIsCustomPrice) {
      return (
        <InfoTooltip
          text="Vorsicht! Die geänderten Preise gelten nur für die Anzeige in BEEM.
        Bei einer Bestellung werden immer die aktuellen Preise für den Kunden aus der WW90 gezogen.
        Deshalb müssen Preise zusätzlich in der WW90 angepasst werden (z.B. Tagespreis)"
          iconColor={ICON_COLOR.ERROR}
        />
      );
    }

    return null;
  };

  return (
    <div className={styles.articleWrapper}>
      <div key={externalArticleId} className={styles.article}>
        <div className={styles.articleImage}>
          <Image type={IMAGE_TYPE.ARTICLE} src={picturePath} alt="Artikelbild" />
        </div>
        <div className={styles.articleContent}>
          <div className={styles.articleDescription}>
            {description}
          </div>
          <div className={styles.articleNumberWrapper}>
            <div className={styles.articleNumber}>{kbn}</div>
            {orderPositionsRemovable && (
            <button type="button" className={styles.articleRemove} onClick={removeArticle}>Entfernen</button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.articleFooter}>
        <div className={styles.articleFooterItem}>
          <div className={styles.inputWrapper}>
            <input
              type="number"
              className={styles.articleInput}
              value={quantity}
              onChange={(e) => {
                changeQuantity(e.target.value);
              }}
              disabled={!articleQuantityEditable}
            />
            <span className={styles.articleInputUnit}>{quantityUnit}</span>
          </div>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              inputMode="numeric"
              className={styles.articleInput}
              value={netPrice}
              placeholder={opNetPrice?.toString()}
              onChange={(e) => {
                changePrice(e.target.value.replace(/[^0-9,.]/g, '').replace(/,/g, '.'));
              }}
              disabled={!priceEditable}
            />
            <span className={styles.articleInputUnit}>Euro</span>
          </div>
          <PriceTooltip />
        </div>
        <div className={styles.articleFooterItem}>
          <div className={styles.positionTotal}>
            {`Gesamt ${(parseFloat(quantity) * parseFloat(netPrice || '0'))?.toFixed(2) || '0,00'} €`}
          </div>
        </div>

      </div>

      {positionError?.quantity && <div className={styles.error}>{positionError?.quantity}</div>}
      {positionError?.netPrice && <div className={styles.error}>{positionError?.netPrice}</div>}

    </div>
  );
};

export default OrderPosition;
