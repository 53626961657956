import styles from './styles.module.scss';

const TicketNewCommentBadge = () => {
  return (
    <div className={styles.newMessageBadge} data-testid="new-messages-indicator">
      Neue Nachrichten
    </div>
  );
};

export default TicketNewCommentBadge;
