import favicon from './assets/favicon.svg';
import opIcon from './assets/tfg-op-icon.svg';
import simpleLogo from './assets/simple_logo.svg';
import logo from './assets/logo.svg';
import chatLogo from './assets/chat_logo.svg';
import privacy from '../commonAssets/beemPrivacyText';
import terms from '../commonAssets/termsText';
import imprint from './assets/imprintText';
import deleteAccountText from '../commonAssets/deleteAccountText';

const config = {
  title: 'TFG BEEM',
  description: 'Einen einfachen, schnellen und rechtssicheren Kontakt zum persönlichen TFG-Kontakt ermöglicht BEEM – die smarte Chat-Plattform.',
  identifier: 'tfg',
  appName: 'TFG',
  defaultBrand: 'TFG',
  url: 'tfgbeem.de',
  apiBaseUrl: 'https://api.tfgbeem.de',
  helpUrl: 'https://hilfe.getbeem.de/',
  iosStoreUrl: 'https://apps.apple.com/us/app/tfg-beem/id1638748972',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=de.tfgbeem',
  supportEmail: 'support@getbeem.de',
  favicon,
  logo,
  chatLogo,
  simpleLogo,
  packageId: 'de.tfgbeem',
  privacyText: privacy,
  termsText: terms,
  usercentricsId: 'CZ5jImIoB',
  imprintText: imprint,
  deleteAccountText,
  welcomeText: 'Willkommen bei TFG BEEM!',
  identifierPlaceholder: 'Mobilnummer oder E-Mail Adresse',
  mainText: 'Bitte geben Sie Ihre Mobilnummer oder E-Mail Adresse ein, um sich anzumelden.',
  opIcon,
};

export default config;
