import { getChannelIsCraftnote } from 'gcs-common/slices/channel/channelSelectors';
import {
  getMessageIsPending,
  getMessageShouldShowOptionsMenu,
  getMessageHasMedia,
} from 'gcs-common/slices/messages/messageSelector';
import { createSelector } from '@reduxjs/toolkit';

export const getSelectedMessageIds = state => state.uiState.selectedMessageIds;
export const getSelectedMessageLength = state => state.uiState.selectedMessageIds.length;
export const getInitialDataLoadSucceeded = state => state.uiState.initialDataLoadSucceeded;
export const getVideoFullscreenId = state => state.uiState.videoFullScreenId;
export const getTicketMedia = state => state.uiState.ticketMedia;

export const getShowDeletionConfirmationModal = state => (
  state.uiState.showDeletionConfirmationModal
);

export const getSelectedMessageId = createSelector(
  [getSelectedMessageIds],
  selectedMessageIds => {
    if (selectedMessageIds && selectedMessageIds.length === 1) {
      return selectedMessageIds[0];
    }
    return undefined;
  },
);

export const getIsAtLeastOneMessageSelected = createSelector(
  [getSelectedMessageLength],
  selectedMessageLength => { return selectedMessageLength !== 0; },
);

export const getIsOnlyOneMessageSelected = createSelector(
  [getSelectedMessageIds],
  selectedMessageIds => {
    if (selectedMessageIds && selectedMessageIds.length === 1) {
      return true;
    }
    return false;
  },
);
export const getIsMessageSelected = (messageId) => createSelector(
  [getSelectedMessageIds],
  selectedMessageIds => selectedMessageIds && selectedMessageIds.includes(messageId),
);

export const getMessageIsSwipeDisabled = (channelId, messageId) => createSelector(
  getMessageHasMedia(messageId),
  getChannelIsCraftnote(channelId),
  getMessageShouldShowOptionsMenu(channelId, messageId),
  getSelectedMessageLength,
  getMessageIsPending(messageId),
  (hasMedia, isCraftnote, optionsEnabled, selectedMessageLength, isMessagePending) => {
    return selectedMessageLength !== 0
      || !optionsEnabled || !!(hasMedia && isCraftnote) || isMessagePending;
  },
);
