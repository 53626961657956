import { captureException } from '@sentry/react';
import { channelInputFileStaged } from 'gcs-common/slices/messageInput/messageInputSlice';
import { selectedChannelIdChanged } from 'gcs-common/slices/channels/channelsSlice';
import { channelForFileSharingSelected } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSlice';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';

const processFile = async (file, channelId, dispatch) => {
  try {
    dispatch(channelInputFileStaged({ channelId, file }));
  } catch (error) {
    const dialogErrorMsg = `Leider ist es momentan nicht möglich, die Datei ${file.title} zu teilen. Wir arbeiten daran, dieses Problem zu beheben.`;
    dispatch(openErrorDialog({ dialogErrorMsg }));
    captureException(error);
  }
};

export const sendSharedFilesToChannel = async (sharedFiles, channelId, dispatch) => {
  const fileProcessingPromises = sharedFiles.map(file => processFile(file, channelId, dispatch));
  await Promise.all(fileProcessingPromises);

  dispatch(selectedChannelIdChanged({ selectedChannelId: channelId }));
  dispatch(channelForFileSharingSelected({ channelId }));
};
