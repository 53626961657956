import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ActionSheet from 'actionsheet-react';
import { getChannelType, getMainContactId } from 'gcs-common/slices/channel/channelSelectors';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import joinSalesSpace from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsThunks/joinSalesSpace';
import leaveSalesSpace from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsThunks/leaveSalesSpace';
import { CHANNEL_TYPE } from 'gcs-common/helper/channelTypeHelper';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import { getCurrentUserId, getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import { setSalesSpaceChannelActionSheetClose } from '../../slices/uiState/uiStateSlice';
import { getIsSalesSpaceChannelActionSheetOpen } from '../../slices/uiState/uiStateSelectors';
import ChannelArchiveItem from '../ChannelArchiveItem/ChannelArchiveItem';

const SalesSpaceChannelActionSheet = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isActionSheetOpen = useSelector(getIsSalesSpaceChannelActionSheetOpen);
  const channelId = useSelector(getSelectedChannelId);
  const isMember = useSelector(getCurrentUserIsMemberOfChannel(channelId));
  const channelType = useSelector(getChannelType(channelId));
  const currentUserId = useSelector(getCurrentUserId);
  const mainContactId = useSelector(getMainContactId(channelId));
  const isAgent = useSelector(getIsAgent);
  const selectedChannelId = useSelector(getSelectedChannelId);

  const isMainContact = mainContactId === currentUserId;

  const handleClose = () => {
    dispatch(setSalesSpaceChannelActionSheetClose());
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ref.current?.close();
  };

  const onJoinSalesSpace = () => {
    dispatch(joinSalesSpace({ channelId, currentUserId }));
    handleClose();
  };

  const onLeaveSalesSpace = () => {
    dispatch(leaveSalesSpace({ channelId }));
    handleClose();
  };

  useEffect(() => {
    if (isActionSheetOpen) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ref.current?.open();
    }
  }, [dispatch, isActionSheetOpen, isMember]);

  return (
    <ActionSheet
      bgStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        transition: 'opacity 150ms ease-in-out 0s, z-index 150ms ease-in-out 0s', // Override default transition
        width: '100vw',
      }}
      closeOnBgTap
      mouseEnable
      onClose={handleClose}
      ref={ref}
      sheetStyle={{
        backgroundColor: '#f1f1f1',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        maxHeight: '50vh',
        overflow: 'hidden',
        width: '100vw',
      }}
      touchEnable
    >
      <div className={styles.actionSheetWrapper}>
        <div className={styles.actionSheetBar} />
        <div className={styles.actionSheetContent}>
          {isAgent && channelType === CHANNEL_TYPE.SALES_SPACE && !isMainContact
          && (isMember ? (
            <div
              role="button"
              tabIndex="0"
              className={styles.actionSheetItem}
              onClick={onLeaveSalesSpace}
            >
              <div className={styles.left}>
                <IconComponent Icon={ICON.LOGOUT} color={ICON_COLOR.LIGHT_GREY} alt="leave chat icon" />
              </div>
              <span className={styles.actionSheetItemText}>
                Chat verlassen
              </span>
            </div>
          ) : (
            <div
              role="button"
              tabIndex="0"
              className={styles.actionSheetItem}
              onClick={onJoinSalesSpace}
            >
              <div className={styles.left}>
                <IconComponent Icon={ICON.LOGIN} color={ICON_COLOR.LIGHT_GREY} alt="join chat icon" />
              </div>
              <span className={styles.actionSheetItemText}>
                Chat beitreten
              </span>
            </div>
          ))
          }

          <Link
            to={`${pathname.includes('/favorites') ? '/' : CHAT_ROUTES.FAVORITES(selectedChannelId)}`}
            onClick={handleClose}
          >
            <div
              className={styles.actionSheetItem}
            >
              <div className={styles.left}>
                { pathname.includes('/favorites')
                  ? <IconComponent Icon={ICON.STAR_FILLED} color={ICON_COLOR.LIGHT_GREY} alt="favorites" />
                  : <IconComponent Icon={ICON.STAR} color={ICON_COLOR.LIGHT_GREY} alt="favorites" />
                }
              </div>
              <span className={styles.actionSheetItemText}>
                Favoriten ansehen
              </span>
            </div>
          </Link>

          <Link
            to={CHAT_ROUTES.SEARCH(selectedChannelId)}
            onClick={handleClose}
          >
            <div
              className={styles.actionSheetItem}
            >
              <div className={styles.left}>
                <IconComponent Icon={ICON.SEARCH} color={ICON_COLOR.LIGHT_GREY} alt="search" />
              </div>
              <span className={styles.actionSheetItemText}>
                Chat durchsuchen
              </span>
            </div>
          </Link>

          <ChannelArchiveItem onClick={handleClose} channelId={channelId} />
        </div>
      </div>
    </ActionSheet>
  );
};

export default SalesSpaceChannelActionSheet;
