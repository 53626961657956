import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageImgDimensions, getMessageMediaHumanFilesize,
} from 'gcs-common/slices/messages/messageSelector';
import PropTypes from 'prop-types';
import messageUpdateImageDimensions from 'gcs-common/slices/messages/messagesThunks/messageUpdateImageDimensions';
import { ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import { Link } from 'react-router-dom';
import arrowDown from 'gcs-common/img/arrow_down_grey.png';
import imageIcon from 'gcs-common/img/image_grey.svg';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import styles from './styles.module.scss';
import useDownloadMessageMedia from '../../hooks/useDownloadMessageMedia';
import LocalImage from '../LocalImage/LocalImage';
import MediaMessageImageFrame from '../MediaMessageImageFrame/MediaMessageImageFrame';

const MediaMessageImage = ({ messageId, maxHeight = 300, disableClick = false }) => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();
  const imgDimensions = useSelector(getMessageImgDimensions(messageId));
  const humanFileSize = useSelector(getMessageMediaHumanFilesize(messageId));

  const { loading, localKey, download } = useDownloadMessageMedia({ messageId });
  const onImageLoad = useCallback((event) => {
    if (imgDimensions) {
      return;
    }
    const { naturalWidth, naturalHeight } = event.target;
    dispatch(messageUpdateImageDimensions({
      messageId, width: naturalWidth, height: naturalHeight,
    }));

  }, [dispatch, imgDimensions, messageId]);

  const localImage = localKey && (
    <LocalImage
      localKey={localKey}
      alt="Bild aus Nachricht"
      onLoad={onImageLoad}
      className={styles.img}
    />
  );

  return (
    <MediaMessageImageFrame
      messageId={messageId}
      maxHeight={maxHeight}
      className={styles.wrapper}
    >
      {localKey && (
        disableClick ? (
          <div>{localImage}</div>
        ) : (
          <Link
            disabled={disableClick}
            className={styles.link}
            to={{
              pathname: CHAT_ROUTES.GALLERY(selectedChannelId, messageId),
            }}
          >
            {localImage}
          </Link>
        ))}
      <div
        className={`${styles.placeholderBackground} ${localKey ? styles.hidden : ''}`}
      >
        {loading && (
          <div>
            {localKey || <Spinner size={ICON_SIZE.X_LARGE} color={ICON_COLOR.WHITE} />}
          </div>
        )}
        {!localKey && !loading && (
          <>
            <img src={imageIcon} alt="Bild" />
            <button
              className={styles.downloadBtnWrapper}
              onClick={download}
              type="button"
              disabled={disableClick}
            >
              <img src={arrowDown} alt="Download" />
              {' '}
              {humanFileSize}
            </button>
          </>

        )}
      </div>
    </MediaMessageImageFrame>
  );
};

MediaMessageImage.propTypes = {
  messageId: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  disableClick: PropTypes.bool,
};

export default MediaMessageImage;
