import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getInitialChannelsLoading } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getSelectedChannelForSharing,
  hasSharedFiles,
} from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import { useEffect } from 'react';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import MessageInputArea from '../MessageInputArea/MessageInputArea';
import ChannelListCraftsman from '../SideMenu/ChannelGroup/ChannelListCraftsman/ChannelListCraftsman';
import ChannelListAgent from '../SideMenu/ChannelGroup/ChannelListAgent/ChannelListAgent';
import HouseSwitcherActionSheet from '../HouseSwitcherActionSheet/HouseSwitcherActionSheet';
import FilePreviewer from '../FilePreviewer/FilePreviewer';
import FileShareHeader from './FileShareHeader/FileShareHeader';


const FileShare = () => {

  const navigate = useNavigate();
  const sharedFiles = useSelector(hasSharedFiles);
  const isAgent = useSelector(getIsAgent);
  const channelForFilesSharing = useSelector(getSelectedChannelForSharing);
  const initialChannelsLoadIng = useSelector(getInitialChannelsLoading);

  useEffect(() => {
    // if we dont have any file to share we route back to the CHAT
    if (!sharedFiles) {
      navigate(CHAT_ROUTES.CHAT);
    }
  }, [sharedFiles, navigate]);

  return (
    <div className={styles.shareWrapper}>
      {!channelForFilesSharing.length
        ? (
          <>
            <FileShareHeader />

            {sharedFiles.length && initialChannelsLoadIng && (
              <div className={styles.loadingWrapper}>
                <LoadingIndicator />
                <div>Lädt Channel...</div>
              </div>
            )}

            {!initialChannelsLoadIng
              && (
                <div className={styles.listWrapper}>
                  {isAgent
                    ? (
                      <ChannelListAgent isForFileShare />
                    )
                    : <ChannelListCraftsman isForFileShare />
                  }
                </div>
              )
            }
            {!isAgent && <HouseSwitcherActionSheet />}
          </>
        )
        : (
          <>
            <FilePreviewer />
            <div className={styles.inputArea}>
              <MessageInputArea />
            </div>
          </>
        )}
    </div>
  );
};

FileShare.propTypes = {};

export default FileShare;
