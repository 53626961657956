import { useDispatch, useSelector } from 'react-redux';
import { getHouse, getSelectedHouseId } from 'gcs-common/slices/houses/housesSelectors';
import { getTotalUnreadCountFromUnselectedHouse } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { setHouseSwitcherActionSheetOpen } from '../../slices/uiState/uiStateSlice';
import SideMenuItem from '../SideMenu/SideMenuItem/SideMenuItem';
import styles from './styles.module.scss';

const HouseSwitcher = () => {
  const dispatch = useDispatch();
  const selectedHouseId = useSelector(getSelectedHouseId);
  const selectedHouse = useSelector(getHouse(selectedHouseId));
  const totalUnreadCount = useSelector(getTotalUnreadCountFromUnselectedHouse);

  return (
    <div className={styles.houseSwitcher}>
      <SideMenuItem
        iconRight={<IconComponent Icon={ICON.HOUSE_SWITCH} color={ICON_COLOR.HOUSE_SWITCH} />}
        label={selectedHouse?.friendlyName || 'Haus auswählen'}
        onClick={() => dispatch(setHouseSwitcherActionSheetOpen())}
        totalUnreadMessages={totalUnreadCount}
      />
    </div>
  );
};

export default HouseSwitcher;
