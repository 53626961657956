/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import ApiProvider from 'gcs-common/provider/ApiProvider';
import router from '../../router/router';
import initializeStore from '../../initializeStore';
import { createMobilePersister } from '../../offline-settings/react-query-persister';

const store = initializeStore();

const AppRoot = () => {
  const persister = createMobilePersister();
  return (
    <Provider store={store}>
      <ApiProvider persister={persister}>
        <RouterProvider router={router} />
      </ApiProvider>
    </Provider>
  );
};

export default AppRoot;
