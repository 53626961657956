import { captureException } from '@sentry/react';
import { FileOpener } from '@capacitor-community/file-opener';
import { getAppName } from 'gcs-common/slices/theme/themeSelectors';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';

const openEtsFile = ({ localKey, contentType = undefined }) => async (dispatch, getState, {
  blobStorageClient,
}) => {
  const appName = getAppName(getState());

  const localPath = await blobStorageClient.getObjectUrl(localKey, { DOMUrl: false });

  if (!IS_NATIVE) {
    window.open(localPath);
    return;
  }

  try {
    await FileOpener.open({
      filePath: localPath,
      contentType,
    });
  } catch (e) {
    captureException(e);

    const options = {
      // If we set "chooserTitle", for me the native sharedialog gets displayed.
      // Otherwise some other dialog...
      chooserTitle: 'App auswählen um Datei zu öffnen', // Android only, you can override the default share sheet title
      message: `${appName}-Chat`, // not supported on some apps (Facebook, Instagram)
      files: [localPath], // an array of filenames either locally or remotely
    };

    window.plugins.socialsharing.shareWithOptions(options);
  }
};

export default openEtsFile;
