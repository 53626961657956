import deletedIcon from 'gcs-common/img/account_deletion.svg';
import styles from './styles.module.scss';

const VideoDeleted = () => {

  return (
    <div className={`${styles.videoMessageContainer} ${styles.videoDeletedMessage}`}>
      <img src={deletedIcon} alt="video-deleted" />
      <p>das Video ist nicht mehr verfügbar</p>
    </div>
  );

};

export default VideoDeleted;

VideoDeleted.propTypes = {
};
