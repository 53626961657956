import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  getChannelIsFreeSalesSpace,
  getCurrentUserIsMemberOfChannel,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import {
  getChannelImage,
  getChannelStatus,
  getChannelSubtitle,
  getChannelTitle,
  getIsHidden,
  getMemberAgentIdsForChannel,
  getSalesSpaceMostAvailableAgent,
} from 'gcs-common/slices/channel/channelSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import changeSelectedChannelId from 'gcs-common/slices/channels/channelsThunks/changeSelectedChannelId';
import { getUnreadMessageCountForChannel } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { getSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import { UNREAD_COUNT_DISPLAY_THRESHOLD } from 'gcs-common/constants/channelConstants';
import Highlight from '../Highlight/Highlight';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import styles from './styles.module.scss';
import { setSideMenuClosed } from '../../slices/uiState/uiStateSlice';
import { sendSharedFilesToChannel } from '../../helper/sendSharedFilesToChannelHelper';

const SalesSpaceChannelListItem = ({ channelId }) => {

  const dispatch = useDispatch();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const channelImage = useSelector(getChannelImage(channelId));
  const channelStatus = useSelector(getChannelStatus(channelId));
  const channelTitle = useSelector(getChannelTitle(channelId));
  const channelSubtitle = useSelector(getChannelSubtitle(channelId));
  const searchInput = useSelector(getSearchInput);
  const isFree = useSelector(getChannelIsFreeSalesSpace(channelId));
  const isMember = useSelector(getCurrentUserIsMemberOfChannel(channelId));
  const agentIds = useSelector(getMemberAgentIdsForChannel(channelId));
  const mostAvailableAgent = useSelector(getSalesSpaceMostAvailableAgent(channelId));
  const isFilesSharing = useSelector(getSharedFiles).length;
  const isHidden = useSelector(getIsHidden(channelId));

  const otherAgentsCount = agentIds.length - (isMember ? 1 : 0);
  const isSelected = channelId === selectedChannelId;

  const sharedFiles = useSelector(getSharedFiles);
  const isChannelsListItemForFileSharing = sharedFiles.length;

  const numUnreadMessages = useSelector(getUnreadMessageCountForChannel(channelId));

  const onChannelSelected = () => {
    if (!isChannelsListItemForFileSharing) {
      dispatch(changeSelectedChannelId({ selectedChannelId: channelId }));
      dispatch(setSideMenuClosed());
    } else {
      sendSharedFilesToChannel(sharedFiles, channelId, dispatch);
    }
  };

  const channelStyle = useMemo(() => {
    if (isSelected && !isFilesSharing) {
      return styles.selectedSalesSpaceChannel;
    }
    if (isChannelsListItemForFileSharing) {
      return styles.unselectedSalesSpaceChannel;
    }
    return styles.unselectedSalesSpaceChannel;
  }, [isSelected, isChannelsListItemForFileSharing, isFilesSharing]);

  const renderedUnread = (
    numUnreadMessages > UNREAD_COUNT_DISPLAY_THRESHOLD
      ? `${numUnreadMessages}+` : numUnreadMessages
  );

  return (
    <button
      type="button"
      onClick={onChannelSelected}
      className={channelStyle}
    >
      <div
        className={`${styles.channelFreeIndicator} ${isFree && styles.channelFreeIndicatorActive}`}
      />
      <div className={styles.channelInfo}>
        <div className={styles.userImage}>
          <ImageWithStatus
            isSalesSpace
            isAgent
            className={styles.imageWrapper}
            imageUrl={channelImage}
            userStatus={channelStatus}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.customerTitle}>
            <Highlight search={searchInput}>{channelTitle}</Highlight>
          </div>
          <div className={styles.customerCompany}>
            <Highlight search={searchInput}>{channelSubtitle}</Highlight>
          </div>
          {isHidden && (
            <div className={styles.archivedIndicator}>
              Archiviert
            </div>
          )}
        </div>
        <div className={styles.unreadMsgContainer}>

          {numUnreadMessages > 0
            && (
              <div className={styles.flexContainerRight}>

                {numUnreadMessages > 0 && (
                <div className={styles.unreadMsgIndicator}>
                  {renderedUnread}
                </div>
                )}

              </div>
            )
          }

          <div className={styles.imageStatusAndButtonContainer}>

            {otherAgentsCount > 0
           && (
             <>
               <div>
                 <ImageWithStatus
                   isSmall
                   isDot
                   imageUrl={mostAvailableAgent?.imageUrl}
                   userStatus={mostAvailableAgent?.status}
                 />
               </div>

               { otherAgentsCount > 1
             && (
             <div className={`${styles.agentCountIndicator} ${isSelected ? styles.white : ''}`}>
               +
               {otherAgentsCount - 1}
             </div>
             )
             }
             </>
           )
           }

          </div>

        </div>
      </div>

    </button>
  );
};

SalesSpaceChannelListItem.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default SalesSpaceChannelListItem;
