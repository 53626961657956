import OnlinePlusProfileForm from 'gcs-common/components/OnlinePlusProfileForm/OnlinePlusProfileForm';
import OnlinePlusMobileWrapper from '../OnlinePlusMobileWrapper';

const OnlinePlusProfileFormMobile = () => {
  return (
    <OnlinePlusMobileWrapper>
      <OnlinePlusProfileForm />
    </OnlinePlusMobileWrapper>
  );
};

export default OnlinePlusProfileFormMobile;
