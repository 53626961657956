import UserCodeForm from 'gcs-common/components/UserCodeForm/UserCodeForm';
import { PhoneInput } from 'gcs-common/design-system/ui/phone-input';
import { useNavigate } from 'react-router';
import Anchor from 'gcs-common/design-system/ui/anchor';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'gcs-common/design-system/ui/form';
import { useFormContext } from 'react-hook-form';
import { CODE_ROUTES } from 'gcs-common/constants/routes';
import { useQueryParams } from 'gcs-common/design-system/hooks/use-query-params';

const PhoneInputWrapper = () => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name="phone"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <PhoneInput
              {...field}
              international
              searchable
              autoFocus
              className="ring-neutral-5 focus-within:ring-accent-9"
              placeholder="Mobilnummer"
            />
          </FormControl>
          <FormMessage showWarningIcon={false} />
        </FormItem>
      )}
    />
  );
};

const AnchorWrapper = () => {
  const navigate = useNavigate();
  const { queryParams } = useQueryParams(['registration']);
  const { registration } = queryParams;
  const requestCodeWithEmail = () => {
    navigate(`${CODE_ROUTES.REQUEST_CODE}${registration ? '?registration=true' : ''}`);
  };
  return (
    <Anchor onClick={() => requestCodeWithEmail()}>
      {registration ? 'Stattdessen mit E-Mail registrieren' : 'Stattdessen mit E-Mail anmelden'}
    </Anchor>
  );
};
const UserCodePhoneForm = () => {
  return (
    <UserCodeForm>
      <PhoneInputWrapper />
      <AnchorWrapper />
    </UserCodeForm>
  );
};

export default UserCodePhoneForm;
