import camelCase from 'lodash-es/camelCase';

export const snakeToCamelCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(value => snakeToCamelCase(value));
  } if (obj && obj instanceof Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: snakeToCamelCase(obj[key]),
      }), {},
    );
  }
  return obj;
};
