/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { Component } from 'react';

class LongPressItem extends Component {

  // eslint-disable-next-line react/sort-comp
  buttonPressTimer;

  componentDidUpdate(prevProps) {
    const { disabled = false } = this.props;
    if (!prevProps.disabled && disabled) {
      clearTimeout(this.buttonPressTimer);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.buttonPressTimer);
  }

  handleButtonPress = (event) => {
    const { disabled = false, onLongPress, timeout } = this.props;
    if (!disabled) {
      const { currentTarget } = event;
      this.buttonPressTimer = setTimeout(() => {
        if (onLongPress) {
          onLongPress(currentTarget);
        }
      }, timeout || 1000);
    }
  };

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  };

  render() {
    const { className, children, onClick } = this.props;
    return (
      <div
        className={className}
        onClick={onClick}
        onTouchStart={this.handleButtonPress}
        onTouchEnd={this.handleButtonRelease}
        onMouseDown={this.handleButtonPress}
        onMouseUp={this.handleButtonRelease}
        onMouseLeave={this.handleButtonRelease}
      >
        {children}
      </div>
    );
  }
}

LongPressItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onLongPress: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  timeout: PropTypes.number,
};

export default LongPressItem;
