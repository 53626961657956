import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import blockUserImage from 'gcs-common/img/block_user.svg';
import reportUserImage from 'gcs-common/img/report_user.svg';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import {
  getMemberDetailsSelectedUserId,
} from 'gcs-common/slices/memberDetails/memberDetailsSelectors';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import Page from '../Page/Page';
import { showBlockUserModal, showReportUserModal } from '../../slices/uiState/uiStateSlice';
import MemberDetails from '../MemberDetails/MemberDetails';

const UserProfile = () => {
  const userId = useSelector(
    getMemberDetailsSelectedUserId,
  );
  const currentUserId = useSelector(getCurrentUserId);
  const isOwnProfile = userId === currentUserId;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backToChannelDetails = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onBlockUserClicked = () => {
    dispatch(showBlockUserModal());
  };

  const onReportUserClicked = () => {
    dispatch(showReportUserModal());
  };

  return (
    <Page className={styles.main}>
      <div className={styles.top}>
        <button
          type="button"
          onClick={backToChannelDetails}
          className={styles.back}
        >
          <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.ROTATE_180} />
        </button>
        <div className={styles.title}>Profil</div>
      </div>
      <div className={styles.body}>
        <MemberDetails />

        { !isOwnProfile
        && (
          <>
            <div className={styles.actionsHeader}>
              Aktionen
            </div>
            <div>
              <div role="button" tabIndex={0} onClick={onReportUserClicked} className={styles.actionsWrapper}>
                <div className={styles.action}>
                  <img src={reportUserImage} alt="Nutzer melden" />
                  Nutzer melden
                </div>
              </div>
              <div className={styles.actionsWrapper}>
                <div role="button" tabIndex={0} onClick={onBlockUserClicked} className={styles.action}>
                  <img src={blockUserImage} alt="Nutzer blockieren" />
                  Nutzer blockieren
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Page>
  );
};

export default UserProfile;
