import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE_TYPE } from 'gcs-common/constants/MessageTypes';
import {
  getMessageBody,
  getMessageFooterText,
  getMessageIsDeleted,
  getMessageIsForwarded,
  getMessageIsFromMe,
  getMessageQuotedAuthorId,
  getMessageQuotedDateCreated,
  getMessageType,
} from 'gcs-common/slices/messages/messageSelector';
import { getUserLoaded } from 'gcs-common/slices/user/userSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { fetchUser } from 'gcs-common/slices/users/usersThunks/fetchUser';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import LinkifyBlank from '../LinkifyBlank/LinkifyBlank';
import QuotedMessageName from './QuotedMessageName/QuotedMessageName';
import styles from './styles.module.scss';
import DeletedMessage from '../DeletedMessage/DeletedMessage';
import MediaMessage from '../MediaMessage/MediaMessage';
import VideoMessage from '../VideoMessage/VideoMessage';

const QuotedMessage = ({ messageId, onCancel, disableClickMedia = false }) => {

  const body = useSelector(getMessageBody(messageId));
  const isForwarded = useSelector(getMessageIsForwarded(messageId));
  const dateCreated = useSelector(getMessageQuotedDateCreated(messageId));
  const authorId = useSelector(getMessageQuotedAuthorId(messageId));
  const messageType = useSelector(getMessageType(messageId));
  const isDeleted = useSelector(getMessageIsDeleted(messageId));
  const isAuthorLoaded = useSelector(getUserLoaded(authorId));
  const isFromMe = useSelector(getMessageIsFromMe(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const messageFooterText = useSelector(getMessageFooterText(messageId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthorLoaded && !isForwarded && authorId) {
      dispatch(fetchUser({ id: authorId }));
    }
  }, [authorId, dispatch, isAuthorLoaded, isForwarded]);

  const displayDateCreated = useMemo(() => {
    return dateCreated.format('D.M.YYYY [um] HH:mm');
  }, [dateCreated]);

  const bodyComponent = useMemo(() => {
    const isMediaType = (messageType === MESSAGE_TYPE.MEDIA_FROM_MAIL
      || messageType === MESSAGE_TYPE.MEDIA);
    const isVideo = messageType === MESSAGE_TYPE.VIDEO;

    if (isDeleted) {
      return <div className={styles.deletedWrapper}><DeletedMessage /></div>;
    }
    if (isMediaType) {
      return (
        <MediaMessage
          maxHeight={100}
          messageId={messageId}
          disableClick={disableClickMedia}
        />
      );
    }
    if (isVideo) {
      return <VideoMessage messageId={messageId} />;
    }

    return (
      <div className={styles.txtMsgBody}>
        <LinkifyBlank>{body}</LinkifyBlank>
      </div>
    );
  }, [body, isDeleted, messageId, messageType, disableClickMedia]);

  return (
    <div
      className={`${styles.replyDraft} ${!isFromMe ? styles.fromOther : styles.fromMe}`}
    >
      <div className={styles.borderWrapper}>
        <div className={styles.draftMessagePlaceholder}>
          <div className={styles.messageHeader}>
            <QuotedMessageName
              channelId={selectedChannelId}
              messageId={messageId}
            />
            {onCancel && (
            <div
              tabIndex={0}
              role="button"
              onKeyUp={onCancel}
              onClick={onCancel}
              className={styles.crossDraft}
            >
              <IconComponent Icon={ICON.CLOSE} style={{ marginRight: '4px' }} />
            </div>
            )}
          </div>
          <div className={styles.msgText}>{bodyComponent}</div>
        </div>

        <div className={styles.messageFooter}>
          {messageFooterText && (
            <div className={styles.messageFooterText}>
              {messageFooterText}
            </div>
          )}
          <span>{displayDateCreated}</span>
        </div>
      </div>
    </div>
  );
};

QuotedMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  disableClickMedia: PropTypes.bool,
};

export default QuotedMessage;
