import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMessageImgDimensions } from 'gcs-common/slices/messages/messageSelector';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

const DEFAULT_RATIO = 7 / 5;

const MediaMessageImageFrame = ({
  messageId, maxHeight, defaultRatio = DEFAULT_RATIO, children, ...props
}) => {
  const { width: maxWidth = maxHeight / defaultRatio, ref } = useResizeDetector();
  const imgDimensions = useSelector(getMessageImgDimensions(messageId));
  const containerMeasures = useMemo(() => {
    let { w: width, h: height } = imgDimensions || {};
    const ratio = imgDimensions ? (width / height) : defaultRatio;
    if (!imgDimensions) {
      height = maxHeight;
      width = height * ratio;
    }
    const isPortrait = maxHeight * ratio > maxWidth;
    if (isPortrait) {
      return {
        width: maxWidth,
        height: maxWidth / ratio,
      };
    }
    if (width < maxWidth) {
      return {
        width,
        height,
      };
    }
    return {
      width: maxHeight * ratio,
      height: maxHeight,
    };
  }, [imgDimensions, maxHeight, maxWidth, defaultRatio]);

  return (
    <div ref={ref} style={{ width: '100%' }} {...props}>
      <div ref={ref} style={containerMeasures}>
        {children}
      </div>
    </div>
  );
};

MediaMessageImageFrame.propTypes = {
  messageId: PropTypes.string.isRequired,
  maxHeight: PropTypes.number.isRequired,
  defaultRatio: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default MediaMessageImageFrame;
