import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../client';
import logoutSuccessEffect from '../../../../slices/auth/authThunks/logout-effect';
import { CurrentUserQueryKeys } from '../current-user/current-user-api';
import { openErrorDialog } from '../../../../slices/errorDialogSlice/errorDialogSlice';
import { RequestBody } from '../../../../../types/typeHelper';

export function useLogoutMutation() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: () => {
      return client.GET('/auth/logout');
    },
    onSuccess: async () => {
      await queryClient.resetQueries(
        { queryKey: [CurrentUserQueryKeys.GET_CURRENT_USER_IS_LOGGED_IN] },
      );
      queryClient.clear();
      dispatch(logoutSuccessEffect());
    },
  });
}

export function useAuth0MobileNativeLoginMutation() {
  const dispatch = useDispatch();
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ body } : { body : RequestBody<'/auth/auth0/login', 'post'> }) => {
      await client.POST('/auth/auth0/login', { body });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        { queryKey: [CurrentUserQueryKeys.GET_CURRENT_USER_IS_LOGGED_IN] },
      );
    },
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message }));
    },
  });
}

export function useTeamsLoginMutation() {
  const dispatch = useDispatch();
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ body } : { body : RequestBody<'/auth/ms-teams/login', 'post'> }) => {
      await client.POST('/auth/ms-teams/login', { body });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        { queryKey: [CurrentUserQueryKeys.GET_CURRENT_USER_IS_LOGGED_IN] },
      );
    },
    onError: (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message }));
    },
  });
}
