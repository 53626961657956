import { TicketMessage as TicketMessageType, TicketMessageType as TicketMessageTypeKeys } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import styles from './styles.module.scss';
import TicketMessageComment from '../TicketMessageComment/TicketMessageComment';
import TicketMessageMedia from '../TicketMessageMedia/TicketMessageMedia';
import TicketNewCommentBadge from '../TicketNewCommentBadge/TicketNewCommentBadge';
import TicketMessagePart from '../TicketMessagePart/TicketMessagePart';
import TicketBotMessage from '../TicketBotMessage/TicketBotMessage';
import TicketRating from '../TicketRating/TicketRating';

interface TicketMessageProps {
  message: TicketMessageType;
  ticketId: string;
}

const TicketMessage = (props: TicketMessageProps) => {
  const { ticketId, message } = props;
  return (
    <div className={styles.messageContent}>

      {message.type === TicketMessageTypeKeys.COMMENT && <TicketMessageComment {...message} />}

      {message.type === TicketMessageTypeKeys.MEDIA
      && <TicketMessageMedia {...message} ticketId={ticketId} />}

      {message.type === TicketMessageTypeKeys.PART && <TicketMessagePart {...message} />}

      {message.type === TicketMessageTypeKeys.BOT_MESSAGE && <TicketBotMessage {...message} />}

      {message.type === TicketMessageTypeKeys.NEW_MESSAGE_INDICATOR && <TicketNewCommentBadge />}


      {message.type === TicketMessageTypeKeys.RATING
      && <TicketRating {...message} ticketId={ticketId} />}

    </div>
  );
};

export default TicketMessage;
