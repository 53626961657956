import withConfirmation, { type ConfirmModalProps } from 'gcs-common/design-system/hoc/confirmation-hoc';
import { useLogoutMutation } from 'gcs-common/clients/api/entities/auth/auth-api';
import Anchor from 'gcs-common/design-system/ui/anchor';

const LogOutAnchorWithConfirm = withConfirmation((props: ConfirmModalProps) => (
  <Anchor {...props} className="hidden text-center lg:block">
    Zurück
  </Anchor>
));

const OnlinePlusLoginReturnButton = () => {
  const logoutMutation = useLogoutMutation();
  const logoutUser = async () => {
    await logoutMutation.mutateAsync();
  };

  return (
    <LogOutAnchorWithConfirm
      title="Möchten Sie den Registrierungsprozess unterbrechen?"
      confirmLabel="JA"
      closeLabel="NEIN"
      confirm={logoutUser}

    >
      <div>
        Sie können sich jederzeit erneut einloggen um Ihren Account zu vervollständigen.
      </div>
    </LogOutAnchorWithConfirm>
  );


};

export default OnlinePlusLoginReturnButton;
