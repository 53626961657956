import { createSelector } from '@reduxjs/toolkit';
import { ProcessesState } from './processesSlice';

const getProcessesState = (state: { processes: ProcessesState }) => state.processes;

export const getProcessSearchInput = createSelector(
  getProcessesState,
  (state) => state.searchInput,
);

export const getFilter = createSelector(
  getProcessesState,
  (state) => state.filter,
);

export const getSelectedProcessNumber = createSelector(
  getProcessesState,
  (state) => state.selectedProcessNumber,
);

export const getSelectedProductNumber = createSelector(
  getProcessesState,
  (state) => state.selectedProductNumber,
);
