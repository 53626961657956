import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageIsPending, getMessageMediaRelativePath,
  getClientMessageId,
} from 'gcs-common/slices/messages/messageSelector';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { useCallback, useEffect } from 'react';
import {
  getHasLocalUrl,
  getMessageMediaDownloadLoading,
  getMessageMediaDownloadFailed, getAwaitingLoadLocalFileUrl,
} from '../slices/media/mediaSelectors';
import loadLocalFileUrl from '../slices/media/mediaThunks/loadLocalFileUrl';
import initiateMessageMediaDownload from '../slices/media/mediaThunks/initiateMessageMediaDownload';

/*
 * hook is responsible for downloading message media
 */
const useDownloadMessageMedia = ({
  messageId,
  automaticDownload = true,
}) => {
  const isPendingMessage = useSelector(getMessageIsPending(messageId));
  // hint: a message has a clientMessageId even if it is not a pending message (anymore)
  const clientMessageId = useSelector(getClientMessageId(messageId));
  const pendingHasLocalUrl = useSelector(getHasLocalUrl(clientMessageId));
  const pendingAwaitingLoadLocalUrl = useSelector(getAwaitingLoadLocalFileUrl(clientMessageId));
  const messageMediaRelativePath = useSelector(getMessageMediaRelativePath(messageId));
  const messageHasLocalUrl = useSelector(getHasLocalUrl(messageMediaRelativePath));
  const messageAwaitingLoadLocalUrl = useSelector(getAwaitingLoadLocalFileUrl(
    messageMediaRelativePath,
  ));
  const localKey = (pendingHasLocalUrl && clientMessageId)
    || (messageHasLocalUrl && messageMediaRelativePath);

  const isChatConnected = useSelector(getIsChatConnected);
  const messageMediaDownloadLoading = useSelector(getMessageMediaDownloadLoading(
    messageMediaRelativePath,
  ));
  const messageFileDownloadFailed = useSelector(getMessageMediaDownloadFailed(
    messageMediaRelativePath,
  ));

  const loading = !localKey && (
    pendingAwaitingLoadLocalUrl
    || messageAwaitingLoadLocalUrl
    || messageMediaDownloadLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // first step: check if local file from pending message can be found
    if (!localKey && clientMessageId) {
      dispatch(loadLocalFileUrl({ key: clientMessageId }));
    }
  }, [localKey, clientMessageId, dispatch]);

  useEffect(() => {
    // second step: check if local file from message can be found
    if (
      !localKey
      && !isPendingMessage
      // either has no clientMessageId or checked already
      // if there is a local URL from pending message
      && (!clientMessageId || !pendingAwaitingLoadLocalUrl)
    ) {
      dispatch(loadLocalFileUrl({ key: messageMediaRelativePath }));
    }
  }, [
    localKey,
    clientMessageId,
    pendingAwaitingLoadLocalUrl,
    messageMediaRelativePath,
    dispatch,
    isPendingMessage,
  ]);

  useEffect(() => {
    // if neither pendingFile or messageFile is found locally, automatically try to download file
    if (
      !localKey
      && !isPendingMessage
      // make sure we have already checked if there is a local url
      && !messageAwaitingLoadLocalUrl
      && isChatConnected
      // dont automatically try a second time if it has failed already
      && !messageFileDownloadFailed
      && automaticDownload
    ) {
      dispatch(initiateMessageMediaDownload({ messageMediaRelativePath }));
    }
  }, [
    localKey,
    messageAwaitingLoadLocalUrl,
    isChatConnected,
    dispatch,
    messageMediaRelativePath,
    messageFileDownloadFailed,
    automaticDownload,
    isPendingMessage,
  ]);

  const download = useCallback(() => {
    // this function enabled the user to manually try to download the file
    dispatch(initiateMessageMediaDownload({ messageMediaRelativePath, showError: true }));
  }, [dispatch, messageMediaRelativePath]);

  return { loading, localKey, download };
};

export default useDownloadMessageMedia;
