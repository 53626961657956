/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ASYNC_STATE from 'gcs-common/constants/AsyncState';
import { onChatConnected, onOnline } from './initializationThunks';

const asyncState = {
  onOnline: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  onChatConnected: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
};

const initialState = {
  ...asyncState,
};

const initializationSlice = createSlice({
  name: 'initialization',
  initialState,
  reducers: {},
  extraReducers: {
    [onOnline.pending]: (state) => {
      state.onOnline.status = ASYNC_STATE.LOADING;
    },
    [onOnline.fulfilled]: (state) => {
      state.onOnline.status = ASYNC_STATE.SUCCEEDED;
    },
    [onOnline.rejected]: (state, action) => {
      state.onOnline.status = ASYNC_STATE.FAILED;
      state.onOnline.error = action.error;
    },
    [onChatConnected.pending]: (state) => {
      state.onChatConnected.status = ASYNC_STATE.LOADING;
    },
    [onChatConnected.fulfilled]: (state) => {
      state.onChatConnected.status = ASYNC_STATE.SUCCEEDED;
    },
    [onChatConnected.rejected]: (state, action) => {
      state.onChatConnected.status = ASYNC_STATE.FAILED;
      state.onChatConnected.error = action.error;
    },
  },
});

export default initializationSlice.reducer;
