import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDeleteCurrentUserError,
  getDeleteCurrentUserLoading,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { resetDeleteCurrentUserError } from 'gcs-common/slices/currentUser/currentUserSlice';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import InfoDialog from '../../../InfoDialog/InfoDialog';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';


const DeletionConfirmationModel = ({ isOpen, onClose, onDeleteAccountClicked }) => {
  const isDeleting = useSelector(getDeleteCurrentUserLoading);
  const hasDeletionError = useSelector(getDeleteCurrentUserError);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetDeleteCurrentUserError());
  }, [dispatch]);

  return (
    <InfoDialog
      title="HINWEIS"
      noLogo
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.content}>
        {isDeleting ? <LoadingIndicator />
          : (
            <>
              <p>
                Sie sind dabei, Ihren BEEM Account dauerhaft zu löschen.
              </p>
              <p>
                Alle verknüpften Daten werden dabei gelöscht.
              </p>
              <p>
                Sie werden dann ausgeloggt.
              </p>
            </>
          )}
        <div className={styles.confirmationButtons}>
          <Button
            disabled={isDeleting}
            className={styles.buttonError}
            onClick={onDeleteAccountClicked}
          >
            Account löschen
          </Button>
          <Button
            disabled={isDeleting}
            className={styles.button}
            onClick={onClose}
          >
            Abbrechen
          </Button>
        </div>
        {hasDeletionError && (
          <div className={styles.inputError}>
            Beim löschen is ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.
          </div>
        )}
      </div>
    </InfoDialog>
  );
};

DeletionConfirmationModel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteAccountClicked: PropTypes.func.isRequired,
};

export default DeletionConfirmationModel;
