import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import createChannel from 'gcs-common/slices/channels/channelsThunks/createChannel';
import { resetCreateChannelAsyncStatus, resetCreateSalesSpaceAsyncStatus } from 'gcs-common/slices/channels/channelsSlice';
import {
  getCreateChannelIsSuccess,
  getCreateSalesSpaceIsSuccess,
  getCreateSalesSpaceIsLoading,
  getCreateChannelIsLoading,
} from 'gcs-common/slices/channels/channelsSelectors';
import createSalesSpace from 'gcs-common/slices/channels/channelsThunks/createSalesSpace';
import { resetSelectedNewChannelOptions, selectNewChannelOptions } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import { CHANNEL_TYPE } from 'gcs-common/helper/channelTypeHelper';
import { getNewChannelOption } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import { ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { setSideMenuClosed } from '../../../slices/uiState/uiStateSlice';
import ChannelListItemNormalNew from '../../ChannelListItemNormalNew/ChannelListItemNormalNew';

const NewChannelItem = ({ newChannelOptionId, multiSelect = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newChannelOption = useSelector(getNewChannelOption(newChannelOptionId));
  const createChannelIsSuccess = useSelector(getCreateChannelIsSuccess);
  const createSalesSpaceIsSuccess = useSelector(getCreateSalesSpaceIsSuccess);
  const createSalesSpaceIsLoading = useSelector(getCreateSalesSpaceIsLoading);
  const createChanelIsLoading = useSelector(getCreateChannelIsLoading);

  const onChannelSelect = () => {
    const userId = newChannelOption?.userId ? [newChannelOption.userId] : null;
    const assignmentId = newChannelOption?.assignmentId;

    dispatch(selectNewChannelOptions({ newChannelOptionId }));

    if (!multiSelect) {
      if (newChannelOption.type === CHANNEL_TYPE.SALES_SPACE) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !createSalesSpaceIsLoading && dispatch(createSalesSpace(
          { assignmentId, userIds: userId },
        ));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !createChanelIsLoading && dispatch(createChannel({ userIds: userId }));
      }
    }
  };

  useEffect(() => {
    if (createChannelIsSuccess || createSalesSpaceIsSuccess) {
      dispatch(resetSelectedNewChannelOptions());
      dispatch(setSideMenuClosed());
      navigate(ROUTES.ROOT);

      if (createChannelIsSuccess) dispatch(resetCreateChannelAsyncStatus());
      if (createSalesSpaceIsSuccess) dispatch(resetCreateSalesSpaceAsyncStatus());
    }
  }, [createChannelIsSuccess, createSalesSpaceIsSuccess, dispatch, navigate]);

  return (
    <ChannelListItemNormalNew
      multiSelect={multiSelect}
      newChannelOptionId={newChannelOptionId}
      onChannelSelect={onChannelSelect}
    />
  );
};

NewChannelItem.propTypes = {
  newChannelOptionId: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
};

export default NewChannelItem;
