import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import { captureException } from '@sentry/react';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import downloadFile from './downloadFile';
import { getDownloadFileLoading, getInitiateMessageMediaDownloadLoading } from '../mediaSelectors';

const initiateEtsMediaDownload = createAsyncThunk(
  'media/initiateMessageMediaDownload',
  async ({ localKey, attachmentUrl, showError = false }, {
    dispatch,
  }) => {
    try {
      dispatch(downloadFile({ key: localKey, remoteUrl: attachmentUrl }));
    } catch (e) {
      if (showError) {
        dispatch(openErrorDialog({ dialogErrorMsg: getErrorMsg('load_media.default') }));
      }
      if (e.message !== 'Failed to fetch') {
        captureException(e);
      }
      throw e;
    }
  },
  {
    condition({ localKey }, { getState }) {
      return !getInitiateMessageMediaDownloadLoading(localKey)(getState())
        && !getDownloadFileLoading(localKey)(getState());
    },
  },
);

export default initiateEtsMediaDownload;
