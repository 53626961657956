import { useSelector } from 'react-redux';
import { getChannelInputStagedFiles } from 'gcs-common/slices/messageInput/messageInputSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import ReportMessageDialog from '../../BlockOrReportUserDialog/ReportMessageDialog';
import ChatHeaderWrapper from '../../ChatHeaderWrapper/ChatHeaderWrapper';
import ChatScroller from '../../ChatScroller/ChatScroller';
import SalesSpaceChannelActionSheet from '../../SalesSpaceChannelActionSheet/SalesSpaceChannelActionSheet';
import Snackbar from '../../Snackbar/Snackbar';
import styles from './styles.module.scss';
import FilePreviewer from '../../FilePreviewer/FilePreviewer';
import MessageInputArea from '../../MessageInputArea/MessageInputArea';

function ChatIndex() {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const isAgent = useSelector(getIsAgent);
  const isJoinedChannel = useSelector(getCurrentUserIsMemberOfChannel(selectedChannelId));
  const files = useSelector(getChannelInputStagedFiles(selectedChannelId));
  const shouldHideInput = !isJoinedChannel && isAgent;
  const showFilePreviewer = files.length !== 0;

  return (
    <div className={styles.main}>
      {showFilePreviewer ? (
        <FilePreviewer multiple />
      ) : (
        <>
          <ChatHeaderWrapper />
          <ChatScroller />
        </>
      )}

      {!shouldHideInput && (
        <>
          <div className={styles.stripe} />
          <MessageInputArea />
        </>
      )}

      <SalesSpaceChannelActionSheet />
      <ReportMessageDialog />
      <Snackbar />
    </div>
  );
}

export default ChatIndex;
