import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../client';
import { RequestBody } from '../../../../../types/typeHelper';
import { openErrorDialog } from '../../../../slices/errorDialogSlice/errorDialogSlice';
import { CurrentUserQueryKeys } from '../current-user/current-user-api';

export function useRequestLoginMutation() {
  const client = useApiClient();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ body }: { body: RequestBody<'/login', 'post'> }) => {
      await client.POST('/login', { body });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        { queryKey: [CurrentUserQueryKeys.GET_CURRENT_USER_IS_LOGGED_IN] },
      );
    },
    onError: async (error) => {
      dispatch(openErrorDialog({ dialogErrorMsg: error.message }));
    },
  });
}
