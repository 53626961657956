import { createSelector } from '@reduxjs/toolkit';
import { calculateChannelTypeForChannelFilter } from '../../helper/channelTypeHelper';
import { getCurrentUserId } from '../currentUser/currentUserSelectors';
import { getChannelListChannelIds, getChannels } from './channelsSelectors';
import { calculateAggregateCount,
  calculateUnreadCountForChannel } from '../../helper/messageHelpers';
import {
  getChannelMembers, getMembers, getMyMember,
} from '../members/membersSelectors';
import { getSelectedHouseId } from '../houses/housesSelectors';
import { filterByPrimaryHouse } from '../channelList/channelFilter';
// eslint-disable-next-line import/no-cycle
import { getOwnAndFreeChannelIds } from '../channelList/channelListSelectors';
import { getChannel } from '../channel/channelSelectors';
import { calculateUnreadCount } from '../../helper/channelHelpers';


export const getUnreadMessageCountForChannel = (channelId) => createSelector(
  getChannel(channelId),
  getMyMember(channelId),
  (
    channel,
    myMember,
  ) => {
    return calculateUnreadCount({
      channel,
      member: myMember,
    });
  },
);

/**
 * The unread count for all channels
 */
export const getTotalUnreadCount = createSelector(
  getOwnAndFreeChannelIds,
  getChannels,
  getChannelMembers,
  getCurrentUserId,
  getMembers,
  (channelIds, channels, channelMembers, currentUserId, members) => {
    return calculateAggregateCount(
      channelIds,
      (channelId) => calculateUnreadCountForChannel(
        channels, channelMembers, members, currentUserId, channelId,
      ),
    );
  },
);

/**
 * The unread count by channelFilterType (Teamchats or Direct Chats)
 */
export const getHasMessagesUnreadForChannelFilter = (channelFilterType) => createSelector(
  getOwnAndFreeChannelIds,
  getChannelMembers,
  getCurrentUserId,
  getMembers,
  getChannels,
  (channelIds, channelMembers, currentUserId, members, channels) => {
    const channelTypes = calculateChannelTypeForChannelFilter(channelFilterType);
    const channelIdsForType = channelIds.filter(
      (channelId) => {
        return channelTypes.includes(channels[channelId]?.channelType);
      },
    );
    const numUnread = calculateAggregateCount(
      channelIdsForType,
      (channelId) => calculateUnreadCountForChannel(
        channels, channelMembers, members, currentUserId, channelId,
      ),
    );

    return numUnread && numUnread > 0;
  },
);

export const getTotalHouseUnreadCount = houseId => createSelector(
  getChannelListChannelIds,
  getChannels,
  getChannelMembers,
  getCurrentUserId,
  getMembers,
  (channelIds, channels, channelMembers, currentUserId, members) => {
    const channelIdsForHouse = channelIds
      ?.filter(filterByPrimaryHouse({ channels, houseId }));

    return calculateAggregateCount(
      channelIdsForHouse,
      (channelId) => calculateUnreadCountForChannel(
        channels, channelMembers, members, currentUserId, channelId,
      ),
    );
  },
);

export const getTotalUnreadCountFromUnselectedHouse = createSelector(
  getChannelListChannelIds,
  getSelectedHouseId,
  getChannels,
  getChannelMembers,
  getCurrentUserId,
  getMembers,
  (channelIds, selectedHouseId, channels, channelMembers, currentUserId, members) => {
    const channelIdsInUnselectedHouses = channelIds
      ?.filter(filterByPrimaryHouse({ channels, houseId: selectedHouseId, exclude: true }));

    return calculateAggregateCount(
      channelIdsInUnselectedHouses,
      (channelId) => calculateUnreadCountForChannel(
        channels, channelMembers, members, currentUserId, channelId,
      ),
    );
  },
);
