import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMessageIndex } from 'gcs-common/slices/messages/messageSelector';
import { getChannelShouldShowReadReceipt } from 'gcs-common/slices/channel/channelSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import NewMessageIndicator from '../NewMessageIndicator/NewMessageIndicator';
import MessageDateTag from '../MessageDateTag/MessageDateTag';
import MessageSelectorContainer from '../MessageSelectorContainer/MessageSelectorContainer';
import Message from '../Message/Message';
import TypingIndicator from '../TypingIndicator/TypingIndicator';
import ReadNotification from '../ReadNotification/ReadNotification';
import { useCurrentlyRenderedVirtuosoData } from '../../hooks/useCurrentlyRenderedVirtuosoData';
import { MessageVisibilityObserver } from '../MessageVisibilityObserver/MessageVisibilityObserver';

const MessageWrapper = ({ messageId }) => {
  const messageIndex = useSelector(getMessageIndex(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);

  const shouldShowReadReceipt = useSelector(getChannelShouldShowReadReceipt(
    selectedChannelId,
  ));

  const message = useCurrentlyRenderedVirtuosoData({ messageId });

  return (
    <div data-message-id={messageId} data-message-index={messageIndex}>
      <MessageVisibilityObserver>
        <MessageDateTag messageId={messageId} />
        <MessageSelectorContainer messageId={messageId}>
          <Message messageId={messageId} />
        </MessageSelectorContainer>
        {shouldShowReadReceipt && <ReadNotification messageId={messageId} />}
        <NewMessageIndicator messageId={messageId} />
        { message?.isTyping && <TypingIndicator /> }
      </MessageVisibilityObserver>
    </div>
  );
};

MessageWrapper.propTypes = {
  messageId: PropTypes.string.isRequired,
};
export default memo(MessageWrapper);
