import {
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import {
  getLoadPreviousMessagesIsLoading,
  getLoadPreviousMessagesError,
} from 'gcs-common/slices/messages/messagesSelectors';
import { getCurrentUserId, getIsAgent, getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getSearchScrollMessageLoading,
  getSearchScrollMessageError,
} from 'gcs-common/slices/chat/chatSelectors';
import resetChatErrors from 'gcs-common/slices/chat/chatThunks/resetChatErrors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { fetchCraftsmanConnections } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import joinSalesSpace from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsThunks/joinSalesSpace';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import AgentAvailabilityBanner from '../AgentAvailabilityBanner/AgentAvailabilityBanner';
import OpMaintenanceBanner from '../OpMaintenanceBanner/OpMaintenanceBanner';
import styles from './styles.module.scss';

import ChatConnectionBanner from '../ChatConnectionBanner/ChatConnectionBanner';
import VirtualMessageScroller from '../VirtualMessageScroller/VirtualMessageScroller';
import ChannelsLoadingBanner from '../ChatLoadingBanner/ChannelsLoadingBanner';

const ChatScroller = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const isAgent = useSelector(getIsAgent);
  const searchScrollMessage = useSelector(getSearchScrollMessageLoading);
  const searchScrollMessageError = useSelector(getSearchScrollMessageError);
  const prevMessageLoadingError = useSelector(getLoadPreviousMessagesError);
  const loadingPreviousMessages = useSelector(getLoadPreviousMessagesIsLoading);
  const isJoinedChannel = useSelector(getCurrentUserIsMemberOfChannel(selectedChannelId));
  const isOnline = useSelector(getIsOnline);
  const currentUserId = useSelector(getCurrentUserId);
  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));

  const shouldHideInput = !isJoinedChannel && isAgent;

  const dispatch = useDispatch();

  const onJoinSalesSpace = () => {
    dispatch(joinSalesSpace({ channelId: selectedChannelId, currentUserId }));
  };

  useEffect(() => {
    if (isOnline && !isAgent) {
      dispatch(fetchCraftsmanConnections());
    }
  }, [isOnline, dispatch, isAgent]);

  useEffect(() => {
    // reset errors when switching channel
    dispatch(resetChatErrors());
  }, [dispatch, selectedChannelId]);

  if (!selectedChannelId) {
    return (
      <div className={styles.chat}>
        <div className={styles.chatArea}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.chat} data-testid="chat-scroller">
      {!isAgent && <AgentAvailabilityBanner />}
      <ChatConnectionBanner />
      <ChannelsLoadingBanner />
      {isOpMaintenanceEnabled && <OpMaintenanceBanner />}
      <div className={styles.chatArea}>
        <div className={styles.headerFixed}>
          {searchScrollMessage && (
            <div className={styles.infoMsg} key={2}>
              <Spinner size={ICON_SIZE.XX_SMALL} />
              &nbsp;Suche in früheren Nachrichten...
            </div>
          )}
          {searchScrollMessageError && (
            <div className={styles.errorMsg} key={1}>
              <IconComponent Icon={ICON.CLOSE} size={ICON_SIZE.X_SMALL} color={ICON_COLOR.WHITE} />
              &nbsp;
              {searchScrollMessageError}
            </div>
          )}

          {prevMessageLoadingError && (
            <div className={styles.errorMsg} key={1}>
              <IconComponent Icon={ICON.CLOSE} size={ICON_SIZE.X_SMALL} color={ICON_COLOR.WHITE} />
              &nbsp;Laden von früheren Nachrichten fehlgeschlagen
            </div>
          )}
          {loadingPreviousMessages && (
            <div className={styles.infoMsg} key={0}>
              <Spinner size={ICON_SIZE.XX_SMALL} />
              &nbsp;Lade frühere Nachrichten...
            </div>
          )}
        </div>
        <VirtualMessageScroller key={selectedChannelId} />
        {shouldHideInput && (
          <>
            <div className={styles.dimmer} />
            <div
              className={styles.joinButton}
              tabIndex="0"
              role="button"
              onClick={onJoinSalesSpace}
            >
              Chat beitreten
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ChatScroller.propTypes = {};

export default ChatScroller;
