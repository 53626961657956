import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getVideoDimensions } from 'gcs-common/slices/messages/messageSelector';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import styles from './styles.module.scss';
import { TestingDataDisplay } from '../../helper/testHelper';

const DEFAULT_RATIO = 7 / 5;

const VideoMessageFrame = ({
  messageId, maxHeight = 300, defaultRatio = DEFAULT_RATIO, children, ...props
}) => {
  const { width: maxWidth = maxHeight / defaultRatio, ref } = useResizeDetector();
  const videoDimensions = useSelector(getVideoDimensions(messageId));
  const containerMeasures = useMemo(() => {
    let { width, height } = videoDimensions || {};
    const ratio = videoDimensions ? (width / height) : defaultRatio;
    if (!videoDimensions) {
      height = maxHeight;
      width = height * ratio;
    }
    const isPortrait = maxHeight * ratio > maxWidth;
    if (isPortrait) {
      return {
        width: maxWidth,
        height: maxWidth / ratio,
      };
    }
    if (width < maxWidth) {
      return {
        width,
        height,
      };
    }
    return {
      width: maxHeight * ratio,
      height: maxHeight,
    };
  }, [videoDimensions, maxHeight, maxWidth, defaultRatio]);

  return (
    <div ref={ref} style={{ width: '100%' }} {...props}>
      <div ref={ref} style={containerMeasures} className={styles.frame}>
        {children}
      </div>
      <TestingDataDisplay text={containerMeasures?.width} />
    </div>
  );
};

VideoMessageFrame.propTypes = {
  messageId: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  defaultRatio: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default VideoMessageFrame;
