import QuickOrdering from 'gcs-common/components/QuickOrdering/QuickOrdering';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router';
import styles from './styles.module.scss';

const QuickOrderingPage = () => {

  const location = useLocation();
  const quickOrderAnimationIn = location.pathname.includes('quickorder');

  return (
    <div className={styles.page}>
      <CSSTransition
        in={quickOrderAnimationIn}
        classNames={{
          enter: styles.quickorderTransitionEnter,
          enterActive: styles.quickorderTransitionEnterActive,
          exit: styles.quickorderTransitionExit,
          exitActive: styles.quickorderTransitionExitActive,
        }}
        timeout={200}
        unmountOnExit
      >
        <QuickOrdering />

      </CSSTransition>
    </div>
  );
};

export default QuickOrderingPage;
