import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionSheet from 'actionsheet-react';
import { getJoinedHousesIds } from 'gcs-common/slices/houses/housesSelectors';
import HouseSwitcherItem from '../HouseSwitcherItem/HouseSwitcherItem';
import styles from './styles.module.scss';
import { setHouseSwitcherActionSheetClose } from '../../slices/uiState/uiStateSlice';
import { getIsHouseSwitcherActionSheetOpen } from '../../slices/uiState/uiStateSelectors';

const HouseSwitcherActionSheet = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const isActionSheetOpen = useSelector(getIsHouseSwitcherActionSheetOpen);
  const joinedHousesIds = useSelector(getJoinedHousesIds);

  const handleClose = () => {
    dispatch(setHouseSwitcherActionSheetClose());
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ref.current?.close();
  };

  useEffect(() => {
    if (isActionSheetOpen) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ref.current?.open();
    }
  }, [dispatch, isActionSheetOpen]);

  return (
    <ActionSheet
      bgStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        transition: 'opacity 150ms ease-in-out 0s, z-index 150ms ease-in-out 0s', // Override default transition
        width: '100vw',
      }}
      closeOnBgTap
      mouseEnable
      onClose={handleClose}
      ref={ref}
      sheetStyle={{
        backgroundColor: '#f1f1f1',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        maxHeight: '50vh',
        overflow: 'hidden',
        width: '100vw',
      }}
      touchEnable
    >
      <div className={styles.actionSheetWrapper}>
        <div className={styles.actionSheetBar} />
        <div className={styles.actionSheetTitle}>
          <span>Haus Wechseln</span>
        </div>
        <div className={styles.actionSheetContent}>
          {joinedHousesIds?.map((houseId) => (
            <HouseSwitcherItem
              handleClose={handleClose}
              houseId={houseId}
              key={houseId}
            />
          ))}
        </div>
      </div>
    </ActionSheet>
  );
};

export default HouseSwitcherActionSheet;
