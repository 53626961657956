import { useCallback, useEffect } from 'react';
import {
  getForwardSearchInput,
} from 'gcs-common/slices/channelList/channelListSelectors';
import {
  setForwardSearchInput,
} from 'gcs-common/slices/channelList/channelListSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

export const ForwardSearchInput = () => {
  const dispatch = useDispatch();
  const forwardSearchInput = useSelector(getForwardSearchInput);
  useEffect(() => {
    return () => {
      dispatch(setForwardSearchInput({ forwardSearchInput: '' }));
    };
  }, [dispatch]);

  const onSearchInputChange = useCallback((event) => {
    dispatch(setForwardSearchInput({ forwardSearchInput: event.target.value }));
  }, [dispatch]);

  return (
    <div className={styles.search}>
      <input
        className={styles.searchInput}
        placeholder="Kontakte durchsuchen"
        type="search"
        value={forwardSearchInput}
        onChange={onSearchInputChange}
      />
    </div>
  );
};
