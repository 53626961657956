import { useCallback, useEffect, useState } from 'react';
import { getCurrentFriendlyName, getCurrentUserCompany, getIsUpdateProfilePicturePending } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { updateCurrentUser } from 'gcs-common/slices/currentUser/currentUserThunks/updateCurrentUser';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import ProfilePictureDisplay from '../../ProfilePicture/ProfilePictureDisplay/ProfilePictureDisplay';
import ProfilePictureOverlay from '../../ProfilePicture/ProfilePictureOverlay/ProfilePictureOverlay';
import SettingsInput from '../SettingsInput/SettingsInput';

const Profile = () => {
  const currentFriendlyName = useSelector(getCurrentFriendlyName);
  const currentCompany = useSelector(getCurrentUserCompany);
  const isChatConnected = useSelector(getIsChatConnected);
  const isUpdatingProfilePicture = useSelector(getIsUpdateProfilePicturePending);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userCompany, setUserCompany] = useState(currentCompany || '');
  const [userName, setUserName] = useState(currentFriendlyName || '');
  const [openOverlay, setOpenOverlay] = useState(false);

  useEffect(() => {
    setUserCompany(currentCompany || '');
  }, [currentCompany]);
  useEffect(() => {
    setUserName(currentFriendlyName || '');
  }, [currentFriendlyName]);

  const onGoBack = () => {
    navigate(-1);
  };

  const updateRemoteCompany = useCallback(async (newCompany) => {
    if (newCompany && newCompany.length > 0) {
      dispatch(updateCurrentUser({ values: { user_company: newCompany } }));
    }
  }, [dispatch]);

  const updateRemoteName = useCallback(async (newName) => {
    if (newName && newName.length > 0) {
      dispatch(updateCurrentUser({ values: { user_name: newName } }));
    }
  }, [dispatch]);

  const toggleOverlay = () => {
    setOpenOverlay(!openOverlay);
  };

  return (
    <div className={styles.profileContainer}>
      {openOverlay && (
        <ProfilePictureOverlay
          toggleOverlay={toggleOverlay}
          disabled={!isChatConnected}
          isUpdatingProfilePicture={isUpdatingProfilePicture}
        />
      )}
      <div className={styles.profileHeader}>
        <button type="button" onClick={onGoBack} className={styles.profileBack}>
          <IconComponent
            Icon={ICON.ARROW}
            transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
            alt="Zurück"
            style={{ position: 'absolute', left: '20px' }}
          />
          <div className={styles.headline}>Profil</div>
        </button>
      </div>

      <div className={styles.profileBody}>
        <ProfilePictureDisplay
          disabled={!isChatConnected}
          toggleOverlay={toggleOverlay}
          placeholderWrapperClassName={styles.imgPlaceholder}
          placeholder={<div className={styles.imgPlaceholderText}>+Bild</div>}
          isUpdatingProfilePicture={isUpdatingProfilePicture}
        />
        <SettingsInput
          disabled={!isChatConnected}
          initialValue={userName}
          onSave={updateRemoteName}
          label="Dein Name"
        />
        <SettingsInput
          disabled={!isChatConnected}
          initialValue={userCompany}
          onSave={updateRemoteCompany}
          label="Deine Firma"
        />
      </div>
      <div className={styles.appVersion}>
        {`App-Version: ${import.meta.env.VITE_BEEM_VERSION_NUMBER} - ${import.meta.env.VITE_BEEM_COMMIT_HASH || 'unbekannt'}`}
      </div>
    </div>
  );
};

export default Profile;
