import { useSelector } from 'react-redux';
import { getUniqueProcessNumber, getUniqueProductNumber } from '../../../../helper/processTextHelper';
import { Parameters, Response } from '../../../../../types/typeHelper';
import { useGetProcessDetails, useGetProcesses } from './processes-api';
import { getChannelOPUserId, getPrimaryHouseForChannel } from '../../../../slices/channel/channelSelectors';
import { getSelectedChannelId } from '../../../../slices/channels/channelsSelectors';
import { getFilter, getSelectedProcessNumber, getSelectedProductNumber } from '../../../../slices/processes/processesSelectors';
import { getIsAgent } from '../../../../slices/currentUser/currentUserSelectors';

export const useGetProcessProductsList = (query: Parameters<'/chat/process-details', 'get'>['query']) => {
  return useGetProcessDetails<Response<'/chat/process-details', 'get'>['items'] | undefined>({
    query,
    select: (data) => (data ? data.items : undefined),
  });
};

export const getSelectedProcess = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelOpUserId: string = useSelector(getChannelOPUserId(selectedChannelId));
  const filter = useSelector(getFilter);
  const { name: queryString, type: queryType } = filter || {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getPrimaryHouseForChannel(selectedChannelId));

  const processNumber = useSelector(getSelectedProcessNumber);

  const { data } = useGetProcesses({
    query: {
      query_type: queryType,
      query_string: queryString,
      user_id: isAgent ? channelOpUserId : undefined,
      house_id: houseId,
    },
  });
  // This checks are added after the hook as it will break React when it detects
  // different number of hooks
  if (!processNumber) return undefined;
  if (!data) return undefined;

  return data.find(({ ProcessNumber,
    ProcessSuffix }) => processNumber === getUniqueProcessNumber(ProcessNumber, ProcessSuffix));
};

export const getSelectedProduct = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getPrimaryHouseForChannel(selectedChannelId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelOpUserId: string = useSelector(getChannelOPUserId(selectedChannelId));
  const selectedProcess = getSelectedProcess();
  const selectedProductNumber = useSelector(getSelectedProductNumber);

  const { data: products } = useGetProcessProductsList({
    house_id: houseId,
    process_number: selectedProcess ? selectedProcess.ProcessNumber : '',
    suffix: selectedProcess ? selectedProcess.ProcessSuffix : '',
    user_id: isAgent ? channelOpUserId : undefined,
  });

  if (!selectedProcess) return undefined;
  if (!products) return undefined;

  return products.find(({
    ArticleNumber, Id,
  }) => getUniqueProductNumber(ArticleNumber, Id) === selectedProductNumber);
};
