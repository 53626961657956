import { useEffect } from 'react';
import useRoundImageChecker from 'gcs-common/hooks/useRoundImageChecker';
import PropTypes from 'prop-types';
import { ONLINE_STATUS } from 'gcs-common/helper/userStatusHelper';
import avatarPlaceholder from 'gcs-common/img/user.svg';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import UserStatusPropType from '../../models/UserStatusPropType';
import styles from './styles.module.scss';
import PersistedImage from '../PersistedImage/PersistedImage';

const basicStatusStyle = {
  ONLINE: styles.aktivStatusIndicator,
  IDLE: styles.inkurzeVerfügbarStatusIndicator,
  OFFLINE: styles.inaktivStatusIndicator,
  UNKNOWN: styles.unknownStatusIndicator,
};

const getStatusColor = ({
  type, isSalesSpace, isAgent, channelMemberCount,
}) => {
  if (!isAgent && isSalesSpace) return styles.noStatusIndicator;
  if (!isSalesSpace && channelMemberCount > 2) return styles.noStatusIndicator;
  return basicStatusStyle[type];
};

const getImageStyle = (isSalesSpace, isAgent) => {
  if (isSalesSpace && !isAgent) return styles.salesSpaceAgentWrapper;
  return styles.normalImageWrapper;
};

const ImageWithStatus = ({
  isSalesSpace = false,
  channelMemberCount = 0,
  imageUrl = null,
  userStatus = { type: ONLINE_STATUS.UNKNOWN },
  className = '',
  isSmall = false,
  isAgent = false,
  hasStar = false,
}) => {
  const { type } = userStatus;
  const imageStatusStyle = getImageStyle(isSalesSpace, isAgent);
  const imageStatusColor = getStatusColor({
    type, isSalesSpace, isAgent, channelMemberCount,
  });
  const [isRound, setImageUrl] = useRoundImageChecker(imageUrl);

  useEffect(() => {
    setImageUrl(imageUrl);
  }, [imageUrl, setImageUrl]);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={`${styles.statusImageWrapper} ${imageStatusStyle} ${imageStatusColor} ${isSmall ? styles.small : ''} ${isRound ? styles.isRound : ''}`}>
        {imageUrl
          ? (
            <PersistedImage
              remoteUrl={imageUrl}
              className={styles.image}
              alt="Profilbild"
            />
          )
          : (
            <img
              className={styles.image}
              src={avatarPlaceholder}
              alt="Profilbild"
            />
          )
      }
      </div>

      { channelMemberCount > 2 && !isSalesSpace
        && (
          <div className={styles.channelMemberCountBadge}>
            {channelMemberCount}
          </div>
        )
      }

      {
        hasStar && (
          <IconComponent
            Icon={ICON.STAR_FILLED}
            color={ICON_COLOR.PRIMARY}
            size={ICON_SIZE.SMALL}
            style={{
              position: 'absolute',
              right: '-4px',
              top: '-4px',
            }}
            alt="Star icon"
          />
        )
      }

    </div>
  );
};

ImageWithStatus.propTypes = {
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  userStatus: UserStatusPropType,
  isSalesSpace: PropTypes.bool,
  channelMemberCount: PropTypes.number,
  isAgent: PropTypes.bool,
  hasStar: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default ImageWithStatus;
