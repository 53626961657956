import localforage from 'localforage';
import { captureException } from '@sentry/react';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';
import blobStorageClient from '../blobStorageClient';

const removePreReduxDataMigration = async (state) => {
  try {
    await localforage.setDriver([localforage.WEBSQL, localforage.INDEXEDDB]);
    await localforage.clear(() => { });
  } catch (e) {
    captureException(e);
  }
  try {
    if (IS_NATIVE) {
      await new Promise((res, rej) => {
        window.sqlitePlugin.openDatabase({
          name: 'GCS.db',
          location: 'default',
          androidDatabaseProvider: 'system',
        }, res, rej);
      });
      await new Promise((res, rej) => {
        window.sqlitePlugin.deleteDatabase({
          name: 'GCS.db',
          location: 'default',
          androidDatabaseProvider: 'system',
        }, res, rej);
      });
    }
  } catch (e) {
    captureException(e);
  }
  try {
    await blobStorageClient.clear();
  } catch (e) {
    captureException(e);
  }
  return state;
};

export default removePreReduxDataMigration;
