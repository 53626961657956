import {
  ICON,
  ICON_SIZE,
  ICON_COLOR,
} from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';


interface StarRatingProps {
  ratingValue: number;
  setRatingValue?: (value: number) => void;
  isEditable?: boolean;
}

const StarRating = (props: StarRatingProps) => {
  const { ratingValue, isEditable = false, setRatingValue = () => {} } = props;
  const rates = [
    {
      key: 0, value: 1,
    },
    {
      key: 1, value: 2,
    },
    {
      key: 2, value: 3,
    },
    {
      key: 3, value: 4,
    },
    {
      key: 4, value: 5,
    },
  ];


  return (
    <div className="flex w-full justify-between pb-3">
      {rates.map(selectedRating => (
        <div
          role="button"
          tabIndex={0}
          key={`${selectedRating.key}-rating`}
          onClick={() => setRatingValue(selectedRating.value)}
          className={isEditable ? 'cursor-pointer' : ''}
        >
          <IconComponent
            key={selectedRating.key}
            Icon={selectedRating.value <= ratingValue ? ICON.STAR_FILLED : ICON.STAR}
            color={selectedRating.value <= ratingValue ? ICON_COLOR.YELLOW : ICON_COLOR.LIGHT_GREY}
            size={ICON_SIZE.XX_LARGE}
          />
        </div>
      ))}
    </div>
  );

};


export default StarRating;
