import {
  getMessageMediaRelativePath,
  getClientMessageId,
} from 'gcs-common/slices/messages/messageSelector';

export const getLocalMediaKey = async (messageId, { getState, blobStorageClient }) => {
  /*
    Determines the key of a media from a messageMediaRelativePath and downlaods it if necessary
   */

  const clientMessageId = getClientMessageId(messageId)(getState());
  const messageMediaRelativePath = getMessageMediaRelativePath(messageId)(getState());

  const pendingExists = await blobStorageClient.objectExists(clientMessageId);
  if (pendingExists) {
    return clientMessageId;
  }
  const mediaExists = await blobStorageClient.objectExists(messageMediaRelativePath);
  if (mediaExists) {
    return messageMediaRelativePath;
  }
  const remoteUrl = `${import.meta.env.VITE_MEDIA_BASE_URL}/${messageMediaRelativePath}`;
  await blobStorageClient.saveRemoteObject(messageMediaRelativePath, remoteUrl);
  return messageMediaRelativePath;

};
