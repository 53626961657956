import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import shareImage from '../../../slices/media/mediaThunks/shareImage';

const GalleryShareButton = ({ messageId }) => {

  const dispatch = useDispatch();

  const onShare = useCallback(async () => {
    await dispatch(shareImage({ messageId }));
  }, [dispatch, messageId]);

  return (
    <button
      type="button"
      onClick={onShare}
      className={styles.shareButton}
    >
      <IconComponent Icon={ICON.SHARE} color={ICON_COLOR.WHITE} size={ICON_SIZE.LARGE} />
    </button>
  );
};

GalleryShareButton.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default GalleryShareButton;
