import { useCallback } from 'react';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getIsHidden } from 'gcs-common/slices/channel/channelSelectors';
import { getToggleChannelVisibilityIsLoading } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { toggleChannelVisibility } from 'gcs-common/slices/channels/channelsThunks/toggleChannelVisibility';
import fetchCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUser';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const ChannelArchiveItem = ({ onClick, channelId }) => {
  const isHidden = useSelector(getIsHidden(channelId));
  const dispatch = useDispatch();
  const isChannelArchiving = useSelector(getToggleChannelVisibilityIsLoading);

  const handleClick = useCallback(() => {
    if (!isChannelArchiving) {
      if (!isHidden) {
        dispatch(trackGALegacyEvent(LEGACY_EVENTS.HIDE_CHANNEL()));
      }

      dispatch(toggleChannelVisibility({ channelId }));
      dispatch(fetchCurrentUser());

      onClick();
    }
  }, [isHidden, isChannelArchiving, dispatch, onClick, channelId]);

  const text = isHidden ? 'Chat wiederherstellen' : 'Chat archivieren';

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        className={styles.actionSheetItem}
        onClick={handleClick}
      >
        <div className={styles.left}>
          <IconComponent
            Icon={isHidden ? ICON.UNARCHIVE : ICON.ARCHIVE}
            color={ICON_COLOR.LIGHT_GREY}
            alt={text}
          />
        </div>
        <span className={styles.actionSheetItemText}>
          {text}
        </span>
      </div>
    </>
  );
};

ChannelArchiveItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  channelId: PropTypes.string.isRequired,
};

export default ChannelArchiveItem;
