import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import activeIcon from 'gcs-common/img/circle_ticked_green.svg';
import unreachableIcon from 'gcs-common/img/status_unreachable.svg';
import inactiveIcon from 'gcs-common/img/status_disconnected.svg';
import OP_CONNECTION_STATUS from 'gcs-common/constants/OpConnectionStatus';
import { getCraftsmanConnectionStatus, getOpConnectionStatusText } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import styles from './styles.module.scss';

const OpConnectionStatus = ({ connectionId, withText = false, width = '25px', height = '25px' }) => {
  const opConnectionStatus = useSelector(getCraftsmanConnectionStatus(connectionId));
  const statusText = useSelector(getOpConnectionStatusText(connectionId));

  const icon = useMemo(() => {
    switch (opConnectionStatus) {
      case OP_CONNECTION_STATUS.CONNECTED: {
        return (<img src={activeIcon} alt="Aktiv" />);
      }
      case OP_CONNECTION_STATUS.DISCONNECTED: {
        return (<img src={inactiveIcon} alt="Inaktiv" />);
      }
      case OP_CONNECTION_STATUS.UNREACHABLE: {
        return (<img src={unreachableIcon} alt="Inaktiv" />);
      }
      default: {
        return (<img src={inactiveIcon} alt="Inaktiv" />);
      }
    }
  }, [opConnectionStatus]);

  return (
    <div className={styles.container} style={{ width, height }}>
      {icon}
      {withText && <div className={styles.text}>{statusText}</div>}
    </div>
  );
};

export default OpConnectionStatus;

OpConnectionStatus.propTypes = {
  withText: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  connectionId: PropTypes.string.isRequired,
};
