import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getCurrentUserIsMemberOfChannel,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import {
  getMemberAgentIdsForChannel,
  getSalesSpaceMostAvailableAgent,
} from 'gcs-common/slices/channel/channelSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { getSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import styles from './styles.module.scss';
import typingIcon from '../../img/icons/edit_even_lighter.svg';
import checkIcon from '../../img/icons/check_white.svg';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import UnreadCountDisplay from '../Chat/UnreadCountDisplay';
import Highlight from '../Highlight/Highlight';

const getChannelStyle = ({ isSelectedChannel, isAgent, isChannelForFileShare }) => {

  let selectedStyle = isAgent
    ? styles.unselectedAgentChannelListItem
    : styles.unselectedCraftsmanChannelListItem;

  if (isSelectedChannel && !isChannelForFileShare) {
    selectedStyle = isAgent
      ? styles.selectedAgentChannelListItem
      : styles.selectedCraftsmanChannelListItem;
  }

  return selectedStyle;
};

const ChannelListItemNormal = ({
  isSelectedChannel,
  unreadCount,
  otherUserTyping = false,
  channelImage = null,
  channelStatus,
  channelTitle,
  channelSubtitle,
  onChannelSelect = () => {},
  multiSelect = false,
  isNewChannelOption = false,
  isSalesSpace = false,
  channelMemberCount = 0,
  channelId,
  isHidden = false,
}) => {
  const isAgent = useSelector(getIsAgent);
  const sharedFiles = useSelector(getSharedFiles);
  const isChannelForFileShare = sharedFiles.length;
  const selectedChannelStyle = getChannelStyle({ isSelectedChannel,
    isAgent,
    isChannelForFileShare });

  const channelStyle = isNewChannelOption ? styles.newChannel : '';

  const groupChannelSelectedStyle = multiSelect && isSelectedChannel ? styles.selectedGroupChannelListItem : '';

  const agentIds = useSelector(getMemberAgentIdsForChannel(channelId));
  const isMember = useSelector(getCurrentUserIsMemberOfChannel(channelId));
  const mostAvailableAgent = useSelector(getSalesSpaceMostAvailableAgent(channelId));
  const selectedChannelId = useSelector(getSelectedChannelId);

  const isSelected = channelId === selectedChannelId;
  const otherAgentsCount = agentIds.length - (isMember ? 1 : 0);
  const searchInput = useSelector(getSearchInput);

  return (
    <button
      type="button"
      onClick={onChannelSelect}
      className={`${styles.craftsmanChannelListItem} ${selectedChannelStyle} ${channelStyle} ${groupChannelSelectedStyle}`.trim()}
    >
      <div className={styles.channelInfo}>
        {!otherUserTyping
          && (
            <ImageWithStatus
              className={styles.imageWrapper}
              imageUrl={channelImage}
              userStatus={channelStatus}
              isSalesSpace={isSalesSpace}
              channelMemberCount={channelMemberCount}
              isAgent={isAgent}
            />
          )}

        {otherUserTyping
          && (
            <img className={styles.userTyping} alt="user-typing" src={typingIcon} />
          )}

        <div className={styles.content}>
          <div className={styles.customerTitle}>
            <div className={styles.customerName}>
              <Highlight search={searchInput}>{channelTitle || 'Unbekannt'}</Highlight>
            </div>
          </div>

          <div className={styles.customerCompany}>
            <Highlight search={searchInput}>{channelSubtitle || 'Unbekannt'}</Highlight>
          </div>

          {isHidden && (
            <div className={styles.archivedIndicator}>
              Archiviert
            </div>
          )}
        </div>

        <div className={styles.imageStatusAndUnreadMsgIndicatorContainer}>
          {unreadCount > 0 && (
            <div className={styles.unreadMsgIndicator}>
              <UnreadCountDisplay unreadCount={unreadCount} />
            </div>
          )}
          <div className={styles.imageStatusAndButtonContainer}>
            {isAgent && isSalesSpace && otherAgentsCount > 0
            && (
              <>
                <div>
                  <ImageWithStatus
                    isSmall
                    imageUrl={mostAvailableAgent?.imageUrl}
                    userStatus={mostAvailableAgent?.status}
                  />
                </div>

                { otherAgentsCount > 1
              && (
              <div className={`${styles.agentCountIndicator} ${isSelected ? styles.white : ''}`}>
                +
                {otherAgentsCount - 1}
              </div>
              )}
              </>
            )
            }
          </div>
        </div>
      </div>

      {multiSelect && (
        <div className={styles.checkIconButton}>
          {isSelectedChannel && <img alt="icon-check" className={styles.checkIcon} src={checkIcon} />}
        </div>
      )}
    </button>
  );
};

ChannelListItemNormal.propTypes = {
  isSelectedChannel: PropTypes.bool.isRequired,
  unreadCount: PropTypes.number.isRequired,
  otherUserTyping: PropTypes.bool,
  channelImage: PropTypes.string,
  channelStatus: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
  channelTitle: PropTypes.string.isRequired,
  channelSubtitle: PropTypes.string.isRequired,
  onChannelSelect: PropTypes.func,
  multiSelect: PropTypes.bool,
  isNewChannelOption: PropTypes.bool,
  isSalesSpace: PropTypes.bool,
  channelMemberCount: PropTypes.number,
  channelId: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
};


export default ChannelListItemNormal;
