import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import { getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { THEME } from 'gcs-common/constants/themes';
import Anchor from 'gcs-common/design-system/ui/anchor';
import { Form } from 'gcs-common/design-system/ui/form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode } from 'react';
import { useRequestUserCodeMutation } from 'gcs-common/clients/api/entities/user-code/user-code-api';
import { useQueryParams } from 'gcs-common/design-system/hooks/use-query-params';
import { CODE_ROUTES } from 'gcs-common/constants/routes';

const FormSchema = z.object({
  email: z.string().email({ message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.' }).optional().or(z.literal('')),
  phone: z.string().min(10, { message: 'Bitte geben Sie eine gültige Telefonnummer ein.' }).optional().or(z.literal('')),
}).superRefine((data, ctx) => {
  if (!data.email && !data.phone) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Bitte geben Sie eine E-Mail-Adresse ein.',
      path: ['email'],
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Bitte geben Sie eine Telefonnummer ein.',
      path: ['phone'],
    });
  }
});


const UserCodeForm = ({ children }: { children: ReactNode }) => {
  const theme = useSelector(getThemeIdentifier);
  const requestUserCode = useRequestUserCodeMutation();
  const { queryParams } = useQueryParams(['registration']);
  const { registration } = queryParams;

  const navigate = useNavigate();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      phone: '',
    },
  });

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    let { email } = data;
    const { phone } = data;
    email = email?.toLocaleLowerCase();
    requestUserCode.mutateAsync({
      body: {
        theme,
        request_type: registration ? 'register' : 'login',
        email: email?.toLocaleLowerCase(),
        phone,
      },
    }).then(() => {
      if (email) {
        navigate(`${CODE_ROUTES.SUBMIT_CODE}?email=${encodeURIComponent(email)}${registration ? '&registration=true' : ''}`);
      } else if (phone) {
        navigate(`${CODE_ROUTES.SUBMIT_CODE}?phone=${encodeURIComponent(phone)}${registration ? '&registration=true' : ''}`);
      }
    });
  };

  const navigateToggle = () => {
    navigate(`${CODE_ROUTES.REQUEST_CODE}${registration ? '' : '?registration=true'}`);
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex h-full flex-col justify-between gap-5">
          <div className="flex grow flex-col gap-5">
            <div>
              <p className="text-2xl">{registration ? 'Jetzt loslegen' : 'Willkommen zurück'}</p>
            </div>

            {children}
            {theme !== THEME.AGENT && (
              <Anchor onClick={() => navigateToggle()}>
                {registration ? 'Bereits ein Konto? Anmelden' : 'Kein Konto? Registrieren'}
              </Anchor>
            )}
          </div>
          <div className="pb-20">
            <Button
              type="submit"
              variant={ButtonVariant.solid_accent}
              id="request-code"
              size={ButtonSize.xl}
              isLoading={requestUserCode.isPending}
              className="w-full"
            >
              Anmeldecode anfordern
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default UserCodeForm;
