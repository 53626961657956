/* eslint-disable class-methods-use-this */

import BaseClient from 'gcs-common/clients/baseClient';

class BrowserMockBlobStorageClient extends BaseClient {
  /*
    Simple blob storage like an s3 bucket
    browser mock, based on objectURL and not actual persistent file storage
   */
  init() {
  }

  _storage = {
    // [key]: ObjectURL
  };

  async getObjectBase64() {
    throw Error('getObjectBase64 Not implemented in browser mock');
  }

  async getObjectUrl(key) {
    if (!this._storage[key]) {
      throw Error('File not found');
    }
    return this._storage[key];

  }

  async getObjectBlob(key) {
    if (!this._storage[key]) {
      throw Error('File not found');
    }
    const objectURL = this._storage[key];
    return this._objectURLAsBlob(objectURL);
  }

  async saveRemoteObject(key, remoteUrl, type) {
    const blob = await (await fetch(remoteUrl)).blob();
    this._storage[key] = URL.createObjectURL(new Blob([blob], { type }));
    return this._storage[key];
  }

  async saveLocalObject(key, jsFile) {
    this._storage[key] = await URL.createObjectURL(jsFile);
    return this._storage[key];
  }

  async objectExists(key) {
    return !!this._storage[key];
  }

  async deleteObject(key) {
    delete this._storage[key];
  }

  async clear() {
    this._storage = {};
  }

  async _objectURLAsBlob(objectURL) {
    return (await fetch(objectURL)).blob();
  }

}

export default BrowserMockBlobStorageClient;
