import clsx from 'clsx';
import Logo from 'gcs-common/icons/ets-mobile-part.svg?react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSelectedTicketId,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import {
  ICON,
  ICON_SIZE,
} from 'gcs-common/constants/IconConstants';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import {
  getThemeIdentifier,
} from 'gcs-common/slices/theme/themeSelectors';
import { MouseEventHandler } from 'react';
import { THEME } from 'gcs-common/constants/themes';
import useTrackOncePerSession from 'gcs-common/hooks/useTrackOncePerSession';
import { useGetETSTicketSelector, PartMessage } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { showSimpleNotification } from '../../../../slices/notification/notificationThunks';
import styles from './styles.module.scss';

interface TicketMessagePartProps extends PartMessage {}

const TicketMessagePart = (props: TicketMessagePartProps) => {
  const { description = null, manufacturer = null, kbn = null } = props;
  const selectedTicketId = useSelector(getSelectedTicketId());
  const ticket = useGetETSTicketSelector({ ticketId: selectedTicketId });
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const themeIdentifier: string = useSelector(getThemeIdentifier);

  if (ticket) {
    if (kbn) {
      useTrackOncePerSession(LEGACY_EVENTS.ONLINE_PLUS_EXTERNAL_LINK_IMPRESSION(), `op-external-link-impression-${ticket.externalJiraKey}-${kbn}`);
    } else {
      useTrackOncePerSession(LEGACY_EVENTS.MESSAGE_WITHOUT_OP_LINK_DISPLAYED(), `message-without-op-link-displayed-${ticket.externalJiraKey}`);
    }
  }

  const copyIdToClipboard = (ticketId: string) => {
    navigator.clipboard.writeText(ticketId).then(() => {}).catch(() => {});
    dispatch(showSimpleNotification(
      {
        notification: 'Ticket-ID in Zwischenablage kopiert',
      },
    ));
  };

  const opLinks:{ [key:string]:string } = {
    [THEME.GC]: `https://www.gconlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
    [THEME.GUT]: `https://www.gutonlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
    [THEME.EFG]: `https://www.efgonlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
    [THEME.HTI]: `https://www.htionlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
    [THEME.ITG]: `https://www.itgonlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
    [THEME.DTG]: `https://www.dtgonlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
    [THEME.TFG]: `https://www.tfgonlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
    [THEME.HFH]: `https://www.hfhonlineplus.de/?productId=${kbn}&ecid=(md:mobil)(source:beem)(cmp:ets)(cnt:op_button)`,
  };

  const url = encodeURI(opLinks[themeIdentifier]!);

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.ONLINE_PLUS_EXTERNAL_LINK_CLICKED()));
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={styles.etsPartMessage}>
      <div className={clsx(styles.etsPartMessageHeader, { [styles.kbnAvailable!]: !!kbn })}>
        <div>
          <Logo />
        </div>
        <div>
          {kbn ? 'Artikel auf ONLINE PLUS gefunden' : 'Ungelisteter Artikel gefunden'}
        </div>
      </div>

      <div className={styles.etsPartMessageBody}>
        <div className={styles.etsPartTable}>
          <div className={styles.etsPartTableHeader}>
            Artikel
          </div>

          <div className={styles.etsPartTableRow}>
            <div>
              Bezeichnung
            </div>
            <div>
              {description}
            </div>
          </div>

          <div className={styles.etsPartTableRow}>
            <div>
              Hersteller
            </div>
            <div>
              {manufacturer}
            </div>
          </div>

          <div className={styles.etsPartTableRow}>
            <div>
              KBN
            </div>
            <div className={clsx({ [styles.greyedOut!]: !kbn })}>
              {kbn || '(Nicht gelistet)'}
            </div>
          </div>
        </div>

        <div className={styles.etsPartFooter}>
          {kbn ? (
            <a
              href={url}
              target="_blank"
              onClick={handleLinkClick}
              rel="noopener, noreferrer"
            >
              Zur ONLINE PLUS-Artikelseite
            </a>
          )
            : (
              <>
                <div className={styles.greyedOut}>
                  Dieses Ersatzteil ist bei ONLINE PLUS nicht gelistet.
                  Bitte kontaktiere deinen Ansprechpartner unter Angabe der Ticket-ID.
                </div>

                <div className={styles.etsPartTableRow}>
                  <div>Ticket-ID:</div>
                  <div className={styles.ticketIdCopy}>
                    #
                    {ticket ? ticket.externalJiraKey : ''}
                    <button type="button" onClick={() => copyIdToClipboard(ticket ? ticket.externalJiraKey : '')}>
                      <IconComponent Icon={ICON.COPY} size={ICON_SIZE.X_SMALL} />
                    </button>
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default TicketMessagePart;
