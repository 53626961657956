import PropTypes from 'prop-types';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const TermsButton = ({ text, handler, open }) => {

  return (
    <button className={styles.listItem} onClick={handler} type="button">
      <span>{text}</span>
      <IconComponent
        Icon={ICON.ARROW}
        transform={open ? ICON_TRANSFORM.ROTATE_270 : ICON_TRANSFORM.ROTATE_90}
        alt="selected"
      />
    </button>
  );
};
TermsButton.propTypes = {
  text: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default TermsButton;
