import { useCallback, useEffect, useMemo } from 'react';
import {
  ICON,
  ICON_SIZE,
  ICON_TRANSFORM,
  ICON_COLOR,
} from 'gcs-common/constants/IconConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { ticketIdSelected } from 'gcs-common/slices/tickets/ticketsSlice';
import moment from 'moment';
import { DELETE_NOTIFICATION_AFTER_SECONDS } from 'gcs-common/slices/tickets/ticketsConstants';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { hasSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import { useGetETSTicketNotificationSelector, useGetETSTicketSelector } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { useDeleteETSTicketNotificationMutation } from 'gcs-common/clients/api/entities/tickets/tickets-api';
import { refetchETSTicketDetails } from 'gcs-common/clients/api/entities/tickets/tickets-api-helpers';
import styles from './styles.module.scss';
import TicketStatus from '../TicketsOverview/TicketCard/TicketStatus/TicketStatus';
import TicketMessages from '../TicketMessages/TicketMessages';
import TicketCreationLoading from '../TicketsCreation/TicketCreationLoading/TicketCreationLoading';
import ChatConnectionBanner from '../../ChatConnectionBanner/ChatConnectionBanner';
import TicketMessageMediaFullScreen from '../TicketMessages/TicketMessageMedia/TicketMessageMediaFullscreen/TicketMessageMediaFullscreen';
import { getTicketMedia } from '../../../slices/uiState/uiStateSelector';
import FileShareHeader from '../../FileShare/FileShareHeader/FileShareHeader';

type TicketDetailsParams = {
  ticketId: string;
};

const TicketDetails = () => {
  const { ticketId = '' } = useParams<TicketDetailsParams>();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const firstTime = query.get('firstTime') === 'true';
  const loadingTitle = query.get('loadingTitle');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isOnline: boolean = useSelector(getIsOnline);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteETSTicketNotification = useDeleteETSTicketNotificationMutation();

  const ticket = useGetETSTicketSelector({ ticketId });
  const ticketNotification = useGetETSTicketNotificationSelector(ticketId);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const mediaFullScreenUrl: string = useSelector(getTicketMedia);
  const loadingText = 'Ihre Ersatzteilanfrage wird erstellt, es dauert nur wenige Sekunden...';
  const sharedFiles = useSelector(hasSharedFiles);

  useEffect(() => {
    // synchronize router with redux,
    // since components downstream rely selectors like getSelectedTicket
    dispatch(ticketIdSelected({ ticketId, firstTime }));
  }, [dispatch, ticketId, firstTime]);

  const refetchData = useCallback(() => {
    if (!ticketId || !isOnline) return;
    refetchETSTicketDetails(ticketId);
  }, [ticketId, isOnline]);

  useEffect(() => {
    refetchData();
  }, [refetchData, ticketNotification?.updatedAt]);

  useEffect(() => {
    const opened = moment();

    return () => {
      if (!ticketNotification?.id) return;

      if (moment().diff(opened, 'seconds') < DELETE_NOTIFICATION_AFTER_SECONDS) return;
      deleteETSTicketNotification.mutate({ path: { id: ticketNotification.id } });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketNotification?.id]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={styles.ticketWrapper}>
      {sharedFiles && (<FileShareHeader />)}
      <div className={styles.ticketDetailsHeader}>
        <button onClick={navigateBack} type="button">
          <IconComponent
            Icon={ICON.ARROW}
            size={ICON_SIZE.LARGE}
            transform={ICON_TRANSFORM.ROTATE_180}
            color={ICON_COLOR.LIGHT_GREY}
          />
        </button>

        <div className={styles.container}>
          <span className={styles.ticketTitle}>{ticket ? ticket.title : loadingTitle}</span>
          {ticket && (
            <div className="flex justify-end">
              <TicketStatus status={ticket.status} />
            </div>
          )}
        </div>

      </div>
      <ChatConnectionBanner />
      {ticket ? <TicketMessages ticketId={ticket.id} />
        : <TicketCreationLoading loadingText={loadingText} />}
      {mediaFullScreenUrl
      && <TicketMessageMediaFullScreen mediaFullScreenUrl={mediaFullScreenUrl} />}
    </div>
  );
};

export default TicketDetails;
