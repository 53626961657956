/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messageVisibilitySessionPages: {}, // pages load per channel
  messageVisibilitySessionIndexBounds: {}, // {lowerLocalIndex, upperLocalIndex}
  // should be set at the beginning of a session holds the min/max-index of "existing" messages
};

const messageVisibilityLoaderSlice = createSlice({
  name: 'messageVisibilityLoader',
  initialState,
  reducers: {
    addedMessageVisibilitySessionPage: (state, action) => {
      const { channelId, page } = action.payload;
      if (!state.messageVisibilitySessionPages[channelId]) {
        state.messageVisibilitySessionPages[channelId] = [];
      }
      state.messageVisibilitySessionPages[channelId].push(page);
    },
    setMessageVisibilitySessionIndexBounds: (state, action) => {
      const { channelId, lowerLocalIndex, upperLocalIndex } = action.payload;
      state.messageVisibilitySessionIndexBounds[channelId] = { lowerLocalIndex, upperLocalIndex };
    },
    resetMessageVisibilitySession: (state) => {
      state.messageVisibilityPagesLoadInSession = {};
      state.messageVisibilitySessionIndexBounds = {};
    },
  },
});

export const {
  addedMessageVisibilitySessionPage,
  setMessageVisibilitySessionIndexBounds,
  resetMessageVisibilitySession,
} = messageVisibilityLoaderSlice.actions;

export default messageVisibilityLoaderSlice.reducer;
