import { configureStore } from '@reduxjs/toolkit';
import { createOffline } from '@redux-offline/redux-offline';
import gccApiClient from 'gcs-common/clients/gccApiClient/gccApiClient';
import chatClient from 'gcs-common/clients/chatClient/chatClient';
import videoClient from 'gcs-common/clients/videoClient/videoClient';
import onExceptionMiddleware from 'gcs-common/middlewares/onExceptionMiddleware';
import googleAnalyticsMiddleware from 'gcs-common/middlewares/googleAnalyticsMiddleware';
import customStoreSerializableCheck from 'gcs-common/constants/customStoreSerializableCheck';
import openErrorDialogMiddleware from 'gcs-common/middlewares/openErrorDialogMiddleware';
import createMigration from 'redux-persist-migrate/src';
import { sentryReduxEnhancer } from 'gcs-common/config/sentryReduxEnhancer';
import createRootReducer from './rootReducer';
import initializeOfflineConfig from './storage/initializeOfflineConfig';
import migrations from './storage/storeMigrations';
import sqliteStorageEngine from './storage/sqliteStorageEngine';
import persistenceSqliteStorageEngineWrapper from './storage/persistenceSqliteStorageEngineWrapper';
import initializePersistenceConfig from './storage/initializePersistenceConfig';
import blobStorageClient from './storage/blobStorageClient';

const migrate = createMigration(migrations, 'persistence');

// Note: For middleware order is important!
const initializeStore = ({ preloadedState } = {}) => {

  const storageEngine = sqliteStorageEngine();
  const persistenceStorageEngine = persistenceSqliteStorageEngineWrapper(storageEngine);

  const persistenceConfig = initializePersistenceConfig(persistenceStorageEngine);

  // this is to avoid a circular dependency
  let store;
  const getStore = () => store;

  const {
    middleware: offlineMiddleware,
    enhanceReducer,
    enhanceStore,
  } = createOffline(initializeOfflineConfig({ persistenceConfig, getStore }));

  store = configureStore({
    reducer: enhanceReducer(createRootReducer()),
    middleware: (getDefaultMiddleware) => [
      onExceptionMiddleware,
      ...getDefaultMiddleware({
        thunk: {
          extraArgument: {
            gccApiClient,
            chatClient,
            videoClient,
            blobStorageClient,
            persistenceStorageEngine,
          },
        },
        // not necessary since we are using immer which detects mutations in dev mode out of the box
        // https://github.com/reduxjs/redux-toolkit/issues/805
        immutableCheck: false,
        serializableCheck: { isSerializable: customStoreSerializableCheck },
      }),
      offlineMiddleware,
      googleAnalyticsMiddleware,
      openErrorDialogMiddleware,
    ],
    enhancers: [sentryReduxEnhancer, enhanceStore, migrate],
    preloadedState,
  });

  return store;
};

export default initializeStore;
