import { useSelector } from 'react-redux';
import { SyncAlt } from '@project-lary/react-material-symbols';
import { getFavicon, getThemeIdentifier, getOpIcon } from 'gcs-common/slices/theme/themeSelectors';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'gcs-common/design-system/ui/form';
import { getIsCurrentUserHasConnections } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Anchor from 'gcs-common/design-system/ui/anchor';
import { Input } from 'gcs-common/design-system/ui/input';
import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import { useCreateConnectionMemberMutation } from 'gcs-common/clients/api/entities/connection-member/connection-member-api';
import { OP_URL_BY_THEME } from 'gcs-common/constants/themes';
import { REGISTRATION_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate, Navigate } from 'react-router';
import OnlinePlusLoginReturnButton from '../OnlinePlusReturnButton/OnlinePlusReturnButton';

type ThemeKeys = keyof typeof OP_URL_BY_THEME;

const FormSchema = z.object({
  username: z.string().min(1, { message: 'Der Benutzername darf nicht leer sein.' }),
  password: z.string().min(1, { message: 'Das Passwort darf nicht leer sein.' }),
});


const OnlinePlusLogin = () => {
  const theme = useSelector(getThemeIdentifier) as ThemeKeys;
  const logo = useSelector(getFavicon);
  const opIcon = useSelector(getOpIcon);
  const navigate = useNavigate();
  const hasConnections = useSelector(getIsCurrentUserHasConnections);


  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const postConnectionMember = useCreateConnectionMemberMutation();

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    const { username, password } = data;
    postConnectionMember.mutateAsync({
      body: {
        username,
        password,
      },
    }).then((opData) => {
      navigate(`${REGISTRATION_ROUTES.REGISTRATION_PROFILE}?company=${opData?.connection?.house?.friendlyName}&username=${opData?.connection?.friendlyName}`);
    });
  };

  if (hasConnections) {
    return (
      <Navigate to={REGISTRATION_ROUTES.REGISTRATION_PROFILE} />
    );
  }

  return (
    <>
      <div className="m-auto flex w-[180px] items-center justify-between">
        <img alt={`${theme}-online-plus`} src={opIcon} className="size-16 rounded-lg" />
        <SyncAlt className="m-auto size-10 text-accent-9" />
        <img alt={`${theme}-icon`} src={logo} className="size-16 rounded-lg" />
      </div>
      <div>
        <p className="text-2xl">Verbindung zu ONLINE PLUS</p>
      </div>
      <div>
        <p className="text-base text-neutral-11">
          ONLINE PLUS wird verwendet, um Ihre Teams, Kontakte und Bestellungen zu finden.
        </p>
      </div>
      {theme in OP_URL_BY_THEME && (
        <div>
          <Anchor href={OP_URL_BY_THEME[theme]} target="_blank">
            Kein ONLINE PLUS Konto? Hier anmelden
          </Anchor>
        </div>
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6 flex h-full flex-col justify-between gap-4">
          <div className="flex grow flex-col gap-5">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Benutzername"
                      inputSize="l"
                      className="pl-2 text-base"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                      placeholder="Passwort"
                      inputSize="l"
                      className="pl-2 text-base"

                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex flex-col gap-4 pb-20">

            <Button
              type="submit"
              variant={ButtonVariant.solid_accent}
              id="request-code"
              size={ButtonSize.xl}
              className="w-full"
              isLoading={postConnectionMember.isPending}
            >
              Verbinden
            </Button>
            <OnlinePlusLoginReturnButton />
          </div>
        </form>
      </Form>
    </>
  );
};

export default OnlinePlusLogin;
