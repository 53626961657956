import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createChannel from 'gcs-common/slices/channels/channelsThunks/createChannel';
import { resetSelectedNewChannelOptions, setGroupChat } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import { resetCreateChannelAsyncStatus } from 'gcs-common/slices/channels/channelsSlice';
import {
  getIsGroupChat,
  getNewChannelOptionsFailed,
  getNewChannelOptionsLoading,
  getNewChannelOptionsPagination,
  getNewChannelOptionTypesToFetch,
  getSelectedNewChannelOptionIds,
  getUserIdsFromSelectedNewChannelOptions,
} from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import {
  getCreateChannelError,
  getCreateChannelIsLoading,
  getCreateChannelIsSuccess,
} from 'gcs-common/slices/channels/channelsSelectors';
import fetchNewChannelOptions from 'gcs-common/slices/newChannelOptions/newChannelOptionsThunks';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { setSearchInput } from 'gcs-common/slices/channelList/channelListSlice';
import { useDebouncedCallback } from 'use-debounce';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { setSideMenuClosed } from '../../slices/uiState/uiStateSlice';
import Button from '../Button/Button';
import NewChannelList from './NewChannelList/NewChannelList';
import NewChatGroupItem from './NewChatGroupItem/NewChatGroupItem';
import styles from './styles.module.scss';
import ShowMoreNewChannelOptions from './ShowMoreNewChannelOptions/ShowMoreNewChannelOptions';
import NewChannelErrorMessage from './NewChannelErrorMessage/NewChannelErrorMessage';


const NewChat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchTerm = useSelector(getSearchInput);
  const isGroupChat = useSelector(getIsGroupChat);
  const fetchLoading = useSelector(getNewChannelOptionsLoading);
  const fetchFailed = useSelector(getNewChannelOptionsFailed);
  const pagination = useSelector(getNewChannelOptionsPagination);
  const newChannelOptionsSelectedIds = useSelector(getSelectedNewChannelOptionIds);
  const newChannelOptionsSelectedUserIds = useSelector(getUserIdsFromSelectedNewChannelOptions);
  const newChannelOptionTypesToFetch = useSelector(getNewChannelOptionTypesToFetch);
  const createChannelIsSuccess = useSelector(getCreateChannelIsSuccess);
  const createChannelError = useSelector(getCreateChannelError);
  const createChanelIsLoading = useSelector(getCreateChannelIsLoading);

  // in each slice we load 20 channel options
  const [currentSlice, setCurrentSlice] = useState(1);

  const onNext = useCallback(() => {
    return dispatch(createChannel({ userIds: newChannelOptionsSelectedUserIds }));
  }, [dispatch, newChannelOptionsSelectedUserIds]);

  const onSearchInputChange = useCallback((event) => {
    dispatch(setSearchInput({ searchInput: event.target.value }));
  }, [dispatch]);

  const onGroupViewClose = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isGroupChat && dispatch(setGroupChat({ groupChat: false }));
    navigate(-1);
  }, [dispatch, navigate, isGroupChat]);

  const showMore = useCallback(() => {
    const nextSlice = currentSlice + 1;
    dispatch(fetchNewChannelOptions({ newChannelOptionTypesToFetch, searchTerm, nextSlice }));
    setCurrentSlice(nextSlice);
  // The dependency is ignored because of this trick with JSON.stringify
  // https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list/59468261#59468261
  // eslint-disable-next-line
  }, [dispatch, JSON.stringify(newChannelOptionTypesToFetch), searchTerm, currentSlice]);

  const [fetchNewChannelOptionsDebounced] = useDebouncedCallback((args) => {
    dispatch(fetchNewChannelOptions(args));
  }, 300);

  useEffect(() => {
    setCurrentSlice(1);
    fetchNewChannelOptionsDebounced({ newChannelOptionTypesToFetch, searchTerm });
    // eslint-disable-next-line
  }, [dispatch, JSON.stringify(newChannelOptionTypesToFetch), searchTerm]);

  useEffect(() => {
    if (createChannelIsSuccess) {
      dispatch(resetSelectedNewChannelOptions());
      dispatch(setSideMenuClosed());
      navigate(ROUTES.ROOT);
      dispatch(resetCreateChannelAsyncStatus());
    }
  }, [createChannelIsSuccess, dispatch, navigate]);

  useEffect(() => {
    if (createChannelError) {
      dispatch(resetSelectedNewChannelOptions());
      dispatch(resetCreateChannelAsyncStatus());
    }
  }, [createChannelError, dispatch]);

  // reset searchInput on cleanup
  useEffect(() => {
    return () => {
      dispatch(setSearchInput({ searchInput: '' }));
    };
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.newChatHeaderWrapper}>
        <div className={styles.newChatHeader}>
          <Button
            className={styles.newChatBack}
            onClick={onGroupViewClose}
            type="button"
          >
            {isGroupChat
              ? <IconComponent Icon={ICON.CLOSE} alt="Schließen" />
              : <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="Zurück" />
          }
          </Button>
          <div className={styles.headline}>
            {isGroupChat ? 'Neue Gruppe erstellen' : 'Neuen Chat starten'}
          </div>
          {isGroupChat && (
            <Button
              className={`${styles.newChatNext} ${newChannelOptionsSelectedIds.length === 0 || createChanelIsLoading ? styles.buttonDisabled : ''}`.trim()}
              disabled={newChannelOptionsSelectedIds.length === 0 || createChanelIsLoading}
              onClick={onNext}
              type="button"
            >
              <IconComponent Icon={ICON.ARROW} alt="Nächste" />

            </Button>
          )}
        </div>
        <div className={styles.searchWrapper}>
          <input
            className={`${styles.searchInput} ${styles.searchInputIcon}`}
            onChange={onSearchInputChange}
            placeholder={isGroupChat ? 'Kontakte suchen' : 'Kontakte oder Gruppe suchen'}
            value={searchTerm}
            type="search"
          />
        </div>
        {!isGroupChat && <NewChatGroupItem />}
      </div>
      <div className={styles.newChatBody}>
        <div className={styles.newChatBodyHeader}>
          <span className={styles.newChatBodyHeaderText}>
            {searchTerm ? 'Suchergebnisse' : 'Vorschläge'}
          </span>
        </div>
        <NewChannelList />
        {!fetchLoading && !fetchFailed && pagination && pagination.slices > currentSlice && (
          <ShowMoreNewChannelOptions onShowMore={showMore} />
        )}
        {fetchLoading && <LoadingIndicator />}
        {fetchFailed && <NewChannelErrorMessage onShowMore={showMore} />}
      </div>
    </div>
  );
};

export default NewChat;
