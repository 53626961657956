import { useSelector } from 'react-redux';
import { getFavoritesForSelectedChannelAsIds } from 'gcs-common/slices/favorites/favoritesSelectors';
import { useNavigate } from 'react-router';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import styles from './styles.module.scss';
import FavoriteItem from './FavoriteItem/FavoriteItem';
import backArrowIcon from '../../img/icons/arrow_back_dark.svg';

function FavoritesPage() {

  const favoriteIds = useSelector(getFavoritesForSelectedChannelAsIds);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const navigate = useNavigate();

  return (
    <div className={styles.favoritesWrapper}>
      <div className={styles.header}>
        <button
          className={styles.backButton}
          type="button"
          onClick={() => navigate(CHAT_ROUTES.CHANNEL(selectedChannelId))}
        >
          <img
            alt="logo"
            src={backArrowIcon}
          />
        </button>
        <div className={styles.favoriteTitle}>Favoriten</div>
      </div>
      <div className={styles.stripe} />
      <div className={styles.favoritesContent}>
        {favoriteIds.map((favoriteId) => (
          <FavoriteItem
            key={favoriteId}
            favoriteId={favoriteId}
          />
        ))}
        {favoriteIds.length === 0
        && <div className={styles.noFavorites}>Keine Favoriten</div>
        }
      </div>
    </div>
  );
}

export default FavoritesPage;
