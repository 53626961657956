import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShowNoGCOPAccountModal } from '../../slices/uiState/uiStateSelectors';
import { setSettingsMenuOpen, setSideMenuOpen, showNoGCOPAccountModalToggled } from '../../slices/uiState/uiStateSlice';
import InfoDialog from '../InfoDialog/InfoDialog';
import styles from './styles.module.scss';
import Button from '../Button/Button';

const NoAccountModal = () => {

  const dispatch = useDispatch();

  const showNoGCOPAccountModal = useSelector(getShowNoGCOPAccountModal);

  const closeModal = useCallback(() => {
    dispatch(showNoGCOPAccountModalToggled());
  }, [dispatch]);

  const openSettings = useCallback(() => {
    dispatch(showNoGCOPAccountModalToggled());
    dispatch(setSideMenuOpen());
    dispatch(setSettingsMenuOpen());
  }, [dispatch]);

  return (
    <InfoDialog
      title="ONLINE PLUS Account"
      isOpen={showNoGCOPAccountModal}
      onClose={closeModal}
    >
      Es besteht keine Verknüpfung zu einem
      ONLINE PLUS Account.
      <br />
      <br />
      Bitte prüfe &quot;Einstellungen/Verknüpfungen bearbeiten&quot;.
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="dialogButtons">
        <Button className={styles.button} type="button" onClick={openSettings}>
          Einstellungen öffnen
        </Button>
      </div>
    </InfoDialog>
  );
};

export default NoAccountModal;
