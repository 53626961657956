import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getHouse, getSelectedHouseId } from 'gcs-common/slices/houses/housesSelectors';
import changeSelectedHouseId from 'gcs-common/slices/houses/housesThunks/changeSelectedHouseId';
import { getTotalHouseUnreadCount } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import checkIcon from '../../img/icons/check_white.svg';
import styles from './styles.module.scss';
import UnreadCountDisplay from '../Chat/UnreadCountDisplay';

const HouseSwitcherItem = ({ handleClose, houseId }) => {
  const dispatch = useDispatch();
  const house = useSelector(getHouse(houseId));
  const selectedHouseId = useSelector(getSelectedHouseId);
  const totalUnreadCount = useSelector(getTotalHouseUnreadCount(houseId));

  const isSelected = selectedHouseId === houseId;

  return (
    <div
      className={`${styles.houseSwitcherItem} ${isSelected ? styles.houseSwitcherItemSeleted : ''}`.trim()}
      onTouchMove={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onTouchEnd={() => {
        dispatch(changeSelectedHouseId({ selectedHouseId: houseId }));
        handleClose();
      }}
    >
      <div className={styles.left}>
        <div className={styles.checkIcon}>
          {isSelected && <img alt="icon-check" src={checkIcon} />}
        </div>
        <span className={styles.houseSwitcherItemText}>{house.friendlyName}</span>
      </div>
      {totalUnreadCount > 0 && (
        <div className={styles.unreadMessages}>
          <UnreadCountDisplay unreadCount={totalUnreadCount} />
        </div>
      )}
    </div>
  );
};

HouseSwitcherItem.propTypes = {
  handleClose: PropTypes.func.isRequired,
  houseId: PropTypes.string.isRequired,
};

export default HouseSwitcherItem;
