import PropTypes from 'prop-types';
import { ICON, ICON_TRANSFORM, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const NavigateBack = (props) => {
  const { text, onBack } = props;
  return (
    <div className={styles.top}>
      <button
        type="button"
        onClick={onBack}
      >
        <IconComponent
          Icon={ICON.ARROW}
          transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
          alt="Zurück"
          color={ICON_COLOR.WHITE}
        />
      </button>

      <div className={styles.title}>{text}</div>
    </div>
  );
};

NavigateBack.propTypes = {
  text: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default NavigateBack;
