import SQLiteStorage from 'redux-persist-sqlite-storage';
// eslint-disable-next-line import/no-extraneous-dependencies
import { asyncLocalStorage } from 'redux-persist/storages';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';


const sqliteStorageEngine = () => {
  if (!IS_NATIVE) {
    return asyncLocalStorage;
  }
  return SQLiteStorage(
    window.sqlitePlugin,
    { androidDatabaseProvider: 'system' },
  );
};

export default sqliteStorageEngine;
