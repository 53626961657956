import { useDispatch, useSelector } from 'react-redux';
import { BLOCK_OR_REPORT_MODAL_TEXT, SNACKBAR_TEXT } from 'gcs-common/constants/textString';
import { getShowBlockUserModal } from '../../slices/uiState/uiStateSelectors';
import { hideBlockUserModal, showSnackbar } from '../../slices/uiState/uiStateSlice';
import BlockOrReportModal from './BlockOrReportModal';

const BlockUserDialog = () => {

  const isOpen = useSelector(getShowBlockUserModal);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideBlockUserModal());
  };
  const onConfirm = () => {
    dispatch(hideBlockUserModal());
    dispatch(showSnackbar({ message: SNACKBAR_TEXT.USER_BLOCKED, icon: 'reverse' }));
  };

  return (
    <BlockOrReportModal
      dialogText={BLOCK_OR_REPORT_MODAL_TEXT.BLOCK_USER}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export default BlockUserDialog;
