/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import {
  getFilter,
} from 'gcs-common/slices/processes/processesSelectors';
import {
  getChannelOPUserId,
  getPrimaryHouseForChannel,
} from 'gcs-common/slices/channel/channelSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { useDebouncedCallback } from 'use-debounce';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useGetProcesses } from 'gcs-common/clients/api/entities/processes/processes-api';
import styles from './styles.module.scss';
import Process from '../Process/Process';

const ProcessList = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId: string = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelOpUserId: string = useSelector(getChannelOPUserId(selectedChannelId));
  const filter = useSelector(getFilter);
  const { name: queryString, type: queryType } = filter || {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent: boolean = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const houseId: string = useSelector(getPrimaryHouseForChannel(selectedChannelId));

  const {
    data, hasNextPage, fetchNextPage, isFetching, error,
  } = useGetProcesses(
    {
      query: {
        query_type: queryType,
        query_string: queryString,
        user_id: isAgent ? channelOpUserId : undefined,
        house_id: houseId,
      },
    },
  );

  const [loadNextProcessPage] = useDebouncedCallback(() => {
    fetchNextPage().then(() => {}).catch(() => {});
  }, 500);

  const processesFetchErrorMessage = useMemo(() => {
    if (!error) return undefined;
    return error.message;
  }, [error]);

  if (isFetching && (!data || data.length === 0)) {
    return (
      <div className={styles.center}>
        <LoadingIndicator key="loading-indicator" />
      </div>
    );
  }

  if (processesFetchErrorMessage) {
    return <div className={styles.errorMessage}>{processesFetchErrorMessage}</div>;
  }

  if (!isFetching && (!data || data.length === 0)) {
    return <div className={styles.errorMessage}>Keine Vorgänge gefunden.</div>;
  }

  return (
    <div className={styles.processList}>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadNextProcessPage}
        hasMore={hasNextPage}
        useWindow={false}
        initialLoad={false}
        threshold={100}
      >
        {data && data.map(
          (process, index) => {
            // use SortDate by default, but fall back to CreationDate if SortDate not available
            let previousDate: string | undefined;
            const previouseProcess = data[index - 1];
            if (previouseProcess) {
              previousDate = previouseProcess.SortDate || previouseProcess.CreationDate;
            }
            return (
              <Process
                key={`${process.ProcessNumber}-${process.ProcessSuffix}`}
                process={process}
                previousProcessSortDate={previousDate}
              />
            );
          },
        )}
        {isFetching && (
        <div className={styles.loader}>
          <LoadingIndicator key="loading-indicator" />
        </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default ProcessList;
