/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key,jsx-a11y/interactive-supports-focus */
import { useDispatch } from 'react-redux';
import { processSelected } from 'gcs-common/slices/processes/processesSlice';
import { getProcessDateTag } from 'gcs-common/helper/processDateHelper';
import { FetchProcessesResponseData } from 'gcs-common/clients/api/entities/processes/processes-api-helpers';
import { getUniqueProcessNumber } from 'gcs-common/helper/processTextHelper';
import ProcessDetailSmall from '../../ProcessDetailSmall/ProcessDetailSmall';
import styles from './styles.module.scss';

interface ProcessProps {
  process: FetchProcessesResponseData[number];
  previousProcessSortDate: string | undefined;
}

const Process = (props: ProcessProps) => {
  const { process, previousProcessSortDate } = props;
  const { SortDate, CreationDate } = process;
  const dateTag = getProcessDateTag(SortDate || CreationDate, previousProcessSortDate);
  const dispatch = useDispatch();

  const setSelectedProcess = () => dispatch(processSelected({
    selectedProcessNumber: getUniqueProcessNumber(process.ProcessNumber, process.ProcessSuffix),
  }));

  return (
    <>
      {dateTag && <div className={styles.dateTag}>{dateTag}</div>}
      <div
        className={styles.processDetailWrapper}
        onClick={setSelectedProcess}
      >
        <ProcessDetailSmall process={process} />
      </div>
    </>
  );
};

export default Process;
