import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../client';
import { Parameters } from '../../../../../types/typeHelper';
import { openErrorDialog } from '../../../../slices/errorDialogSlice/errorDialogSlice';
import { debugLogger } from '../../../../helper/debugLogger';

interface UseGetUploadMedia {
  query: Parameters<'/chat/media/upload-url', 'get'>['query'];
}

export function useGetUploadMediaMutation() {
  const client = useApiClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ query }: UseGetUploadMedia) => {
      const { data } = await client.GET('/chat/media/upload-url', {
        params: { query },
      });
      return data;
    },
    onError: async (error: any) => {
      debugLogger('Failed to fetch upload URL:', error);
      dispatch(openErrorDialog({ dialogErrorMsg: error.message }));
    },
  });
}

export function usePreSignUrl() {
  return useMutation({
    mutationFn: async ({ url, file }: { url: string; file: File }) => {
      const response = await fetch(url, {
        method: 'PUT',
        body: file,
      });

      if (!response.ok) {
        throw new Error('Failed to upload file to presigned URL');
      }

      return response;
    },
    onError: async (error: any) => {
      debugLogger('Failed to upload file:', error);
    },
  });
}
