import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import {
  getSelectedTicketId,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { useGetETSTicketMessagesSelector, useGetETSTicketMessagesLoadingSelector, TicketMessageType } from 'gcs-common/clients/api/entities/tickets/tickets-api-selectors';
import styles from './styles.module.scss';
import TicketMessage from '../TicketMessage/TicketMessage';

const TicketMessagesList = () => {
  const ticketId = useSelector(getSelectedTicketId());
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentUserId: string = useSelector(getCurrentUserId);
  const messagesLoading = useGetETSTicketMessagesLoadingSelector({ ticketId });
  const messages = useGetETSTicketMessagesSelector({ ticketId, currentUserId });
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  useEffect(() => {
    virtuosoRef.current?.scrollToIndex({
      index: messages?.length - 1,
      align: 'start',
      behavior: 'auto',
    });
  }, [messages]);

  return (
    <div className={styles.commentsList}>
      {messagesLoading && !messages.length && <LoadingIndicator />}
      {messages.length > 0 && (
        <Virtuoso
          ref={virtuosoRef}
          data={messages}
          initialTopMostItemIndex={messages.length - 1}
          itemContent={(index, message) => (
            <TicketMessage
              key={message.type === TicketMessageType.PART
                || message.type === TicketMessageType.RATING ? index : message.id}
              message={message}
              ticketId={ticketId}
            />
          )}
        />
      )}
    </div>
  );
};

export default TicketMessagesList;
