import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import {
  filterByHiddenStatus,
  filterByPrimaryHouse,
  filterBySearch,
  filterBySelectedCraftsmanSection,
  filterBySelectedSalesSpaceChannelFilter,
  filterOwnChannels,
} from './channelFilter';
import { ChannelOrder, sortByDate } from './channelSorter';
import {
  getChannels, getChannelListChannelIds,
} from '../channels/channelsSelectors';
import { calculateChannelType } from '../../helper/channelHelpers';
import {
  getChannelMembers,
  getMembers,
} from '../members/membersSelectors';

import { getCurrentUserId, getIsAgent } from '../currentUser/currentUserSelectors';
import { getSelectedHouseId } from '../houses/housesSelectors';
import { getIsChatConnected } from '../chatConnection/chatConnectionSelectors';
import { calculateChannelTypeForChannelFilter } from '../../helper/channelTypeHelper';
import CRAFTSMAN_CHANNEL_SECTIONS from '../../constants/CraftsmanChannelSections';
import { SalesSpaceChannelFilter } from '../../helper/channelOrderingHelper';
import { getChannelListState } from './channelListSelectors/getChannelListState';
import { getSelectedChannelOrder } from './channelListSelectors/getSelectedChannelOrder';
import { getUsers } from '../users/usersSelectors';
// eslint-disable-next-line import/no-cycle
import { getDisplayChannelsIds } from './channelListSelectors/getDisplayChannelsIds';
import { getChannelNotificationDates } from '../tickets/ticketsSelectors';


export const getForwardSearchInput = createSelector(
  getChannelListState,
  (channelList) => channelList.forwardSearchInput,
);

// get ids of all direct channels and sales spaces where you are member of or which is free
export const getOwnAndFreeChannelIds = createSelector(
  getChannelListChannelIds,
  getIsAgent,
  getChannelMembers,
  getMembers,
  getCurrentUserId,
  getUsers,
  getIsChatConnected,
  (
    channelIds,
    isAgent,
    channelMembers,
    members,
    currentUserId,
    users,
    isConnected,
  ) => {
    return channelIds.filter(filterBySelectedSalesSpaceChannelFilter({
      isAgent,
      selectedSalesSpaceChannelFilter: SalesSpaceChannelFilter.FREE_AND_OWN,
      channelMembers,
      members,
      currentUserId,
      users,
      isConnected,
    }));
  },
);

// get ids of all direct channels and sales spaces where you are member
export const getOwnChannelIds = createSelector(
  getChannelListChannelIds,
  getChannelMembers,
  getMembers,
  getCurrentUserId,
  (
    channelIds,
    channelMembers,
    members,
    currentUserId,

  ) => {
    return channelIds.filter(filterOwnChannels({
      channelMembers,
      members,
      currentUserId,
    }));
  },
);

export const getNextValidChannel = (channelId) => createSelector(
  getChannelListChannelIds,
  getChannels,
  (channelIds, channels) => {
    const currentChannelType = calculateChannelType(channels[channelId]);

    const channelsWithCurrentType = channelIds.filter(id => (
      calculateChannelType(channels[id]) === currentChannelType
      && id !== channelId));

    return channelsWithCurrentType.length
      ? channelsWithCurrentType[0]
      : channelIds.find(id => id !== channelId);
  },
);

export const getFirstChannelId = createSelector(
  getChannelListChannelIds,
  getIsAgent,
  (allChannelIds) => {
    return allChannelIds && allChannelIds[0];
  },
);

export const getCraftsmanChannelSectionChannelIds = (
  section,
  houseId = undefined,
) => createSelector(
  getDisplayChannelsIds,
  getChannels,
  getSelectedHouseId,
  (displayChannelIds, channels, selectedHouseId) => {
    const filteredBySection = displayChannelIds && displayChannelIds
      .filter(filterBySelectedCraftsmanSection({ section, channels }));

    if (section === CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS) {
      const filteredHouse = houseId || selectedHouseId;
      return filteredBySection
        .filter(filterByPrimaryHouse({ channels, houseId: filteredHouse }));
    }

    return filteredBySection;
  },
);

export const getFirstChannelForHouseId = (houseId) => createSelector(
  getCraftsmanChannelSectionChannelIds(CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS, houseId),
  getCraftsmanChannelSectionChannelIds(CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS, houseId),
  (houseChannels, internalChannels) => {
    if (houseChannels && houseChannels.length) {
      return houseChannels[0];
    }
    if (internalChannels && internalChannels.length) {
      return internalChannels[0];
    }
    return undefined;
  },
);

export const getForwardChannelIds = (channelId) => createSelector(
  createStructuredSelector({
    unfilteredSids: getOwnChannelIds,
    channels: getChannels,
    forwardSearchInput: getForwardSearchInput,
    currentUserId: getCurrentUserId,
    channelMembers: getChannelMembers,
    members: getMembers,
    users: getUsers,
    isAgent: getIsAgent,
  }),
  ({
    unfilteredSids,
    channels,
    forwardSearchInput,
    currentUserId,
    channelMembers,
    members,
    users,
    isAgent,
  }) => {
    const unsortedSids = unfilteredSids
      .filter((iChannelId) => iChannelId !== channelId)
      .filter(filterBySearch({
        searchInput: forwardSearchInput,
        channels,
        currentUserId,
        channelMembers,
        members,
        users,
        isAgent,
      }));
    return sortByDate({ channelIds: unsortedSids, channels });
  },
);


export const getChannelCountForChannelFilter = (selectedChannelFilter) => createSelector(
  getChannelListChannelIds,
  getChannels,
  getUsers,
  getCurrentUserId,
  (allChannelIds, channels, users, currentUserId) => {
    if (!allChannelIds) {
      return undefined;
    }

    const selectedChannelTypes = calculateChannelTypeForChannelFilter(selectedChannelFilter);
    const allDisplayedChannelIds = allChannelIds.filter(filterByHiddenStatus({
      currentUserId,
      users,
    }));

    return allDisplayedChannelIds
      .filter(id => {
        const channelType = calculateChannelType(channels[id]);
        return selectedChannelTypes.includes(channelType);
      })
      ?.length;
  },
);

export const getDateTagsForJoinedChannels = createSelector(
  getDisplayChannelsIds,
  getChannels,
  getChannelNotificationDates,
  (displayChannelIds, channels, channelNotificationDates) => {
    return displayChannelIds.reduce((red, channelId, index) => {
      const channel = channels[channelId];
      const dateCreatedRaw = channel.lastMessageDate || channel.updatedAt;
      if (!dateCreatedRaw) {
        return red;
      }
      let dateCreated = moment(dateCreatedRaw);
      const notificationDate = channelNotificationDates
        ? channelNotificationDates[channelId]
        : null;
      if (notificationDate) {
        dateCreated = moment.max(dateCreated, moment(notificationDate));
      }
      let showDateTag = (index === 0);
      if (!showDateTag) {
        const channelBefore = channels[displayChannelIds[index - 1]];
        const dateChannelBeforeRaw = channelBefore.lastMessageDate || channelBefore.updatedAt;
        if (!dateChannelBeforeRaw) {
          return red;
        }
        let dateChannelBefore = moment(dateChannelBeforeRaw);
        const notificationDateBefore = channelNotificationDates
          ? channelNotificationDates[channelId]
          : null;
        if (notificationDateBefore) {
          dateChannelBefore = moment.max(dateChannelBefore, moment(notificationDateBefore));
        }
        showDateTag = !dateCreated.isSame(dateChannelBefore, 'day');
      }

      return {
        ...red,
        [channelId]: showDateTag ? dateCreated : null,
      };
    }, {});
  },
);
export const getDateTagForJoinedChannel = (channelId) => createSelector(
  getDateTagsForJoinedChannels,
  getSelectedChannelOrder,
  (dateTagsForJoinedChannels, channelOrder) => {
    return channelOrder === ChannelOrder.DATE
      && dateTagsForJoinedChannels
      && dateTagsForJoinedChannels[channelId];
  },
);
