import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isSelectableMessage } from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import checkIcon from '../../img/icons/check_white.svg';
import {
  addSelectedMessageIds,
  removeSelectedMessageIds,
} from '../../slices/uiState/uiStateSlice';
import {
  getIsMessageSelected,
  getIsAtLeastOneMessageSelected,
  getSelectedMessageIds,
} from '../../slices/uiState/uiStateSelector';
import styles from './styles.module.scss';

const MessageSelectorContainer = ({ messageId, children }) => {
  const isAtLeastOneMessageSelected = useSelector(getIsAtLeastOneMessageSelected);
  const isSelected = useSelector(getIsMessageSelected(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedMessages = useSelector(getSelectedMessageIds);
  const selectableMessage = useSelector(isSelectableMessage(selectedChannelId, messageId));
  const dispatch = useDispatch();

  const onMessageClicked = useCallback(() => {
    if (selectedMessages.length !== 0) {
      if (isSelected) {
        dispatch(removeSelectedMessageIds({ messageId }));
      } else {
        dispatch(addSelectedMessageIds({ messageId }));
      }
    }
  }, [dispatch, messageId, isSelected, selectedMessages]);

  if (!selectableMessage) {
    return (children);
  }

  return (
    <div
      role="none"
      onClick={onMessageClicked}
      className={isSelected ? styles.selectedMessageWrapper : styles.messageWrapper}
    >
      {isAtLeastOneMessageSelected && (
        <div className={isSelected
          ? styles.selectedCheckIconWrapper
          : styles.checkIconWrapper}
        >
          {isSelected && (
            <img
              src={checkIcon}
              alt="checkme"
            />
          )}
        </div>
      )}
      <div className={styles.children}>
        {children}
      </div>
    </div>
  );
};

export default MessageSelectorContainer;

MessageSelectorContainer.propTypes = {
  messageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
