import { createSelector } from '@reduxjs/toolkit';
import {
  getChannelInputStagedFiles,
  getChannelInputStagedProcess,
  getChannelInputStagedProduct,
  getChannelInputStagedReplyMessageId,
  getChannelInputText,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { getMemberCraftsmenCountForChannel, getChannelIsInternal } from 'gcs-common/slices/channel/channelSelectors';


export const getShowSendButton = (channelId) => createSelector(
  getChannelInputStagedFiles(channelId),
  getChannelInputStagedProcess(channelId),
  getChannelInputStagedProduct(channelId),
  getChannelInputText(channelId),
  (
    stagedFiles,
    stagedProcess,
    stagedProduct,
    text,
  ) => {

    return stagedFiles.length
      || stagedProcess
      || stagedProduct
      || (text && text.length > 0);
  },
);

export const getShowMagicCubeButton = (channelId) => createSelector(
  getChannelInputStagedFiles(channelId),
  getChannelInputStagedProcess(channelId),
  getChannelInputStagedProduct(channelId),
  getChannelInputStagedReplyMessageId(channelId),
  getChannelInputText(channelId),
  getMemberCraftsmenCountForChannel(channelId),
  getChannelIsInternal(channelId),
  (
    stagedFiles,
    stagedProcess,
    stagedProduct,
    stagedReply,
    text,
    channelCraftsmenCount,
    channelIsInternal,
  ) => {
    return !stagedFiles.length
      && !stagedProcess
      && !stagedProduct
      && !stagedReply
      && !(text && text.length > 0)
      && (channelCraftsmenCount === 1)
      && !channelIsInternal;
  },
);
