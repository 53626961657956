import PropTypes from 'prop-types';
import ChannelListItemNormalWithId from '../ChannelListItemNormalWithId/ChannelListItemNormalWithId';

const ChannelListItem = ({ channelId }) => {
  return (
    <ChannelListItemNormalWithId channelId={channelId} />
  );
};

ChannelListItem.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelListItem;
