import { useSelector } from 'react-redux';
import OP_PERMISSIONS from 'gcs-common/helper/opSettingsHelper';
import PropTypes from 'prop-types';
import { getIsManualCraftsmanConnection, isOpConnectionPermissionGranted } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import styles from './styles.module.scss';

const CraftsmanConnectionPermissions = ({ connectionId }) => {

  const showNettoPriceAllowed = useSelector(
    isOpConnectionPermissionGranted(connectionId, OP_PERMISSIONS.PROCESSES_SHOW_NETTO_PRICE),
  );
  const showBruttoPriceAllowed = useSelector(
    isOpConnectionPermissionGranted(connectionId, OP_PERMISSIONS.PROCESSES_SHOW_BRUTTO_PRICE),
  );
  const searchEnabled = useSelector(
    isOpConnectionPermissionGranted(connectionId, OP_PERMISSIONS.PROCESSES_ENABLED),
  );
  const isManual = useSelector(getIsManualCraftsmanConnection(connectionId));

  if (isManual) {
    return null;
  }

  return (
    <div className={styles.permissionsSection}>
      <div className={styles.headline}>Berechtigungen</div>

      <div className={styles.container}>
        <div className={styles.permissionsRow}>
          {/* TODO: This part should be done when the opm_ service is refactored */}
          <div>Vorgangssuche anzeigen</div>
          <div>{searchEnabled ? 'Ja' : 'Nein'}</div>
        </div>
        <div className={styles.permissionsRow}>
          <div>Listenpreise anzeigen</div>
          <div>{showBruttoPriceAllowed ? 'Ja' : 'Nein'}</div>
        </div>
        <div className={styles.permissionsRow}>
          <div>Nettopreise anzeigen</div>
          <div>{showNettoPriceAllowed ? 'Ja' : 'Nein'}</div>
        </div>
      </div>
    </div>
  );
};

CraftsmanConnectionPermissions.propTypes = {
  connectionId: PropTypes.string.isRequired,
};

export default CraftsmanConnectionPermissions;
