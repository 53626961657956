import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import YesToggle from 'gcs-common/img/yes_toggle.svg?react';
import NoToggle from 'gcs-common/img/no_toggle.svg?react';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { toogleSendMessageOnEnter } from '../../../slices/localSettings/localSettingsSlice';
import { getSendMessageOnEnter } from '../../../slices/localSettings/localSettingsSelectors';
import styles from './styles.module.scss';

const ChatSettings = () => {
  const sendMessageOnEnter = useSelector(getSendMessageOnEnter);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onToggleSendMessageOnEnter = useCallback(() => {
    dispatch(toogleSendMessageOnEnter());
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.TOGGLE_SEND_ON_ENTER()));
  }, [dispatch]);

  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const header = useMemo(() => {
    return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className={styles.header} onClick={onGoBack}>
        <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="Zurück" />
        <div className={styles.headline}>
          Chat Einstellungen
        </div>
      </div>
    );
  }, [onGoBack]);

  return (
    <div className={styles.mainWrapper}>
      {header}
      <div className={styles.title}>CHAT</div>
      <div className={styles.switchWrapper}>
        <div className={styles.boldText}>Senden mit Enter-Taste</div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onClick={onToggleSendMessageOnEnter}>
          {sendMessageOnEnter ? <YesToggle /> : <NoToggle />}
        </div>
      </div>
      <div className={styles.explanatoryText}>
        Mit der Enter-Taste wird die Nachricht gesendet.
        Diese Einstellung ist nur für die mobile Version gültig.
      </div>
    </div>
  );

};

export default ChatSettings;
