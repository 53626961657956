import removePreReduxDataMigration from './migrations/removePreReduxDataMigration';
import clearReduxStateMigration from './migrations/clearReduxStateMigration';

const migrations = {
  1: removePreReduxDataMigration,
  2: clearReduxStateMigration,
  // 1: sampleMigration,
};

export default migrations;
