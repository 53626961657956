import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getVideoUploadProgress } from 'gcs-common/slices/videos/videosSelectors';
import { ICON_SIZE } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import styles from './styles.module.scss';

const VideoUploadProgress = ({ videoId }) => {
  const uploadPercentage = useSelector(getVideoUploadProgress(videoId));

  if (uploadPercentage === 0) {
    return (
      <div className={styles.videoMessageContainer}>
        <div className={styles.loader}>
          <div className={styles.icon}>
            <Spinner size={ICON_SIZE.X_LARGE} />
          </div>
        </div>
        <p>Warten auf Upload</p>
      </div>
    );
  }

  return (
    <div className={styles.videoMessageContainer}>
      <div className={styles.loader}>
        <div className={styles.icon}>
          <Spinner size={ICON_SIZE.X_LARGE} />
        </div>
      </div>
      <p>Video Upload</p>
      <div className={styles.uploadPercentage}>
        <span>
          {uploadPercentage}
          {' '}
          %
        </span>
      </div>
    </div>
  );

};

export default VideoUploadProgress;

VideoUploadProgress.propTypes = {
  videoId: PropTypes.string.isRequired,
};
