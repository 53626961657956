import { ICON, ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { TICKET_STATI, TicketStati } from 'gcs-common/slices/tickets/ticketsConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import { FetchETSTicketsResponseData } from 'gcs-common/src/clients/api/entities/tickets/tickets-api-helpers';
import { cn } from 'gcs-common/design-system/utils/tailwind-merge';

interface TicketStatusProps {
  status: keyof FetchETSTicketsResponseData['status'];
}

// Variation of the TicketStatus interface only for this ui component
const TICKET_STATUS_BADGE: TicketStati = {
  ...TICKET_STATI,
  // @ts-expect-error extending object for this component only
  rating: {
    title: 'Anfrage bewerten',
    backgroundColor: 'bg-info-alpha-3',
    foregroundColor: 'text-info-alpha-11',
  },
} as const;

const TicketStatus = (props:TicketStatusProps) => {
  const { status } = props;
  const title = TICKET_STATUS_BADGE[status]?.title || 'Unbekannt';
  const iconColor = TICKET_STATUS_BADGE[status]?.iconColor || ICON_COLOR.LIGHT_GREY;
  const badgeStyling = 'px-2 py-1 flex-row inline-flex items-center rounded text-xs gap-1.5';

  return (
    <span
      className={cn(
        badgeStyling,
        TICKET_STATUS_BADGE[status]?.backgroundColor,
        TICKET_STATUS_BADGE[status]?.foregroundColor,
      )}
    >
      {TICKET_STATUS_BADGE[status]?.iconColor && (
        <IconComponent
          Icon={ICON.ECLIPSE}
          size={ICON_SIZE.XXXX_SMALL}
          color={iconColor}
        />
      )}
      {title}
    </span>
  );

};

export default TicketStatus;
