
export const getSharedFiles = state => state.fileToAppSharing.sharedFiles;

export const getIsFilesShareWasClosed = state => state.fileToAppSharing.isFilesShareWasClosed;

// eslint-disable-next-line max-len
export const getSelectedChannelForSharing = state => state.fileToAppSharing.selectedChannelForSharing;

export const getFileShareLoading = state => state.fileToAppSharing.fileShareLoading;

export const hasSharedFiles = state => state.fileToAppSharing.sharedFiles.length > 0;
