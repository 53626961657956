import 'gcs-common/polyfills/ResizeObserver';

import './index.css';
import Modal from 'react-modal';
import * as Sentry from '@sentry/capacitor';
import smoothscroll from 'smoothscroll-polyfill';
import { App as CapacitorApp } from '@capacitor/app';
import { sentryConfig } from 'gcs-common/config/sentryConfig';
import { createRoot } from 'react-dom/client';
import './scss/global.scss';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';
import * as serviceWorker from './serviceWorker';
import AppRoot from './components/AppRoot/AppRoot';

// kick off the polyfill!
smoothscroll.polyfill();

Modal.setAppElement('#root');
Sentry.init(sentryConfig());

const container = document.getElementById('root');
const root = createRoot(container);

const startApp = async () => {
  if (IS_NATIVE) {
    const { id } = await CapacitorApp.getInfo();
    window.packageId = id;
  }

  root.render(
    <AppRoot />,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();

  if (!IS_NATIVE) {
    window.Keyboard = { isVisible: false };
  }
};

startApp();
