import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import { captureException } from '@sentry/react';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { fetchVideoFromUrl } from 'gcs-common/helper/videoHelper';
import { startVideoIsDownloading, stopVideoIsDownloading } from 'gcs-common/slices/videos/videosSlice';
import { Media } from '@capacitor-community/media';
import { showSnackbar } from '../../uiState/uiStateSlice';

const downloadVideo = createAsyncThunk(
  'media/downloadVideo',
  async (
    { key, showError = false, playbackId, videoDownloadResolution },
    { dispatch, extra: { blobStorageClient } },
  ) => {
    const albumName = 'Download';
    let albumIdentifier = '';
    const snackbarMessage = 'Video erfolgreich heruntergeladen!';
    const snackbarError = 'Video-Download fehlgeschlagen!';

    try {
      const remoteUrl = await fetchVideoFromUrl(playbackId, videoDownloadResolution);
      dispatch(startVideoIsDownloading());

      let albums = await Media.getAlbums();
      albumIdentifier = albums.albums.find((a) => a.name === albumName)?.identifier || null;
      if (!albumIdentifier) {
        await Media.createAlbum({ name: albumName });
        albums = await Media.getAlbums();
        albumIdentifier = albums.albums.find((a) => a.name === albumName)
          ?.identifier;
      }

      const localUrl = await blobStorageClient.saveRemoteObject(key, remoteUrl, 'localUrl');
      try {
        await Media.saveVideo({ path: localUrl, albumIdentifier });
        dispatch(showSnackbar({ message: snackbarMessage }));
        dispatch(stopVideoIsDownloading());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        dispatch(showSnackbar({ message: snackbarError }));
        dispatch(stopVideoIsDownloading());
        throw e;
      }
    } catch (e) {
      if (showError) {
        dispatch(openErrorDialog({ dialogErrorMsg: getErrorMsg('load_media.default') }));
      }
      if (e.message !== 'Failed to fetch') {
        captureException(e);
      }
      dispatch(showSnackbar({ message: snackbarError }));
      dispatch(stopVideoIsDownloading());
      throw e;
    }
  },
);

export default downloadVideo;
