export const FETCH_PROCESSES_ERRORS = {
  NO_OP_ACCOUNT: 'get_processes.no_op_account',
  MISSING_CREDENTIALS: 'get_processes.missing_credentials',
  BLOCKED_ACCOUNT: 'get_processes.blocked_gcop_account',
  INVALID_CREDENTIALS: 'get_processes.invalid_credentials',
  GCOP_PROBLEMS: 'get_processes.gcop_problems',
  NOT_CONFIGURED_INSTALLATION_LIST: 'get_processes.not_configured_installation_list',
  NOT_CONFIGURED_DOCUMENT_LIST: 'get_processes.not_accepted_documents',
  SEARCH_DISABLED: 'get_processes.search_processes_disabled',
  SERVICE_NOT_ACTIVATED: 'get_processes.service_not_activated',
} as const;
