import PropTypes from 'prop-types';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { useSelector } from 'react-redux';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import { getOnScreenKeyboardHeight } from '../../../slices/uiState/uiStateSelectors';
import { useFilePicker } from '../../../hooks/useFilePicker';

function FilePicker({
  fileSizeLimit = 25,
  stageFileCallback = undefined,
  allowedFileTypes = undefined,
  unsupportedFileMessage = 'Unsupported file type',
}) {
  const onScreenKeyboardHeight = useSelector(getOnScreenKeyboardHeight);
  const { openGallery, openFileExplorer, openCamera } = useFilePicker(
    fileSizeLimit, stageFileCallback, allowedFileTypes, unsupportedFileMessage,
  );

  return (
    <div className={styles.filePickerWrapper} style={{ height: `${onScreenKeyboardHeight}px` }}>
      <div className={styles.filePickerContainer}>
        <button type="button" className={styles.filePickerBtn} onClick={openGallery}>
          <span className={styles.filePickerBtnIcon}>
            <IconComponent
              Icon={ICON.IMAGE}
              color={ICON_COLOR.DARKEST_GREY}
            />
          </span>
          Galerie
        </button>
        <button type="button" className={styles.filePickerBtn} onClick={openCamera}>
          <span className={styles.filePickerBtnIcon}>
            <IconComponent
              Icon={ICON.PHOTO}
              color={ICON_COLOR.DARKEST_GREY}
            />
          </span>
          Kamera
        </button>
        <button type="button" className={styles.filePickerBtn} onClick={openFileExplorer}>
          <span className={styles.filePickerBtnIcon}>
            <IconComponent
              Icon={ICON.FILE}
              color={ICON_COLOR.DARKEST_GREY}
            />
          </span>
          Dateien
        </button>
      </div>
    </div>
  );
}

FilePicker.propTypes = {
  fileSizeLimit: PropTypes.number,
  stageFileCallback: PropTypes.func,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  unsupportedFileMessage: PropTypes.string,
};

export default FilePicker;
