import { persistenceKeyPrefix } from './initializePersistenceConfig';

const persistenceSqliteStorageEngineWrapper = (sqlLiteStorageEngine) => {
  if (!sqlLiteStorageEngine) {
    throw Error('sqlLiteStorageEngine undefined!');
  }
  return {
    ...sqlLiteStorageEngine,
    async clear({ whitelist = [] } = {}) {
      const whiteListWithPrefix = whitelist.map(key => persistenceKeyPrefix + key);
      const persistenceKeys = await this._getPersistenceKeys();
      return Promise.all(
        persistenceKeys
          .filter(key => !whiteListWithPrefix.includes(key))
          .map(async key => sqlLiteStorageEngine.removeItem(key)),
      );
    },
    async _getPersistenceKeys() {
      const allStoreKeys = await sqlLiteStorageEngine.getAllKeys();
      return allStoreKeys.filter(key => key.includes(persistenceKeyPrefix));
    },
  };
};

export default persistenceSqliteStorageEngineWrapper;
