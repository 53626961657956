import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const BlockOrReportModal = ({ dialogText, isOpen, onClose, onConfirm }) => {

  return (
    <>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <div className={styles.dialogBox}>
          <div role="button" tabIndex={0} onClick={onClose} className={styles.closeButtonWrapper}>
            <IconComponent Icon={ICON.CLOSE} alt="close" />
          </div>
          <div className={styles.dialogTextWrapper}>
            {dialogText}
          </div>
          <div role="button" tabIndex={0} onClick={onConfirm} className={styles.confirmButtonWrapper}>
            <div className={styles.confirmButton}>Bestätigen</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

BlockOrReportModal.propTypes = {
  dialogText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default BlockOrReportModal;
