import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { getTicketFormDraft } from 'gcs-common/slices/tickets/ticketsSelectors';
import { ticketFormChanged } from 'gcs-common/slices/tickets/ticketsSlice';
import { hasSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import {
  filesShareClosed,
  sharedFilesCleared,
} from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSlice';
import { useAddETSTicketCommentMutation } from 'gcs-common/clients/api/entities/tickets/tickets-api';
import { ERROR_DIALOG } from 'gcs-common/constants/errorDialogConstants';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { useUploadETSAttachments } from 'gcs-common/clients/api/entities/tickets/tickets-api-helpers';
import TicketMessagesList from './TicketMessagesList/TicketMessagesList';
import TicketCreationLoading from '../TicketsCreation/TicketCreationLoading/TicketCreationLoading';
import TicketForm from '../TicketForm/TicketForm';
import styles from './styles.module.scss';

interface TicketMessagesProps {
  ticketId: string;
}

const TicketMessages = (props: TicketMessagesProps) => {
  const { ticketId } = props;
  const dispatch = useDispatch();
  const draft = useSelector(getTicketFormDraft(ticketId));
  const [description, setDescription] = useState<string>(draft?.description || '');
  const [isSendingDisabled, setSendingDisabled] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isOnline: boolean = useSelector(getIsOnline);
  const [attachments, setAttachments] = useState<File[]>(draft?.attachments || []);
  const loadingText = 'Ihre zusätzlichen Informationen werden verarbeitet. Bitte haben Sie einen Moment Geduld.';
  const sharedFiles = useSelector(hasSharedFiles);
  const addETSTicketComment = useAddETSTicketCommentMutation();
  const uploadETSAttachments = useUploadETSAttachments();

  useEffect(() => {
    setSendingDisabled((description || '').trim().length < 1);
  }, [description]);

  useEffect(() => {
    if (description || attachments.length > 0) {
      dispatch(ticketFormChanged({
        ticketId,
        formValues: { description, attachments },
      }));
    } else {
      dispatch(ticketFormChanged({ ticketId, formValues: undefined }));
    }
  }, [dispatch, description, attachments, ticketId]);

  const submit = async () => {
    if (!isOnline) return;
    setSendingDisabled(true);
    setSubmitting(true);

    try {
      await addETSTicketComment.mutateAsync({
        path: {
          ticket_id: ticketId,
        },
        body: {
          body: description.trim(),
        },
      });
      try {
        await uploadETSAttachments.uploadFiles(ticketId, attachments);
      } catch (e) {
        // Error uploading one or more attachments
        dispatch(openErrorDialog({ dialogErrorCode: 'ets.attachment.upload.failed', errorDialog: ERROR_DIALOG.MODAL }));
        setSendingDisabled(false);
        setSubmitting(false);
        return;
      }
    } catch (e) {
      // Error adding comment
      setSendingDisabled(false);
      setSubmitting(false);
      return;
    }
    setSendingDisabled(false);
    setSubmitting(false);
    setAttachments([]);
    setDescription('');
    if (sharedFiles) {
      dispatch(sharedFilesCleared());
      dispatch(filesShareClosed());
    }
  };

  return (
    <div className={styles.ticketCommentsContainer}>
      {!attachments.length && (
        <TicketMessagesList />
      )}

      {isSubmitting && attachments.length ? (
        <TicketCreationLoading loadingText={loadingText} />
      ) : (
        <TicketForm
          setDescription={setDescription}
          onClick={submit}
          isDisabled={isSendingDisabled}
          attachments={attachments}
          setAttachments={setAttachments}
          description={description}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default TicketMessages;
