import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputIsSendingDisabled,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { sendCurrentMessageState } from 'gcs-common/slices/messageInput/messageInputThunks/sendCurrentMessageState';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const SendButton = () => {

  const dispatch = useDispatch();
  const selectedChannelId = useSelector(getSelectedChannelId);
  const isSendingDisabled = useSelector(getChannelInputIsSendingDisabled(selectedChannelId));

  const sendMessage = useCallback((event) => {
    // Note: Do not remove, blocks the unfocus if onKeyDown/onMouseDown is used!
    event.preventDefault();
    if (!isSendingDisabled) {
      dispatch(sendCurrentMessageState({ channelId: selectedChannelId }));
    }
  }, [dispatch, selectedChannelId, isSendingDisabled]);

  return (
    <button
      type="button"
      disabled={isSendingDisabled}
      onMouseDown={sendMessage}
      className={styles.sendButton}
    >
      <IconComponent
        Icon={ICON.SEND}
        color={ICON_COLOR.WHITE}
        style={{ marginLeft: '2px' }}
        alt="send icon"
      />
    </button>
  );
};

export default SendButton;
