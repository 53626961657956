import favicon from './assets/favicon.svg';
import opIcon from './assets/agent-op-icon.svg';
import simpleLogo from './assets/simple_logo.svg';
import logo from './assets/logo.svg';
import chatLogo from './assets/slogan.png';
import privacy from '../commonAssets/beemPrivacyText';
import terms from '../commonAssets/termsText';
import imprint from './assets/imprintText';
import deleteAccountText from '../commonAssets/deleteAccountText';

const config = {
  title: 'BEEM',
  description: 'Mit BEEM stellen Fachhandwerker ihrem persönlichen Fachgroßhandel-Kontakt schnell und einfach Fragen stellen. Jederzeit rechtssicher.',
  identifier: 'agent',
  appName: 'BEEM',
  defaultBrand: 'BEEM',
  url: 'getbeem.de',
  apiBaseUrl: 'https://api.getbeem.de',
  helpUrl: 'https://hilfe.getbeem.de/',
  iosStoreUrl: 'https://apps.apple.com/us/app/beem-app/id1612676323',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=de.getbeem',
  supportEmail: 'support@getbeem.de',
  favicon,
  logo,
  chatLogo,
  simpleLogo,
  packageId: 'de.getbeem',
  privacyText: privacy,
  usercentricsId: '0ulqs2LH6',
  termsText: terms,
  imprintText: imprint,
  deleteAccountText,
  welcomeText: 'Mitarbeiterlogin',
  identifierPlaceholder: 'E-Mail Adresse',
  mainText: 'Bitte geben Sie Ihre E-Mail Adresse ein, um sich als Mitarbeiter anzumelden.',
  opIcon,
};

export default config;
