import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getSalesSpaceChannelIdsForGrouping,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import SalesSpaceChannelListItem from '../SalesSpaceChannelListItem/SalesSpaceChannelListItem';
import styles from './styles.module.scss';

/**
 * A section in the Sales Space List
 * containing all the sales space channels for a specific grouping
 */
const SalesSpaceChannelGroupingSection = ({ grouping }) => {

  const groupingChannelIds = useSelector(getSalesSpaceChannelIdsForGrouping(grouping));

  if (!groupingChannelIds) {
    return null;
  }

  return (
    <div key={grouping}>
      <div className={styles.channelListSectionHeader}>
        <div className={styles.groupingHeader}>
          {grouping}
        </div>
      </div>
      {groupingChannelIds.map(channelId => {
        return (
          <SalesSpaceChannelListItem
            key={channelId}
            channelId={channelId}
          />
        );
      })
        }
    </div>
  );
};

SalesSpaceChannelGroupingSection.propTypes = {
  grouping: PropTypes.string.isRequired,
};

export default SalesSpaceChannelGroupingSection;
