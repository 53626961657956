import { useCallback, useEffect } from 'react';
import { updateAgentStatus } from 'gcs-common/slices/currentAgent/currentAgentThunks';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { ONLINE_STATUS } from 'gcs-common/helper/userStatusHelper';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { App } from '@capacitor/app';
import { addBreadcrumb } from '@sentry/react';
import { appPaused, appResumed } from '../slices/uiState/uiStateSlice';

/**
 * See https://capacitorjs.com/docs/apis/app#addlistenerappstatechange
 */
const usePauseResumeEventListener = () => {
  const dispatch = useDispatch();
  const isAgent = useSelector(getIsAgent);

  const isChatConnected = useSelector(getIsChatConnected);

  const setStatusOnline = useCallback(() => {
    dispatch(updateAgentStatus({ statusType: ONLINE_STATUS.ONLINE }));
  }, [dispatch]);

  const setStatusIdle = useCallback(() => {
    dispatch(updateAgentStatus({ statusType: ONLINE_STATUS.IDLE }));
  }, [dispatch]);

  const setStatus = useCallback(({ isActive }) => {

    if (isActive) {
      setStatusOnline();
    } else {
      setStatusIdle();
    }
  }, [setStatusIdle, setStatusOnline]);

  useEffect(() => {
    let handle;
    if (isAgent && isChatConnected) {
      handle = App.addListener('appStateChange', setStatus);
    }
    return async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      handle && (await handle).remove();
    };
  }, [isAgent, setStatus, isChatConnected]);

  useEffect(() => {
    if (isAgent && isChatConnected) {
      setStatusOnline();
    }
  }, [setStatusOnline, isAgent, isChatConnected]);

  const recordStatusChangeForSentry = useCallback(({ isActive }) => {
    addBreadcrumb({
      level: 'info',
      category: 'appStateChange',
      message: isActive ? 'Resume app from background' : 'Pause app to background',
    });
  }, []);

  useEffect(() => {
    const handlePromise = App.addListener('appStateChange', recordStatusChangeForSentry);
    return async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      handlePromise && (await handlePromise).remove();
    };
  }, [recordStatusChangeForSentry]);

  useEffect(() => {

    const handlePromise = App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        dispatch(appResumed());
      } else {
        dispatch(appPaused());
      }
    });
    return async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      handlePromise && (await handlePromise).remove();
    };
  }, [dispatch]);

};

export default usePauseResumeEventListener;
