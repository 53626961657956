import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRehydrationFinished } from 'gcs-common/slices/persistence/persistenceSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getDialogError } from 'gcs-common/slices/errorDialogSlice/errorDialogSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import { LOGIN_ROUTES, REGISTRATION_ROUTES } from 'gcs-common/constants/routes';
import { IS_IOS } from 'gcs-common/constants/deviceConstants';
import { useLocation } from 'react-router';
import setBgColor from '../helper/bgColorHelper';
import styles from '../scss/status-bar-colors.module.scss';
import {
  getInitialDataLoadSucceeded,
  getIsAtLeastOneMessageSelected, getShowDeletionConfirmationModal,
} from '../slices/uiState/uiStateSelector';
import {
  getShowAppMustUpgradeDialog,
  getshowFetchProcessesErrorModal,
  getShowNoGCOPAccountModal,
} from '../slices/uiState/uiStateSelectors';

const useStatusBarColorByRoute = () => {

  const { pathname } = useLocation();
  const { dialogErrorMsg } = useSelector(getDialogError);
  const showMustUpgradeDialog = useSelector(getShowAppMustUpgradeDialog);
  const hasSelectedMessageId = useSelector(getIsAtLeastOneMessageSelected);
  const rehydrationFinished = useSelector(getRehydrationFinished);
  const initialDataLoadSucceeded = useSelector(getInitialDataLoadSucceeded);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const showFetchProcessesErrorModal = useSelector(getshowFetchProcessesErrorModal);
  const showNoGCOPAccountModal = useSelector(getShowNoGCOPAccountModal);
  const showDeletionConfirmationModal = useSelector(getShowDeletionConfirmationModal);
  const isAgent = useSelector(getIsAgent);
  const isJoinedChannel = useSelector(getCurrentUserIsMemberOfChannel(selectedChannelId));

  useEffect(() => {
    if ((dialogErrorMsg || showMustUpgradeDialog || showDeletionConfirmationModal) && !IS_IOS) {
      setBgColor(styles.modalBg);
      return;
    }
    switch (pathname) {
      case '/':
        if (hasSelectedMessageId) {
          setBgColor(styles.selectedMessageTop, styles.chatBgBottom);
          break;
        }
        if (
          !rehydrationFinished || (!selectedChannelId || !initialDataLoadSucceeded)
        ) {
          setBgColor(styles.loaderBg);
          break;
        }
        if (!IS_IOS && (
          showFetchProcessesErrorModal
          || showNoGCOPAccountModal
        )) {
          setBgColor(styles.modalLightBgTop, styles.modalLightBgBottom);
          return;
        }
        if (isAgent && !isJoinedChannel) {
          setBgColor(styles.unjoinedChatBgTop, styles.unjoinedChatBgBottom);
          break;
        }
        setBgColor(styles.chatBgTop, styles.chatBgBottom);
        break;
      case LOGIN_ROUTES.LOGIN:
        setBgColor(styles.headerBgTop, styles.headerBgBottom);
        break;
      case REGISTRATION_ROUTES.REGISTRATION_PROFILE:
      case REGISTRATION_ROUTES.REGISTRATION:
        setBgColor(styles.registerProfileBg);
        break;
      case '/channel':
        setBgColor(styles.channelDetailsBgTop, styles.channelDetailsBgBottom);
        break;
      case '/gallery':
        setBgColor(styles.imageSwiperBg);
        break;
      case '/forward':
        setBgColor(styles.forwardMessageBgTop, styles.forwardMessageBgBottom);
        break;
      case '/search':
        setBgColor(styles.searchBgTop, styles.searchBgBottom);
        break;
      case '/tutorial':
        setBgColor(styles.tutorialPageBg);
        break;
      case '/changelog':
        setBgColor(styles.infoPopupBg);
        break;
      case '/settings':
        setBgColor(styles.settingsBgTop, styles.settingsBgBottom);
        break;
      case '/gcopAccounts':
        setBgColor(styles.searchBgTop);
        break;
      case '/settings/chatSettings':
        setBgColor(styles.chatSettingsBg);
        break;
      default:
        setBgColor(styles.defaultBg);
        break;
    }
  }, [
    showNoGCOPAccountModal,
    showMustUpgradeDialog,
    showFetchProcessesErrorModal,
    selectedChannelId,
    initialDataLoadSucceeded,
    rehydrationFinished,
    dialogErrorMsg,
    pathname,
    hasSelectedMessageId,
    showDeletionConfirmationModal,
    isAgent,
    isJoinedChannel,
  ]);
};

export default useStatusBarColorByRoute;
