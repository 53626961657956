import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChannelImage, getChannelMemberCount, getChannelStatus,
  getChannelSubtitle, getChannelTitle,
  getIsSalesSpaceChannelType,
} from 'gcs-common/slices/channel/channelSelectors';
import {
  channelInputForwardRecipientChannelIdStaged,
  channelInputForwardRecipientChannelIdUnstaged,
} from 'gcs-common/slices/messageInput/messageInputSlice';
import { getChannelInputForwardRecipientChannelId } from 'gcs-common/slices/messageInput/messageInputSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getForwardSearchInput } from 'gcs-common/slices/channelList/channelListSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import checkIcon from '../../img/icons/check_white.svg';
import styles from './styles.module.scss';
import Highlight from '../Highlight/Highlight';

const ForwardChannelListItem = ({ channelId }) => {

  const dispatch = useDispatch();
  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedChannelIds = useSelector(
    getChannelInputForwardRecipientChannelId(selectedChannelId),
  );
  const forwardSearchInput = useSelector(getForwardSearchInput);
  const channelTitle = useSelector(getChannelTitle(channelId));
  const channelSubtitle = useSelector(getChannelSubtitle(channelId));
  const channelImage = useSelector(getChannelImage(channelId));
  const channelStatus = useSelector(getChannelStatus(channelId));
  const isSalesSpace = useSelector(getIsSalesSpaceChannelType(channelId));
  const isSelected = selectedChannelIds && selectedChannelIds.includes(channelId);
  const channelMemberCount = useSelector(getChannelMemberCount(channelId));
  const isAgent = useSelector(getIsAgent);

  const onToggle = useCallback(() => {
    if (isSelected) {
      dispatch(channelInputForwardRecipientChannelIdUnstaged(
        { channelId: selectedChannelId, recipientChannelId: channelId },
      ));
    } else {
      dispatch(channelInputForwardRecipientChannelIdStaged(
        { channelId: selectedChannelId, recipientChannelId: channelId },
      ));
    }
  }, [channelId, dispatch, isSelected, selectedChannelId]);

  return (
    <div
      role="none"
      onClick={onToggle}
      className={isSelected ? styles.selectedChannelListItem : styles.listItem}
    >
      <div className={isSelected
        ? styles.selectedCheckIconWrapper
        : styles.checkIconWrapper}
      >
        {isSelected && (
          <img
            src={checkIcon}
            alt="checkme"
          />
        )}
      </div>
      <ImageWithStatus
        imageUrl={channelImage}
        userStatus={channelStatus}
        isSalesSpace={isSalesSpace}
        channelMemberCount={channelMemberCount}
        isAgent={isAgent}
      />
      <div className={styles.content}>
        <div>
          <Highlight search={forwardSearchInput}>
            {channelTitle}
          </Highlight>
        </div>
        <div className={styles.customerCompany}>
          <Highlight search={forwardSearchInput}>{channelSubtitle}</Highlight>
        </div>
      </div>
    </div>
  );
};

ForwardChannelListItem.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ForwardChannelListItem;
