import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { useQueryClient } from '@tanstack/react-query';
import { getGetETSTicketNotificationsQueryKey } from '../clients/api/entities/tickets/tickets-api-helpers';

interface UseTicketNotificationsProps {
  refetchOnWindowFocus: boolean
}

/** Fetches and continously updates ETS ticket notifications. */
const useTicketNotifications = (props: UseTicketNotificationsProps) => {
  const { refetchOnWindowFocus } = props;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const etsEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.ETS_ENABLED));
  const isOnline = useSelector(getIsOnline);

  useEffect(() => {
    if (!etsEnabled || !isOnline) return undefined;

    queryClient.invalidateQueries({ queryKey: getGetETSTicketNotificationsQueryKey() });

    // periodically refetch notifications from server
    // to be replaced with websockets once we have them
    const timeInterval = 1 * 60 * 1000; // 1 minute
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries({ queryKey: getGetETSTicketNotificationsQueryKey() });
    }, timeInterval);

    // refetch notifications instantly whenever user is coming to back to the app
    const onFocus = () => {
      queryClient.invalidateQueries({ queryKey: getGetETSTicketNotificationsQueryKey() });
    };
    if (refetchOnWindowFocus) {
      window.addEventListener('focus', onFocus);
    }

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('focus', onFocus);
    };
  }, [dispatch, etsEnabled, refetchOnWindowFocus, isOnline, queryClient]);
};

export default useTicketNotifications;
