import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { processSelected } from 'gcs-common/slices/processes/processesSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { getUniqueProcessNumber } from 'gcs-common/helper/processTextHelper';
import styles from './styles.module.scss';
import cubeIcon from '../../../img/icons/cube.svg';

function ProcessMessage({ process }) {
  const {
    Hint,
  } = process;
  // Note: The OR operator here, to read the messages
  // that are already saved in twilio properly
  const Number = process.ProcessNumber || process.Number;
  const TypeText = process.ProcessTypeDescription || process.TypeText;
  const Suffix = process.ProcessSuffix || process.Suffix;

  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setProcess = () => {
    navigate(CHAT_ROUTES.PROCESSES(selectedChannelId));
    dispatch(processSelected({
      selectedProcessNumber: getUniqueProcessNumber(process.ProcessNumber, process.ProcessSuffix),
    }));
  };

  return (
    <div className={styles.process} role="presentation" onClick={setProcess}>
      <div className={styles.magicCubeWrapper}>
        <img src={cubeIcon} alt="process-detail-cube" className={styles.magicCubeSmallIcon} />
      </div>
      <div>
        <div className={styles.processWrapper}>
          <div className={styles.processHeader}>{`${TypeText}: `}</div>
          <span>{getUniqueProcessNumber(Number, Suffix)}</span>
          <div className={styles.processDetail}>{Hint}</div>
        </div>
      </div>
    </div>
  );

}

export default ProcessMessage;

ProcessMessage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  process: PropTypes.object.isRequired,
};
