import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFileType } from 'gcs-common/helper/fileHelper';
import { FILE_TYPE } from 'gcs-common/constants/fileTypes';
import { createFileURL, removeFileURL } from 'gcs-common/helper/fileUrlHelper';
import fileWhite from '../../img/icons/file_white_outline.svg';
import PdfPreviewer from '../PdfPreviewer/PdfPreviewer';
import VideoLocalPlayer from '../VideoLocalPlayer/VideoLocalPlayer';

const LocalFilePreview = (
  {
    file,
    filePlaceHolder = fileWhite,
    ...props
  },
) => {
  const fileType = getFileType(file);
  const [mediaUrl, setMediaUrl] = React.useState('');

  useEffect(() => {
    if (file instanceof File) {
      const url = createFileURL(file);
      setMediaUrl(url);
      return () => removeFileURL(url);
    }
    setMediaUrl(file.webPath);
    return () => {};
  }, [file, fileType]);

  switch (fileType) {
    case FILE_TYPE.IMAGE:
      return (
        <img
          alt="local-preview"
          src={mediaUrl || filePlaceHolder}
          {...props}
        />
      );
    case FILE_TYPE.PDF:
      return <PdfPreviewer pdfFile={mediaUrl} />;
    case FILE_TYPE.VIDEO:
      return <VideoLocalPlayer videoUrl={mediaUrl} />;
    default:
      return <img alt="local-preview" src={filePlaceHolder} {...props} />;
  }
};

LocalFilePreview.propTypes = {
  file: PropTypes.shape().isRequired,
  filePlaceHolder: PropTypes.string,
};

export default LocalFilePreview;
