import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getVideoThumbnailUrl } from 'gcs-common/helper/videoHelper';
import playIcon from 'gcs-common/img//movie.svg';
import { openVideoFullscreen } from '../../slices/uiState/uiStateSlice';
import styles from './styles.module.scss';

const VideoStreamThumbnail = ({ messageId, playbackId, disableClick }) => {
  const dispatch = useDispatch();

  const playVideoFullscreen = useCallback(() => {
    dispatch(openVideoFullscreen({ videoMessageId: messageId, videoId: playbackId }));
  }, [dispatch, playbackId, messageId]);

  return (
    <button type="button" className={styles.thumbnailContainer} disabled={disableClick} onClick={playVideoFullscreen}>
      <div className={styles.playBtnContainer}>
        <div className={styles.icon}>
          <img
            src={playIcon}
            alt="video-indicator"
          />
        </div>
      </div>
      <img src={getVideoThumbnailUrl(playbackId)} alt="video-thumbnail" />
    </button>
  );
};

export default VideoStreamThumbnail;

VideoStreamThumbnail.propTypes = {
  playbackId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  disableClick: PropTypes.bool.isRequired,
};
