import { useSelector } from 'react-redux';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import ChatHeader from '../ChatHeader/ChatHeader';
import { getIsAtLeastOneMessageSelected } from '../../slices/uiState/uiStateSelector';

const ChatHeaderWrapper = () => {
  const isAtLeastOneMessageSelected = useSelector(getIsAtLeastOneMessageSelected);

  return (
    <div data-testid="chat-header-wrapper">
      {isAtLeastOneMessageSelected
        ? <HeaderMenu />
        : (
          <ChatHeader />
        )}
    </div>
  );
};

export default ChatHeaderWrapper;

ChatHeaderWrapper.propTypes = {
};
