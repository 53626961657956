// from design standpoint
import PropTypes from 'prop-types';
import { UNREAD_COUNT_DISPLAY_THRESHOLD } from 'gcs-common/constants/channelConstants';

const UnreadCountDisplay = ({ unreadCount }) => {
  if (!unreadCount) { return null; }
  const hasOverflow = unreadCount > UNREAD_COUNT_DISPLAY_THRESHOLD;
  const displayCount = hasOverflow ? UNREAD_COUNT_DISPLAY_THRESHOLD : unreadCount;
  return `${displayCount}${hasOverflow ? '+' : ''}`;
};

UnreadCountDisplay.propTypes = {
  unreadCount: PropTypes.number.isRequired,
};

export default UnreadCountDisplay;
