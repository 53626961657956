import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getConnectionIdsForHouse } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { getHouseName } from 'gcs-common/slices/houses/housesSelectors';
import { ConnectionComponent } from '../ConnectionComponent/ConnectionComponent';
import styles from './styles.module.scss';

export const HouseConnections = ({ houseId }) => {

  const houseName = useSelector(getHouseName(houseId));
  const connectionIds = useSelector(getConnectionIdsForHouse(houseId));
  return (
    <div>
      <div className={styles.title}>
        <div>Haus</div>
        <div>{houseName}</div>
      </div>
      {connectionIds.map(
        connectionId => (
          <ConnectionComponent key={connectionId} connectionId={connectionId} />
        ),
      )}
    </div>
  );
};

HouseConnections.propTypes = {
  houseId: PropTypes.string.isRequired,
};
