import { useGetUploadMediaMutation, usePreSignUrl } from './media-api';
import { createFileFromUrl } from '../../../../helper/fileHelper';
import { debugLogger } from '../../../../helper/debugLogger';

export const useSignedUploadMedia = () => {
  const { mutateAsync, isPending } = useGetUploadMediaMutation();
  const { mutateAsync: mutateSignUrl, isPending: isUploading } = usePreSignUrl();

  const uploadSignedMedia = async (imageUrl: string, currentUserId: string) => {
    if (!imageUrl) {
      return null;
    }

    try {
      const fileName = `${currentUserId}_${Date.now()}.png`;
      const file = await createFileFromUrl(imageUrl, fileName, 'image/png');
      const presignedUrl = await mutateAsync({
        query: {
          file_name: fileName,
        },
      });

      if (presignedUrl) {
        await mutateSignUrl({
          url: presignedUrl.preSignedUrl,
          file,
        });
        return encodeURI(presignedUrl.imageUrl);
      }
    } catch (error) {
      debugLogger('Error during upload:', error);
    }

    return null;
  };

  const isProcessing = isPending || isUploading;

  return { uploadSignedMedia, isProcessing };
};
