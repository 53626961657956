import { useState, useRef } from 'react';
import { Dialog, DialogTrigger, DialogContent } from 'gcs-common/design-system/ui/dialog';
import { Button, ButtonSize, ButtonVariant } from 'gcs-common/design-system/ui/button';
import Anchor from 'gcs-common/design-system/ui/anchor';
import { PhotoCamera } from '@project-lary/react-material-symbols';
import Camera from 'gcs-common/design-system/camera/camera';
import { getPictureFromCamera } from 'gcs-common/helper/capacitorImageHelper';
import IconButton from './icon-button';
import { IS_NATIVE } from 'gcs-common/constants/deviceConstants';


interface ProfilePictureProps {
  value?: string;
  onChange: (value: string) => void;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ value, onChange }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [step, setStep] = useState<'select' | 'camera'>('select');
  const fileInputRef = useRef<HTMLInputElement>(null);


  const handleCapturedImages = (images: string[]) => {
    if (images.length > 0) {
      const latestImage = images[images.length - 1];
      if (latestImage) {
        onChange(latestImage);
        setShowDialog(false);
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === 'string') {
          onChange(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCaptureWithCapacitor = async () => {
    try {
      const result = await getPictureFromCamera('CAMERA', 'profile_image', false, false);
      if (result && typeof result === 'string') {
        onChange(result);
      }
    } catch (error) {
      console.error('Error capturing image with Capacitor:', error);
    }
  };

  return (
    <div className="flex items-center justify-center">
      {IS_NATIVE ? (
        <div className="flex size-[100px] items-center justify-center rounded-full bg-neutral-5" onClick={handleCaptureWithCapacitor}>
          {value ? (
            <img
              src={value}
              alt="Profile"
              className="size-full cursor-pointer rounded-full object-cover"
            />
          ) : (
            <IconButton
              Icon={PhotoCamera}
              className="flex cursor-pointer rounded-md text-accent-9"
              size="l"
            />
          )}
        </div>
      ) : (
        <Dialog
          open={showDialog}
          onOpenChange={(open) => setShowDialog(open)}
        >
          <div className="flex size-[100px] items-center justify-center rounded-full bg-neutral-5">
            <DialogTrigger asChild>
              {value ? (
                <img
                  src={value}
                  alt="Profile"
                  className="size-full cursor-pointer rounded-full object-cover"
                />
              ) : (
                <IconButton
                  Icon={PhotoCamera}
                  className="flex cursor-pointer rounded-md text-accent-9"
                  size="l"
                />
              )}
            </DialogTrigger>
          </div>
          <DialogContent className="m-0  w-96 border-none py-5 outline-none" hideDefaultCloseButton>
            {step === 'select' ? (
              <div className="flex flex-col items-center justify-center gap-4">
                <Button
                  variant={ButtonVariant.soft_neutral}
                  id="select-gallery-button"
                  size={ButtonSize.l}
                  className="w-full"
                  onClick={() => fileInputRef.current?.click()}

                >
                  Ein Bild hochladen
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  className="hidden"
                  onChange={(event) => {
                    handleFileUpload(event, onChange);
                    setShowDialog(false);
                  }}
                />
                <Button
                  variant={ButtonVariant.soft_neutral}
                  id="select-camera-button"
                  size={ButtonSize.l}
                  className="w-full"
                  onClick={() => setStep('camera')}
                >
                  Foto machen
                </Button>
                <Anchor
                  onClick={() => setShowDialog(false)}
                  className="no-underline"
                >
                  Abbrechen
                </Anchor>
              </div>
            ) : (
              <Camera
                onClosed={() => {
                  setShowDialog(false);
                  setStep('select');
                }}
                onCapturedImages={handleCapturedImages}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default ProfilePicture;
