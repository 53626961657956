import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessages } from '../messages/messagesSelectors';
import { setToolbarTab } from '../uiState/uiStateSlice';
import { TOOL_TABS } from '../../constants/toolUIFlags';
import { filesFromMessages } from '../../helper/mediaHelper';

export const newTicketFromMessages = createAsyncThunk(
  'tickets/newTicketFromMessages',
  (
    { messageIds }: { channelId: string, messageIds: string[] },
    { getState, dispatch },
  ) => {
    const messages = getMessages(getState());
    const selectedMessages = messageIds.map(id => messages[id]);

    const files = filesFromMessages(selectedMessages);

    dispatch(setToolbarTab({ tab: TOOL_TABS.TICKETS }));

    return { messages: selectedMessages, files };
  },
);
