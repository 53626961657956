import { createSelector } from '@reduxjs/toolkit';
import avatarPlaceHolder from '../../img/user.svg';
import ASYNC_STATE from '../../constants/AsyncState';
import { getIsChatConnected } from '../chatConnection/chatConnectionSelectors';
import { calculateUserStatus } from '../../helper/userStatusHelper';

export const getCurrentUser = state => state.currentUser.currentUser;

export const getIsCurrentUserLoading = (state) => (
  state.currentUser.fetch.status === ASYNC_STATE.LOADING
);

export const getIsCurrentUserHasConnections = state => (
  state.currentUser.currentUser && state.currentUser.currentUser.hasConnections
);
export const getIsAgent = state => (
  state.currentUser.currentUser && state.currentUser.currentUser.isAgent
);

export const getCurrentUserId = state => (
  state.currentUser.currentUser && state.currentUser.currentUser.id
);
export const getAgentNumber = state => (
  state.currentUser.currentUser && state.currentUser.currentUser.agentNumber
);
export const getEmail = state => (
  state.currentUser.currentUser && state.currentUser.currentUser.email
);
export const getCraftsmanBusinessUnit = state => (
  state.currentUser.currentUser && state.currentUser.currentUser.businessUnitName
);
export const getIsUpdateProfilePicturePending = state => (
  state.currentUser.isUpdateProfilePicturePending
);

export const getPermissions = createSelector(
  getCurrentUser,
  (currentUser) => {
    return currentUser ? (currentUser.permissions || []) : undefined;
  },
);

export const getHasPermission = (permissionToCheck) => createSelector(
  getPermissions,
  (permissions) => {
    if (!permissions) {
      // caution: undefined is different form true/false. It means we don't know yet
      return undefined;
    }
    return permissions.includes(permissionToCheck);
  },
);

export const getCurrentUserRoles = createSelector(
  getCurrentUser,
  currentUser => currentUser?.roles || [],
);

export const getFeatureFlags = createSelector(
  getCurrentUser,
  (currentUser) => currentUser?.featureFlags || [],
);

export const getHasFeatureFlag = featureFlag => createSelector(
  getFeatureFlags,
  (featureFlags) => featureFlags.includes(featureFlag),
);

export const getCurrentFriendlyName = createSelector(
  getCurrentUser,
  (currentUser) => currentUser?.friendlyName,
);

export const getCurrentUserImage = createSelector(
  getCurrentUser,
  (currentUser) => currentUser?.imageUrl || avatarPlaceHolder,
);

export const getHasCurrentUserImage = createSelector(
  getCurrentUser,
  (currentUser) => !!(currentUser?.imageUrl),
);

export const getCurrentUserCompany = createSelector(
  getCurrentUser,
  (currentUser) => currentUser?.userCompany,
);

export const getCurrentUserStatus = createSelector(
  getCurrentUser, getIsChatConnected,
  (currentUser, isConnected) => {
    return calculateUserStatus(isConnected, currentUser);
  },
);

export const getIsRegistered = createSelector(
  getCurrentUser,
  (currentUser) => {
    if (!currentUser) {
      return undefined;
    }
    return !!currentUser.registered;
  },
);

export const getDeleteCurrentUserLoading = state => (
  state.currentUser.delete.status === ASYNC_STATE.LOADING
);

export const getDeleteCurrentUserError = state => (
  state.currentUser.delete.error
);

export const getToggleChannelVisibilityIsLoading = (state) => (
  state.currentUser.toggleChannelVisibility.status === ASYNC_STATE.LOADING
);

export const getCurrentUserErpCustomersLoading = state => {
  return state.currentUser.fetchCurrentUserErpCustomers.status === ASYNC_STATE.LOADING;
};

export const getCurrentUserErpCustomers = state => state.currentUser.erpCustomers.data;
