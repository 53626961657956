import { useSelector } from 'react-redux';
import { getSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelTypeFilter';
import { getDisplayChannelsIds } from 'gcs-common/slices/channelList/channelListSelectors/getDisplayChannelsIds';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { setSearchInput } from 'gcs-common/slices/channelList/channelListSlice';
import { CHANNEL_FILTER_TYPES } from 'gcs-common/helper/channelTypeHelper';
import PropTypes from 'prop-types';
import { getChannelListChannelIds } from 'gcs-common/slices/channels/channelsSelectors';
import ChannelTypeSelector from '../../ChannelTypeSelection/ChannelTypeSelector';
import styles from './styles.module.scss';
import ChannelSearchInput from '../../../ChannelSearchInput/ChannelSearchInput';
import SideMenuMessage from '../../SideMenuMessage/SideMenuMessage';
import SalesSpaceChannelSortAndFilter from '../../ChannelSort/SalesSpaceChannelSortAndFilter/SalesSpaceChannelSortAndFilter';
import DirectChatChannelSort from '../../ChannelSort/DirectChatChannelSort/DirectChatChannelSort';
import ChannelList from '../ChannelList/ChannelList';
import SalesSpaceChannelList from '../../SalesSpaceChannelList/SalesSpaceChannelList';

const ChannelListAgent = ({ isForFileShare = false }) => {
  const allChannelIds = useSelector(getChannelListChannelIds);
  const channelsToShow = useSelector(getDisplayChannelsIds);
  const channelTypeFilter = useSelector(getSelectedChannelTypeFilter);

  return (
    <>
      <div className={styles.channelListTopBar}>
        <ChannelTypeSelector />
      </div>
      <ChannelSearchInput type="searchInput" getSearchInput={getSearchInput} setSearchInput={setSearchInput} light />

      {channelsToShow?.length === 0 && isForFileShare && (
        <p style={{ marginTop: '30px', textAlign: 'center' }}>Keine Chats gefunden</p>
      )}

      {channelTypeFilter === CHANNEL_FILTER_TYPES.TEAM_CHATS && (
      <SalesSpaceChannelSortAndFilter />
      )}

      {channelTypeFilter === CHANNEL_FILTER_TYPES.DIRECT_CHATS && <DirectChatChannelSort />}

      {allChannelIds?.length === 0 && !isForFileShare && (
        <SideMenuMessage text="Um einen Chat zu starten, tippen Sie bitte auf den Plus-Button." />
      )}

      {channelsToShow?.length === 0 && isForFileShare && (
        <p style={{ marginTop: '30px', textAlign: 'center' }}>Keine Chats gefunden</p>
      )}

      {channelTypeFilter !== CHANNEL_FILTER_TYPES.TEAM_CHATS
       && channelsToShow?.length !== 0 && <ChannelList /> }
      {channelTypeFilter === CHANNEL_FILTER_TYPES.TEAM_CHATS
       && channelsToShow?.length !== 0 && <SalesSpaceChannelList /> }
    </>
  );
};

ChannelListAgent.propTypes = {
  isForFileShare: PropTypes.bool,
};

export default ChannelListAgent;
