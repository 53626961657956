import { type VariantProps, cva } from 'class-variance-authority';
import type * as React from 'react';
import { cn } from '../utils/tailwind-merge';

const badgeVariants = cva(
  'inline-flex items-center text-xs font-normal leading-4 tracking-[.04em]',
  {
    variants: {
      variant: {
        solid_success: 'bg-success-alpha-9 text-white',
        solid_error: 'bg-error-9 text-white',
        soft_success: 'bg-success-alpha-3 text-success-alpha-9',
        soft_error: 'bg-error-3 text-error-alpha-11',
        soft_warning: 'bg-warning-alpha-3 text-warning-alpha-11',
        soft_neutral: 'bg-neutral-alpha-3 text-neutral-alpha-11',
      },
      type: {
        text: 'whitespace-nowrap break-words rounded-[3px] px-2 py-1',
        counter: 'size-[20px] justify-center rounded-md',
      },
    },
    defaultVariants: {
      variant: 'soft_neutral',
      type: 'text',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, type, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, type }), className)} {...props} />;
}

export { Badge, badgeVariants };
