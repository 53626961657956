/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  seenTutorialsPages: [],
};

const persistWhitelist = [
  'seenTutorialsPages',
];


const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    addToSeenTutorialsPage: (state, action) => {
      const { page } = action.payload;
      state.seenTutorialsPages = Array.from(new Set([...state.seenTutorialsPages, page]));
    },
  },
});

export { persistWhitelist as tutorialPersistWhitelist };

export const {
  addToSeenTutorialsPage,
} = tutorialSlice.actions;

export default tutorialSlice.reducer;
