// eslint-disable-next-line import/no-cycle
import { TicketsState } from '../../../../slices/tickets/ticketsSlice';
import { Parameters, RequestBody, RequestForm, Response } from '../../../../../types/typeHelper';
import { useApiClient, reactQueryClient } from '../../client';
import { createFileFromUrl } from '../../../../helper/fileHelper';
// eslint-disable-next-line import/no-cycle
import { useUploadETSTicketAttachmentsMutation } from './tickets-api';


export enum TicketsClientQueryKeys {
  GET_ETS_TICKETS = 'GET_ETS_TICKETS',
  GET_ETS_TICKET_RATING = 'GET_ETS_TICKET_RATING',
  CREATE_ETS_TICKET = 'CREATE_ETS_TICKET',
  ADD_ETS_TICKET_RATING = 'ADD_ETS_TICKET_RATING',
  PATCH_ETS_TICKET_STATUS = 'PATCH_ETS_TICKET_STATUS',
  DELETE_ETS_TICKET_COMMENT = 'DELETE_ETS_TICKET_COMMENT',
  DELETE_ETS_TICKET_NOTIFICATION = 'DELETE_ETS_TICKET_NOTIFICATION',
  GET_ETS_TICKET_PARTS = 'GET_ETS_TICKET_PARTS',
  GET_ETS_TICKET_NOTIFICATIONS = 'GET_ETS_TICKET_NOTIFICATIONS',
  GET_ETS_TICKET = 'GET_ETS_TICKET',
  GET_ETS_TICKET_ATTACHMENTS = 'GET_ETS_TICKET_ATTACHMENTS',
  GET_ETS_TICKET_COMMENTS = 'GET_ETS_TICKET_COMMENTS',
  UPLOAD_ETS_TICKET_ATTACHMENTS = 'UPLOAD_ETS_TICKET_ATTACHMENTS',
  ADD_ETS_TICKET_COMMENT = 'ADD_ETS_TICKET_COMMENT',
  DELETE_ETS_TICKET_RATING = 'DELETE_ETS_TICKET_RATING',
}

export type FetchETSTicketsResponseData = Response<'/chat/ets/tickets', 'get'>;
export type FetchETSTicketRatingResponseData = Response<'/chat/ets/tickets/{ticket_id}/rating', 'get'>;
export type FetchETSTicketNotificationsResponseData = Response<'/chat/current-user/ets-notifications', 'get'>;
export type FetchETSTicketCommentsResponseData = Response<'/chat/ets/tickets/{ticket_id}/comments', 'get'>;
export type FetchETSTicketAttachmentsResponseData = Response<'/chat/ets/tickets/{ticket_id}/attachments', 'get'>;
export type FetchETSTicketPartsResponseData = Response<'/chat/ets/tickets/{ticket_id}/parts', 'get'>;
export type FetchETSTicketResponseData = Response<'/chat/ets/tickets/{ticket_id}', 'get'>;

export const getGetETSTicketsQueryKey = (query: Parameters<'/chat/ets/tickets', 'get'>['query']) => [
  TicketsClientQueryKeys.GET_ETS_TICKETS, query];

export const getGetETSTicketRatingQueryKey = (path: Parameters<'/chat/ets/tickets/{ticket_id}/rating', 'get'>['path']) => [
  TicketsClientQueryKeys.GET_ETS_TICKET_RATING, path];

export const getGetETSTicketPartsQueryKey = (path: Parameters<'/chat/ets/tickets/{ticket_id}/parts', 'get'>['path']) => [
  TicketsClientQueryKeys.GET_ETS_TICKET_PARTS, path];

export const getGetETSTicketNotificationsQueryKey = () => [
  TicketsClientQueryKeys.GET_ETS_TICKET_NOTIFICATIONS];

export const getGetETSTicketQueryKey = (path: Parameters<'/chat/ets/tickets/{ticket_id}', 'get'>['path']) => [
  TicketsClientQueryKeys.GET_ETS_TICKET, path];

export const getGetETSTicketAttachmentsQueryKey = (path: Parameters<'/chat/ets/tickets/{ticket_id}/attachments', 'get'>['path']) => [
  TicketsClientQueryKeys.GET_ETS_TICKET_ATTACHMENTS, path];

export const getGetETSTicketCommentsQueryKey = (path: Parameters<'/chat/ets/tickets/{ticket_id}/comments', 'get'>['path']) => [
  TicketsClientQueryKeys.GET_ETS_TICKET_COMMENTS, path];


export const useETSTicketsApiHelper = () => {
  const client = useApiClient();
  return {
    createETSTicket: async ({ body }: {
      body: RequestBody<'/chat/ets/tickets', 'post'>,
    }) => {
      return client.POST('/chat/ets/tickets', {
        body,
      });
    },
    addETSTicketRating: async ({ path, body }: {
      body: RequestBody<'/chat/ets/tickets/{ticket_id}/rating', 'post'>,
      path: Parameters<'/chat/ets/tickets/{ticket_id}/rating', 'post'>['path']
    }) => {
      return client.POST('/chat/ets/tickets/{ticket_id}/rating', {
        body,
        params: {
          path,
        },
      });
    },
    patchETSTicketStatus: async ({ path }: {
      path: Parameters<'/chat/ets/tickets/{ticket_id}/status', 'patch'>['path'],
    }) => {
      return client.PATCH('/chat/ets/tickets/{ticket_id}/status', {
        params: {
          path,
        },
      });
    },
    deleteETSTicketComment: async ({ path }:{
      path: Parameters<'/chat/ets/tickets/{ticket_id}/comments/{comment_id}', 'delete'>['path']
    }) => {
      return client.DELETE('/chat/ets/tickets/{ticket_id}/comments/{comment_id}', {
        params: {
          path,
        },
      });
    },
    deleteETSTicketNotification: async ({ path }:{
      path: Parameters<'/chat/ets/notifications/{id}', 'delete'>['path']
    }) => {
      return client.DELETE('/chat/ets/notifications/{id}', {
        params: {
          path,
        },
      });
    },
    uploadETSTicketAttachments: async ({ path, form }: {
      path: Parameters<'/chat/ets/tickets/{ticket_id}/attachments', 'post'>['path'],
      form: RequestForm<'/chat/ets/tickets/{ticket_id}/attachments', 'post'>,
    }) => {
      return client.POST('/chat/ets/tickets/{ticket_id}/attachments', {
        params: {
          path,
        },
        body: form,
        bodySerializer(body) {
          const fd = new FormData();
          body.file.forEach((f) => {
            fd.append('file', f);
          });
          return fd;
        },
      });
    },
    addETSTicketComment: async ({ path, body }: {
      body: RequestBody<'/chat/ets/tickets/{ticket_id}/comments', 'post'>,
      path: Parameters<'/chat/ets/tickets/{ticket_id}/comments', 'post'>['path']
    }) => {
      return client.POST('/chat/ets/tickets/{ticket_id}/comments', {
        params: {
          path,
        },
        body,
      });
    },
    deleteETSTicketRating: async ({ path }: {
      path: Parameters<'/chat/ets/tickets/{ticket_id}/rating', 'delete'>['path']
    }) => {
      return client.DELETE('/chat/ets/tickets/{ticket_id}/rating', {
        params: {
          path,
        },
      });
    },
  };
};

export const convertETSTicketsFiltersToQuery = (filters: TicketsState['filters']): Parameters<'/chat/ets/tickets', 'get'>['query'] => {
  return {
    index: filters.pageIndex,
    size: filters.pageSize,
    sorted_column_id: filters.sortedColumnId,
    sort_order: filters.sortOrder,
    status: filters.status,
    start_date: filters.startDate,
    end_date: filters.endDate,
    erp_customer_ids: filters.erpCustomerIds,
    search: filters.search,
    channel_id: filters.channelId,
    creator_id: filters.creatorId,
  };
};

export const generateETSTicketsFilters = (filters: Partial<TicketsState['filters']>):TicketsState['filters'] => {
  return {
    pageIndex: 0,
    pageSize: 10,
    sortedColumnId: undefined,
    sortOrder: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    erpCustomerIds: undefined,
    search: undefined,
    channelId: undefined,
    creatorId: undefined,
    ...filters,
  };
};

export const refetchETSTicketDetails = (ticketId: string) => {
  reactQueryClient.invalidateQueries({
    queryKey: getGetETSTicketQueryKey({ ticket_id: ticketId }),
  });
  reactQueryClient.invalidateQueries({
    queryKey: getGetETSTicketAttachmentsQueryKey({ ticket_id: ticketId }),
  });
  reactQueryClient.invalidateQueries({
    queryKey: getGetETSTicketCommentsQueryKey({ ticket_id: ticketId }),
  });
  reactQueryClient.invalidateQueries({
    queryKey: getGetETSTicketPartsQueryKey({ ticket_id: ticketId }),
  });
  reactQueryClient.invalidateQueries({
    queryKey: getGetETSTicketRatingQueryKey({ ticket_id: ticketId }),
  });
};

export const useUploadETSAttachment = () => {
  const { mutateAsync, isPending } = useUploadETSTicketAttachmentsMutation();

  const upload = async (
    ticketId: string,
    attachment: File | { webPath: string; name: string; type: string },
  ) => {
    let file = attachment;

    if (!(file instanceof File)) {
      file = await createFileFromUrl(file.webPath, file.name, file.type);
    }

    return mutateAsync({
      path: {
        ticket_id: ticketId,
      },
      form: {
        // @ts-expect-error OpenAPI annotates files as strings ¯\_(ツ)_/¯
        file: [file],
      },
    });
  };

  return { upload, isPending };
};

export const useUploadETSAttachments = () => {
  const { upload, isPending } = useUploadETSAttachment();

  const uploadFiles = async (ticketId: string, files: File[]) => {
    const attachmentsPromises = files.map((file) => upload(ticketId, file));
    await Promise.all(attachmentsPromises);
  };

  return { uploadFiles, isPending };
};
