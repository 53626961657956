import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getMessage,
} from 'gcs-common/slices/messages/messageSelector';
import { VIDEO_STATUS } from 'gcs-common/constants/videoStatus';
import VideoUploadProgress from './VideoUploadProgress/VideoUploadProgress';
import VideoProcessing from './VideoProcessing/VideoProcessing';
import VideoDeleted from './VideoDeleted/VideoDeleted';
import VideoMessageFrame from '../VideoMessageFrame/VideoMessageFrame';
import VideoStreamThumbnail from '../VideoStreamThumbnail/VideoStreamThumbnail';

const VideoMessage = ({ messageId, disableClick = false }) => {
  const video = useSelector(getMessage(messageId));

  return (
    <VideoMessageFrame messageId={messageId}>
      {video.videoStatus === VIDEO_STATUS.UPLOADING && (
      <VideoUploadProgress videoId={video.videoId} />
      )}
      {video.videoStatus === VIDEO_STATUS.PROCESSING && (
      <VideoProcessing />
      )}
      {video.videoStatus === VIDEO_STATUS.READY && (
      <VideoStreamThumbnail
        playbackId={video.videoPlaybackId}
        messageId={messageId}
        disableClick={disableClick}
      />
      )}
      {video.videoStatus === VIDEO_STATUS.DELETED && (
      <VideoDeleted />
      )}
    </VideoMessageFrame>
  );
};

export default VideoMessage;

VideoMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  disableClick: PropTypes.bool,
};
