import { useState, ReactNode } from 'react';
import { TransformComponent, TransformWrapper, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import styles from './styles.module.scss';

interface ZoomWrapperProps {
  children: ReactNode;
}

const ZoomWrapper = (props: ZoomWrapperProps) => {
  const { children } = props;
  const [zoom, setZoom] = useState(false);

  const handleZoom = (e: ReactZoomPanPinchRef) => {
    if (e.state.scale > 1 && !zoom) {
      setTimeout(() => setZoom(true), 200);
    } else if (e.state.scale <= 1 && zoom) {
      setZoom(false);
    }
  };

  return (
    <TransformWrapper
      initialScale={1}
      doubleClick={{ mode: zoom ? 'reset' : 'zoomIn' }}
      minScale={1}
      panning={{ disabled: !zoom }}
      onPanningStop={handleZoom}
      onZoom={handleZoom}
    >
      <TransformComponent
        wrapperClass={styles.wrapperClass}
        contentClass={styles.contentClass}
      >
        {children}
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomWrapper;
