/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sendMessageOnEnter: false,
};

const persistWhitelist = [
  'sendMessageOnEnter',
];


const localSettingsSlice = createSlice({
  name: 'localSettings',
  initialState,
  reducers: {
    toogleSendMessageOnEnter: (state) => {
      state.sendMessageOnEnter = !state.sendMessageOnEnter;
    },
  },
});

export { persistWhitelist as localSettingsPersistWhitelist };

export const {
  toogleSendMessageOnEnter,
} = localSettingsSlice.actions;

export default localSettingsSlice.reducer;
