import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getMessageAttributesText,
  getMessageMediaIsImage,
} from 'gcs-common/slices/messages/messageSelector';
import MediaMessageImage from '../MediaMessageImage/MediaMessageImage';
import MediaMessageFile from '../MediaMessageFile/MediaMessageFile';

const MediaMessage = ({
  messageId,
  maxHeight = 300,
  CustomTextComponent = null,
  disableClick = false,
}) => {

  const isImage = useSelector(getMessageMediaIsImage(messageId));
  const text = useSelector(getMessageAttributesText(messageId));

  return (
    <>

      {isImage ? (
        <MediaMessageImage
          messageId={messageId}
          maxHeight={maxHeight}
          disableClick={disableClick}
        />
      ) : (
        <MediaMessageFile
          messageId={messageId}
          maxHeight={maxHeight}
          CustomTextComponent={CustomTextComponent}
          disableClick={disableClick}
        />
      )}

      {text && (
      <div>
        {CustomTextComponent ? <CustomTextComponent text={text} /> : text}
      </div>
      )}
    </>
  );
};

export default MediaMessage;
MediaMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  CustomTextComponent: PropTypes.elementType,
  disableClick: PropTypes.bool,
};
