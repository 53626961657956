import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getCurrentFriendlyName,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getAppName, getUrl } from 'gcs-common/slices/theme/themeSelectors';
import React from 'react';
import MessageLikeTextBox from '../MessageLikeTextBox/MessageLikeTextBox';
import styles from './styles.module.scss';

import bubbleIcon from '../../img/icons/bubble.svg';
import imageIcon from '../../img/icons/image.svg';
import documentIcon from '../../img/icons/document.svg';
import magicCubeIcon from '../../img/icons/cube.svg';
import statusIcon from '../../img/icons/status.svg';
import dotGreenIcon from '../../img/icons/dot_green.svg';
import dotYellowIcon from '../../img/icons/dot_yellow.svg';
import dotRedIcon from '../../img/icons/dot_red.svg';
import webIcon from '../../img/icons/web.svg';
import favoriteIcon from '../../img/icons/star_full.svg';
import searchIconWhite from '../../img/icons/search_white.svg';
import searchIcon from '../../img/icons/search_primary.svg';
import menuIcon from '../../img/icons/menu.svg';

export const WelcomeInfo = () => {

  const friendlyName = useSelector(getCurrentFriendlyName);
  const appName = useSelector(getAppName);

  return (
    <>
      <MessageLikeTextBox width="90%" className={styles.h}>
        Hallo
        {' '}
        {friendlyName}
        ,
        <br />
        bevor es gleich los geht, eine kurze Einführung in
        {` ${appName} `}
        BEEM!
      </MessageLikeTextBox>

      <MessageLikeTextBox width="70%">
        Wenn Sie schon Kunde bei uns sind, können Sie sich mit
        ihren persönlichen Ansprechpartnern verbinden.
        Dafür starten Sie ganz einfach einen Chat mit in BEEM.
      </MessageLikeTextBox>
    </>
  );
};

export const MediaInfo = React.memo(() => {
  return (
    <>
      <MessageLikeTextBox width="50%" className={styles.h}>
        Nachrichten, Bilder und Dokumente
      </MessageLikeTextBox>

      <MessageLikeTextBox width="100%">
        Sie können uns Nachrichten, Bilder und Dokumente schicken, um uns genau die Informationen
        zur Verfügung zu stellen,
        die wir brauchen, um Sie optimal zu unterstützen. Zudem können Sie auch direkt auf
        Nachrichten
        antworten.
      </MessageLikeTextBox>

      <MessageLikeTextBox width="40%">
        <div className={styles.row}>
          <img alt="bubble" src={bubbleIcon} className={styles.mediaImage} />
          <img alt="file" src={imageIcon} className={styles.mediaImage} />
          <img alt="document" src={documentIcon} className={styles.mediaImage} />
        </div>
      </MessageLikeTextBox>
    </>
  );
});

export const GCOPInfo = React.memo(() => {

  return (
    <>
      <MessageLikeTextBox width="70%" className={styles.h}>
        ONLINE PLUS Vorgänge
      </MessageLikeTextBox>

      <MessageLikeTextBox width="100%">
        Zudem können Sie Vorgänge aus ONLINE PLUS in den Chat einfügen,
        um uns genau zu sagen was für Änderungen
        Sie an Ihren Bestellungen vornehmen möchten oder was genau Sie nachbestellen wollen.
      </MessageLikeTextBox>

      <MessageLikeTextBox width="20%">
        <img alt="magic-cube" src={magicCubeIcon} />
      </MessageLikeTextBox>
    </>
  );
});

export const StatusInfo = React.memo(() => {
  return (
    <>
      <MessageLikeTextBox width="35%" className={styles.h}>
        <div className={styles.row}>
          Status
          <img alt="status" className={styles.favoriteIcon} src={statusIcon} />
        </div>
      </MessageLikeTextBox>

      <MessageLikeTextBox width="90%">
        Der Status informiert Sie immer, wie schnell Sie eine Antwort von uns erwarten
        können.
        <div className={styles.statusWrapper}>
          <div>
            <img alt="dot-green" src={dotGreenIcon} />
            {' '}
            Ihr persönlicher Innendienst-Mitarbeiter ist aktiv
          </div>
          <div>
            <img alt="dot-yellow" src={dotYellowIcon} />
            {' '}
            persönlicher Innendienst-Mitarbeiter ist im Haus, aber
            gerade nicht am Platz
          </div>
          <div>
            <img alt="dot-red" src={dotRedIcon} />
            {' '}
            wir sind nicht im Haus
          </div>
        </div>
        Zudem wird Ihnen angezeigt, wenn eine Vertretung den Chat übernimmt.
      </MessageLikeTextBox>
    </>
  );
});

export const WebInfo = React.memo(() => {
  const url = useSelector(getUrl);
  return (
    <>
      <MessageLikeTextBox width="15%">
        <img alt="web" src={webIcon} />
      </MessageLikeTextBox>

      <MessageLikeTextBox width="100%">
        Der Chat funktioniert nicht nur über das Handy. Wenn Sie am PC sitzen, melden Sie sich
        einfach im Browser, mit
        der gleichen Handynummer oder E-Mail Adresse, an:
        <br />
        <br />
        <span className={styles.h}>
          {url}
        </span>
        <br />
        <br />
      </MessageLikeTextBox>
    </>
  );
});

export const FavoriteInfo = React.memo(() => {
  return (
    <>
      <MessageLikeTextBox width="35%" className={styles.h}>
        <div className={styles.row}>
          Favoriten
          <img className={styles.favoriteIcon} alt="favorite" src={favoriteIcon} />
        </div>
      </MessageLikeTextBox>

      <MessageLikeTextBox width="90%">
        Indem Sie lange auf eine Nachricht drücken, können Sie diese als Favoriten markieren.
      </MessageLikeTextBox>
      <MessageLikeTextBox width="60%">
        Durch auswählen des Sterns in der Statusleiste können Sie Ihre Favoriten einsehen,
        entfernen und direkt zu der Nachricht springen.
      </MessageLikeTextBox>
    </>
  );
});

export const SearchFeatureInfo = React.memo(({ dark = false }) => {
  return (
    <>
      <MessageLikeTextBox width="70%" className={styles.h}>
        <span className={styles.bold}>Suche</span>
      </MessageLikeTextBox>

      <MessageLikeTextBox width="80%">
        Sie können jetzt Ihren Chat mit dem Innendienst durchsuchen. Klicken Sie einfach auf das
        Such-Symbol neben dem Namen Ihres Innendienst-Mitarbeiters und suchen Sie nach beliebigen
        Begriffen.
      </MessageLikeTextBox>

      <MessageLikeTextBox width="10%" className={`${dark && styles.dark} ${styles.center}`}>
        <img alt="search" className={styles.searchIcon} src={dark ? searchIconWhite : searchIcon} />
      </MessageLikeTextBox>
    </>
  );
});

SearchFeatureInfo.propTypes = {
  dark: PropTypes.bool,
};

export const SideMenuInfo = React.memo(({ dark = false }) => {
  return (
    <>
      <MessageLikeTextBox width="60%" className={styles.h}>
        Neues
        <span className={styles.bold}> Seitenmenü</span>
      </MessageLikeTextBox>

      <MessageLikeTextBox width="80%">
        Ab jetzt können Sie über das neue Seitenmenü bequem zwischen Ihren Kanälen wechseln.
      </MessageLikeTextBox>

      <MessageLikeTextBox width="10%" className={`${dark && styles.dark} ${styles.center}`}>
        <img alt="menu" className={styles.menuIcon} src={menuIcon} />
      </MessageLikeTextBox>
    </>
  );
});

SideMenuInfo.propTypes = {
  dark: PropTypes.bool,
};
