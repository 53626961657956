/* eslint-disable max-len, @typescript-eslint/no-unsafe-assignment */

import { ICON } from 'gcs-common/constants/IconConstants';
import PropTypes from 'prop-types';
import { clearStore } from 'gcs-common/slices/store/storeActions';
import { useDispatch } from 'react-redux';
import { FC } from 'react';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

interface FullScreenErrorDialogProps {
  dialogErrorMsg?: string,
}

const FullScreenErrorDialog: FC<FullScreenErrorDialogProps> = ({ dialogErrorMsg = undefined }) => {
  const dispatch = useDispatch();

  const resetApp = () => {
    dispatch(clearStore());
    window.location.reload();
  };

  return (
    <div className={styles.errorWrapper}>
      <IconComponent Icon={ICON.REPORT} />
      <h3>Ein Fehler ist aufgetreten.</h3>
      <p>Bitte laden Sie die App neu und kontaktieren Sie den Support, wenn das Problem weiterhin besteht.</p>
      {dialogErrorMsg && <p>{dialogErrorMsg}</p>}
      <button type="button" onClick={resetApp}>Neu laden</button>
    </div>
  );
};

FullScreenErrorDialog.propTypes = {
  dialogErrorMsg: PropTypes.string,
};

export default FullScreenErrorDialog;
