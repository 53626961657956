export const ZIP_TYPES = [
  'application/x-bzip',
  'application/x-bzip2',
  'application/gzip',
  'application/zip',
  'application/x-7z-compressed',
  'application/vnd.rar',
  'application/x-tar',
  'application/x-freearc',
];
export const WORD_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/rtf',
];
export const EXCEL_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'text/csv',
];
export const POWERPOINT_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
];
export const CODE_TYPES = [
  'application/x-csh',
  'text/css',
  'text/html',
  'text/javascript',
  'application/json',
  'application/x-httpd-php',
  'application/x-sh',
  'application/xhtml+xml',
  'application/xml',
  'text/xml',
];
