import * as SentryReact from '@sentry/react';
import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router';

const trackingEnabled = import.meta.env.VITE_SENTRY_ENABLED === 'true';
const sampleRate = trackingEnabled ? 0.10 : 0.0;

export const sentryConfig = () => ({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_BUILD_ENVIRONMENT,
  // eslint-disable-next-line no-underscore-dangle
  release: import.meta.env.VITE_BEEM_VERSION_NUMBER,
  integrations: [
    new SentryReact.BrowserTracing({
      routingInstrumentation: SentryReact.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
      ),
    }),
    new SentryReact.Replay(),
  ],
  // Warning: Don't set too deep or errors won't
  // get send as they are getting to big!
  normalizeDepth: 3,
  tracesSampleRate: sampleRate,
  ignoreErrors: ['ResizeObserver loop'],
  beforeSend: (event) => {
    if (trackingEnabled) {
      return event;
    }
    return null;
  },
  // Session Replay
  replaysSessionSampleRate: sampleRate * 0.5,
  replaysOnErrorSampleRate: sampleRate,
  debug: false,
  tracePropagationTargets: `https://api.${window.location.hostname}`,
});
