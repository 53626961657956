import { useState } from 'react';
// import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';

const OpMaintenanceBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  // TODO: Make a generic banner that can be triggered with a feature flag from the backend
  const etsAvailableMessage = 'Gute Nachrichten! Unser Ersatzteilservice steht Ihnen nach einem technischen Problem wieder zur Verfügung.';

  if (!showBanner) return null;

  return (
    <div className={styles.opMaintenanceBanner}>
      {/* <div className={styles.bannerText}>{opMaintenanceMessage}</div> */}
      <div className={styles.bannerText}>{etsAvailableMessage}</div>
      <button className={styles.closeButton} type="button" onClick={() => setShowBanner(false)}>
        <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.BLACK} />
      </button>
    </div>
  );
};

export default OpMaintenanceBanner;
