import { useSelector } from 'react-redux';
import { getCraftsmanChannelSectionChannelIds } from 'gcs-common/slices/channelList/channelListSelectors';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import CRAFTSMAN_CHANNEL_SECTIONS from 'gcs-common/constants/CraftsmanChannelSections';
import PropTypes from 'prop-types';
import ChannelGroup from '../ChannelGroup';
import HouseSwitcher from '../../../HouseSwitcher/HouseSwitcher';
import SideMenuMessage from '../../SideMenuMessage/SideMenuMessage';
import styles from './styles.module.scss';
import TicketsChannelItem from '../../../Tickets/TicketsChannelItem/TicketsChannelItem';

const ChannelListCraftsman = ({ isForFileShare = false }) => {
  const houseChannels = useSelector(
    getCraftsmanChannelSectionChannelIds(CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS),
  );

  const internalChannels = useSelector(
    getCraftsmanChannelSectionChannelIds(CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS),
  );

  const isEtsMobileEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.ETS_MOBILE_ENABLED));

  return (
    <>
      <HouseSwitcher />
      {(houseChannels.length === 0 && !isForFileShare) && (
        <SideMenuMessage text="Um einen Chat mit dem Haus zu starten, tippen Sie auf den Plus-Button unten rechts." />
      )}

      {houseChannels.length === 0 && internalChannels === 0 && isForFileShare && (
      <p style={{ marginTop: '30px', textAlign: 'center' }}>Keine Chats gefunden</p>
      )}
      <div className={styles.channelSectionsWrapper}>
        {isEtsMobileEnabled && (<TicketsChannelItem />)}
        <ChannelGroup
          sectionName={CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS}
          channelIds={houseChannels}
          className={internalChannels.length > 0 ? styles.channelGroupHouse : ''}
        />
        <ChannelGroup
          sectionName={CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS}
          channelIds={internalChannels}
          className={houseChannels.length > 0 ? styles.channelGroupInternal : ''}
        />
      </div>
    </>
  );
};

ChannelListCraftsman.propTypes = {
  isForFileShare: PropTypes.bool,
};

export default ChannelListCraftsman;
