import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ALTERNATIVE_NEW_CHANNEL_SECTIONS_LIST,
  NEW_CHANNEL_SECTIONS_LIST,
} from 'gcs-common/constants/newChannelSections';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { resetSelectedNewChannelOptions, resetNewChannelOptions } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import { getIsGroupChat } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import NewChannelGroup from '../NewChannelGroup/NewChannelGroup';

const NewChannelList = () => {

  const dispatch = useDispatch();
  const isAgent = useSelector(getIsAgent);
  const isGroupChat = useSelector(getIsGroupChat);
  const sections = isAgent ? ALTERNATIVE_NEW_CHANNEL_SECTIONS_LIST : NEW_CHANNEL_SECTIONS_LIST;
  const searchInput = useSelector(getSearchInput);

  useEffect(() => {
    if (!isGroupChat) {
      dispatch(resetSelectedNewChannelOptions());
    }
  }, [dispatch, isGroupChat]);

  useEffect(() => {
    dispatch(resetNewChannelOptions());
  }, [dispatch, searchInput]);

  return (
    <div>
      {sections.map((sectionName) => (
        <NewChannelGroup
          key={sectionName}
          sectionName={sectionName}
        />
      ))}
    </div>
  );
};

export default NewChannelList;
