/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { createSlice } from '@reduxjs/toolkit';
import { clearStore } from '../store/storeActions';

const initialState = {
  version: 0,
  rehydrationFinished: false,
};

const persistenceWhitelist = [
  'version',
];

// import { REHYDRATE } from 'redux-persist/constants';
const REHYDRATE = 'persist/REHYDRATE';

const persistenceSlice = createSlice({
  name: 'persistence',
  initialState,
  reducers: {},
  extraReducers: {
    [REHYDRATE]: (state, action) => {
      // Note: we need to set the version from migration here manually,
      // otherwise the version will not be rehydrated
      const { persistence: { version } = {} } = action.payload;
      state.rehydrationFinished = true;
      state.version = version;
    },
    [clearStore.type]: (state) => {
      // when we clear the store, the initial
      // rehydration is still valid
      state.rehydrationFinished = true;
    },
  },
});

export { persistenceWhitelist as persistencePersistWhitelist };

export const {
  setRehydrationFinished,
} = persistenceSlice.actions;

export default persistenceSlice.reducer;
