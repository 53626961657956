import { ONLINE_STATUS } from 'gcs-common/helper/userStatusHelper';
import { LastOnlineStatusBadge } from 'gcs-common/components/LastOnlineStatusBadge/LastOnlineStatusBadge';
import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getChannelStatusRelevantUserId,
} from 'gcs-common/slices/channel/channelSelectors';
import { getUserIsAgent, getUserStatus } from 'gcs-common/slices/user/userSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import FeatureFlagProtectedRoute from 'gcs-common/components/ProtectedRoutes/FeatureFlagProtectedRoute';
import PropTypes from 'prop-types';


export function ChatNavBarLastOnlineStatusBadge(
  { selectedChannelId },
) {
  const currentUserIsAgent = useSelector(getIsAgent);
  const channelStatusUserId = useSelector(getChannelStatusRelevantUserId(selectedChannelId));
  const channelStatusUserIsAgent = useSelector(getUserIsAgent(channelStatusUserId));
  const channelStatus = useSelector(getUserStatus(channelStatusUserId));

  return (
    <FeatureFlagProtectedRoute enabledFlag={FEATURE_FLAGS.LAST_ONLINE_BADGE_ENABLED}>
      {
          !channelStatusUserIsAgent
          && channelStatus.type !== ONLINE_STATUS.ONLINE
          && currentUserIsAgent
          && (
          <LastOnlineStatusBadge userStatus={channelStatus} hideLabel />
          )
      }
    </FeatureFlagProtectedRoute>
  );
}

ChatNavBarLastOnlineStatusBadge.propTypes = {
  selectedChannelId: PropTypes.string.isRequired,
};
