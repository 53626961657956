import IconComponent from 'gcs-common/components/Icon/Icon';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentNumber } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useCallback } from 'react';
import styles from './styles.module.scss';
import { setSalesSpaceChannelActionSheetOpen } from '../../../slices/uiState/uiStateSlice';

export function KebabMenuButton() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const agentNumber = useSelector(getAgentNumber);
  const dispatch = useDispatch();

  const handleActionSheetButtonClick = useCallback(() => {
    dispatch(setSalesSpaceChannelActionSheetOpen());
  }, [dispatch]);

  return (
    <>
      <button
        className={styles.dotsButton}
        type="button"
        onClick={handleActionSheetButtonClick}
        data-testid="kebab-menu-button"
      >
        <IconComponent Icon={ICON.MORE} color={ICON_COLOR.LIGHT_GREY} />
      </button>

      {agentNumber && (
        <a
          href={`tel:${agentNumber}`}
          onClick={() => {
            dispatch(trackGALegacyEvent(LEGACY_EVENTS.PHONE_CLICKED()));
          }}
        >
          <IconComponent Icon={ICON.CALL} color={ICON_COLOR.LIGHT_GREY} alt="call" />
        </a>
      )}
    </>
  );
}
