import { type ComponentType, type PropsWithChildren, useEffect, useState } from 'react';
import { cn } from '../utils/tailwind-merge';
import type { ButtonVariant } from '../ui/button';
import { DialogContainer } from '../ui/confirmation-dialog';

export type ConfirmModalProps = PropsWithChildren<{
  title: string;
  confirmLabel: string;
  closeLabel?: string;
  confirm: () => unknown;
  close?: () => void;
  /** Allows to disable buttons while performing a request. */
  isLoading?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  /** Allows to pass a function to check if the the modal should open on click */
  shouldOpen?: () => boolean;
  onConfirmModalOpen?: () => void;
  closeButtonVariant?: ButtonVariant;
  confirmButtonVariant?: ButtonVariant;
  checkboxText?: string;
  form?: string;
  className?: string;
}>;

const withConfirmation = <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P & ConfirmModalProps> => ({
  title,
  confirmLabel,
  closeLabel = 'Abbrechen',
  confirm,
  close,
  children,
  isLoading,
  isSuccess,
  shouldOpen,
  onConfirmModalOpen,
  closeButtonVariant,
  confirmButtonVariant,
  checkboxText,
  form,
  ...props
}: P & ConfirmModalProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (shouldOpen && shouldOpen() === false) {
      return;
    }
    onConfirmModalOpen?.();

    openModal();
  };

  const handleConfirm = async () => {
    await confirm();
  };

  const handleModalClose = () => {
    close?.();
    closeModal();
  };

  const openModal = () => {
    setShowModal(true);
    // startViewTransition(() => {
    //   setShowModal(true);
    // });
  };

  const closeModal = () => {
    if (isLoading) return;
    setShowModal(false);
    // startViewTransition(() => {
    //   setShowModal(false);
    // });
  };

  useEffect(() => {
    if (isSuccess) {
      handleModalClose();
    }
  }, [isSuccess]);

  return (
    <>
      <WrappedComponent
        className={cn({
          '[view-transition-name:dialog]': !showModal,
        })}
        onClick={handleButtonClick}
        {...(props as P)}
      />
      <DialogContainer
        title={title}
        confirmLabel={confirmLabel}
        closeLabel={closeLabel}
        isLoading={isLoading}
        handleModalClose={handleModalClose}
        handleConfirm={handleConfirm}
        showModal={showModal}
        closeButtonVariant={closeButtonVariant}
        confirmButtonVariant={confirmButtonVariant}
        checkboxText={checkboxText}
        form={form}
      >
        {children}
      </DialogContainer>
    </>
  );
};

export default withConfirmation;
