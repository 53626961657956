import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';

const loadLocalFileUrl = createAsyncThunk(
  'media/loadLocalFileUrl',
  async ({ key }, { extra: { blobStorageClient } }) => {
    let url;
    const objectExists = await blobStorageClient.objectExists(key);
    if (objectExists) {
      try {
        url = await blobStorageClient.getObjectUrl(key);
      } catch (e) {
        captureException(e);
      }
    }
    return { url };


  },
);

export default loadLocalFileUrl;
