// single source of truth for themes
export const THEME = {
  AGENT: 'agent',
  ITG: 'itg',
  HTI: 'hti',
  GC: 'gc',
  GUT: 'gut',
  EFG: 'efg',
  DTG: 'dtg',
  TFG: 'tfg',
  HFH: 'hfh',
} as const;

export const OP_URL_BY_THEME = {
  gc: 'https://www.gc-gruppe.de/online-plus-zugang',
  itg: 'https://www.itg-handel.de/online-plus-zugang',
  gut: 'https://www.gut-gruppe.de/online-plus-zugang',
  dtg: 'https://www.dtg-handel.de/online-plus-zugang',
  tfg: 'https://www.tfg-gruppe.de/service/tfg-online-plus/zugang',
  efg: 'https://www.efg-gruppe.de/online-plus-zugang',
  hti: 'https://www.hti-handel.de/online-plus-zugang',
} as const;
