import { isClientMessageId } from 'gcs-common/helper/reduxOfflineHelper';
import { debugLogger } from 'gcs-common/helper/debugLogger';

/**
 * react-virtuoso message list provides an imperative API to update its internal data.
 * Instead of simply passing the list of messages, we have to tell virtuoso to
 * either append, prepend, insert, or update data.
 * To keep the integration minimal-invasive, this helper function
 * syncs the redux message state with the internal state of virtuoso.
 */
export function useSynchronizeVirtuoso(
  virtuosoData,
  reduxData,
) {
  if (!virtuosoData || !reduxData) {
    return;
  }

  // batch the virtuoso update in one render
  virtuosoData.batch(() => {
    const vMessages = virtuosoData.get();
    const vMessagesMap = new Map(vMessages.map((item, index) => [item.id, index]));

    const rMessages = reduxData;
    const rMessageMap = new Map(rMessages.map((item, index) => [item.id, index]));

    // Step 1: Remove elements from virtuoso that are not in redux
    const toRemove = vMessages.filter(m => {
      const reduxHasNoId = !rMessageMap.has(m.id);
      const reduxHasNotPending = !reduxData
        .find(reduxMessage => reduxMessage.clientMessageId === m.id);
      return reduxHasNoId && reduxHasNotPending;
    });

    toRemove.forEach(m => {
      const index = vMessagesMap.get(m.id);
      debugLogger('Virtuoso Deleting', m.id);
      virtuosoData.deleteRange(index, 1, false);
      vMessagesMap.delete(m.id);
    });

    // Step 2: Determine elements to prepend, append, and insert in virtuoso
    const toPrepend = [];
    const toAppend = [];
    const toInsert = [];
    const toReplace = [];

    let v = 0;
    let r = 0;

    while (r < rMessages.length) {
      const rMessage = rMessages[r];

      const id = vMessagesMap.get(rMessage.id);
      const vMessageMissing = id === undefined;
      const vExistsAlready = !vMessageMissing;

      if (vExistsAlready) {
        r++;
        // do nothing
        continue;
      }

      const pendingIndex = vMessagesMap.get(rMessage.clientMessageId);

      if (pendingIndex !== undefined) {
        debugLogger('Virtuoso Replace pending Message', rMessage.id);
        toReplace.push({ message: rMessage, index: pendingIndex });
        r++;
        continue;
      }

      if (isClientMessageId(rMessage.id)) {
        debugLogger('Virtuoso Append pending Message', rMessage.id);
        toAppend.push(rMessage);
        r++;
        continue;
      }

      const firstVMessage = vMessages[0];
      const lastVMessage = vMessages[vMessages.length - 1];

      if (vMessages.length === 0 || rMessage.index < firstVMessage.index) {
        // prepend
        toPrepend.push(rMessage);
        r++;
      } else if (rMessage.index > lastVMessage.index) {
        // append
        toAppend.push(rMessage);
        r++;
      } else {
        while (v < vMessages.length && vMessages[v].index < rMessages[r].index) {
          v++;
        }
        // insert at index
        // TODO this index is not correct after e.g. prepending items
        toInsert.push({ item: rMessage, index: v });
        r++;
      }
    }

    // Replace
    if (toReplace.length > 0) {
      toReplace.forEach(({ message, index }) => {
        debugLogger('Virtuoso Inserting', message.id);
        virtuosoData.deleteRange(index, 1);
        virtuosoData.insert([message], index, (data) => {
          const { atBottom } = data;
          if (atBottom) {
            return { index: 'LAST', align: 'end', behavior: 'auto' };
          }
          return false;
        });
      });
    }

    // Prepend all new items at once
    if (toPrepend.length > 0) {
      debugLogger('Virtuoso Prepending', toPrepend);
      virtuosoData.prepend(toPrepend);
    }

    // Insert remaining items in the correct positions
    toInsert.forEach(({ item, index }) => {
      debugLogger('Virtuoso Inserting', item);
      virtuosoData.insert([item], index);
    });

    // Append all new items at once
    if (toAppend.length > 0) {
      debugLogger('Virtuoso Appending', toAppend);
      virtuosoData.append(toAppend, (data) => {
        const { atBottom } = data;
        if (atBottom) {
          debugLogger('Virtuoso Appending and Scroll to bottom', toAppend);
          return { index: 'LAST', align: 'end', behavior: 'auto' };
        }
        debugLogger('Virtuoso Appending and no scroll', toAppend);
        return false;
      });
    }
  });
}


export const useSynchronizeVirtuosoTypingIndicator = (
  virtuosoData,
  latestMessageId,
  otherUserTyping,
) => {
  virtuosoData?.map((m) => {
    if (m.id === latestMessageId && otherUserTyping) {
      return {
        ...m,
        isTyping: true,
      };
    }
    return {
      ...m,
      isTyping: false,
    };

  }, 'smooth');
};


export const useSynchronizeVirtuosoUnreadDivider = (
  virtuosoData,
  latestMessageId,
  unreadDividerMessageId,
) => {
  virtuosoData?.map((m) => {
    if (m.id === unreadDividerMessageId && m.id !== latestMessageId) {
      return {
        ...m,
        showUnreadNumber: true,
      };
    }
    return {
      ...m,
      showUnreadNumber: false,
    };

  }, 'smooth');
};


export const useSynchronizeVirtuosoDeliveredIcon = (
  virtuosoData,
  lastDeliveredId,
) => {
  virtuosoData?.map((m) => {
    if (m.id === lastDeliveredId) {
      return {
        ...m,
        isDelivered: true,
      };
    }
    return {
      ...m,
      isDelivered: false,
    };

  }, 'smooth');
};


export const useSynchronizeVirtuosoPendingIcon = (virtuosoData) => {
  virtuosoData?.map((m) => {
    if (isClientMessageId(m.id)) {
      return {
        ...m,
        showPendingIcon: true,
      };
    }
    return {
      ...m,
      showPendingIcon: false,
    };

  }, 'smooth');
};


export const useSynchronizeVirtuosoReadIcon = (virtuosoData, lastReadId) => {
  virtuosoData?.map((m) => {
    if (m.id === lastReadId) {
      return {
        ...m,
        isRead: true,
      };
    }
    return {
      ...m,
      isRead: false,
    };

  }, 'smooth');
};
