import { Navigate } from 'react-router';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useGetCurrentUserIsLoggedIn } from 'gcs-common/clients/api/entities/current-user/current-user-api';
import LoginMobile from './LoginMobile';

function LoginRootMobile() {
  const { isSuccess } = useGetCurrentUserIsLoggedIn();

  if (isSuccess) {
    return <Navigate to={CHAT_ROUTES.CHAT} replace />;
  }

  return (
    <LoginMobile />
  );
}

export default LoginRootMobile;
