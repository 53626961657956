import PropTypes from 'prop-types';
import ChevronIcon from 'gcs-common/img/arrow.svg?react';
import styles from './styles.module.scss';

const ShowMoreNewChannelOptions = ({ onShowMore }) => (
  <button type="button" className={styles.showMoreButtonWrapper} onClick={onShowMore}>
    <span className={styles.showMoreButton}>
      Mehr anzeigen
      <ChevronIcon className={styles.showMoreChevronDown} />
    </span>
  </button>
);

ShowMoreNewChannelOptions.propTypes = {
  onShowMore: PropTypes.func.isRequired,
};

export default ShowMoreNewChannelOptions;
