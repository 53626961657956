import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSidebar } from 'gcs-common/design-system/ui/sidebar';
import Icon from 'gcs-common/components/Icon/Icon';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import { selectOrder } from '../../../slices/quickOrdering/quickOrderingSlice';
import { getIsMobile } from '../../../slices/init/initSelectors';
import { setToolbarTab } from '../../../slices/uiState/uiStateSlice';
import { TOOL_TABS } from '../../../constants/toolUIFlags';
import { getHasFeatureFlag, getIsAgent } from '../../../slices/currentUser/currentUserSelectors';
import FEATURE_FLAGS from '../../../helper/featureFlagsHelper';
import {
  ORDER_STATUS_COLOR,
  ORDER_STATUS_DESCRIPTION_AGENT,
  ORDER_STATUS_DESCRIPTION_CUSTOMER,
} from '../../../slices/quickOrdering/quickOrderingConstants';
import { CHAT_ROUTES } from '../../../constants/routes';
import { getSelectedChannelId } from '../../../slices/channels/channelsSelectors';

const OrderMessage = ({ order }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useSelector(getIsMobile);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const sidebar = useSidebar();

  const onSelectOrder = () => {
    dispatch(selectOrder({ orderId: order.id }));
    if (isMobile) {
      navigate(CHAT_ROUTES.QUICK_ORDER(selectedChannelId));
    } else {
      sidebar.setOpen(true);
      dispatch(setToolbarTab({ tab: TOOL_TABS.ORDERS }));
    }
  };
  const status = order?.status;
  const isAgent = useSelector(getIsAgent);
  const hasFeatureFlag = useSelector(getHasFeatureFlag(FEATURE_FLAGS.QUICKORDERING_ENABLED));
  if (!hasFeatureFlag) {
    return 'Diese Nachricht kann mit Deiner Version von BEEM noch nicht angezeigt werden';
  }
  return (
    <div
      className={styles.process}
      role="presentation"
      onClick={onSelectOrder}
    >
      <div className={styles.magicCubeWrapper}>
        <Icon Icon={ICON.QUICKORDERING} color={ICON_COLOR.PRIMARY_BUTTON} />
      </div>
      <div>
        <div className={styles.processWrapper}>
          <div className={styles.processHeader}>
            {order.title || 'Bestellung'}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flex: '0 0 auto',
          }}
          >
            <div style={{
              backgroundColor: ORDER_STATUS_COLOR[status],
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              marginRight: '5px',
              marginBottom: '2px',
              flex: '0 0 auto',
            }}
            />
            <div
              style={{
                fontSize: '12px',
                color: ORDER_STATUS_COLOR[status],
              }}
            >
              {isAgent
                ? ORDER_STATUS_DESCRIPTION_AGENT[status]
                : ORDER_STATUS_DESCRIPTION_CUSTOMER[status]}
            </div>
          </div>
          {order.customerOrderText && (
          <div className={styles.processDetail}>{order.customerOrderText}</div>
          )}
          {order.customerOrderNumber && (
          <div className={styles.processDetail}>{order.customerOrderNumber}</div>
          )}
          <div className={styles.processDetail}>
            Positionen:
            {order.orderPositions?.length}
          </div>
        </div>
      </div>
    </div>
  );
};

OrderMessage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.any,
};

export default OrderMessage;
