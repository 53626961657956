import { type PropsWithChildren, useEffect, useState } from 'react';
import { cn } from '../utils/tailwind-merge';
import { Button, ButtonVariant } from './button';
import { Checkbox } from './checkbox';
import {
  Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle,
} from './dialog';

export type ConfirmModalProps = PropsWithChildren<{
  title?: string;
  confirmLabel?: string;
  confirmText?: string;
  closeLabel?: string;
  isLoading?: boolean;
  showModal: boolean;
  handleModalClose?: () => void;
  handleConfirm?: () => void;
  closeButtonVariant?: ButtonVariant;
  confirmButtonVariant?: ButtonVariant;
  checkboxText?: string;
  form?: string;
}>;

export function DialogContainer({
  title,
  confirmLabel,
  confirmText,
  closeLabel,
  handleModalClose,
  handleConfirm,
  children,
  isLoading,
  showModal,
  closeButtonVariant = ButtonVariant.soft_neutral,
  confirmButtonVariant = ButtonVariant.soft_accent,
  checkboxText = '',
  form,
}: ConfirmModalProps) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <Dialog onOpenChange={handleModalClose} open={showModal}>
      <DialogContent
        className={cn({
          '[view-transition-name:dialog]': showModal,
        })}
      >
        <DialogHeader className="border-b border-neutral-4">
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="space-y-2 border-b border-neutral-4 p-4 text-sm">{children || confirmText}</div>
        {checkboxText && (
          <div className="flex items-center gap-2 space-y-2 p-4 text-sm">
            <Checkbox id="checked" onCheckedChange={(c) => setIsChecked(!!c)} checked={isChecked} />
            <label htmlFor="checked" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed">
              {checkboxText}
            </label>
          </div>
        )}

        <DialogFooter>
          <Button variant={closeButtonVariant} onClick={handleModalClose}>
            {closeLabel}
          </Button>
          <Button
            variant={confirmButtonVariant}
            isLoading={isLoading}
            onClick={handleConfirm}
            disabled={!!checkboxText && !isChecked}
            form={form}
          >
            {confirmLabel}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

interface ConfirmationDialogProps {
  title?: string;
  confirmLabel?: string;
  confirmText?: string;
  closeLabel?: string;
  confirm: () => unknown;
  close?: () => void;
  isLoading?: boolean;
  isSuccess?: boolean;
}

export function ConfirmationDialog({
  title = 'Seite mit nicht gespeicherten Änderungen verlassen?',
  confirmLabel = 'Seite verlassen',
  confirmText = 'Wenn du diese Seite verlässt, werden alle nicht gespeicherten Änderungen gelöscht.',
  closeLabel = 'Bleiben',
  confirm,
  close,
  isLoading,
  isSuccess,
}: ConfirmationDialogProps) {
  const [showModal, setShowModal] = useState(true);

  const handleConfirm = async () => {
    await confirm();
  };

  const handleModalClose = () => {
    close?.();
    closeModal();
  };

  const closeModal = () => {
    if (isLoading) return;
    setShowModal(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleModalClose();
    }
  }, [isSuccess]);

  return (
    <>
      <DialogContainer
        title={title}
        confirmLabel={confirmLabel}
        closeLabel={closeLabel}
        confirmText={confirmText}
        isLoading={isLoading}
        handleModalClose={handleModalClose}
        handleConfirm={handleConfirm}
        showModal={showModal}
      />
    </>
  );
}
