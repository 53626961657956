import { Button, ButtonVariant, ButtonSize } from 'gcs-common/design-system/ui/button';
import { useSelector } from 'react-redux';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from 'gcs-common/design-system/ui/input-otp';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'gcs-common/design-system/ui/form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { useRequestLoginMutation } from 'gcs-common/clients/api/entities/login/login-api';
import Anchor from 'gcs-common/design-system/ui/anchor';
import { useQueryParams } from 'gcs-common/design-system/hooks/use-query-params';
import { useRequestUserCodeMutation } from 'gcs-common/clients/api/entities/user-code/user-code-api';
import { toast } from 'gcs-common/design-system/hooks/use-toast';

const FormSchema = z.object({
  pin: z.string().min(6, {
    message: 'Ihr einmaliges Passwort muss aus 6 Zeichen bestehen.',
  }),
});


const LoginForm = () => {
  const theme = useSelector(getThemeIdentifier);

  const { queryParams } = useQueryParams(['email', 'phone', 'registration']);

  const { email, phone, registration } = queryParams;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: '',
    },
  });

  const requestLogin = useRequestLoginMutation();
  const requestUserCode = useRequestUserCodeMutation();


  const requestNewCode = async () => {
    await requestUserCode.mutateAsync({
      body: {
        theme,
        request_type: 'login',
        email,
        phone,
      },
    });
    toast({
      description: 'Neuer Code gesendet',
      duration: 5000,
      variant: 'success',
    });
  };

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    requestLogin.mutate({
      body: {
        theme,
        code: data.pin,
        email,
        phone,
      },
    });
  };

  let loginText = `Falls für ${email || phone} ein Konto existiert, geben Sie den erhaltenen 6-stelligen Code ein.`;

  if (registration) {
    loginText = `Bitte den 6-stelligen Code eingeben, der an ${email || phone} gesendet wurde.`;
  }

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex h-full flex-col justify-between gap-5">
          <div className="flex flex-col justify-between gap-5">
            <p className="text-2xl">Code eingeben</p>
            <p className="text-base text-neutral-11">
              {loginText}
            </p>
            <FormField
              control={form.control}
              name="pin"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputOTP {...field} maxLength={6}>
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormMessage showWarningIcon={false} />
                </FormItem>
              )}
            />
            <div>
              <Anchor onClick={() => requestNewCode()} isLoading={requestUserCode.isPending}>
                Neuen Code anfordern
              </Anchor>
            </div>
          </div>

          <div className="pb-20">
            <Button
              type="submit"
              variant={ButtonVariant.solid_accent}
              id="login-button"
              size={ButtonSize.xl}
              isLoading={requestLogin.isPending}
              className="w-full"
            >
              Anmeldung mit Code
            </Button>
          </div>

        </form>
      </Form>
    </>
  );
};

export default LoginForm;
