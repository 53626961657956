import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMessagesFavoriteIds } from 'gcs-common/slices/favorites/favoritesSelectors';
import {
  getMessageCanReply,
  getSelectedMessagesBody,
  getSelectedMessagesCanCopy,
  getSelectedMessagesCanDelete,
  getSelectedMessagesCanFavorite,
  getSelectedMessagesAreAllFavorites,
  getSelectedMessagesCanReport,
  getSelectedMessagesCanForward,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import deleteMessage from 'gcs-common/slices/messages/messagesThunks/deleteMessage';
import {
  channelInputForwardMessagesStaged,
  channelInputReplyMessageStaged,
} from 'gcs-common/slices/messageInput/messageInputSlice';
import { addFavorite } from 'gcs-common/slices/favorites/favoritesThunks/addFavorite';
import { removeFavorite } from 'gcs-common/slices/favorites/favoritesThunks/removeFavorite';
import { ICON, ICON_COLOR, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import { resetSelectedMessageIds, showReportMessageModal } from '../../slices/uiState/uiStateSlice';
import {
  getIsOnlyOneMessageSelected,
  getSelectedMessageLength,
  getSelectedMessageId,
  getSelectedMessageIds,
} from '../../slices/uiState/uiStateSelector';
import copyTextToClipboard from '../../slices/device/deviceThunks/textToClipboard';

const HeaderMenu = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedMessageId = useSelector(getSelectedMessageId);
  const selectedMessageIds = useSelector(getSelectedMessageIds);
  const onlyOneMessageSelected = useSelector(getIsOnlyOneMessageSelected);
  const selectedMessagesLength = useSelector(getSelectedMessageLength);
  const allMessagesBody = useSelector(getSelectedMessagesBody(selectedMessageIds));
  const favoriteIds = useSelector(getSelectedMessagesFavoriteIds(selectedMessageIds));
  const areAllFavorites = useSelector(getSelectedMessagesAreAllFavorites(selectedMessageIds));
  const canReply = useSelector(getMessageCanReply(selectedChannelId, selectedMessageId));
  const canForwardAll = useSelector(getSelectedMessagesCanForward(
    selectedChannelId,
    selectedMessageIds,
  ));
  const canDeleteAll = useSelector(getSelectedMessagesCanDelete(
    selectedChannelId,
    selectedMessageIds,
  ));
  const canFavoriteAll = useSelector(getSelectedMessagesCanFavorite(
    selectedChannelId,
    selectedMessageIds,
  ));
  const canCopyAll = useSelector(getSelectedMessagesCanCopy(selectedChannelId, selectedMessageIds));
  const canReportAll = useSelector(getSelectedMessagesCanReport(selectedMessageIds));

  const replyToMessage = useCallback(() => {
    dispatch(channelInputReplyMessageStaged(
      { channelId: selectedChannelId, messageId: selectedMessageId },
    ));
    dispatch(resetSelectedMessageIds());
  }, [selectedChannelId, selectedMessageId, dispatch]);

  const onForwardMessage = () => {
    dispatch(channelInputForwardMessagesStaged(
      { channelId: selectedChannelId, messageIds: selectedMessageIds },
    ));
    navigate(CHAT_ROUTES.MESSAGE_FORWARD(selectedChannelId));
    dispatch(resetSelectedMessageIds());
  };

  const onDeleteMessage = useCallback(() => {
    selectedMessageIds.forEach((messageId) => {
      dispatch(deleteMessage({ messageId }));
    });
    if (favoriteIds.length > 0) {
      favoriteIds.forEach((favoriteMessageId) => {
        dispatch(removeFavorite({ channelId: selectedChannelId, favoriteId: favoriteMessageId }));
      });
    }
    dispatch(resetSelectedMessageIds());
  }, [selectedMessageIds,
    favoriteIds,
    selectedChannelId,
    dispatch,
  ]);

  const onCopyMessage = useCallback(() => {
    dispatch(copyTextToClipboard({ text: allMessagesBody }));
    dispatch(resetSelectedMessageIds());
  }, [dispatch, allMessagesBody]);

  const onToggleFavorite = useCallback(() => {
    if (!areAllFavorites) {
      selectedMessageIds.forEach((messageId) => {
        dispatch(addFavorite({
          channelId: selectedChannelId,
          messageId,
        }));
      });
    } else {
      favoriteIds.forEach((favoriteMessageId) => {
        dispatch(removeFavorite({ channelId: selectedChannelId, favoriteId: favoriteMessageId }));
      });
    }
    dispatch(resetSelectedMessageIds());
  }, [dispatch,
    favoriteIds,
    areAllFavorites,
    selectedChannelId,
    selectedMessageIds,
  ]);

  const onBack = useCallback(() => {
    dispatch(resetSelectedMessageIds());
  }, [dispatch]);

  const onReportMessage = useCallback(() => {
    dispatch(showReportMessageModal());
  }, [dispatch]);

  return (
    <div className={styles.headerMenu}>

      <button
        type="button"
        onClick={onBack}
        className={styles.backButton}
      >
        <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="back" />
        <span className={styles.numberSelectedMessages}>{selectedMessagesLength}</span>
      </button>

      <div className={styles.headerMenuButtons}>
        {canReportAll && (
        <button
          type="button"
          onClick={onReportMessage}
          className={styles.headerMenuButton}
        >
          <IconComponent Icon={ICON.REPORT} color={ICON_COLOR.ERROR} alt="report icon" />
        </button>
        )}

        {canDeleteAll && (
          <button
            type="button"
            onClick={onDeleteMessage}
            className={styles.headerMenuButton}
          >
            <IconComponent Icon={ICON.DELETE} color={ICON_COLOR.ERROR} alt="delete icon" />
          </button>
        )}
        {canCopyAll && (
          <button
            type="button"
            onClick={onCopyMessage}
            className={styles.headerMenuButton}
          >
            <IconComponent Icon={ICON.COPY} alt="copy icon" />
          </button>
        )}
        {canReply && onlyOneMessageSelected && (
          <button
            type="button"
            onClick={replyToMessage}
            className={styles.headerMenuButton}
          >
            <IconComponent Icon={ICON.FORWARD} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="reply icon" />
          </button>
        )}
        {canFavoriteAll && (
        <button
          type="button"
          onClick={onToggleFavorite}
          className={styles.headerMenuButton}
        >
          {areAllFavorites
            ? <IconComponent Icon={ICON.STAR_FILLED} alt="favorite icon" />
            : <IconComponent Icon={ICON.STAR} alt="favorite icon" />
          }
        </button>
        )}
        {canForwardAll && (
          <button
            type="button"
            onClick={onForwardMessage}
            className={styles.headerMenuButton}
          >
            <IconComponent Icon={ICON.FORWARD} alt="forward icon" />
          </button>
        )}
      </div>
    </div>
  );
};

export default HeaderMenu;
