import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import ThumbnailByFileType from '../ThumbnailByFileType/ThumbnailByFileType';

const FileThumbnail = ({
  loading, file, selected, onClick, onClose,
}) => {
  const handleClose = useCallback((e) => {
    onClose(file);
    e.stopPropagation();
  }, [file, onClose]);

  return (
    <div className={selected ? styles.thumbnailPreviewSelected : styles.thumbnailPreview}>
      <ThumbnailByFileType file={file} onClick={onClick} />
      <button
        disabled={loading}
        type="button"
        className={styles.thumbnailClose}
        onClick={handleClose}
      >
        <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.WHITE} size={ICON_SIZE.SMALL} />
      </button>
    </div>
  );
};

FileThumbnail.propTypes = {
  file: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FileThumbnail;
