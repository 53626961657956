import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { setATTPermission } from 'gcs-common/slices/attPermission/attPermissionSlice';
import { IS_IOS } from 'gcs-common/constants/deviceConstants';


const ATT_STATUS = {
  NOT_DETERMINED: 'notDetermined',
  RESTRICTED: 'restricted',
  DENIED: 'denied',
  AUTHORIZED: 'authorized',
};

const useATTPermission = async () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const requestPermission = async () => {
      let hasATTPermission = false;
      let { status } = await AppTrackingTransparency.getStatus();
      if (status === ATT_STATUS.NOT_DETERMINED) {
        const result = await AppTrackingTransparency.requestPermission();
        // eslint-disable-next-line prefer-destructuring
        status = result.status;
      }
      if (status === ATT_STATUS.AUTHORIZED) {
        hasATTPermission = true;
      }
      dispatch(setATTPermission({ attPermission: hasATTPermission }));
    };

    if (IS_IOS) {
      requestPermission();
    } else {
      dispatch(setATTPermission({ attPermission: true }));
    }

  }, [dispatch]);
};

export default useATTPermission;
