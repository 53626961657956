import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MuxPlayer from '@mux/mux-player-react';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import styles from './styles.module.scss';

const VideoStreamPlayer = ({ playbackId }) => {
  const currentUserId = useSelector(getCurrentUserId);

  return (
    <MuxPlayer
      className={styles.muxPlayer}
      streamType="on-demand"
      playbackId={playbackId}
      playsInline
      metadata={{
        viewer_user_id: currentUserId,
      }}
      autoPlay
    />
  );
};

export default VideoStreamPlayer;
VideoStreamPlayer.propTypes = {
  playbackId: PropTypes.string.isRequired,
};
