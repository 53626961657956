import moment from 'moment';

export const getProcessDateTag = (sortDate: string,
  lastSortDate: string | undefined): string | null => {
  const processDate = moment(sortDate);
  let tag = null;
  let lastTimeTag = null;
  if (lastSortDate) {
    lastTimeTag = moment(lastSortDate);
  }
  if (!lastTimeTag || !lastTimeTag.isSame(processDate, 'day')) {
    tag = moment(processDate).format('dddd, L');
  }
  return tag;
};
