
const event = (category: string, action: string) => {
  return {
    category,
    action,
  };
};

export const LEGACY_EVENTS = {
  DIRECT_MAIL_LINK: () => event('DIRECT_MAIL_LINK', 'OPENED'),
  TOGGLE_FINISHED_LIST: () => event('TODO', 'TOGGLE_FINISHED_LIST'),
  BEGIN_FOCUS: () => event('MESSAGE', 'BEGIN'),
  EDIT_DUE_TIME: () => event('TODO', 'EDIT_DUE_TIME'),
  EDIT_TASK: () => event('TODO', 'EDIT_TASK'),
  REARRANGE: () => event('TODO', 'REARRANGE'),
  ADD_FROM_MESSAGE: () => event('TODO', 'ADD_FROM_MESSAGE'),
  TODO_FORWARD_EMAIL: () => event('TODO', 'TODO_FORWARD_EMAIL'),
  MESSAGE_FORWARD_EMAIL: () => event('MESSAGE', 'MESSAGE_FORWARD_EMAIL'),
  LOGOUT: () => event('SETTINGS', 'LOGOUT_CLICKED'),
  PHONE_CLICKED: () => event('PHONE', 'CLICKED'),
  TOGGLE_SEND_ON_ENTER: () => event('CHAT_SETTINGS', 'TOGGLE_SEND_ON_ENTER'),
  SHOW_DELETE_CONFIRMATION: () => event('CHAT_SETTINGS', 'SHOW_DELETE_CONFIRMATION'),
  SHARE_MEDIA: () => event('MEDIA_SHARING', 'SELECT_MEDIA_TO_SHARE'),
  HIDE_CHANNEL: () => event('CHANNEL', 'HIDE_BUTTON_CLICKED'),
  ONLINE_PLUS_EXTERNAL_LINK_CLICKED: () => event('ONLINE_PLUS', 'EXTERNAL_LINK_CLICKED'),
  ONLINE_PLUS_EXTERNAL_LINK_IMPRESSION: () => event('ONLINE_PLUS', 'EXTERNAL_LINK_IMPRESSION'),
  MESSAGE_WITHOUT_OP_LINK_DISPLAYED: () => event('ONLINE_PLUS', 'MESSAGE_WITHOUT_OP_LINK_DISPLAYED'),
};
