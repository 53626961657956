import defaultConfig from '@redux-offline/redux-offline/lib/defaults';
import gccApiClient from 'gcs-common/clients/gccApiClient/gccApiClient';
import chatClient from 'gcs-common/clients/chatClient/chatClient';
import videoClient from 'gcs-common/clients/videoClient/videoClient';
import offlineEffect from 'gcs-common/offline/offlineEffect';
import offlineDiscard from 'gcs-common/offline/offlineDiscard';
import offlineQueue from 'gcs-common/offline/offlineQueue';
import offlineRetry from 'gcs-common/offline/offlineRetry';
import blobStorageClient from './blobStorageClient';

const initializeOfflineConfig = ({ persistenceConfig, getStore }) => ({
  ...defaultConfig,
  effect: offlineEffect({
    gccApiClient,
    chatClient,
    videoClient,
    blobStorageClient,
    getStore,
  }),
  discard: offlineDiscard({
    getStore,
  }),
  persistOptions: persistenceConfig,
  queue: offlineQueue,
  retry: offlineRetry,
});

export default initializeOfflineConfig;
