import { FetchProcessDetailsResponseData } from '../clients/api/entities/processes/processes-api-helpers';
import { pad } from './paddedNumberHelper';

const quantityUnitToString: { [key: string]: string } = {
  ST: 'Stück',
  M: 'm',
  SA: 'Satz',
  RO: 'Rolle',
  SET: 'Set',
  PA: 'Paar',
  DOS: 'Dose',
  QM: 'm²',
  BTL: 'Beutel',
  TUB: 'Tube',
  PAK: 'Packung',
  KTU: 'Kartusche',
  SCK: 'Sack',
  KG: 'kg',
  FL: 'Flasche',
  EIM: 'Eimer',
  PK: 'Paket',
  KT: 'Karton',
  KAN: 'Kanister',
  L: 'l',
};

export const convertQuantityToString = (quantityUnit: string) => {
  const quantityEnums = Object.keys(quantityUnitToString);
  if (quantityEnums.includes(quantityUnit)) {
    return quantityUnitToString[quantityUnit];
  }
  return quantityUnit;
};

export const convertPriceToString = (price: FetchProcessDetailsResponseData['items'][number]['GrossSalesPrice'], { Currency: currency, QuantityUnit: quantityUnit }: FetchProcessDetailsResponseData['items'][number]) => {
  if (!price || Number(price) === 0) {
    return undefined;
  }

  if (currency && price.includes(currency)) {
    return price;
  }
  const priceFormatted = new Intl.NumberFormat('de-de', {
    style: 'currency',
    currency,
  });

  return `${priceFormatted.format(Number(price))} Per 1 ${convertQuantityToString(quantityUnit || '')}`;
};

const processTypeToString: { [key: string]: string } = {
  KL: 'Rückgabe',
  LS: 'Liefer-Auftrag Strecke',
  LI: 'Liefer-Auftrag',
  LA: 'Abhol-Auftrag',
  KS: 'Kundenrücknahme Strecke',
  AN: 'Angebot',
  BS: 'Sonderbestellung Strecke',
  KB: 'Rückgabe',
  LK: 'Reparatur',
  LD: 'Kundendienst Anforderung',
  AB: 'Abrufauftrag',
  RU: 'Rückstand',
};

export const convertProcessTypeToString = (processType: string, processTypeDescription: string) => {
  const processTypeEnums = Object.keys(processTypeToString);
  if (processTypeEnums.includes(processType)) {
    return processTypeToString[processType];
  }
  return processTypeDescription;
};

const orderStatusToString: { [key: string]: string } = {
  7: 'Ware ist zugestellt.',
  4: 'Ware ist in Kommissionierung.',
  3: 'Ware ist in Kommissionierung.',
  F: 'Ware ist fakturiert.',
  9: 'Freigabe zur Fakturierung.',
  5: 'Ware liegt zur Abholung bereit',
};

export const convertOrderStatusToString = (
  orderStatus: string, orderStatusText: string, processType: string,
) => {

  if (['BB', 'BS'].includes(processType) && orderStatus === '5') {
    // SMOOT-4324 special case to not show the order status
    return '';
  }

  const statusTextEnums = Object.keys(orderStatusToString);
  if (statusTextEnums.includes(orderStatus)) {
    return orderStatusToString[orderStatus];
  }
  return orderStatusText;
};

export const getUniqueProcessNumber = (ProcessNumber: string, ProcessSuffix: string) => {
  return `${ProcessNumber}-${pad(ProcessSuffix)}`;
};

export const getUniqueProductNumber = (ArticleNumber: string | undefined, Id: string) => {
  return ArticleNumber + Id;
};
