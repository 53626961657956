export const MESSAGE_TYPE = {
  PROCESS: 'PROCESS',
  PRODUCT: 'PRODUCT',
  TEXT: 'TEXT',
  REPLY: 'REPLY',
  MEDIA: 'MEDIA',
  FORWARD: 'FORWARD',
  MEDIA_FROM_MAIL: 'MEDIA_FROM_MAIL',
  CRAFTSMAN_NOTIFICATION: 'CRAFTSMAN_NOTIFICATION',
  VIDEO: 'VIDEO',
  ORDER: 'ORDER',
};

export const MESSAGE_SUB_TYPE = {
  NONE: 'NONE',
};

export const SENT_FROM_TYPE = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
  CRAFTNOTE: 'CRAFTNOTE',
  SYSTEM: 'SYSTEM',
};

export const MESSAGE_SENT_FROM_TYPE = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
  CRAFTNOTE: 'CRAFTNOTE',
  SYSTEM: 'SYSTEM',
};

export type ButtonType = {
  type: string;
  buttonText: string;
  message: string;
  children: { [key: string]: ButtonType };
};

export const PRODUCT_BUTTON_TYPES = {
  ADD_TO_CHAT: {
    type: 'PRODUCT_LINK',
    buttonText: 'In Chat einfügen',
    message: 'Artikel %s: ',
    children: {},
  },
  RETURN: {
    type: 'PRODUCT_RETURN',
    buttonText: 'Rückgabewunsch',
    message: 'Ich möchte Artikel %s zurückgeben.',
    children: {},
  },
  FETCH: {
    type: 'PRODUCT_FETCH',
    buttonText: 'Produkt abrufen',
    message: 'Ich würde gerne Artikel %s abrufen.',
    children: {},
  },
  RECLAIM: {
    type: 'PRODUCT_RECLAIM',
    buttonText: 'Reklamation',
    message: '',
    children: {
      MISSING: {
        type: 'PRODUCT_RECLAIM_MISSING',
        buttonText: 'Fehlt in Lieferung',
        message: 'In meiner Lieferung fehlt der Artikel %s.',
        children: {},
      },
      WRONG: {
        type: 'PRODUCT_RECLAIM_WRONG',
        buttonText: 'Falscher Artikel',
        message: 'Anstatt dem Artikel %s wurde ein falscher Artikel geliefert.',
        children: {},
      },
      DAMAGED: {
        type: 'PRODUCT_RECLAIM_DAMAGED',
        buttonText: 'Artikel beschädigt',
        message: 'Der Artikel %s ist beschädigt.',
        children: {},
      },
      LESS: {
        type: 'PRODUCT_RECLAIM_LESS',
        buttonText: 'Zu wenig geliefert',
        message: 'Für den Artikel %s wurden weniger geliefert als bestellt.',
        children: {},
      },
      MORE: {
        type: 'PRODUCT_RECLAIM_MORE',
        buttonText: 'Zu viel geliefert',
        message: 'Für den Artikel %s wurden mehr geliefert als bestellt.',
        children: {},
      },
    },
  },
} as const;

export const PROCESS_BUTTON_TYPES = {
  ADD_TO_CHAT: {
    type: 'PROCESS_LINK',
    buttonText: 'In Chat einfügen',
    message: 'Vorgang %s-%s: ',
    children: {},
  },
  ADDRESS_WRONG: {
    type: 'PROCESS_ADDRESS_WRONG',
    buttonText: 'Falsche Lieferadresse',
    message: 'Für Vorgang %s-%s ist die Lieferadresse falsch.',
    children: {},
  },
  DELIVERY_MISSING: {
    type: 'PROCESS_DELIVERY_MISSING',
    buttonText: 'Fehlende Lieferung',
    message: 'Für Vorgang %s-%s fehlt die Lieferung.',
    children: {},
  },
} as const;

const PROCESS_TYPES_KEYS = ['LIEFERAUFTRAG', 'ANGEBOT', 'BESTELLVORGANG_AUF_ABRUF', 'ABHOLAUFTRAG', 'REPERATUR',
  'RUECKGABEWUNSCH', 'STRECKENLIEFERUNG', 'SONDERBESTELLUNG_STRECKE', 'RUECKSTAND', 'RUECKGABE', 'HANDLIEFERSCHEIN',
  'KUNDENDIENST_BESTELLUNG',
] as const;

type ProcessTypes = {
  [key in typeof PROCESS_TYPES_KEYS[number]]: {
    type: string,
    productButtons: typeof PRODUCT_BUTTON_TYPES[keyof typeof PRODUCT_BUTTON_TYPES][],
    processButtons: typeof PROCESS_BUTTON_TYPES[keyof typeof PROCESS_BUTTON_TYPES][],
  }
};

export const PROCESS_TYPES: ProcessTypes = {
  LIEFERAUFTRAG: {
    type: 'Lieferauftrag',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
      PRODUCT_BUTTON_TYPES.RETURN,
      PRODUCT_BUTTON_TYPES.RECLAIM,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
      PROCESS_BUTTON_TYPES.ADDRESS_WRONG,
      PROCESS_BUTTON_TYPES.DELIVERY_MISSING,
    ],
  },
  ANGEBOT: {
    type: 'Angebot',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
      PRODUCT_BUTTON_TYPES.FETCH,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  BESTELLVORGANG_AUF_ABRUF: {
    type: 'Bestellvorgang auf Abruf',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
      PRODUCT_BUTTON_TYPES.FETCH,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  ABHOLAUFTRAG: {
    type: 'Abholauftrag',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  REPERATUR: {
    type: 'Reparatur',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  RUECKGABEWUNSCH: {
    type: 'Rückgabewunsch',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  STRECKENLIEFERUNG: {
    type: 'Streckenlieferung',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  SONDERBESTELLUNG_STRECKE: {
    type: 'Sonderbestellung Strecke',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  RUECKSTAND: {
    type: 'Rückstand',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  RUECKGABE: {
    type: 'Rückgabe',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  HANDLIEFERSCHEIN: {
    type: 'Handlieferschein',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
  KUNDENDIENST_BESTELLUNG: {
    type: 'Kundendienst Bestellung',
    productButtons: [
      PRODUCT_BUTTON_TYPES.ADD_TO_CHAT,
    ],
    processButtons: [
      PROCESS_BUTTON_TYPES.ADD_TO_CHAT,
    ],
  },
} as const;
