import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { getGccApiClientInit } from 'gcs-common/slices/gccApiConnection/gccApiConnectionSelectors';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { shutdownChatClient } from 'gcs-common/slices/chatConnection/chatConnectionThunks/chatConnectionThunks';
import useTicketNotifications from 'gcs-common/hooks/useTicketNotifications';
import { IS_IOS } from 'gcs-common/constants/deviceConstants';
import {
  onChatConnected,
  onOffline,
  onOnline,
} from '../../slices/initialization/initializationThunks';
import { getIsAppInForeground } from '../../slices/uiState/uiStateSelectors';
import usePushNotifications from '../../customHooks/usePushNotifications';
import useSharedFiles from '../../customHooks/useSharedFiles';

const ChatRoot = () => {

  const currentUserId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();
  const isOnline = useSelector(getIsOnline);
  const isChatConnected = useSelector(getIsChatConnected);
  const isGccApiClientInitialized = useSelector(getGccApiClientInit);
  const isAppInForeground = useSelector(getIsAppInForeground);
  useSharedFiles();

  const notIOSOrInForeground = !IS_IOS || isAppInForeground;

  useEffect(() => {
    return () => {
      dispatch(shutdownChatClient());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isOnline
      // iOS has problems to keep the connection when it comes back
      // to the foreground so we need to reconnect
      && notIOSOrInForeground
      // check for currentUserId to make sure the first /get_current_user call has finished already
      // this is important because in the case of a new registration, the user is created by calling
      // /get_current_user
      && isGccApiClientInitialized
      && currentUserId
    ) {
      debugLogger('[ONLINE] IM ONLINE');
      dispatch(onOnline());
    } else {
      debugLogger('[ONLINE] IM OFFLINE');
      dispatch(onOffline());
    }
  }, [dispatch, isOnline, currentUserId, isGccApiClientInitialized, notIOSOrInForeground]);

  useEffect(() => {
    if (isChatConnected) {
      dispatch(onChatConnected());
    }
  }, [dispatch, isChatConnected]);

  useTicketNotifications({ refetchOnWindowFocus: true });
  usePushNotifications();

  return <Outlet />;

};

export default ChatRoot;
