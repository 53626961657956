import { createAsyncThunk } from '@reduxjs/toolkit';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import fetchFavorites from 'gcs-common/slices/favorites/favoritesThunks/fetchFavorites';
import fetchJoinedHouses from 'gcs-common/slices/houses/housesThunks/fetchJoinedHouses';
import {
  initClient,
  removeAllChatListeners, setupChatListenersAfterInit,
  setupChatListenersBeforeInit,
  shutdownChatClient,
} from 'gcs-common/slices/chatConnection/chatConnectionThunks/chatConnectionThunks';
import { initialChannelsLoadSucceeded } from 'gcs-common/slices/channels/channelsSlice';
import { captureMessage } from '@sentry/react';
import { getStateSize } from 'gcs-common/slices/users/usersSelectors';
import fetchCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUser';
import fetchCurrentUserChannels from 'gcs-common/slices/channels/channelsThunks/fetchCurrentUserChannels';
import { ALL_CHANNEL_TYPES } from 'gcs-common/helper/channelTypeHelper';
import fetchMessages from 'gcs-common/slices/messages/messagesThunks/fetchMessages';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { setLoadInitialDataSucceeded } from '../uiState/uiStateSlice';
import {
  resetMessageVisibilitySession,
} from '../messageVisibilityLoader/messageVisibilityLoaderSlice';

export const onOnline = createAsyncThunk(
  'initialization/onOnline',
  async (_, { dispatch, getState }) => {
    debugLogger('onOnline');
    await dispatch(fetchCurrentUserChannels({ channelTypes: ALL_CHANNEL_TYPES, skipTwilio: true }));
    const selectedChannelId = getSelectedChannelId(getState());
    if (selectedChannelId) {
      dispatch(fetchMessages({
        channelId: selectedChannelId,
        singlePage: true,
      }));
    }
    dispatch(initialChannelsLoadSucceeded());
    dispatch(setLoadInitialDataSucceeded());
    dispatch(fetchJoinedHouses());
    dispatch(initClient());
  },
);

export const onChatConnected = createAsyncThunk(
  'initialization/onChatConnected',
  async (_, { dispatch, getState }) => {
    // We need to remove the chat listeners to add them in the correct order again
    await dispatch(removeAllChatListeners());
    await dispatch(setupChatListenersBeforeInit());

    await dispatch(fetchCurrentUserChannels({ channelTypes: ALL_CHANNEL_TYPES }));

    // WARNING: SUPER IMPORTANT!
    // set the setupChatListenersAfterInit AFTER fetching of channels!
    // Otherwise, there will be
    // bazillion userUpdated-Events from twilio
    // for each user in each channel we load
    await Promise.all([
      dispatch(setupChatListenersAfterInit()),
      dispatch(fetchCurrentUser()),
      dispatch(fetchFavorites()),
    ]);

    const storeSize = getStateSize(getState());
    captureMessage('onChatConnected finished', {
      tags: { storeSize },
    });
  },
);

export const onOffline = () => async (dispatch) => {
  dispatch(resetMessageVisibilitySession());
  await dispatch(shutdownChatClient());
};
