import { Device } from '@capacitor/device';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';

export const trackCustomEvent = (type) => async (
  dispatch,
  getState,
  { gccApiClient },
) => {
  const deviceId = await Device.getId();
  const deviceInfo = await Device.getInfo();
  const currentUserId = getCurrentUserId(getState());
  gccApiClient.trackCustomEvent({ type, data: { deviceId, deviceInfo, userId: currentUserId } });
};
