// eslint-disable-next-line import/no-cycle
import { FetchETSTicketsResponseData } from '../../clients/api/entities/tickets/tickets-api-helpers';
import { Schema } from '../../../types/typeHelper';
import { ICON_COLOR, IconColorValue } from '../../constants/IconConstants';


export const TICKET_VIEWS = {
  FORM: 'form',
  DETAILS: 'details',
} as const;

/**
 * We configured the Elastic Beanstalk to have a maximun upload limit of: 20MB.
 * In: gcs-api/src/.platform/nginx/conf.d/client_max_body_size.conf
 * Using a bit less than that to accomodate for metadata overhead.
 */
export const MAX_TICKET_ATTACHMENT_SIZE = 19.996 * 1024 * 1024;

export const DELETE_NOTIFICATION_AFTER_SECONDS = 0;

export type TicketStati = {
  [key in keyof FetchETSTicketsResponseData['status']]: {
    title: string;
    iconColor?: IconColorValue;
    backgroundColor: string;
    foregroundColor: string;
  }
};

export const TICKET_STATI: TicketStati = {
  sent: {
    title: 'Gesendet',
    iconColor: ICON_COLOR.BLUE,
    backgroundColor: 'bg-blue-4',
    foregroundColor: 'text-blue-10',
  },
  declined: {
    title: 'Rückmeldung erforderlich',
    iconColor: ICON_COLOR.MUSTARD,
    backgroundColor: 'bg-yellow-4',
    foregroundColor: 'bg-yellow-12',
  },
  in_progress: {
    title: 'In Arbeit',
    iconColor: ICON_COLOR.BLUE,
    backgroundColor: 'bg-blue-4',
    foregroundColor: 'text-blue-10',
  },
  wont_do: {
    title: 'Ersatzteil nicht verfügbar',
    iconColor: ICON_COLOR.LIGHT_RED,
    backgroundColor: 'bg-red-4',
    foregroundColor: 'text-red-11',
  },
  done: {
    title: 'Erledigt',
    iconColor: ICON_COLOR.SUCCESS,
    backgroundColor: 'bg-green-4',
    foregroundColor: 'text-green-11',
  },
};

type TicketInputChannels = {
  [key in Schema<'EtsInputChannelEnum'>]: {
    key: Schema<'EtsInputChannelEnum'>;
    label: string;
    hidden?: boolean;
  };
};

export const TICKET_INPUT_CHANNELS: TicketInputChannels = {
  email: {
    key: 'email',
    label: 'E-Mail',
  },
  phone: {
    key: 'phone',
    label: 'Telefon',
  },
  chat: {
    key: 'chat',
    label: 'BEEM',
  },
  craftsman: {
    key: 'craftsman',
    label: 'Fachhandwerker',
    /**
     * This value is automatically set in the craftsman app
     * and shouldn't be selected by an agent.
     */
    hidden: true,
  },
};
