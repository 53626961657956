import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getRehydrationFinished } from 'gcs-common/slices/persistence/persistenceSelectors';
import { SplashScreen } from '@capacitor/splash-screen';
import useTheme from 'gcs-common/hooks/useTheme';
import { getUsercentricsId } from 'gcs-common/slices/theme/themeSelectors';
import useUsercentrics from 'gcs-common/hooks/useUsercentrics';
import useTrackLocationChange from 'gcs-common/hooks/useTrackLocationChange';
import DebugUserSwitcher from 'gcs-common/components/DebugUserSwitcher/DebugUserSwitcher';
import { globalInit } from '../../slices/initSlice/initThunks';
import useBackButtonHandler from '../../customHooks/useBackButtonHandler';
import useStatusBarColorByRoute from '../../customHooks/useStatusBarColorByRoute';
import useKeyboardListener from '../../customHooks/useKeyboardListener';
import useATTPermission from '../../customHooks/useATTPermission';
import useSafeAreaInsets from '../../customHooks/useSafeAreaInsets';
import ChangeTheme from '../ChangeTheme/ChangeTheme';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import AppMustUpdateDialog from '../AppMustUpdateDialog/AppMustUpdateDialog';
import hideFilePickerFromDom from '../../slices/uiState/uiStateThunks/hideFilePickerFromDom';
import hideKeyboard from '../../slices/device/deviceThunks/hideKeyboard';
import styles from './styles.module.scss';

function App() {
  useBackButtonHandler();
  useStatusBarColorByRoute();
  useSafeAreaInsets();
  useKeyboardListener();

  const usercentricsId = useSelector(getUsercentricsId);

  useUsercentrics(usercentricsId);
  useTrackLocationChange();
  useATTPermission();

  useTheme();

  const dispatch = useDispatch();

  const rehydrationFinished = useSelector(getRehydrationFinished);

  useEffect(() => {
    if (rehydrationFinished) {
      dispatch(globalInit());
      // Prevent showing the spinner
      // when SplashScreen.launchShowDuration is not over
      setTimeout(() => {
        SplashScreen.hide({ fadeOutDuration: 300 });
      }, 500);
    }
  }, [dispatch, rehydrationFinished]);


  const handleGlobalAppClick = (event) => {
    dispatch(hideKeyboard({ targetDomElement: event.target }));
    dispatch(hideFilePickerFromDom({ targetDomElement: event.target }));
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={handleGlobalAppClick} className={styles.app}>
      <DebugUserSwitcher />
      <ChangeTheme />
      <Outlet />
      <ErrorDialog />
      <AppMustUpdateDialog />
    </div>
  );

}

export default App;
