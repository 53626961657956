import {
  useCallback,
  useEffect,
} from 'react';
import Sidebar from 'react-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstChannelId } from 'gcs-common/slices/channelList/channelListSelectors';
import { getVideoFullscreenId, getVideoMessageId } from 'gcs-common/slices/uiState/uiStateSelectors';
import useChannelInitialization from 'gcs-common/hooks/useChannelInitialization';
import { getFileShareLoading } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import { Outlet } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import SideMenu from '../SideMenu/SideMenu';
import { setSettingsMenuClosed, setSideMenuClosed, setSideMenuOpen } from '../../slices/uiState/uiStateSlice';
import {
  getIsSettingsMenuOpen,
  getIsSideMenuOpen,
} from '../../slices/uiState/uiStateSelectors';
import NoAccountModal from '../NoAccountModal/NoAccountModal';
import OpErrorModal from '../OpErrorModal/OpErrorModal';
import {
  getInitialDataLoadSucceeded,
} from '../../slices/uiState/uiStateSelector';
import Settings from '../Settings/Settings';
import HouseSwitcherActionSheet from '../HouseSwitcherActionSheet/HouseSwitcherActionSheet';
import NoChannels from '../NoChannels/NoChannels';
import VideoFullscreen from '../VideoFullscreen/VideoFullscreen';
import ChatIndex from '../Chat/ChatIndex/ChatIndex';

const ChatWrapper = () => {

  const dispatch = useDispatch();
  const initialDataLoadSucceeded = useSelector(getInitialDataLoadSucceeded);
  const sideMenuOpen = useSelector(getIsSideMenuOpen);
  const settingsMenuOpen = useSelector(getIsSettingsMenuOpen);
  const firstChannelId = useSelector(getFirstChannelId);
  const hasChannels = !!firstChannelId;
  const fileShareLoading = useSelector(getFileShareLoading);
  const videoFullScreenId = useSelector(getVideoFullscreenId);
  const videoMessageId = useSelector(getVideoMessageId);

  useChannelInitialization();

  useEffect(() => {
    if (initialDataLoadSucceeded && !hasChannels) {
      dispatch(setSideMenuClosed());
    }
  }, [dispatch, hasChannels, initialDataLoadSucceeded]);

  const handleSetOpen = useCallback((open) => {
    if (open) {
      dispatch(setSideMenuOpen());
    } else {
      dispatch(setSideMenuClosed());
      dispatch(setSettingsMenuClosed());
    }
  }, [dispatch]);

  if (fileShareLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingIndicator />
        Lädt...
      </div>
    );
  }

  if (!initialDataLoadSucceeded) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingIndicator />
        Lädt Channel...
      </div>
    );
  }

  return (
    <>
      <Sidebar
        sidebar={settingsMenuOpen ? <Settings /> : <SideMenu />}
        open={sideMenuOpen}
        onSetOpen={handleSetOpen}
        sidebarClassName={styles.sideBar}
        overlayClassName={styles.sideBarOverlay}
      >
        {hasChannels ? <ChatIndex /> : <NoChannels />}
        <NoAccountModal />
        <OpErrorModal />
      </Sidebar>
      <HouseSwitcherActionSheet />
      {videoFullScreenId && (
      <VideoFullscreen playbackId={videoFullScreenId} messageId={videoMessageId} />
      )}
      <Outlet />
    </>
  );
};

ChatWrapper.propTypes = {};

export default ChatWrapper;
