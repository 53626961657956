import { Badge, BadgeProps } from 'gcs-common/design-system/ui/badge';
import { ONLINE_STATUS } from 'gcs-common/helper/userStatusHelper';
import { UserStatusType } from 'gcs-common/types/userStatus';


function calculateVariant(userStatus: UserStatusType): { badgeVariant: BadgeProps['variant']; badgeText: string, label?: string } {
  const now = new Date();
  const timestamp = userStatus.updatedAt ? new Date(userStatus.updatedAt) : new Date(0);
  const diffInSeconds = Math.floor((now.getTime() - timestamp.getTime()) / 1000);

  const secondsInDay = 86400;
  const secondsInWeek = secondsInDay * 7;
  const secondsInMonth = secondsInDay * 30;

  if (userStatus.type === ONLINE_STATUS.ONLINE) {
    return {
      badgeVariant: 'solid_success',
      badgeText: 'Online',
    };
  } if (userStatus.type === ONLINE_STATUS.IDLE) {
    return {
      badgeVariant: 'solid_success',
      badgeText: 'In Kürze verfügbar',
    };
  }

  if (diffInSeconds < secondsInDay) {
    return {
      badgeVariant: 'soft_success',
      badgeText: 'Heute',
      label: 'Zuletzt online',
    };
  } if (diffInSeconds < secondsInWeek) {
    const days = Math.floor(diffInSeconds / secondsInDay);
    const badgeText = days === 1 ? `vor ${days} Tag` : `vor ${days} Tagen`;
    return {
      badgeVariant: 'soft_warning',
      badgeText,
      label: 'Zuletzt online',
    };
  }
  if (diffInSeconds < secondsInMonth) {
    const weeks = Math.floor(diffInSeconds / secondsInWeek);
    const badgeText = weeks === 1 ? `vor ${weeks} Woche` : `vor ${weeks} Wochen`;
    return {
      badgeVariant: 'soft_error',
      badgeText,
      label: 'Zuletzt online',
    };
  }
  const badgeText = 'vor über 1 Monat';
  return {
    badgeVariant: 'soft_error',
    badgeText,
    label: 'Zuletzt online',
  };
}

export function LastOnlineStatusBadge(
  { userStatus, hideLabel }: { userStatus?: UserStatusType, hideLabel?: boolean },
) {

  if (!userStatus || userStatus.type === ONLINE_STATUS.UNKNOWN) {
    return null;
  }

  const { badgeVariant, badgeText, label } = calculateVariant(userStatus);

  return (
    <div data-testid="online-status-badge" className="flex items-center gap-2">
      {label && !hideLabel && <div className="text-sm text-neutral-11">{label}</div>}
      <Badge variant={badgeVariant}>
        {badgeText}
      </Badge>
    </div>
  );
}
