import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { calculateSalesSpaceChannelGroupingName } from '../../../helper/channelHelpers';
// eslint-disable-next-line import/no-cycle
import { getDisplayChannelsIds } from '../../channelList/channelListSelectors/getDisplayChannelsIds';
import { sortSalesSpaceChannels } from '../../channelList/channelSorter';
import { getChannels } from '../../channels/channelsSelectors';
import { getChannelNotificationDates } from '../../tickets/ticketsSelectors';
import { getSelectedSalesSpaceChannelsGrouping } from './getSelectedSalesSpaceChannelsGrouping';
import { getSelectedSalesSpaceChannelsInGroupSort } from './getSelectedSalesSpaceChannelsInGroupSort';

export const getSalesSpaceChannelIdsByGrouping = createSelector(
  createStructuredSelector({
    displayChannelsIds: getDisplayChannelsIds,
    selectedSalesSpaceChannelsGrouping: getSelectedSalesSpaceChannelsGrouping,
    channels: getChannels,
    selectedSalesSpaceChannelsInGroupSort: getSelectedSalesSpaceChannelsInGroupSort,
    channelNotificationDates: getChannelNotificationDates,
  }),
  ({
    displayChannelsIds,
    selectedSalesSpaceChannelsGrouping,
    channels,
    selectedSalesSpaceChannelsInGroupSort,
    channelNotificationDates,
  }) => {
    if (!displayChannelsIds) {
      return null;
    }
    // group by selectedSalesSpaceChannelsGrouping
    const unsortedGroupedChannelIds = displayChannelsIds.reduce((red, channelId) => {
      const channelNotificationDate = channelNotificationDates[channelId] || null;
      const channelGroupingName = calculateSalesSpaceChannelGroupingName(
        selectedSalesSpaceChannelsGrouping,
        channels[channelId],
        channelNotificationDate,
      );
      if (!red[channelGroupingName]) {
        // eslint-disable-next-line no-param-reassign
        red[channelGroupingName] = [];
      }
      red[channelGroupingName].push(channelId);
      return red;
    }, {});

    // sort channels in each group
    return Object.fromEntries(
      Object.entries(unsortedGroupedChannelIds).map(([group, channelIds]) => [group,
        sortSalesSpaceChannels({
          sortingDirection: selectedSalesSpaceChannelsInGroupSort,
          channelIds,
          channels,
          channelNotificationDates,
        })]),
    );
  },
);
