import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import { getChannelInputStagedForwardMessageIds } from 'gcs-common/slices/messageInput/messageInputSelector';
import { getAreAllOtherUsersOfflineForChannel } from 'gcs-common/slices/channel/channelSelectors';
import { fetchAgentAvailabilityMessage } from 'gcs-common/slices/currentCraftsmanUser/currentCraftsmanUserThunks/fetchAgentAvailabilityMessage';
import {
  getAgentAvailabilityMessageForChannel,
} from 'gcs-common/slices/currentCraftsmanUser/currentCraftsmanUserSelectors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import styles from './styles.module.scss';

function AgentAvailabilityBanner() {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const isForwardMessageStaged = useSelector(
    getChannelInputStagedForwardMessageIds(selectedChannelId),
  );
  const otherUsersOffline = useSelector(getAreAllOtherUsersOfflineForChannel(selectedChannelId));
  const agentAvailabilityMessage = useSelector(
    getAgentAvailabilityMessageForChannel(selectedChannelId),
  );
  const isOnline = useSelector(getIsOnline);

  const dispatch = useDispatch();

  useEffect(() => {
    if (otherUsersOffline && selectedChannelId) {
      dispatch(fetchAgentAvailabilityMessage({
        channelId: selectedChannelId,
      }));
    }
  }, [dispatch, otherUsersOffline, selectedChannelId]);

  if (
    isOnline
    && otherUsersOffline
    && agentAvailabilityMessage
    && (!isForwardMessageStaged || isForwardMessageStaged.length === 0)
  ) {
    return (
      <div className={styles.agentAvailability}>
        <div>{agentAvailabilityMessage}</div>
      </div>
    );
  }

  return null;
}

export default AgentAvailabilityBanner;
