import favicon from './assets/favicon.svg';
import opIcon from './assets/gut-op-icon.svg';
import simpleLogo from './assets/simple_logo.svg';
import logo from './assets/logo.svg';
import chatLogo from './assets/chat_logo.svg';
import privacy from '../commonAssets/beemPrivacyText';
import terms from '../commonAssets/gutTermsText';
import imprint from './assets/imprintText';
import deleteAccountText from '../commonAssets/deleteAccountText';

const config = {
  title: 'G.U.T. BEEM',
  description: 'Mit BEEM können Fachhandwerker Fragen rechtssicher an ihren persönlichen G.U.T.-Ansprechpartner stellen – jederzeit, schnell und einfach!',
  identifier: 'gut',
  appName: 'G.U.T.',
  defaultBrand: 'G.U.T.',
  url: 'gutbeem.de',
  apiBaseUrl: 'https://api.gutbeem.de',
  helpUrl: 'https://hilfe.getbeem.de/',
  iosStoreUrl: 'https://apps.apple.com/us/app/g-u-t-beem/id1612672837',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=de.gutbeem',
  supportEmail: 'support@getbeem.de',
  favicon,
  logo,
  chatLogo,
  simpleLogo,
  packageId: 'de.gutbeem',
  privacyText: privacy,
  termsText: terms,
  usercentricsId: 'GYP0_DSsY',
  imprintText: imprint,
  deleteAccountText,
  welcomeText: 'Willkommen bei G.U.T. BEEM!',
  identifierPlaceholder: 'Mobilnummer oder E-Mail Adresse',
  mainText: 'Bitte geben Sie Ihre Mobilnummer oder E-Mail Adresse ein, um sich anzumelden.',
  opIcon,
};

export default config;
