import { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useQueryParams } from 'gcs-common/design-system/hooks/use-query-params';
import { CODE_ROUTES, LOGIN_ROUTES } from 'gcs-common/constants/routes';
import IconButton from 'gcs-common/design-system/ui/icon-button';
import { ArrowBackIos } from '@project-lary/react-material-symbols';


const UserCodeMobileWrapper = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { queryParams } = useQueryParams(['registration', 'email', 'phone']);

  const onGoBack = () => {
    const { pathname } = location;
    const { registration, phone } = queryParams;

    if (pathname === CODE_ROUTES.SUBMIT_CODE) {

      const selectedMethod = phone ? 'phone' : 'email'; // email is the default method.
      const isRegistration = registration === 'true' ? '&registration=true' : '';

      navigate(`${CODE_ROUTES.REQUEST_CODE}?method=${selectedMethod}${isRegistration}`);
    } else if (pathname === CODE_ROUTES.REQUEST_CODE) {
      navigate(LOGIN_ROUTES.LOGIN);
    }
  };

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex justify-start px-4 pt-1">
        <IconButton
          Icon={ArrowBackIos}
          onClick={onGoBack}
          className="flex cursor-pointer rounded-md bg-white"
          size="l"
        />

      </div>
      <div className="flex grow flex-col px-6 text-center">
        {children}
      </div>
    </div>
  );
};

export default UserCodeMobileWrapper;
