import { useCurrentlyRenderedData } from '@virtuoso.dev/message-list';

export const useCurrentlyRenderedVirtuosoData = ({ messageId }) => {
  const data = useCurrentlyRenderedData();

  if (messageId) {
    return data.find(d => d.id === messageId);
  }

  return data;
};
