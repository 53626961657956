import { useEffect } from 'react';
import { ShareExtension } from 'capacitor-share-extension';
import { useDispatch, useSelector } from 'react-redux';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { getIsFilesShareWasClosed, hasSharedFiles } from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSelector';
import {
  fileShareLoadingStarted,
  filesShareReopened,
  sharedFilesAdded,
  fileShareLoadSucceeded,
} from 'gcs-common/slices/fileToAppSharing/fileToAppSharingSlice';
import { useNavigate } from 'react-router';
import {
  decodeObjectValues,
  replaceAsteriskToExtensionInFileType,
  replaceWithAllowedFileType,
} from 'gcs-common/helper/fileHelper';
import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';
import { captureException } from '@sentry/react';
import { compressImages } from 'gcs-common/helper/capacitorImageHelper';
import { HAS_SHARE_EXTENSION } from 'gcs-common/constants/deviceConstants';

const convertFiletype = (file) => {
  let mimeType = replaceAsteriskToExtensionInFileType(file.title, file.type);
  mimeType = replaceWithAllowedFileType(mimeType);
  return {
    ...file,
    mimeType,
    type: mimeType,
  };
};

const convertWebPath = (file) => {
  return {
    ...file,
    webPath: Capacitor.convertFileSrc(file.url),
  };
};

const addFileName = (file) => {
  return {
    ...file,
    name: file.title,
  };
};

export const readFileSizes = async (files) => {
  const promises = files
    .map(async (file) => {
      const { size } = await Filesystem.stat({ path: file.url });
      return {
        ...file,
        size,
      };
    });

  try {
    return await Promise.all(promises);
  } catch (e) {
    captureException(e);
    return files;
  }
};

const useSharedFiles = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFilesShareWasClosed = useSelector(getIsFilesShareWasClosed);
  const sharedFiles = useSelector(hasSharedFiles);

  const getSharedFilesDataForClosedApp = async () => {
    // only add the files to the store if the store is empty.
    if (!isFilesShareWasClosed && !sharedFiles) {
      const filesData = await ShareExtension.checkSendIntentReceived();
      const { payload } = filesData;
      if (payload?.length) {
        let files = payload
          .map(f => decodeObjectValues(f))
          .map(f => convertFiletype(f))
          .map(f => convertWebPath(f))
          .map(f => addFileName(f));

        files = await compressImages(files);
        // read file sizes after potential compression
        files = await readFileSizes(files);
        dispatch(sharedFilesAdded({ sharedFiles: files }));
        dispatch(fileShareLoadSucceeded());
      }
    }
  };

  const getSharedFilesDataForOpenedApp = () => {
    dispatch(fileShareLoadingStarted());
    dispatch(filesShareReopened());
    getSharedFilesDataForClosedApp();
  };

  const navigateToShareFilesPage = () => {
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.SHARE_MEDIA()));
    navigate('/shareFiles');
  };

  useEffect(() => {
    if (HAS_SHARE_EXTENSION) {
      // this listener starts applying once the app is open and in the background.
      window.addEventListener('sendIntentReceived', getSharedFilesDataForOpenedApp);
      if (sharedFiles) {
        navigateToShareFilesPage();
      } else {
        // therefore when the app is close we need to get the files.
        // and we only call it if the store is empty
        getSharedFilesDataForClosedApp();
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Media sharing is not available on this device');
    }
    return () => window.removeEventListener('sendIntentReceived', getSharedFilesDataForOpenedApp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedFiles, isFilesShareWasClosed]);

};

export default useSharedFiles;
