import {
  getMediaMessageContentType,
  getMessageMediaFilename,
} from 'gcs-common/slices/messages/messageSelector';
import { getLocalMediaKey } from '../mediaHelper';

const addShareableFileName = (dataUrl, fileName) => {
  return `df:${fileName};${dataUrl}`;
};

const toDataUrl = (base64, type) => {
  return `data:${type};base64,${base64}`;
};

const shareImage = ({ messageId }) => async (dispatch, getState, {
  blobStorageClient,
  chatClient,
}) => {
  const localMediaKey = await getLocalMediaKey(messageId, {
    getState,
    blobStorageClient,
    chatClient,
  });

  const fileName = getMessageMediaFilename(messageId)(getState());
  const contentType = getMediaMessageContentType(messageId)(getState());

  // Note: its also possible to share files via url, but cordova-plugin-file
  // is currently not saving fileName/contentType, which are needed
  // for sharing so we currently use base64 as transport medium and inject
  // the fileName as needed for the socialSharing plugin
  const base64 = await blobStorageClient.getObjectBase64(localMediaKey);

  const dataUrl = toDataUrl(base64, contentType);
  const shareableBase64 = addShareableFileName(dataUrl, fileName || '');

  const options = {
    // If we set "chooserTitle", for me the native sharedialog gets displayed.
    // Otherwise some other dialog...
    chooserTitle: 'App auswählen', // Android only, you can override the default share sheet title
    files: [shareableBase64], // an array of filenames either locally or remotely
  };

  window.plugins.socialsharing.shareWithOptions(options);
};

export default shareImage;
