import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getForwardChannelIds,
  getForwardSearchInput,
} from 'gcs-common/slices/channelList/channelListSelectors';
import styles from './styles.module.scss';
import ForwardChannelListItem from '../ForwardChannelListItem/ForwardChannelListItem';
import ForwardChannelListHeader from '../ForwardChannelListHeader/ForwardChannelListHeader';
import { ForwardSearchInput } from '../ForwardSearchInput/ForwardSearchInput';

const ForwardChannelList = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const forwardChannelIds = useSelector(getForwardChannelIds(selectedChannelId));
  const forwardSearchInput = useSelector(getForwardSearchInput);

  return (
    <>
      <ForwardChannelListHeader />
      <ForwardSearchInput />
      {(!forwardSearchInput || forwardSearchInput.length === 0) && (
        <div className={styles.title}>
          LETZTE CHATS
        </div>
      )}
      <div className={styles.listWrapper}>
        <Virtuoso
          data={forwardChannelIds}
          itemContent={(_, channelId) => (
            <ForwardChannelListItem
              channelId={channelId}
            />
          )}
        />
      </div>
    </>
  );
};

ForwardChannelList.propTypes = {};

export default ForwardChannelList;
