import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import loadingPlaceholder from 'gcs-common/img/loading_placeholder.svg';
import {
  getAwaitingDownloadFile,
  getAwaitingLoadLocalFileUrl,
  getHasLocalUrl,
} from '../../slices/media/mediaSelectors';
import loadLocalFileUrl from '../../slices/media/mediaThunks/loadLocalFileUrl';
import downloadFile from '../../slices/media/mediaThunks/downloadFile';
import LocalImage from '../LocalImage/LocalImage';

const PersistedImage = ({
  remoteUrl, alt, placeholder = loadingPlaceholder, className, ...props
}) => {
  const hasLocalUrl = useSelector(getHasLocalUrl(remoteUrl));
  const isOnline = useSelector(getIsOnline);
  const awaitingDownload = useSelector(getAwaitingDownloadFile(remoteUrl));
  const awaitingLoadLocalFileUrl = useSelector(getAwaitingLoadLocalFileUrl(remoteUrl));
  const loading = !hasLocalUrl && (awaitingLoadLocalFileUrl || awaitingDownload);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasLocalUrl) {
      dispatch(loadLocalFileUrl({ key: remoteUrl }));
    }
  }, [hasLocalUrl, remoteUrl, dispatch]);

  useEffect(() => {
    if (!awaitingLoadLocalFileUrl && !hasLocalUrl && isOnline) {
      dispatch(downloadFile({ key: remoteUrl, remoteUrl }));
    }
  }, [awaitingLoadLocalFileUrl, remoteUrl, dispatch, hasLocalUrl, isOnline]);

  if (hasLocalUrl) {
    return (
      <LocalImage
        localKey={remoteUrl}
        alt={alt}
        className={className}
        {...props}
      />
    );
  }

  if (loading) {
    return (
      <img src={placeholder} alt="Lädt..." className={className} />
    );
  }
  // this is the error case. currently the same default as in loading is shown
  return (
    <img src={placeholder} alt="Fehler" className={className} />
  );
};

PersistedImage.propTypes = {
  remoteUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default PersistedImage;
