import { useEffect } from 'react';
import { captureMessage } from '@sentry/react';
import { loadScript } from '../helper/loadScript';

// these ids are coming from the usercentrics dashboard
export const USERCENTRICS_IDS = {
  USERLANE: '5wohJ1Vz9',
  GOOGLE_ANALTICS: 'HkocEodjb7',
  GOOGLE_TAG_MANAGER: 'BJ59EidsWQ',
  AWS: 'J39GyuWQq',
  FIREBASE: 'uNl9XGnZC',
  AUTH0: '7mOrpUraa',
};

const usercentricsEnabled = import.meta.env.VITE_USERCENTRICS_ENABLED === 'true';
const usercentricsVersion = import.meta.env.VITE_USERCENTRICS_VERSION || '';

const loadUsercentrics = async (usercentricsId) => {
  if (usercentricsEnabled && usercentricsId) {
    await loadScript('https://app.usercentrics.eu/browser-ui/3.31.0/loader.js', {
      scriptDataAttributes: {
        settingsId: usercentricsId,
        language: 'de',
        version: usercentricsVersion,
      },
      scriptId: 'usercentrics-cmp',
    },
    'head');

    if (!window.uc) {
      captureMessage('Usercentrics did not load');
      return;
    }

    // does not reload on "save" unfortunately
    window.uc.reloadOnOptIn(USERCENTRICS_IDS.GOOGLE_ANALTICS);
    window.uc.reloadOnOptOut(USERCENTRICS_IDS.GOOGLE_ANALTICS);
    window.uc.reloadOnOptIn(USERCENTRICS_IDS.USERLANE);
    window.uc.reloadOnOptOut(USERCENTRICS_IDS.USERLANE);
    window.uc.reloadOnOptIn(USERCENTRICS_IDS.GOOGLE_TAG_MANAGER);
    window.uc.reloadOnOptOut(USERCENTRICS_IDS.GOOGLE_TAG_MANAGER);

    window.uc.deactivateBlocking([
      USERCENTRICS_IDS.AWS,
      USERCENTRICS_IDS.FIREBASE,
      USERCENTRICS_IDS.AUTH0,
    ]);
  }
};

const useUsercentrics = (usercentricsId) => {
  useEffect(() => {
    (async () => {
      await loadUsercentrics(usercentricsId);
    })();
  }, [usercentricsId]);
};

export const showUsercentricsSettings = () => {
  if (window.UC_UI) {
    window.UC_UI.showSecondLayer();
  } else {
    // eslint-disable-next-line no-console
    console.info('Usercentrics not loaded. This is normal for a local dev environment, otherwise it should not happen');
  }
};

export const getUsercentricsUserGaveConsent = (id) => {
  if (window.UC_UI) {
    return window.UC_UI.getServicesBaseInfo()
      .filter(data => data.consent.status === true)
      .filter(data => data.id === id).length > 0;
  }
  return false;
};

export default useUsercentrics;
