import { ComponentProps } from 'react';

interface ButtonProps extends ComponentProps<'button'> {

}

const Button = (props: ButtonProps) => {
  const {
    children,
    type = 'button',
    className = '',
    ...otherProps
  } = props;
  const currentClassName = className?.includes('FilterTypeButton') ? className : `gc-btn-1 ${className}`;
  return (
  // eslint-disable-next-line react/button-has-type
    <button
        // eslint-disable-next-line react/button-has-type
      type={type}
      className={currentClassName}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
