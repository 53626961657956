
export default class BaseModel {
  constructor(id, createdAt, updatedAt, createdBy, updatedBy, rest = undefined) {
    this.id = id;
    this.createdAt = createdAt || rest?.created_at;
    this.updatedAt = updatedAt || rest?.updated_at;
    this.createdBy = createdBy || rest?.created_by;
    this.updatedBy = updatedBy || rest?.updated_by;
  }

  toPlain() {
    return Object.getOwnPropertyNames(this).reduce((a, b) => {
      // prevent that undefined values are serialized
      if (this[b] === undefined) {
        return a;
      }
      // eslint-disable-next-line no-param-reassign
      a[b] = this[b];
      return a;
    }, {});
  }

}
